import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SweetAlertService } from 'src/app/sweetalert.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @ViewChild('addAreaForm', { static: false }) addAreaFormControl: any;
  signUpForm: FormGroup;
  lastName: any;
  countryId: any;
  stateId: any;
  cityId: any;
  areaId: any;
  pinCode: any;
  address: any;
  occupation: any;
  identification: any;
  fssaiNo: any;
  feedback: any;
  countryList: any[] = [];
  stateList: any[] = [];
  cityList: any[] = [];
  areaList: any[] = [];
  newAreaName: any;
  CountryCode = '99';
  countryCodes: Array<any> = [];
  countryCodeReadOnly: boolean = false;
  otp: number = 0;
  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private sweetAlert: SweetAlertService,
    private router: Router,
  ) {
    this.signUpForm = new FormGroup({
      CustomerName: new FormControl('', Validators.required),
      // LastName: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
      Address: new FormControl('', Validators.required),
      Pincode: new FormControl('', Validators.required),
      CountryId: new FormControl('', Validators.required),
      CityId: new FormControl('', Validators.required),
      StateId: new FormControl('', Validators.required),
      AreaId: new FormControl('0', Validators.required),
      LoginId: new FormControl('', Validators.required),
      PromoCode: new FormControl('')
    });
    this.getCountryList();
  }

ngOnInit() {
  const otp_info = JSON.parse(localStorage.getItem('OTP_INFO') || '{}');
  this.countryCodeReadOnly = !!otp_info.CountryCode //? true: false;
  this.CountryCode = otp_info.CountryCode ?? '+91';
  this.otp = otp_info.OTP;
  otp_info.CountryId = 123 // set default country code
  this.signUpForm.patchValue(otp_info);
  
  

  this.commonService.fetchCountryCodes().subscribe((response: any) => {
    this.countryCodes = response.Data;
    // const object = {"Id":this.countryList[100].Id, "CommonDesc":this.countryList[100].CommonDesc}
    // console.log(object);
    // this.signUpForm.patchValue(object);
  });
}
  onSelectionCountryCode = (e: any) => {
    this.CountryCode = e.value;
  };

  // getCountryList = () => {
  //   this.apiService.getApi(API_ENDPOINTS.GET_COUNTRIES, {}, false).subscribe((res: any) => {
  //     if (res) {
  //       this.countryList = [...res.Data];
  //       this.stateList = [];
  //       this.cityList = [];
  //       this.areaList = [];
  //       this.stateId = null;
  //       this.cityId = null;
  //       this.areaId = null;
  //       this.countryId = 123;
  //       this.getStateList(123);
  //     }
  //   })
  // };

getCountryList = () => {
  this.apiService.getApi(API_ENDPOINTS.GET_COUNTRIES, {}, false).subscribe({
    next: (response: any) => {
      if (response && response.Data) {
        this.countryList = [...response.Data];
        this.stateList = [];
        this.cityList = [];
        this.areaList = [];
        this.stateId = null;
        this.cityId = null;
        this.areaId = null;
        this.countryId = 123;
        this.getStateList(this.countryId);

      }
    },
    error: (error: any) => {
      console.error('register.component.ts : 114 : getCountryList: ', error);
    },
    complete: () => {
      //console.log('API request completed');
    }
  });
};
  getStateList = (countryCode: any, item?: any) => {
    if (this.signUpForm.controls['CountryId'].value) {
      const url = `${API_ENDPOINTS.GET_STATES}${this.signUpForm.controls['CountryId'].value}`;
      this.apiService.getApi(url, {}, false).subscribe((res: any) => {
        if (res) {
          this.stateList = [...res.Data];
          this.cityList = [];
          if (item && Object.keys(item).length) {
            this.stateId = item.StateId;
            this.getCityList(this.stateId, item);
          }
        }
      });
    } else {
      this.stateList = [];
      this.cityList = [];
      this.areaList = [];
      this.stateId = null;
      this.cityId = null;
      this.areaId = null;
    }
  };

  getCityList(stateCode: any, item?: any) {
    if (this.signUpForm.controls['StateId'].value) {
      const url = `${API_ENDPOINTS.GET_CITIES}${this.signUpForm.controls['StateId'].value}`;
      this.apiService.getApi(url).subscribe((res: any) => {
        if (res.Code === 1000 && res?.Data) {
          this.cityList = [...res.Data];
          if (item && Object.keys(item).length) {
            this.cityId = Number(item.CityId);
            this.getAreaList(this.cityId, item);
          }
        }
      });
    } else {
      this.cityList = [];
      this.cityId = null;
      this.areaList = [];
      this.areaId = null;
    }
  };

  getAreaList(cityCode: any, item?: any) {
    cityCode = Number(this.signUpForm.controls['CityId'].value);
    if (+cityCode === -1) {
      const data = {
        countryList: this.countryList?.length ? [...this.countryList] : [],
        stateList: this.stateList?.length ? [...this.stateList] : [],
        countryId: this.countryId ? this.countryId : 0,
        stateId: this.stateId ? this.stateId : 0
      };
      // this.addNewCityRefModel.openModal(data);
    } else {
      if (cityCode > 0) {
        const url = `${API_ENDPOINTS.GET_AREA}${cityCode}`;
        this.apiService.getApi(url).subscribe((res: any) => {
          if (res.Code === 1000 && res?.Data) {
            this.areaList = [{ Id: -1, CommonDesc3: '+Add New' }, ...res.Data];
            if (item && Object.keys(item).length) {
              this.areaId = Number(item.AreaId);
            }
          } else {
            this.areaList = [{ Id: -1, CommonDesc3: '+Add New' }];
            this.areaId = null;
          }
        });
      }
    }
  };

  onSubmit = (): void => {
    let payLoad = { CountryCode: this.CountryCode.replace('+', '').trim(), ...this.signUpForm.value, AreaId: !this.signUpForm.controls['AreaId'].value ? 0 : this.signUpForm.controls['AreaId'].value }
    if (this.countryCodeReadOnly) {
      payLoad = { OTP: this.otp, ...payLoad };
    }
    this.apiService.postApi(API_ENDPOINTS.SIGN_UP_BY_USER, payLoad, {}, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000) {
          localStorage.removeItem('OTP_INFO');
          this.sweetAlert.showAlert('success', 'Welcome Aboard!', 'Congratulations! You\'re now part of our family. Happy Shopping!');
          this.router.navigate(['login']);
        } else {
          this.sweetAlert.showAlert('error', 'Registration Unsuccessful', res.Message);
        }
      });
  };

  onAreaChange = (): void => {
    if (Number(this.signUpForm.controls['AreaId'].value) === -1) {
      this.commonService.openModal('areaAddPopUp');
    }
  };

  closeAreaModal = (type?: any) => {
    if (!type) {
      this.areaId = null;
    }
    this.commonService.closeModal('areaAddPopUp');
    this.addAreaFormControl.resetForm();
  };

  submitArea = (): void => {
    const data = {
      Id: 0,
      CommonDesc3: this.newAreaName,
      ShortName3: this.newAreaName,
      CommonCode: 104,
      CommonId2: Number(this.signUpForm.controls['CityId'].value)
    };
    this.apiService.postApi(API_ENDPOINTS.AREA, data, {}, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000) {
          this.sweetAlert.showToast('success', 'New Area Successfully Added!');
          this.getAreaList(this.cityId, { AreaId: +res.Data });
          this.closeAreaModal(true);
        } else {
          this.sweetAlert.showToast('error', res.Message);
        }
      });
  };

  get f(): any {
    return this.signUpForm.controls;
  }
}