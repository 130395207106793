import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { NgForm, Validators } from '@angular/forms';
import { API_ENDPOINTS } from '../api.endpoints';
import { APP_CONSTANT } from '../app.constant';
import { STORAGE_KEY } from '../browser.storage.constant';
import { SweetAlertService } from '../sweetalert.service';
import { Subscription, take, timer } from 'rxjs';

// export interface FormState {
//   isSignIn?: boolean;
//   isSignUp?: boolean;
//   isPasswordForgot?: boolean;
// }

// export interface ForgotPasswordDialogInterface extends FormState {
//   mobileNumber?: number;
//   code?: number;
//   isOtpVerfied?: boolean;
//   isOtpReceived?: boolean;
  
//   formState?: FormState;
//   password?: any;
//   confirmPassword?: any;
//   username?: string;
//   otpId?: number;
// }

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  // view Children's
  @ViewChild('otpInput') otpInput!: ElementRef;
  @ViewChild('forgot_password_form', { static: false })
  forgotPasswordFormControl: NgForm | any;

  // output emitter
  @Output() closeModal = new EventEmitter();

  // subscription's
  timerSubscription$: Subscription = new Subscription();
  apiSubscription$: Subscription = new Subscription();

  // variable declairation
  default_country_code: string = '+91';
  country_code_list: Array<any> = [];

  // form variables
  mobileNumber: number | null = null;
  code: number|''='';
  password?: string;
  confirmPassword?: string;
  isOtpReceived: boolean = false;
  isVerifiedOtp: boolean = false;
  username?: string;
  otpId?: number;

  resendDisabled: boolean = false;
  count: number = 180; // 3 minutes in seconds
  remainingTime: number = this.count;
  tick: number = 1000;

  isFocused: boolean = false;

  constructor(
    private commonService: CommonService,
    private alertService: SweetAlertService,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.retrieveCountryCodes();
  }

  // #1
  retrieveCountryCodes = () => {
    this.commonService.fetchCountryCodes().subscribe((response: any) => {
      this.country_code_list = response.Data;
    });
  };

  // #2
  selectCountryCode = (event: any) => {
    console.log('selectCountryCode event: ', event);
    this.default_country_code = event.value;
  };

  // #3
  submit() {
    let mobileNo = this.mobileNumber + '';

    if (mobileNo.length !== 10) {
      this.alertService.showToast(
        'error',
        'Please enter a valid mobile number and try again.'
      );
      return;
    }

    console.log('submit():97 ', this.forgotPasswordFormControl.form);

    if (!this.isOtpReceived && !this.isVerifiedOtp) {
      this.generateOtp();
      return;
    }

    if (
      this.isOtpReceived && !this.isVerifiedOtp) {
      this.handleOtpVerification();
      return;
    }

    if (this.isOtpReceived && this.isVerifiedOtp) {
      this.handleChangePassword();
      return;
    }
  }

  // helper methods
  generateOtp() {
    const queryParam = {
      CountryCode: this.normalizeCountryCodePrefix(),
      MobileNo: this.mobileNumber,
      OTPFor: 'ForgetPassword',
    };

    const endpoint = API_ENDPOINTS.FORGOT_PASSWORD_GENERATE_OTP;

    // new with improved observable single object
    this.apiService.getApi(endpoint, queryParam).subscribe({
      next: (response: any) => {
        // console.log('Observable emitted the next value at generateOtp: ', response);
        if (response.Code === 1000) {
          this.isOtpReceived = true;
          if (this.otpInput) {
            const element = this.otpInput.nativeElement.focus();
          }

          this.otpId = response.Data;

          // Initialize the timer
          this.startResendTimer();
          this.alertService.showToast(
            'success',
            'OTP Sent! Check your mobile for the code.'
          );
        } else {
          this.alertService.showToast('error', response.Description);
        }
      },
      error: (err: any) => {
        console.error('Observable emitted an error at generateOtp: ', err);
      },
      complete: () => {},
    });
  }

  normalizeCountryCodePrefix = () => {
    return this.default_country_code.replace('+', '').trim();
  };

  openModal(type: string = 'forgotPassword'): void {
    // this.onAnchorClick(type);
    this.commonService.openModal('forgot_password_popup');
  }

  // handle OTP verification
  handleOtpVerification() {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }
    const data = {
      CountryCode: this.normalizeCountryCodePrefix(),
      Id: this.otpId,
      Code: this.code,
      MobileNo: this.mobileNumber,
      // Type: this.forgotPasswordDialog.formStates.signUp ? 1 : 2
    };
    this.commonService.verifyOtp(data).subscribe({
      next: (res: any) => {
        // console.log('verifyOtp():197 : ',res);
        if (res.Code === 1000) {
          this.alertService.showToast('success', 'Verification Successful!');
          this.isVerifiedOtp = true;
          
          // Password change
          if (this.isVerifiedOtp) {
            this.handleChangePassword();
          }
        } else {
          this.alertService.showToast('error', res.Message);
        }
      },
      error: (err: any) => {
        this.alertService.showToast('error', err.Message);
      },
      complete: () => {},
    });
  }

  

  handleChangePassword() {
    if (!this.password || !this.confirmPassword) {
      this.alertService.showToast('warning', 'Please enter both password fields.');
      return;
    }

    // Check if passwords match
    if (this.password !== this.confirmPassword) {
      this.alertService.showToast('warning','Passwords must match. Please check and try again.');
      return;
    }

    // Check if Mobile No and Code fields are empty
    if (!this.mobileNumber || !this.code) {
      this.alertService.showToast('warning', 'Please enter Mobile No and Code.');
      return;
    }

    // Prepare data for API request
    const data = {
      CountryCode: this.normalizeCountryCodePrefix(),
      MobileNo: this.mobileNumber,
      NewPassword: this.password,
      ConfirmPassword: this.confirmPassword,
      ParentTypeId: 40,
      OTP: this.code,
    };

    // Send API request to change password
    this.apiSubscription$ = this.apiService
      .postApi(API_ENDPOINTS.USER_RESET_PASSWORD, data)
      .subscribe({
        next: (response: any) => {
          // Check if password is updated successfully
          if (response && response.Code === 1000 && response.Data) {
            this.alertService.showToast('success', 'Password Updated!');
            this.forgotPasswordFormControl.resetForm();
            this.resetForm();
            this.closeForm();
          } else if (response && response.Description) {
            // Show error message if provided by the API
            this.alertService.showToast('error', response.Message);
          } else {
            // Show generic error message if no specific error message is provided
            this.alertService.showToast(
              'error',
              'An error occurred while updating the password.'
            );
          }
        },
        error: () => {
          // Show error message if an error occurred during the API request
          this.alertService.showToast(
            'error',
            'An error occurred while updating the password.'
          );
        },
        complete: () => {},
      });

    //apiSubscription$.unsubscribe();
  }

  closeForm(data?: any) {
    this.commonService.closeModal('forgot_password_popup');
    this.closeModal.emit({});
  }

  resetForm() {
    // this.onAnchorClick('signIn');
    this.resetUtilityModal();
  }

  resetUtilityModal() {
    this.isOtpReceived = false;
    this.isVerifiedOtp = false;
  }

 
  startResendTimer(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$?.unsubscribe();
      this.timerSubscription$ = new Subscription();
    }
    this.resendDisabled = true;
    this.timerSubscription$ = timer(0, this.tick)
      .pipe(take(this.remainingTime))
      .subscribe({
        next: () => {
          --this.remainingTime;
          if (this.remainingTime === 0) {
            this.resendDisabled = false;
            this.timerSubscription$.unsubscribe();
          }
        },
        error: (err: any) =>
          console.error('Observable emitted an error for timer: ' + err),
        complete: () => {
          this.timerSubscription$.unsubscribe();
        },
      });
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  resendOtp(): void {
    this.generateOtp();
    this.remainingTime = this.count; // Reset to 3 minutes
    this.startResendTimer();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }
    if (this.apiSubscription$) {
      this.apiSubscription$.unsubscribe();
    }
  }
}