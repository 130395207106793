<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>{{screenName}}</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">{{parentScreenName}}</a></li>
                        <ng-container *ngIf="previousScreenName">
                            <li class="breadcrumb-item"><a href="/{{previousScreenUrl}}">{{previousScreenName}}</a></li>
                        </ng-container>
                        <li class="breadcrumb-item active" aria-current="page">{{screenName}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>