import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-section',
  templateUrl: './breadcrumb-section.component.html',
  styleUrls: ['./breadcrumb-section.component.scss']
})
export class BreadcrumbSectionComponent {
  @Input('screenName') screenName: string = '';
  @Input('previousScreenName') previousScreenName?: string = '';
  @Input('previousScreenUrl') previousScreenUrl?: string = '';
  @Input('parentScreenName') parentScreenName: string = '';
}
