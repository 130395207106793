<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Cancellation, Returns &amp; Refunds Policy</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Cancellation, Returns &amp; Refunds Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-12">
                <p class="h6 lh-base mb-2 text-dark-emphasis">&nbsp;Cancellation Policy is a scheme provided on g9kart website available at <a href="www.g9kart.com">www.g9kart.com</a> or the mobile application under the brand name <strong>“G9kart” (collectively “Platform”)</strong> in relation to the cancel the products being ordered on the Platform (“Products”).</p>
                <p class="h6 lh-base mb-3 text-dark-emphasis">&nbsp;The mechanism in which an order placed on the Platform by the customer <strong>(“User”)</strong> can be cancelled by the User, the supplier, or by g9kart is laid down in this cancellation policy <strong>(“Policy”)</strong>.</p>
            </div>

            <div class="col-sm-12">
                <ol class="list-group list-group-numbered text-start fs-5 "> 
                    <li class="list-group-item list-group-flush border-0">
                        <span class="h4 text-capitalize text-body ">&nbsp;Cancellation By the User </span> 
                        <ol class="list-group list-group-numbered fs-6"> 
                            <li class="list-group-item border-0">&nbsp;
                                An order can be cancelled by the User after it has been placed through the Platform and before it has been
                                dispatched by the supplier.
                            </li> 
                            <li class="list-group-item border-0">&nbsp;
                                In order to cancel, <strong>'My Orders'</strong> in the <strong>'Profile'</strong> can be visited by the User.
                                Once redirected to the My Orders page, the order of the product that is to be cancelled can be clicked on by
                                the user. The "Cancel Order" option can be clicked on, and the reason for the cancellation can be provided from
                                the options given.
                            </li> 
                            <li class="list-group-item border-0"> 
                                 
                                <span class="h5 text-capitalize text-body ">&nbsp;Order can be cancelled by the user for the below mentioned Reasons: </span> 
                                <ol class="list-group list-group-numbered fs-6"> 
                                    <li class="list-group-item border-0">&nbsp;If the user placed the order for testing purpose.</li> 
                                    <li class="list-group-item border-0">&nbsp;If multiple orders for the same Product have been placed by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;If the expected delivery date is not found acceptable by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;If a change to the shipping or billing address is desired by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;If the decision about placing the order is reconsidered by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;If the contract details or the payment mode are to be updated or changed by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;If the size or the colour of the Product is to be changed by the User.</li> 
                                    <li class="list-group-item border-0">&nbsp;For reasons determined by any other circumstances.</li> 
                                </ol>
                                
                            </li> 
                        </ol>
                    </li> 
                    <li class="list-group-item list-group-flush border-0">
                        <span class="h4 text-capitalize text-body ">&nbsp;Cancellation By the Supplier </span> 
                        <ol class="list-group list-group-numbered fs-6"> 
                            <li class="list-group-item border-0">&nbsp;
                                The order can only be cancelled by the supplier within 2 day after the day on which the Product was supposed
                                to be dispatched by the Supplier.
                            </li> 
                            <li class="list-group-item border-0"> 
                                 
                                <span class="h5 text-capitalize text-body ">&nbsp;Order can be cancelled by the Supplier for the below mentioned Reasons: </span> 
                                <ol class="list-group list-group-numbered fs-6"> 
                                    <li class="list-group-item border-0">&nbsp;If the ordered Product is no longer available with the supplier.</li> 
                                    <li class="list-group-item border-0">&nbsp;The ordered Product has been run out of stock by the supplier.</li> 
                                    <li class="list-group-item border-0">&nbsp;The order is unable to be dispatched on time by the supplier.</li> 
                                </ol>
                                
                            </li> 
                        </ol>
                    </li>
                    
                    <li class="list-group-item list-group-flush border-0">
                        <span class="h4 text-capitalize text-body ">&nbsp;Cancellation By g9Kart </span> 
                        <ol class="list-group list-group-numbered fs-6"> 
                            <li class="list-group-item border-0">&nbsp;
                                The order can be cancelled by g9kart anytime from the date on which the order was placed till the delivery of
                                the Product to the User.
                            </li> 
                            
                            <li class="list-group-item border-0"> 
                                 
                                <span class="h5 text-capitalize text-body ">&nbsp;Order can be cancelled by g9kart for the below mentioned Reasons: </span> 
                                <ul class="list-group list-group-numbered border-0 fs-6"> 
                                    <li class="list-group-item border-0">&nbsp; If the Product&apos;s delivery has failed and the Product has been returned by the seller.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the address where the Product should be delivered is deemed unserviceable.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the payment was made by the User through online methods and payment confirmation was not received by g9kart.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the shipping or billing address provided by the User is found to be incomplete or incorrect.</li> 
                                    <li class="list-group-item border-0">&nbsp; If g9kart&apos;s policies of fair business practices are not met by the supplier.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the Product has been lost while in transit.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the order cannot be dispatched by the supplier due to regulatory lockdown or other restrictions.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the order has been auto-cancelled by the system because of a high risk of the product being returned to the supplier.</li> 
                                    <li class="list-group-item border-0">&nbsp; If user confirmation during delivery is not received by g9kart.</li> 
                                    <li class="list-group-item border-0">&nbsp; If cash on delivery was chosen by the User and it is not available for the ordered Product.</li> 
                                    <li class="list-group-item border-0">&nbsp; If the order cannot be fulfilled by g9kart due to other reasons.</li> 
                                    <li class="list-group-item border-0">&nbsp; If cancellation is sought by g9kart for any reason deemed appropriate by them.</li>
                                </ul>
                                
                            </li> 

                        </ol>
                    </li>

                    <li class="list-group-item list-group-flush border-0">
                        <span class="h4 text-capitalize text-body "> Refunds </span> 
                        <ol class="list-group list-group-numbered fs-6"> 
                            <li class="list-group-item border-0">&nbsp;
                                If payment has been made by the User before the cancellation of the order that was placed, a refund will be
                                given to the User. The same source of payment (bank accounts, UPI etc.) will be credited with the refund from
                                which the payment had been received. If any other method, such as wallets, has been chosen by the User, then
                                also the source of origin will be credited with the amount. A refund of the entire amount paid by the User
                                will be received by the User. In case of any discrepancies concerning the receipt of the refund amount,
                                additional information like a bank statement, Transaction Id or any other relevant document may be requested
                                by g9kart.
                            </li> 
                            <li class="list-group-item border-0">&nbsp;
                                If any discount vouchers or promotional offers were used by the User when placing the order that was cancelled,
                                same shall be forfeited.
                            </li> 
                            <li class="list-group-item border-0"> 
                                 
                                <span class="h6 text-capitalize text-body ">&nbsp;The processing timeline of Refund after the product has been received by us or when we have been notified by the seller of the receipt of the products is as follow: </span> 
                                <ol class="list-group list-group-numbered fs-6"> 
                                    <li class="list-group-item border-0">&nbsp;If Credit Card, Debit Card, Net Banking, UPI Linked Bank Account is used for initiating Refund, then amount will be reflected in the customer Account within 5-7 Business Days.</li>
                                </ol>
                                
                            </li> 
                        </ol>
                    </li>

                    <li class="list-group-item list-group-flush border-0">
                        <span class="h4 text-capitalize text-body "> Miscellaneous </span> 
                        <ol class="list-group list-group-numbered  fs-6"> 
                            <li class="list-group-item border-0">&nbsp;
                                The right to amend, change, modify, add or remove any portion of this policy at any time without any prior written notice to you is reserved by g9kart, at its sole discretion.
                            </li> 
                            <li class="list-group-item border-0">&nbsp;
                                To review this policy periodically for any updates/ changes is your responsibility.
                            </li> 
                            <li class="list-group-item border-0">&nbsp;
                                For any further queries regarding cancellation or refund customers may reach out to customer support at <a href="mailto:support@g9kart.com">Support@g9kart.com</a> or <a href="mailto:info@g9kart.com">info@g9kart.com</a>
                            </li> 
                        </ol>
                    </li>
                </ol> 
                  
            </div>

        </div>
    </div>
</section>
<!-- section end -->
