 <!-- footer -->
 <footer class="footer-light">
    <div class="light-layout">
        <div class="container">
            <section class="small-section border-section border-top-0">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="subscribe">
                            <div>
                                <h4>Stay ahead of the curve!</h4>
                                <p>Stay in with G9kart: Unlock Exclusive Updates by Subscribing to Our Newsletter.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 text-center text-lg-end px-0">
                        <a href="../assets/images/awards-certs/registration_certificate.pdf" target="_blank" class="
                        ">
                            <img src="../assets/images/awards-certs/quality-research-organization.webp" style="height: 10vh;" alt="iso_cert">
                        </a>
                        <!-- <form
                            action="/"
                            class="form-inline subscribe-form auth-form needs-validation" method="post"
                            id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
                            <div class="form-group mx-sm-3">
                                <input type="email" class="form-control" name="email" id="email"
                                    placeholder="Enter your email" required="required" [(ngModel)]="Email">
                            </div>
                            <button type="submit" class="btn btn-solid" id="mc-submit" [disabled]="!Email" (click)="subscribeEmail()">subscribe</button>
                        </form> -->
                    </div>
                </div>
            </section>
        </div>
    </div>
    <section class="section-b-space light-layout">
        <div class="container">
            <div class="row footer-theme partition-f">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-title footer-mobile-title">
                        <h4>about</h4>
                    </div>
                    <div class="footer-contant">
                        <div class="footer-logo">
                            <img src="../assets/images/icon/logo-wide.webp" alt="g9kart_logo"> 
                            <!-- <h4 class="h4 fw-bold ">G9KART SERVICES<br class="d-none d-md-block " />PRIVATE LIMITED</h4> -->
                        </div>
                        <p>G9KART: Building Trust, Connecting Bharat, &amp; Empowering Commerce for All and and Transparency Unite in Every Transaction</p>
                        <div class="footer-social">
                            <ul>
                                <li><a href="https://www.facebook.com/people/Adiphi/61554501064788/?mibextid=2JQ9oc" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                <li><a href="https://twitter.com/g9kart"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.instagram.com/adiphi_in?igshid=YzAwZjE1ZTI0Zg==" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                                <!-- <li><a href="https://rss.com/feed/g9kart"><i class="fa fa-rss" aria-hidden="true"></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col offset-xl-1">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>my account</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>Information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <!-- <li><a href="/user-agreement">User Agreement</a></li> -->
                                <li><a href="/cancellation-policy">Cancellation, Returns &amp; Refunds Policy</a></li>
                                <li><a href="/shipping-policy">Shipping Policy</a></li>
                                <li><a href="/guidelines">Guidelines</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col">
                    <div class="sub-title">
                        <div class="footer-title">
                            <h4>store information</h4>
                        </div>
                        <div class="footer-contant">
                            <ul class="contact-list">
                                <li><i class="fa fa-map-marker"></i> 
                                    <span style="font-size: 11px;">G9KART SERVICES PRIVATE LIMITED</span><br/> 
                                   
                                    <address>2nd Floor, Hari Om Tower<br/>
                                        Jamuna Nagar, Sodala,<br/>
                                        Jaipur - 302006,<br/>
                                        Rajasthan, Bharat</address>
                                </li>
                                <!-- <li><i class="fa fa-phone"></i>Call Us:  +91 xxx-xxx-xxxx</li>
                                <li><i class="fa fab fa-whatsapp"></i>WA: +91 xxx-xxx-xxxx</li> -->
                                <li><i class="fa fa-building"></i>CIN: U82990RJ2023PTC090527</li>
                                <li><i class="fa fa-envelope"></i>Email Us: Support@g9kart.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row footer-theme partition-f">
                <div class="col text-start text-md-end">
                    <a href="../assets/images/awards-certs/registration_certificate.pdf" target="_blank" class="cert-container">
                        <img src="../assets/images/awards-certs/quality-research-organization.webp" style="height: 13vh;" alt="iso_cert">
                    </a>
                </div>
            </div> -->
        </div>
    </section>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i class="fa fa-copyright" aria-hidden="true"></i> {{currentYear}} - {{nextYear}} | Powered by
                            G9Kart Designed by <a href="https://saniiro.com">Saniiro</a></p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <!-- <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="https://www.visa.co.in/"><img src="../assets/images/icon/visa.png" alt="visa_card_icon"></a>
                            </li>
                            <li>
                                <a href="https://www.mastercard.co.in/en-in.html"><img src="../assets/images/icon/mastercard.png" alt="master_card_icon"></a>
                            </li>
                            <li>
                                <a href="https://www.paypal.com/in/home"><img src="../assets/images/icon/paypal.png" alt="paypal_icon"></a>
                            </li>
                            <li>
                                <a href="https://www.americanexpress.com/in"><img src="../assets/images/icon/american-express.png" alt="americal_express_icon"></a>
                            </li>
                            <li>
                                <a href="https://www.discover.com/"><img src="../assets/images/icon/discover.png" alt="discover_icon"></a>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->