export const API_ENDPOINTS = {
  ADDRESS: 'api/v1/setting/Address',
  AREA: 'api/v1/setting/CommonMaster3',
  ALL_ORDERED_LIST: 'api/v1/SP_BuyNowList',
  ORDER_CANCELLATION: 'api/v1/expp/service/awb/cancellations',
  ORDER_RETURN_REPLACE: 'api/v1/exs/service/awb/ndr_resolutions',
  BRAND_LIST: 'api/v1/Brand',
  CANCEL_ORDER_ITEM: 'api/v1/SP_UpdateItemBuyNowStatus',
  CART_ITEM_QTY: 'api/v1/SP_AddToCartCount',
  CART_LIST: 'api/v1/SP_AddToCart',
  CART_LIST_WITH_CHARGE: 'api/v1/SP_AddToCartWithDetails',
  CHECK_OUT_ORDER: 'api/v1/SP_BuyNow',
  COMMON_CODE: 'api/v1/setting/CommonMaster?CommonCode=',
  CUSTOMER_ADD: 'api/v1/setting/CustomerUser',
  CUSTOMER_CREATE_PASSWORD: 'api/v1/common/Customer/CustomerCreate',
  CUSTOMER_GET: 'api/v1/setting/CustomerUser',
  CUSTOMER_LOGIN: 'api/v1/authentication/sp_custlogin',
  DELIVERY_STATUS: 'api/v1/SP_UpdateItemDeliveryStatus',
  EMPTY_CART_ITEMS: 'api/v1/SP_EmptyCart',
  // FORGOT_PASSWORD_GENERATE_OTP: 'api/v1/SP_GenerateOtp',
  FORGOT_PASSWORD_GENERATE_OTP: 'api/v1/sp/forgot/password',
  FRONT_BANNER: 'api/v1/BannerImages',
  GENERATE_OTP: 'api/v1/SP_GenerateOtp',
  GET_COUNTRY_CODES: 'api/v1/setting/countryutility',
  GET_AREA: 'api/v1/setting/CommonMaster3?CommonCode=104&CommonId2=',
  GET_CITIES: 'api/v1/setting/CommonMaster2?CommonCode=103&CommonId1=',
  GET_COUNTRIES: 'api/v1/setting/CommonMaster?CommonCode=101',
  GET_IN_TOUCH: 'api/v1/common/WebsiteContactUs',
  GET_STATES: 'api/v1/setting/CommonMaster1?CommonCode=102&CommonId=',
  HOME_ITEMS: 'api/v1/SP_FrontendAllItems',
  ITEM_CATEGORY: 'api/v1/SP_FrontendCategory',
  ITEM_DETAILS: 'api/v1/SP_FrontendItemDetails',
  ITEM_MASTER: 'api/v1/Item',
  MOBILE_COUNTRY_CODE: 'api/v1/setting/countryutility',
  ORDER_SEARCH: 'api/v1/SP_FrontendSearch',
  PAYMENT_METHOD: 'api/v1/SP_FrontendPaymentMethod',
  PAYTM_CALLBACK: 'api/v1/callbackapytm',
  PAYUMONEY_RES: 'api/v1/callbackpayumoney',
  PAYUMONEY_SIGNUP: 'api/v1/callbackpayumoneybycustomer',
  PAYUMONEY_TOPUP: 'api/v1/callbackpayumoneybytopup',
  PAYUMONEY_UPGRADE: 'api/v1/callbackpayumoneybywalletupgrade',
  PINCODE_CHECK: 'api/v1/setting/PincodeMaster',
  PINCODE_MASTER: 'api/v1/setting/PincodeMaster',
  PROFILE_EDIT: 'api/v1/common/Customer/CustomerEdit',
  PROFILE_GET: 'api/v1/common/Customer/GetCustomerList',
  RATE_AND_REVIEW: 'api/v1/SP_BuyNowReview',
  RATE_AND_REVIEW_GET: 'api/v1/SP_GetBuyNowReview',
  REF_LINK: 'api/v1/common/RefLink',
  SHIPPING_CHARGE_RANGE: 'api/v1/SP_ItemChargeTransaction',
  SIGN_UP_BY_USER: 'api/v1/common/Customer/CustomerCreate',
  UPDATE_CART_QTY: 'api/v1/SP_UpdateQtyAddToCart',
  // USER_CHANGE_PASSWORD: 'api/v1/ForgotPasswordCustomer',
  USER_RESET_PASSWORD: 'api/v1/customer/password/reset',
  VENDOR_AGENT: 'api/v1/common/vendoragent',
  VENDOR_REG: 'api/v1/common/Vendor',
  // VERIFY_OTP: 'api/v1/authentication/otp',
  // VERIFY_OTP: 'api/v1/otp',
  VERIFY_OTP: 'api/v1/auth/otp/verify',
  WALLET_MASTER: 'api/v1/setting/WalletCardType',
  WALLET_TOPUP: 'api/v1/common/Customer/WalletTopupByAdmin',
  WALLET_TOPUP_MASTER: 'api/v1/setting/WalletTopupMaster',
  WALLET_TRANSACTIONS: 'api/v1/account/report/wallettransactions',
  WALLET_UPGRADE: 'api/v1/common/Customer/WalletUpgrade',
  COUPON_LIST: 'api/v1/SP_GetCouponList',
  COUPON_OFFER: 'api/v1/CouponSetting',
  NEWS_LETTER_SUBSCRIBER: 'api/v1/common/newslettersubscriber',
  PASSWORD_UPDATE: 'api/v1/customer/password/update',
  TRACK_ORDER: 'api/v1/expp/service/awb/tracking',
  CALCEL_ORDER: 'api/v1/expp/service/awb/cancellations',

  //PaymentGateway
  PHONE_PAY: '/apis/pg-sandbox/pg/v1/pay',
  PHONE_PAY_PAYMENT_RESPONSE: 'api/v1/checkout/payment/phonepe/response',
  PHONE_PAY_PAYMENT_STATUS: '/api/v1/checkout/payment/phonepe/transaction/status',
  SEARCH_PRODUCT: '/api/v1/SP_FrontendSearch'
};

