import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SweetAlertService } from 'src/app/sweetalert.service';
import { ToasterService } from 'src/app/toaster.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {
  private params: any = {};
  private Id: string = '';
  constructor(
    protected commonService: CommonService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToasterService,
    private sweetAlert: SweetAlertService
  ) {
    this.route.queryParamMap.subscribe((parameters: any) => {
      this.Id = parameters.params['Id'];
      this.apiService.getApi(API_ENDPOINTS.PHONE_PAY_PAYMENT_STATUS + '?Id=' + this.Id, {})
        .subscribe({
          next: (response:any) => {
            if(response && response.Code === 1000){
              this.sweetAlert.showToast('success', 'Order successfully placed!');
              this.router.navigate(['dashboard'], {
              queryParams: { selectedTab: 'orders' },
            });
            }
          },
          error: (err: any) => this.sweetAlert.showToast('error', 'Order failed!'+err),
          complete: () => console.log('Observable emitted the complete notification')
        });
    });
  }

  ngOnInit() {
  }
}
