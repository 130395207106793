
<!-- breadcrumb start -->
<app-breadcrumb-section screenName="collection" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!-- section start -->
<section class="section-b-space ratio_asos py-3">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="collection-content col-sm-12">
                    <div class="page-main-content">
                        <div class="top-banner-wrapper">
                            <ng-container *ngIf="categoryBannerImg">
                                <a href="javascript:void(0)">
                                    <img src="{{categoryBannerImg}}" class="img-fluid blur-up lazyload" alt="">
                                </a>
                            </ng-container>
                            <ng-container *ngIf="bannerImageDescription">
                                <div class="top-banner-content small-section text-center">
                                    <h4>Major savings on premium brands</h4>
                                    <p>{{bannerImageDescription}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="collection-filter"
                [class]="brands && utilityModal.attributes && utilityModal.categories ? 
                'col-sm-3' : 'd-none'" >
                    <!-- side-bar colleps block stat -->
                    <div class="collection-filter-block scrollbar-width-thin">
                        <!-- brand filter start -->
                        <div class="collection-mobile-back "><span class="filter-back">
                            <i class="fa fa-angle-left" aria-hidden="true"></i> back</span></div>
                        <ng-container *ngIf="brands.length > 0">
                            <div class="collection-collapse-block open">
                                <h3 class="collapse-block-title">brand</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <ng-container *ngFor="let brand of brands;let i = index;">
                                            <div class="form-check collection-filter-checkbox" >
                                                <input type="checkbox" class="form-check-input" id="customCheck-{{i}}"
                                                    name="customCheck-{{i}}" [(ngModel)]="brand.selected"
                                                    (change)="getProudctByCategory({})">
                                                <label class="form-check-label" for="customCheck-{{i}}">{{ brand?.Name |
                                                    titlecase }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let attribute of utilityModal.attributes;let i = index;">
                            <!-- color filter start here -->
                            <ng-container *ngIf="attribute.Name==='Color' || attribute.Name==='COLOR'">
                       <div class="collection-collapse-block open" >
                                    <h3 class="collapse-block-title">colors</h3>
                                    <div class="collection-collapse-block-content">
                                        <div class="color-selector">
                                            <ul>
                                                <ng-container *ngFor="let attributeVal of attribute.AttributeValue;let i = index;">
                                                    <li [id]="attributeVal.Id" class="{{'color-'+i}}"
                                                        [style.background-color]="attributeVal.Name"
                                                        (click)="onChangeAttribute($event, attribute.Name, attributeVal.Id)">
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- size filter start here -->
                            <ng-container *ngIf="attribute.Name!=='Color' && attribute.Name!=='COLOR'">
                                <div class="collection-collapse-block border-0 open" >
                                    <h3 class="collapse-block-title">{{attribute.Name}}</h3>
                                    <div class="collection-collapse-block-content">
                                        <div class="collection-brand-filter">
                                            <ng-container  *ngFor="let attributeVal of attribute.AttributeValue;let i = index;">
                                                <div class="form-check collection-filter-checkbox" >
                                                    <input type="checkbox" class="form-check-input" id="{{attributeVal.Id}}"
                                                        (change)="onChangeAttribute($event, attribute.Name, attributeVal.Id)">
                                                    <label class="form-check-label"
                                                        for="{{attributeVal.Id}}">{{attributeVal.Name}}</label>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- size filter start here -->
                        <ng-container *ngIf="utilityModal.categories?.length">
                            <div class="collection-collapse-block border-0 open" >
                                <h3 class="collapse-block-title">Categories</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="form-check collection-filter-checkbox " style="margin-bottom: 5px!important;"
                                            *ngFor="let category of utilityModal.categories;let i = index;">
                                            <label class="form-check-label" (click)="_cs.navigateToCategorySlug(category)">{{
                                                category?.Name |
                                                titlecase }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- price filter start here -->

                        <ng-container  *ngIf="this.utilityModal.priceRange.length">
                            <div class="collection-collapse-block border-0 open" >
                                <h3 class="collapse-block-title">price</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="wrapper mt-3">
                                        <div class="range-slider">
                                            <div class="row">
                                                <input type="range" class="js-range-slider" id="rangeCheck"
                                                    name="rangeCheck" [value]="selectedRange" [min]="minValue"
                                                    [max]="maxValue" (change)="onChangePrice()"
                                                    [(ngModel)]="selectedRange" />
                                            </div>
                                            <div class="row">
                                                <div class="col-6 text-start">
                                                    <label class="custom-control-label">
                                                        {{minValue | currency:"INR" }}
                                                    </label>
                                                </div>
                                                <div class="col-6 text-start">
                                                    <label class="custom-control-label">
                                                        {{selectedRange | currency:"INR"}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- silde-bar colleps block end here -->

                    <!-- side-bar single product slider start -->
                    <ng-container *ngIf="newProducts.length">
                        <div class="theme-card">
                            <h5 class="title-border">new product</h5>
                            <div class="offer-slider slide-1">
                                <div>
                                    <ng-container *ngFor="let product of newProducts">
                                        <div class="media" >
                                            <a href="javascript:void(0)"><img class="img-fluid blur-up lazyload"
                                                    src="{{ product.FilePath  ? product.FilePath : '../assets/images/pro3/2.jpg' }}"
                                                    alt=""></a>
                                            <div class="media-body align-self-center">
                                                <!-- <div class="rating"><i class="fa fa-star"></i> <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i> <i class="fa fa-star"></i> <i
                                                        class="fa fa-star"></i>
                                                </div> -->
                                                <a href="javascript:void(0)" [routerLink]="[createLinkHandler('product', product)]" [target]="'_blank'">
                                                    <h6>{{ product?.ItemName ? product.ItemName : 'anonymous' }}</h6>
                                                </a>
                                                <h4>{{product?.price | currency:"INR"}}</h4>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- side-bar single product slider end -->
                    <!-- side-bar banner start here -->
                    <!-- <div class="collection-sidebar-banner">
                        <a href="#"><img src="../assets/images/side-banner.png" class="img-fluid blur-up lazyload"
                                alt=""></a>
                    </div> -->
                    <!-- side-bar banner end here -->
                </div>
                <div class="collection-content"
                [class]="brands && utilityModal.attributes && utilityModal.categories ? 
                'col-sm-9' : 'col-sm-12'">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="collection-product-wrapper">
                                    <div class="product-top-filter">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="filter-main-btn"><span class="filter-btn btn btn-theme"><i
                                                            class="fa fa-filter" aria-hidden="true"></i> Filter</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <ng-container  *ngIf="utilityModal.products.length>0">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="product-filter-content">
                                                        <div class="search-count">
                                                            <h5>Showing Products 1-{{visibleProductsCount}} of
                                                                {{utilityModal.products.length}} Results</h5>
                                                        </div>
                                                        <div class="collection-view">
                                                            <ul>
                                                                <li><i class="fa fa-th grid-layout-view"></i></li>
                                                                <li><i class="fa fa-list-ul list-layout-view"></i></li>
                                                            </ul>
                                                        </div>
                                                        <div class="collection-grid-view">
                                                            <ul>
                                                                <li><img src="../assets/images/icon/2.png" alt=""
                                                                        class="product-2-layout-view"></li>
                                                                <li><img src="../assets/images/icon/3.png" alt=""
                                                                        class="product-3-layout-view"></li>
                                                                <li><img src="../assets/images/icon/4.png" alt=""
                                                                        class="product-4-layout-view"></li>
                                                                <li><img src="../assets/images/icon/6.png" alt=""
                                                                        class="product-6-layout-view"></li>
                                                            </ul>
                                                        </div>
                                                        <div class="product-page-per-view">
                                                            <select [(ngModel)]="visibleProductsCount">
                                                                <option value="24">24 Products Per Page
                                                                </option>
                                                                <option value="50">50 Products Per Page
                                                                </option>
                                                                <option value="100">100 Products Per Page
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="product-page-filter">
                                                            <select name="sortElementName" [(ngModel)]="selectedFilterValue"
                                                                (change)="getProudctByCategory({})">
                                                                <!-- <option value="High to low">Sorting items</option>
                                                                <option value="Low to High">50 Products</option>
                                                                <option value="Low to High">100 Products</option> -->
                                                                <option value="0">Newest</option>
                                                                <option value="1">Price Low to High</option>
                                                                <option value="2">Price High to Low</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="product-wrapper-grid product-load-more">
                                        <div class="row margin-res">
                                            <div class="col load-more-sec"
                                                *ngIf="!loading && utilityModal?.products?.length === 0">
                                                <a>No Items available</a>
                                            </div>
                                            <div class="col-xl-3 col-6 col-grid-box"
                                                *ngFor="let item of utilityModal?.products; let i = index">
                                                <div class="product-box">
                                                    <div class="img-wrapper">
                                                        <div class="front">
                                                            <a href="javascript:void(0)" [routerLink]="[createLinkHandler('product', item)]"
                                                                routerLinkActive="router-link-active" [target]="'_blank'">
                                                                <img [src]="item.FilePath?item.FilePath:categoryImg"
                                                                    class="img-fluid blur-up lazyload bg-img object-fit-cover" alt="">
                                                            </a>
                                                        </div>
                                                       
                                                        <div class="cart-info cart-wrap">
                                                            <button data-bs-toggle="modal" data-bs-target="#addtocart"
                                                                title="Add to cart"
                                                                (click)="addToCartAndWishList(item, i)">
                                                                <i class="ti-shopping-cart">
                                                                </i>
                                                            </button>
                                                            <a href="javascript:void(0)" title="Add to Wishlist"
                                                                (click)="addToCartAndWishList(item, i, 1)"><i
                                                                    class="ti-heart" aria-hidden="true"></i></a>
                                                            <!-- <a href="javascript:void(0)" data-bs-toggle="modal"
                                                                data-bs-target="#quick-view" title="Quick View">
                                                                <i class="ti-search" aria-hidden="true"></i></a>
                                                            <a href="compare.html" title="Compare"><i class="ti-reload"
                                                                    aria-hidden="true"></i>
                                                            </a> -->
                                                        </div>
                                                    </div>
                                                    <div class="product-detail">
                                                        <div>
                                                            <span class="score">
                                                                <div class="score-wrap">
                                                                    <ng-container *ngIf="(item.Rating/5*100) > 0">
                                                                        <span class="stars-active" [style.width.%]="item.Rating/5*100">
                                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                                            <i class="fa fa-star" aria-hidden="true"></i>
                                                                        </span>
                                                                        <span class="stars-inactive">
                                                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                                                        </span>
                                                                    </ng-container>
                                                                </div>
                                                            </span>

                                                            <a [routerLink]="[createLinkHandler('product', item)]"
                                                                routerLinkActive="router-link-active" [target]="'_blank'">
                                                                <h6>{{ item.ItemName | titlecase }}</h6>
                                                            </a>
                                                            <h4 class="price-detail">{{item.SaleRate | currency:'INR'}}
                                                                <ng-container *ngIf="item.MrpRate>0 && item.SaleRate < item.MrpRate">
                                                                    <del> {{item.MrpRate | currency:'INR'}} </del>
                                                                </ng-container>
                                                            </h4>
                                                            <ng-container *ngIf="item && item.Offer !== '' && item.Offer!== null">
                                                                <span class="text-success fw-bold"> Offer </span>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="load-more-sec" *ngIf="utilityModal.products.length>0"><a href="javascript:void(0)" class="loadMore">load
                                            more</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->