import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/cookie.service';

@Component({
  selector: 'app-cookiebar',
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss'],
})
export class CookiebarComponent implements OnInit {
  showCookieBar = !this.cookieService.isCookieAccepted;

  constructor(private cookieService: CookieService) {}

  ngOnInit(): void {
    console.log('isCookieAccepted: ', this.cookieService.isCookieAccepted);
    // Set a timeout to show the cookie bar after 5000 milliseconds (8 seconds)
    setTimeout(() => {
      if(this.showCookieBar){
        $('.cookie-bar').addClass('show');
      }
    }, 5000);
  }

  acceptCookies() {
    this.cookieService.acceptCookies();
    this.showCookieBar = true;
  }

  declineCookies() {
    this.cookieService.declineCookies();
    this.showCookieBar = false;
  }
}
