import { Component } from '@angular/core';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  constructor(private seoService: SeoService){
    seoService.updateMetaTags('About Us - Discover Our Story | G9Kart', 
    'Learn about the journey of G9Kart - from our humble beginnings to our mission-driven approach. Explore our commitment to quality, customer service, and innovation.',
    'About Us, Our Story, Ecommerce Brand History, Mission-Driven Company, Quality Products, Customer Service Excellence, Innovation in Ecommerce, G9Kart Journey, Company Values, Meet Our Team');
  }
}
