import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

// Show toast messages
showToast(icon: 'success' | 'error' | 'warning' | 'info', title: string, options?: SweetAlertOptions): void {
  // set default options
  const defaultOptions: SweetAlertOptions = {
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  };
  const toastOptions: SweetAlertOptions = Object.assign({}, defaultOptions, options);
  Swal.fire({
    icon,
    title,
    ...toastOptions
  });
}

  showAlert(icon: 'success' | 'error' | 'warning' | 'info', title: string, text?: string, options?: SweetAlertOptions): Promise<any> {
    const defaultOptions: SweetAlertOptions = {
      icon,
      title,
      text,
      confirmButtonText: 'OK',
      confirmButtonColor: '#2874f0',
    };

    const alertOptions: SweetAlertOptions | any = { ...defaultOptions, ...options };

    return Swal.fire(alertOptions);
  }

  // Show confirmation message
  showConfirm(icon: 'success' | 'error' | 'warning' | 'info' | 'question', title: string, text?: string, confirmButtonText?:string, cancelButtonText?:string, options?: SweetAlertOptions): Promise<any> {
    const defaultOptions: SweetAlertOptions = {
      icon,
      title,
      text,
      confirmButtonText: confirmButtonText ?? 'OK', // "Yes, delete it!",
      confirmButtonColor: '#2874f0',
      cancelButtonColor: '#d33',
      cancelButtonText: cancelButtonText ?? 'Cancel', //"No, cancel!",
      showConfirmButton: true,
      showCancelButton: true,
    };

    const alertOptions: SweetAlertOptions | any = { ...defaultOptions, ...options };

    return Swal.fire(alertOptions);
  }
}
