import { Injectable} from '@angular/core';
import { HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { ToasterService } from './toaster.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptorService implements HttpInterceptor {
  sessionExpired = new Subject<boolean>();
  constructor(private toaster: ToasterService,
              private cs: CommonService) { }
             
  intercept(req: any, next: any): any {
    return next.handle(req).pipe(
      map((event: any) => {
        if (event && Object.keys(event).length && event.body && Object.keys(event.body).length) {
          console.log(this.cs);
          if (event.body.Code === 5018) {
            this.toaster.showError('', 'Session Time Out Please Logout and Login Again');
            this.sessionExpired.next(true);
            return throwError(event);
          } else {
            return event;
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        // if (!_.isEmpty(error) && !_.isEmpty(error.error)) {
        //   if (error.error.statusCode === 404) {
        //     this.toaster.showError('', error.error.message);
        //   }
        // }
        // this.commonService.logOut();
        return throwError(error);
      }));
  }
}
