<!-- breadcrumb start -->
<app-breadcrumb-section screenName="product" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!-- section start -->
<section>
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <ng-container *ngIf="Images && Images.length > 1">
                    <div class="col-lg-1 col-sm-2 col-2">
                        <div class="row">
                            <div class="col-12 p-2">

                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig0" >
                                    <ng-container *ngFor="let item of Images; let i = index;">
                                        <div ngxSlickItem class="slide border border-1" 
                                        (click)="onChangeThumbImage(item)" (mouseenter)="onChangeThumbImage(item)"
                                        class="img-box-vv">
                                            <img [src]="item?.FilePath" alt=""
                                                    class="img-fluid blur-up lazyload" width="100%">
                                        </div>
                                    </ng-container>
                                </ngx-slick-carousel>

                                <!-- <div class="slider-right-nav">
                                    <ng-container *ngFor="let item of Images; let i = index">
                                        <div (click)="onChangeThumbImage(item)" (mouseenter)="onChangeThumbImage(item)" class="img-box-v">
                                            <img [src]="item?.FilePath" alt="" class="img-fluid blur-up lazyload">
                                        </div>
                                    </ng-container>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-lg-5 col-sm-10 col-10" [class]="Images && Images.length>1?'col-sm-10 col-10':'offset-lg-1 offset-md-2 col-sm-12 col-12'">
                    <div class="row">

                        <div class="col-12 border border-1">
                            <lib-ngx-image-zoom
                                [thumbImage]="[mediumImage ?? '../assets/images/pro/1.jpg']"
                                [fullImage]="[mediumImage ?? '../assets/images/pro/1.jpg']"
                                [magnification]="2"
                                [enableScrollZoom]="true"
                                [enableLens]="false"
                                [lensWidth]="150"
                                [lensHeight]="200"
                            ></lib-ngx-image-zoom>
                        </div>

                        <ng-container *ngIf="productDetails && productDetails.length>1">
                            <div class="col-12 mt-2 ms-1" >

                                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1" >
                                    <ng-container *ngFor="let product of productDetails; let i = index;">
                                        <div ngxSlickItem class="slide" (click)="RedirectLink('product', product)" class="img-box-h">
                                            <img src="{{ product.ImageList && product.ImageList[0] ? product.ImageList[0].FilePath: '../assets/images/no-image.png'}}" alt=""
                                                    class="img-fluid blur-up lazyload" width="100%">
                                        </div>
                                    </ng-container>
                                </ngx-slick-carousel>

                                <!-- <div class="slider-nav">
                                    <ng-container *ngFor="let product of productDetails; let i = index;">
                                        <div (click)="RedirectLink('product', product)" class="img-box-h">
                                            <img src="{{ product.ImageList && product.ImageList[0] ? product.ImageList[0].FilePath: '../assets/images/no-image.png'}}" alt=""
                                                class="img-fluid blur-up lazyload"></div>
                                    </ng-container>
                                </div> -->
                            </div>
                        </ng-container>

                    </div>

                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <!-- <div class="product-count">
                            <ul>
                                <li>
                                    <img src="../assets/images/fire.gif" class="img-fluid" alt="image">
                                    <span class="p-counter">37</span>
                                    <span class="lang"> orders in last 24 hours</span>
                                </li>
                                <li>
                                    <img src="../assets/images/person.gif" class="img-fluid user_img" alt="image">
                                    <span class="p-counter">44</span>
                                    <span class="lang"> active view this</span>
                                </li>
                            </ul>
                        </div> -->
                        <h2>{{itemDetail?.ItemName}}</h2>
                        <ng-container *ngIf="this.averageRatingOfProduct > 0">
                            <div class="rating-section">
                                <span class="score">
                                    <div class="score-wrap">
                                        <span class="stars-active" [style.width.%]="(averageRatingOfProduct/5)*100">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                        </span>
                                        <span class="stars-inactive">
                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                            <i class="fa fa-star-o" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </span>

                                <h6>{{this.averageRatingOfProduct}} ratings</h6>
                            </div>
                        </ng-container>

                        <ng-container  *ngIf="IsTopProduct">
                            <div class="label-section">
                                <span class="btn btn-sm btn-xs badge-grey-color btn-animation">#1 Best seller</span>
                                <!-- <span class="label-text">in fashion</span> -->
                            </div>
                        </ng-container>

                        <ng-container *ngIf="IsLatest">
                            <div class="label-section" >
                               <span class="btn btn-sm btn-xs badge-theme-color btn-animation">New Arrival</span>
                            </div>
                        </ng-container>

                        <h3 class="price-detail">
                            {{itemDetail?.SaleRate | currency:"INR"}}
                            <ng-container *ngIf="itemDetail?.MrpRate > itemDetail?.SaleRate">
                                <del>
                                    {{itemDetail?.MrpRate | currency:"INR"}}
                                </del>
                                <span>{{itemDetail?.PercentageOff | number:'1.0-0'}}% off</span>
                                <br>
                                <div *ngIf="Offerdetails > 0 && Offerdetails?.Offer !== null && Offerdetails?.Offer !== ''"
                                    class="d-flex flex-column justify-content-start align-content-center mt-2">
                                    <h5 class="text-bolder">Available Offers </h5>
                                    <div class="fs-6 small" *ngFor="let item of Offerdetails">
                                        <i class="fa fa-tag text-success"></i>
                                        {{item?.Offer}}
                                    </div>
                                </div>
                            </ng-container>
                        </h3>

                        <div class="addeffect-section product-description border-product">
                            <h6 *ngIf="AvailableStock === 1" class="product-title text-success">{{stockLastOneMessage}}</h6>
                            <h6 *ngIf="AvailableStock === 2 && AvailableStock > 0" class="product-title text-success">{{stockLastTwoMessage}}</h6>
                            <h6 *ngIf="AvailableStock < 5 && AvailableStock > 2" class="product-title text-success">{{stockLastFewMessage}}</h6>
                            <div class="row py-3 align-items-end checkreset_btn_perent">
                                <form #pinCodeCheckForm="ngForm" novalidate (ngSubmit)="pinCodeCheckForm.form.valid && checkPincodeDelivery()">
                                    <div class="col-1 pr-0 deliver_word">
                                        <label class="font-weight-bold">Delivery</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 pr-0 deliver_word">
                                            <input type="number" class="form-control" placeholder="Enter Pincode"
                                                name="pinCodeElementName" [(ngModel)]="pinCode"
                                                (input)="onPinCodeChange()" required
                                                [ngClass]="{'is-invalid': pinCodeCheckForm.touched && !pinCode}" />

                                            <ng-container *ngIf="pinCode && isDeliverable!== null">
                                                <div [ngClass]="{'text-success': isDeliverable === 1,'text-danger': isDeliverable === 2}">
                                                    {{ isDeliverable === 1 ? "Will Deliver in 24-48 Hrs" : isDeliverable === 2 ?
                                                        "Can not be Deliver on this location" : ""
                                                    }}
                                                </div>
                                            </ng-container>
                                            <ng-container  *ngIf="pinCodeCheckForm.touched && !pinCode">
                                                <div [ngClass]="{'text-danger': pinCodeCheckForm.touched && !pinCode}">
                                                    Please enter pincode.
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="col-2 ">
                                            <button type="submit" class="btn btn-primary small-bt" [disabled]="!pinCode">
                                                Check
                                            </button>
                                        </div>
                                    </div>
                                    <div class="pl-0 deliver_inp"></div>
                                </form>

                            </div>


                            <h6 class="product-title">quantity</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus"
                                            data-field="" (click)="reduceQuantityByOne()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number" value={{(this.itemDetail?.Qty?this.itemDetail?.Qty:1)}} readonly>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" data-field=""
                                            (click)="increaseQuantityByOne()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>


                        </div>
                        <div class="product-buttons">
                            <ng-container *ngIf="isOutOfStock || AvailableStock === 0">
                                <button id="cartEffect" class="btn btn-outline-danger" [disabled]="isOutOfStock || AvailableStock == 0" >
                                    Out of Stock
                                </button>
                            </ng-container>
                            <ng-container *ngIf="!isOutOfStock && AvailableStock >= 1">
                                <a  href="javascript:void(0)" id="cartEffect" class="btn btn-solid hover-solid btn-animation"
                                    (click)="addToCartAndWishList(itemDetail)" >
                                    <i class="fa fa-shopping-cart me-1" aria-hidden="true"></i>
                                    {{ itemDetail && itemDetail.IsAddedToCart===1? "Go To Cart" : "Add To Cart"}}
                                </a>
                            </ng-container>
                            <a href="javascript:void(0)" class="btn btn-solid" (click)="addToCartAndWishList(itemDetail, 1)">
                                <i class="fa fa-bookmark fz-16 me-2" aria-hidden="true"></i>
                                wishlist
                            </a>
                        </div>

                        <div *ngIf="itemAttributeList.length">
                            <ng-container *ngFor="let attribute of itemAttributeList;let i = index">
                                <div class="collection-collapse-block" *ngIf="attribute.Name==='COLOR'">
                                    <h6 class="product-title size-text collapse-block-title"
                                        [innerText]="attribute.Name"></h6>
                                    <div class="collection-collapse-block-content">
                                        <div class="color-selector">
                                            <ul>
                                                <ng-container
                                                    *ngFor="let attributeVal of attribute.AttributeValue;let i = index;">
                                                    <li [id]="attributeVal.Id" class="{{'color-'+i}}"
                                                        [style.background-color]="attributeVal.Name==='Multicolor'? '':attributeVal.Name"
                                                        [style.background-image]="attributeVal.Name==='Multicolor'? 'linear-gradient(to right, red , yellow)':''"
                                                        (click)="onChangeAttribute($event, attribute.Id, attributeVal)">
                                                    </li>
                                                </ng-container>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="attribute.Name!=='COLOR' && attribute.Name!=='Size'">
                                    <div class="addeffect-section product-description collection-collapse-block border-product">
                                        <h6 class="product-title size-text collapse-block-title">{{attribute.Name}}
                                            <ng-container *ngIf="attribute.Name=='Size'">
                                                <span style=" margin-right: 15px;">
                                                    <a data-bs-toggle="modal" data-bs-target="#sizemodal">size chart</a>
                                                </span>
                                            </ng-container>
                                        </h6>
                                        <div class="collection-collapse-block-content row">
                                            <div class="size-box">
                                                <ng-container *ngFor="let attributeVal of attribute.AttributeValue;let i = index;">
                                                    <div class="float-start px-2">
                                                        <div class="attribute-btn">
                                                            <button class='btn btn-solid' [classList]="attribute.Name"
                                                                [id]="attributeVal.Id" 
                                                                (click)="onChangeAttribute($event, attribute.Id, attributeVal)">
                                                                {{attributeVal.Name}}
                                                            </button>
                                                        </div>
                                                        <!-- <div class="attribute-btn">
                                                            <button type="button" [id]="attributeVal.Id"
                                                                [class]="['btn btn-solid '+ attribute.Name]"
                                                                (click)="onChangeAttribute($event, attribute.Id, attributeVal)">{{attributeVal.Name}}</button>
                                                        </div> -->
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="addeffect-section product-description">

                            <!-- <div class="product-count border-product">
                            <ul>
                                <li>
                                    <img src="../assets/images/icon/truck.png" class="img-fluid" alt="image">
                                    <span class="lang">Free shipping for orders above {{500 | currency:"INR"}} INR</span>
                                </li>
                            </ul>
                        </div>
                        <div class="border-product">
                            <h6 class="product-title">Sales Ends In</h6>
                            <div class="timer">
                                <p id="demo"></p>
                            </div>
                        </div>
                        <div class="border-product">
                            <h6 class="product-title">shipping info</h6>
                            <ul class="shipping-info">
                                <li>100% Original Products</li>
                                <li>Free Delivery on order above {{799 | currency:"INR"}}</li>
                                <li>Pay on delivery is available</li>
                                <li>Easy 30 days returns and exchanges</li>
                            </ul>
                        </div> -->
                            <div class="border-product">
                                <h6 class="product-title">share it</h6>
                                <div class="product-icon">
                                    <ul class="product-social">
                                        <li><a [href]="[createShareLink('facebook')]" [target]="'_blank'"><i class="fa fa-facebook-f"></i></a></li>
                                        <!-- <li><a [href]="[createShareLink('google')]" [target]="'_blank'"><i class="fa fa-google-plus"></i></a></li> -->
                                        <li><a [href]="[createShareLink('twitter')]" [target]="'_blank'"><i class="fa fa-twitter"></i></a></li>
                                        <li><a [href]="[createShareLink('instagram')]" [target]="'_blank'"><i class="fa fa-instagram"></i></a></li>
                                        <!-- <li><a [href]="[createShareLink('rss')]" [target]="'_blank'"><i class="fa fa-rss"></i></a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->







<!-- product-Specification starts -->
<section class="tab-product pt-0 mt-5" *ngIf="specificationList && specificationList.length > 0 || (isUserLoggedIn() && isProductPurchased)">
    <div class="container">
        <div class="row">
            <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">

                <ng-container *ngIf="specificationList">
                    <ng-container *ngFor="let specification of specificationList; let i = index;">
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" id="{{specification.Id}}-tab"
                                [href]="['#'+specification.Id]" role="tab" [ngClass]="{'active': selectedTab === i}"
                                (click)="selectTab(i)">{{specification?.SpecificationName}}
                            </a>

                            <div class="material-border"></div>
                        </li>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="isUserLoggedIn() && isProductPurchased">
                    <li class="nav-item">
                        <a class="nav-link" id="review-top-tab" data-bs-toggle="tab" href="#top-review"
                            role="tab" aria-selected="false"><i class="icofont icofont-contacts"></i>Write a Review</a>
                        <div class="material-border"></div>
                    </li>
                </ng-container>

            </ul>


            <div class="tab-content nav-material" id="top-tabContent">
                <ng-container *ngFor="let specification of specificationList; let i = index;">
                    <div class="tab-pane fade" id="{{specification.Id}}" role="tabpanel" [ngClass]="{'show active': selectedTab === i}">
                        <div class="container specview">
                            <div class="tab-content nav-material" id="spec-tabContent-container">
                                <ng-container *ngFor="let specContent of specification.subSpecificationList; let j = index;">
                                    <div class="product-tab-discription">
                                       <div class="part">
                                            <h5 class="inner-title">{{specContent?.CategoryName}}:</h5>
                                            <p>{{specContent?.Name}}</p>
                                        </div>
                                        <!-- <div class="part">
                                            <h5 class="inner-title">size & fit:</h5>
                                            <img [src]="specContent?.FilePath" [attr.alt]="specContent?.ImageName">
                                        </div> -->
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isUserLoggedIn() && isProductPurchased">
                    <div class="tab-pane fade" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">
                        <form class="theme-form" [formGroup]="reviewForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="form-row row">
                                <div class="col-md-12">
                                    <div class="media">
                                        <label>Rating</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="media-body ms-3">
                                        <div class="rating three-star">
                                            <ngb-rating [max]="5" [(rate)]="starRating" [readonly]="false"
                                                formControlName="Stars" required></ngb-rating>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="f.Stars.value == null || f.Stars.touched || f.Stars.errors">
                                        <small class="text-danger font-weight-bold">
                                            Please provide a star rating.
                                        </small>
                                    </ng-container>
                                </div>

                                <div class="col-md-6">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" id="name" name="CustomerName"
                                        placeholder="Enter Your name" formControlName="CustomerName" required [ngClass]="{
                                            'is-invalid': (f.CustomerName.touched && f.CustomerName.errors)}">

                                    <ng-container *ngIf="f.CustomerName.touched && f.CustomerName.errors && f.CustomerName.errors.required">
                                        <small class="text-danger font-weight-bold">
                                            Please provide a customer name.
                                        </small>
                                    </ng-container>
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" name="Email" placeholder="Email"
                                        formControlName="Email" required [ngClass]="{'is-invalid': (f.Email.touched && f.Email.errors)}">
                                    <ng-container *ngIf="f.Email.touched && f.Email.errors && f.Email.errors.required">
                                        <small class="text-danger font-weight-bold">
                                            Please provide a email.
                                        </small>
                                    </ng-container>
                                </div>

                                <div class="col-md-12">
                                    <label for="review"><b>Review</b></label>
                                    <textarea class="form-control" placeholder="Wrire Your Testimonial Here"
                                        id="exampleFormControlTextarea1" rows="6" name="Review"
                                        formControlName="Review"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <button class="btn btn-solid" type="submit">Submit Your Review</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-container>

            </div>

        </div>
    </div>
</section>



<!-- related product section start -->
<ng-container *ngIf="recommendedProducts && recommendedProducts.length > 0">
    <section class="section-b-space ratio_asos mb-3">
        <div class="container">
            <div class="row">
                <div class="col-12 product-related">
                    <h2>related products</h2>
                </div>
            </div>
            <div class="row search-product">
                <ng-container *ngFor="let item of recommendedProducts; let i=index">
                    <span>{{i+1}}</span>
                    <!-- <ng-container *ngIf="item.FilePath"> -->
                        <div class="col-xl-2 col-md-4 col-6" >
                            <div class="product-box">
                                <div class="img-wrapper">
                                    <div class="front ">
                                        <a [routerLink]="['/product', item.Slug, item.ItemId]",
                                            routerLinkActive="router-link-active"
                                            [target]="'_blank'" class="">
                                            <img [src]="[item.FilePath]" class="img-fluid blur-up lazyload bg-img" alt=""></a>
                                    </div>

                                    <div class="cart-info cart-wrap">
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#addtocart"
                                            title="Add to cart" (click)="addToCartAndWishList(item)">
                                            <i class="ti-shopping-cart"></i></button>
                                        <button type="button" data-bs-toggle="modal" data-bs-target="#buyNow" title="Buy Now"
                                            (click)="navigateToBuyProduct(item)"></button>
                                        <a href="javascript:void(0)" title="Add to Wishlist"
                                            (click)="addToCartAndWishList(item, 1, 1)"><i class="ti-heart" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="product-detail">
                                    <span class="score">
                                        <div class="score-wrap">
                                            <span class="stars-active" [style.width.%]="item.Rating/5*100">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </span>
                                            <span class="stars-inactive">
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </span>
                                    <a [routerLink]="['/product', item.Slug, item.ItemId]" [target]="'_blank'">
                                        <h6>{{item?.ItemName}}</h6>
                                    </a>
                                    <h4>{{item?.SaleRate | currency:"INR"}} <del>{{item?.MrpRate | currency:"INR"}}</del></h4>
                                </div>
                            </div>
                        </div>
                    <!-- </ng-container> -->
                </ng-container>
            </div>
        </div>
    </section>
</ng-container>


<!-- section start -->
<ng-container *ngIf="topTenReviews && topTenReviews.length > 0">
    <section class="section-b-space blog-detail-page review-page">
        <div class="container">
            <div class="row">
                <div class="col-12 product-related">
                    <h2>Ratings & Reviews</h2>
                </div>
            </div>
            <div class="row">
                <ng-container  *ngFor="let userReview of topTenReviews | paginate: { itemsPerPage: count, currentPage:p}">
                <div class="col-sm-12 w-100 py-2">
                    <ul class="comment-section">
                            <li>
                                <div class="media">
                                    <img src="../assets/images/avatar.png" alt="Generic placeholder image">
                                    <div class="media-body">
                                        <h6>{{ userReview?.CustomerName }} <span>({{ userReview?.CreatedOn | date:'dd MMMM yyyy'}}
                                            <!-- at {{ userReview?.CreatedOn | date:'hh mm a'}} -->
                                            )</span></h6>
                                        <p>{{userReview?.Review}}</p>

                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
</ng-container>
<!-- Section ends -->
