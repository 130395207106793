<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Shipping Policy</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Shipping Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- about section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
           
            <div class="col-sm-12">
                <h4 class="h6 mb-3 text-dark lh-base">Thank you for shopping at G9kart! We are committed to providing
                    you with a seamless and hassle-free shopping experience. This Shipping Policy outlines important
                    information about how we handle the shipment of your orders.</h4>
            </div>

            <h4 class="h4">About Shipping Policies</h4>
            <div class="col-sm-12">
                <h4 class="h5">Order Processing Time </h4>
                <p class="h6 text-dark lh-base mb-3 ">All orders are processed within 2-5 business days from the date of purchase. Please note that business days do not include weekends or public holidays.</p>
                

                <h4 class="h5">Shipping Methods</h4>
                <p class="h6 text-dark lh-base mb-2">We offer the following shipping methods:</p>
                <p class="h6 text-dark lh-base mb-2 ms-3"><strong>Standard Shipping:</strong> Estimated delivery within 5-7 business days.</p>
                <p class="h6 text-dark lh-base mb-3 ms-3"><strong>Expedited Shipping:</strong> Estimated delivery within 2-5 business days.</p>
               

                <h4 class="h5">Shipping Rates</h4>
                <p class="h6 text-dark lh-base mb-3 ">Shipping rates are calculated based on the destination,
                    package weight, and selected shipping method. You can view the shipping cost during the
                    checkout process.</p>

                <h4 class="h5">Free Shipping</h4>
                <p class="h6 text-dark lh-base mb-3 ">Once your order has been shipped, you will receive a
                    confirmation email with a tracking number. You can use this tracking number to monitor the
                    progress of your shipment on our website or the carrier's website</p>
                
                <h4 class="h5">Shipment Delays</h4>
                <p class="h6 text-dark lh-base mb-3 ">While we make every effort to ensure that your order is delivered
                    promptly, please note 	 such as weather, customs, or carrier issues. We appreciate your
                    understanding in such cases.</p>
                
                <h4 class="h5">Address Accuracy</h4>
                <p class="h6 text-dark lh-base mb-3 ">It is essential to provide accurate shipping information during the
                    checkout process. We are not responsible for any delays or issues caused by incorrect shipping
                    details. Please double-check your shipping address to ensure the smooth delivery of your order.</p>
                
                <h4 class="h5">Lost or Damaged Packages</h4>
                <p class="h6 text-dark lh-base mb-3 ">In the unfortunate event that your package is lost or arrives
                    damaged, please contact our customer support team within 14 days of the delivery date. We will work
                    to resolve the issue promptly and provide further instructions.</p>
                
                <h4 class="h5">Returns and Exchanges</h4>
                <p class="h6 text-dark lh-base mb-3 ">For information about our return and exchange policy, please
                    refer to our <a href="/cancellation-policy">Cancellation, Returns & Refunds Policy</a>.</p>
                
                <h4 class="h5">Contact Information</h4>
                <p class="h6 text-dark lh-base mb-3 ">If you have any questions or concerns regarding our shipping
                    policy, please contact our customer support team at <a href="mailto:support@g9kart.com">support@g9kart.com</a></p>
                
                <h4 class="h5">Shipping Policy Updates</h4>
                <p class="h6 text-dark lh-base mb-3 ">Our Shipping Policy is subject to occasional updates. Any revisions will be promptly displayed on this page, and we recommend periodically checking for changes.</p>
                
            </div>

            <div class="col-sm-12 bg-info-subtle mt-3 py-3 rounded-2">
                <h5 class="h6 mb-3 text-dark lh-base">By placing an order with G9kart, you agree to abide
                    by the <a href="/terms-conditions">terms and conditions</a> outlined in this Shipping Policy. We appreciate your trust
                    and are dedicated to providing you with the best possible shopping experience.</h5>

                <h5 class="h6 mb-3 text-dark lh-base">Thank you for choosing G9kart!</h5>
            </div>
            
        </div>
    </div>
</section>
<!-- about section end -->