<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Privacy Policy</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- about section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-12">
                <h4 class="h5">  What information does G9 Kart collect? </h4>
                <p class="h6 mb-2 text-dark">At G9KART, we are committed to protecting your privacy and ensuring the security
                    of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your
                    personal data when you use our e-commerce platform.
                </p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Information We Collect </h4>
                <p class="h6 mb-2 text-dark">We may collect various types of information from you, including but not limited to</p>
                <ul class="list-group list-group-flush list-group-numbered ">
                    <li class="list-group-item border-0 h6 text-dark"><strong>Information:</strong> Such as your name, address, email, and phone number. </li>
                    <li class="list-group-item border-0 h6 text-dark"> <strong>Payment Information:</strong> When you make a purchase on our website, we collect payment details,
                        such as credit card information.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark"><strong>Account Information:</strong> Information provided during the account creation process, including username and password.</li>
                    <li class="list-group-item border-0 h6 text-dark"><strong>Device and Browsing Information:</strong> We may collect information about your device and how you interact with our website, including your IP address, browser type, and cookies.</li>
                </ul>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">How We Use Your Information? </h4>
                <p class="h6 mb-2 text-dark">We may use your personal information for the following purposes</p>
                <ul class="list-group list-group-flush list-group-numbered ">
                    <li class="list-group-item border-0 h6 text-dark">
                        To process and fulfill your orders.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        To communicate with you about your orders, product updates, and promotions.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        To improve and customize your shopping experience.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        To comply with legal and regulatory requirements.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        To protect the security and integrity of our website and services.
                    </li>
                </ul>
            </div>



            <div class="col-sm-12">
                <h4 class="h5"> Information Sharing? </h4>
                <p class="h6 mb-2 text-dark">We do not sell or rent your personal information to third parties.
                    However, we may share your information with trusted third-party service providers who help
                    us operate our website and offer our services, such as payment processors and shipping
                    companies. We ensure that these third parties maintain the same level of data protection and
                    security as we do.
                </p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Your Rights </h4>

                <p class="h6 mb-2 text-dark">You have the right to: </p>
                <ul class="list-group list-group-flush list-group-numbered ">
                    <li class="list-group-item border-0 h6 text-dark">
                        Access and correct your personal information.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        Opt out of receiving promotional communications.
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        Request the deletion of your account and personal data
                    </li>
                    <li class="list-group-item border-0 h6 text-dark">
                        Raise concerns about the use of your data by contacting us at [Contact Information].
                    </li>
                </ul>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Cookies </h4>
                <p class="h6 mb-2 text-dark">Yes, Our website uses cookies to enhance your browsing experience. You can manage your cookie preferences by adjusting your browser settings.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Security </h4>
                <p class="h6 mb-2 text-dark">We implement reasonable security measures to protect your personal information. However, no method of data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your information.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Changes to This Policy </h4>
                <p class="h6 mb-2 text-dark">We may update this Privacy Policy as necessary to reflect changes in our practices and legal requirements. The updated policy will be posted on our website with the revised effective date.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Contact Us </h4>
                <p class="h6 mb-2 text-dark">If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at <a href="mailto:support@g9kart.com">support@g9kart.com</a>.</p>
                <p class="h6 mb-2 text-dark">Thank you for trusting <strong>G9KART</strong> with your personal information. We are committed to protecting your privacy and providing a secure and enjoyable shopping experience.</p>
            </div>

        </div>
    </div>
</section>
<!-- about section end -->
