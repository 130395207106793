    <!-- breadcrumb start -->
    <div class="breadcrumb-section">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="page-title">
                        <h2>search</h2>
                    </div>
                </div>
                <div class="col-sm-6">
                    <nav aria-label="breadcrumb" class="theme-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="breadcrumb-item active">search</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <!-- breadcrumb End -->


    <!--section start-->
    <section class="authentication-page">
        <div class="container">
            <section class="search-block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <form class="form-header">
                                <div class="input-group">
                                    <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)"
                                        placeholder="Search Products......">
                                    <div class="input-group-append">
                                        <button class="btn btn-solid"><i class="fa fa-search"></i>Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- section end -->



    <section class="blog pt-5 ratio2_3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ngx-slick-carousel class="carousel" [config]="offerCarouselConfig">
                        <ng-container *ngFor="let offer of couponOffers;">
                            <div class="col-md-12" ngxSlickItem class="slide col-md-4 p-3">
                                <a href="javascript:void(0)">
                                    <!-- class="classic-effect" -->
                                    <div>
                                        <div class="blur-up lazyloaded">
                                            <ng-container *ngIf="offer.Img">
                                                <img [src]="offer.Img" 
                                                    class="coupon-img img-fluid blur-up lazyload bg-img" 
                                                    alt="{{ offer?.Name }}" />
                                            </ng-container>
                                            <ng-container *ngIf="!offer.Img">
                                                <img src="../../../assets/images/no-image.png"
                                                    class="coupon-img img-fluid blur-up lazyload bg-img"
                                                    alt="offer_img" />
                                            </ng-container>
                                        </div>
                                        <!-- <span></span> -->
                                    </div>
                                </a>
                                <div class="offer_content text-center">
                                    <h3 class="slide_title">{{ offer?.Name }}</h3>
                                    <h6>Use Code: {{ offer?.Code }}</h6>
                                    <!-- <button class="btn btn-primary small-bt" 
                                        (click)="navigateToCategoryWithCoupon(offer?.CouponId)">
                                        Shop Now
                                    </button> -->
                                    <button class="btn btn-primary small-bt" 
                                        (click)="navigateToCategoryWithCoupon(offer)">
                                        Shop Now
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </section>
