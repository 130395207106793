import { Component } from '@angular/core';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {
  constructor(private seoService: SeoService){
    seoService.updateMetaTags('Terms and Conditions - G9Kart', 
    'Read and agree to the terms and conditions of G9Kart. Learn about our policies, rules, and regulations governing the use of our website and services.',
    'Terms and Conditions, Website Policies, User Agreement, Terms of Service, Legal Agreement, Usage Rules, Website Rules, Privacy Policy, Data Protection, Compliance Requirements');
  }
}
