import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { ToasterService } from 'src/app/toaster.service';

@Component({
  selector: 'app-get-offers',
  templateUrl: './get-offers.component.html',
  styleUrls: ['./get-offers.component.scss'],
})
export class GetOffersComponent {
  couponOffers: Array<any> = [];

  // carousel config
  offerCarouselConfig = {
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  constructor( private router: Router, private _api: ApiService ) {
    this.getCouponList();
  }

  /** 
 * This function retrieves the list of available coupon offers from the API endpoint. 
 * If the response code is 1000, it updates the couponOffers array with the data received. 
 * If the data received contains coupon terms, it updates the couponItems array. 
 * If the response code is not 1000 or if no data is received, it sets the couponOffers array to an empty array. 
 */ 
  getCouponList() {
    this._api.getApi(API_ENDPOINTS.COUPON_OFFER, {}).subscribe((res: any) => {
      console.log('coupon: ', res);

      if (res.Code === 1000) {
        if (
          res.Data &&
          res.Data.CouponTimmingList &&
          res.Data.CouponTimmingList.length
        ) {
          this.couponOffers = [...res.Data.CouponTimmingList];
          this.updateCouponItems(res.Data.CouponTermsList);
        } else {
          this.couponOffers = [];
        }
      } else {
        this.couponOffers = [];
      }
    });
  }

/**
 * This function updates the items of each coupon offer based on the provided coupon terms list.
 * @param couponTermsList - The list of coupon terms.
 */
updateCouponItems(couponTermsList: any[]) {
  this.couponOffers.forEach((coupon) => {
    coupon.items = couponTermsList.filter(
      (x: any) => x.CouponId === coupon.CouponId
    );
  });
}

/** 
 * Navigate to coupon items based on coupon ID. 
 * @param couponId - ID of the coupon. 
 * @param offer - item object. 
 */ 
navigateToCategoryWithCoupon = (offer: any) => {
    const query = {
      Type: 'Category',
      Id: offer?.couponId,
    };

    this._api
      .getApi(API_ENDPOINTS.COUPON_OFFER, query)
      .subscribe((res: any) => {
        if (res.Code === 1000 && res.Data.CouponTermsList.length > 0) {
          this.router.navigate(['category', offer.Slug], {
            queryParams: {
              categoryId: 0,
              categoryParentId: 0,
              couponid: offer?.couponId,
            },
          });
        }
      });
  };
}
