<!-- breadcrumb start -->
<app-breadcrumb-section screenName="Ratings & Reviews" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->

<!-- section start -->

<section class="section-b-space blog-detail-page review-page">

    <ng-container *ngIf="ratingAndReviews.length === 0">
        <div class="w-100 text-center" >
            <span class="fs-5 text-black-50">No Rating and Reviews</span>
        </div>
    </ng-container>

    <ng-container *ngIf="ratingAndReviews && this.numberOfReviews > 0">
        <div class="container">
            <div class="row">
                <div class="col-12 product-related">
                    <!-- <h2>Ratings & Reviews</h2> -->

                    <div class="rating">
                        <span class="score">
                            <div class="score-wrap">
                                <span class="stars-active" [style.width.%]="convertToPercentOverall()">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                </span>
                                <span class="stars-inactive">
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                    <i class="fa fa-star-o" aria-hidden="true"></i>
                                </span>
                            </div>
                        </span>
                    </div>
                    <h2> {{ averageRating }}</h2>

                </div>
            </div>
            <div class="row">
                <ng-container  *ngFor="let userReview of ratingAndReviews | paginate: { itemsPerPage: count, currentPage:p}">
                <div class="col-sm-12 w-100 py-2">
                    <ul class="comment-section">
                            <li>
                                <div class="media">
                                    <!-- <span class="border border-dark rounded-circle px-3 py-2 mx-2 my-2">
                                        <i class="fa fa-user fa-3x"></i>
                                    </span> -->
                                    <img src="../assets/images/avatar.png" alt="{{userReview?.CustomerName}}">
                                    <div class="media-body">
                                        <h6>{{ userReview?.CustomerName }} <span>({{ userReview?.CreatedOn | date:'dd MMMM yyyy'}} 
                                            <!-- at {{ userReview?.CreatedOn | date:'hh mm a'}} -->
                                            )</span></h6>
                                        <p>{{userReview?.Review}}</p>
                                        
                                        <p class="rating">
                                            <span class="score">
                                                <div class="score-wrap">
                                                    <span class="stars-active" [style.width.%]="convertToPercent(userReview?.Stars)">
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="stars-inactive">
                                                        <i class="fa fa-star-o" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </span>
                                            {{ userReview?.Stars }}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>

            <div class="pagination" *ngIf="this.numberOfReviews > 0">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>

        </div>
    </ng-container>
</section>

<!-- Section ends -->