<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Covenant of Users</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Covenant of Users</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- about section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-12">
            
                <p class="h6 mb-2 text-dark">The use of the “G9 KART” Application (including www.rasamv.company, 
                    hereinafter referred to as "the App" or "We" or "website" and its related apps, services and 
                    tools) is governed by the terms and conditions mentioned in this user agreement (hereinafter 
                    referred to as "the User Agreement" or "Agreement" or “Terms of Use”).
                </p>
                <p class="h6 mb-2 text-dark">G9 KART is a marketplace where users from all over India connect to 
                    buy and sell goods. It is owned by Rasa Multi Venture , a company incorporated under Companies 
                    Act, 1956 with a registered office at RASA-Multi Venture Private Limited,SCO No: 156-157,First
                    FloorSector 9c, Madhya Marag,Chandigarh-160009. By using the App in any manner, including but
                    not limited to visiting or browsing the App, you (User) agree to be bound by this Agreement,
                    including those additional terms and conditions, rules, policies, guidelines referenced herein
                    and/or available by hyperlink.
                </p>
                <p class="h6 mb-2 text-dark">This Agreement applies to all users of the App, including without
                    limitation, users who are vendors, retailers, customers, merchants, contributors of content,
                    information and other materials or services on the App. This Agreement has to be read in
                    conjunction, and not in derogation, with any other document/agreement singed/accepted by the
                    Users. Any inconsistency between the terms of this Agreement and the terms of the other
                    document(s)/agreement(s), will be decided and settled by G9 KART.
                </p>
            </div>
            
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Amendments </h4>    
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item">
                        <p class="h6 mb-2 text-dark">G9 KART reserves the right to update and modify this User Agreement,
                            as well as G9 KART Rules and Policies, as needed. Any changes will be communicated by posting
                            a revised version on the App. By continuing to use the App, Users acknowledge and accept these
                            revisions, thus remaining bound by G9 KART's evolving terms.
                        </p>
                    </li>
                </ul>        
            </div>

            <div class="col-sm-12">
                <h4 class="text-uppercase"> Qualification Criteria </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">The App may only be accessed and used by individuals who are legally eligible to enter into binding contracts in accordance with the Indian Contract Act of 1872.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> G9 KART: Your Premier Event Destination </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">G9 KART serves as a platform that enables users, in compliance with our policies, to engage in buying and selling activities within a fixed-price framework. Please note that G9 KART solely functions as a medium through which users can access a broader audience for their transactions. We want to emphasize that G9 KART does not directly participate in transactions between buyers and retailers.</p>
                        <p class="h6 mb-2 text-dark">All the information and products listed on our platform are provided "as is, where is." Consequently, G9 KART lacks control over the quality, safety, morality, or legality of the items listed, the accuracy of the listings, the retailers' ability to sell items, or the buyers' ability to complete purchases. It's important to understand that we do not pre-screen users or the content they provide.</p>
                        <p class="h6 mb-2 text-dark">G9 KART cannot guarantee that a transaction will be successfully completed. We are not responsible for any unsatisfactory or delayed services, or for issues arising from items that are out of stock, backordered, or otherwise unavailable. Please be aware that all items offered by retailers are available for a limited time and are subject to the retailers' supply.</p>
                        <p class="h6 mb-2 text-dark">It's essential to recognize that G9 KART does not transfer legal ownership of items from the retailer to the buyer. We also do not make any representations or warranties regarding the attributes of items or services listed on our platform, including their quality, value, or marketability. We do not endorse or support any specific sales or purchases made on our platform, and we are not liable for any errors or omissions, whether originating from us or third parties.</p>
                        <p class="h6 mb-2 text-dark">Furthermore, G9 KART cannot verify the true identity, age, or nationality of users. We do not make any representations or warranties concerning the attributes of our users, such as legal title, creditworthiness, identity, age, or nationality. We strongly recommend that buyers independently verify the legitimacy of any user they choose to transact with and exercise their best judgment.</p>
                        <p class="h6 mb-2 text-dark">Please understand that G9 KART is a venue, and as such, we are not responsible or liable for any content posted by retailers, other users, or third parties on our platform. Users should use our services at their own discretion and risk.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Buyer Policies and Recommendations </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">When making a purchase on our platform, it's essential to understand and adhere to our buyer policies. By completing a purchase, you are acknowledging your agreement to abide by these terms. Please carefully review the conditions of sale outlined in the item's description, provided that they do not violate our User Agreement or any applicable laws.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Your Well-being Matters </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">G9 KART prioritizes your safety and satisfaction. While we strive to create a secure shopping environment, we want to remind you that we cannot guarantee the absence of side effects from items purchased through our platform. We encourage you to exercise caution and conduct research before finalizing your purchase.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Leverage User Testimonials </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">To help you make informed decisions, we recommend exploring testimonials and feedback from other users who have interacted with the retailer you're interested in. Their experiences can offer valuable insights into the reliability and quality of the products and services offered.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Transparency and Responsibility </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item ">
                        <p class="h6 mb-2 text-dark">At G9 KART, we take proactive steps to verify the authenticity of our retailers. However, it's important to understand that we cannot confirm the identity of every party involved or guarantee the quality, safety, or legality of every listing. By using our platform, you acknowledge and accept this inherent risk.</p>
                    </li>
                </ul>
            </div>
            <div class="col-sm-12">
                <h4 class="text-uppercase"> Trust Your Instincts </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item">
                        <p class="h6 mb-2 text-dark">We encourage all our users to apply common sense when engaging in transactions. If you encounter any suspicious behavior or suspect any misuse of our services, please don't hesitate to contact G9 KART. Your vigilance helps maintain the integrity of our community and promotes a safer shopping experience for everyone.</p>
                    </li>
                </ul>

            </div>

            <div class="col-sm-12">
                <h4 class="text-uppercase"> RETAILER POLICIES </h4>
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item">
                        <h4>Redemption of Earnings:</h4>
                        <p class="h6 mb-2 text-dark ">G9 KART reserves the right to keep remittances to the Retailer on hold for the safety of 'www.rasamv.company' and its Users. In such cases, the Retailer will be notified and required to complete additional checks and verifications. G9 KART may also contact the Retailer for further information and documentation.</p>
                        <p class="h6 mb-2 text-dark">If a listing or account is terminated by G9 KART or if you close your account, you remain obligated to pay all unpaid fees plus any applicable penalties. G9 KART may issue warnings, temporarily or indefinitely suspend or terminate a retailer's membership, and even take legal action for non-payment of fees.</p>
                        <p class="h6 mb-2 text-dark">G9 KART reserves the right to refuse its services to anyone, for any reason, at any time.</p>
                    </li>

                    <li class="list-group-item">
                        <h4>Listing Description and Pricing:</h4>
                        <p class="h6 mb-2 text-dark">Retailers warrant that their listed items comply with G9 KART's policies and are legally sellable. Listings represent a binding offer at the specified price. The contract of purchase is formed when a member complies with the conditions and completes the checkout.</p>
                        <p class="h6 mb-2 text-dark">Retailers must provide accurate and up-to-date information about themselves. Listings may include text descriptions, graphics, and pictures relevant to the sale, while adhering to intellectual property and privacy rights.</p>
                        <p class="h6 mb-2 text-dark">All listed items must be kept in stock for successful sales fulfillment. Price alterations after a sale are not allowed to avoid transaction fees.</p>
                    </li>
                    <li class="list-group-item">
                        <h4>Sales: </h4>
                        <p class="h6 mb-2 text-dark">All sales are binding, and retailers must promptly ship orders or complete transactions. Transactions between buyers and retailers are legally binding contracts.</p>
                    </li>
                    <li class="list-group-item">
                        <h4>Handling of Credits:</h4>
                        <p class="h6 mb-2 text-dark">Users may earn credits through campaigns offered by G9 KART, subject to campaign rules. G9 KART may change the rules and terminate the credits system without refunds. Credits can be used solely for eligible product purchases through the Service as specified by G9 KART and cannot be exchanged for cash or transferred to others.</p>
                        <p class="h6 mb-2 text-dark">Upon account termination or membership lapse, all owned credits become invalid and cannot be reinstated.</p>
                    </li>
                    <li class="list-group-item">
                        <h4>Prohibited Goods:</h4>
                        <p class="h6 mb-2 text-dark text-">G9 KART retailers must comply with applicable laws and shipping restrictions for their items. While legal, some item types are prohibited on G9 KART's marketplace if they don't align with G9 KART's spirit and selling criteria.</p>
                        <p class="h6 mb-2 text-dark">These improved policies provide a clearer understanding of the terms and conditions for retailers on G9 KART's platform.</p>
                    </li>
                    
                </ul>
            </div>

        </div>
    </div>
</section>
<!-- about section end -->
