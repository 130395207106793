import { Component } from '@angular/core';
import { CommonService } from './common.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showSpinner = false;


  constructor(private _cs: CommonService,
    private router: Router) {
    if (this._cs.isVendorAgentTokenExist()) {
      this._cs.getAllCategories();
    } else {
      this._cs.vendorAgentTokenFetched.subscribe((res) => {
        this._cs.getAllCategories();
      })
    }
    this.router.events.subscribe((evt) => {
      if ((evt instanceof NavigationStart)) {
        this.showSpinner = true;
        //Once enableing Loader when no page load happen
        $('.loader_skeleton').css("display", "block");
      }
      if ((evt instanceof NavigationEnd)) {
        /*=====================
            01.Pre loader
            ==========================*/
        setTimeout(function () {
          $('.loader_skeleton').fadeOut('slow');
          $('body').css({
            'overflow': 'auto'
          });
          $('.loader_skeleton').css("display", "none");
        }, 1500);
        $('.loader_skeleton').remove('slow');
        $('body').css({
          'overflow': 'hidden'
        });
        /*Above code is of assets/js/Script.js
        */
        setTimeout(() => {
          this.showSpinner = false;
        }, 1000);
      }
    });
  }
}
