import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, take } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { APP_CONSTANT } from 'src/app/app.constant';
import { CommonService } from 'src/app/common.service';
import { ToasterService } from 'src/app/toaster.service';

export interface CategoryProudctInterface {
  categoryId: number;
  parentCategoryid: number;
  categoryName: string | null;
  products: Array<any>;
  categories: Array<any>;
  attributes: Array<any>;
  priceRange: Array<any>;
  slug: string | null;
}

@Component({
  selector: 'app-category-infinite-scroll',
  templateUrl: './category-infinite-scroll.component.html',
  styleUrls: ['./category-infinite-scroll.component.scss'],
})
export class CategoryInfiniteScrollComponent implements OnInit, OnDestroy {
  categoryId: string | undefined | null;
  categoryParentId: string | undefined | null;
  categoryName: string | undefined | null;
  e: any;
  visibleProductsCount: number = 24;
  categoryBannerImg: string = '';
  bannerImageDescription: string = '';
  categoryImg: string = '';

  AttributeStr: string = '';
  newProducts: any = [];
  selectedRange = APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE;
  minValue = APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE;
  maxValue = APP_CONSTANT.DEFAULT_SLIDER_CEIL_VALUE;
  options: any = {
    floor: APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE,
    ceil: APP_CONSTANT.DEFAULT_SLIDER_CEIL_VALUE,
  };
  utilityModal: CategoryProudctInterface = {
    categoryId: 0,
    parentCategoryid: 0,
    categoryName: '',
    products: [],
    categories: [],
    attributes: [],
    priceRange: [],
    slug: null,
  };
  listView = false;
  selectedFilterValue = 0;
  brands: any[] = [];
  rangeList: any;
  loading: boolean = false;
  page = 1;
  size = 12;
  throttle = 100;
  scrollDistance = 1;
  scrollUpDistance = 2;
  couponId: any;
  selectedCategory: any = {};
  colorAttributes: any = [];
  cartItems: any = [];
  slug: any;
  private brandListSubscription$: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    public _cs: CommonService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService
  ) {
    if (this._cs.isVendorAgentTokenExist()) {
      // this.getBrandsList();
    } else {
      this._cs.vendorAgentTokenFetched.subscribe(() => {
        // this.getBrandsList();
      });
    }
  }

  // **RUNNING
  ngOnInit(): void {
    // Subscribe to changes in route parameters
    this.route.params.subscribe((params) => {
      // Extract slug parameter from route
      const slug = params['slug'];
      const id = params['id'];
      this.slug = slug;
      // const name = params['name'];

      // Set slug in your utilityModal or wherever it's needed
      this.utilityModal.slug = slug;
      this.utilityModal.categoryId = id;
      // this.utilityModal.categoryId = id

      // Now you can use slug to perform any necessary operations
      this.page = 1;
      this.setSliderOptions();
      this.getProudctByCategory({ setAttribute: true, slug: slug });
      this.getSideCategories(slug);
    });

    // Fetch cart items
    this._cs.fetchCartItems().subscribe((cartItems: any) => {
      if (cartItems.Code === 1000 && cartItems.Data) {
        this.cartItems = cartItems.Data;
      }
    });
  }

  setEvents = () => {
    $('.collapse-block-title').on('click', function (e) {
      e.preventDefault;
      const speed = 300;
      const thisItem = $(this).parent(),
        nextLevel = $(this).next('.collection-collapse-block-content');
      if (thisItem.hasClass('open')) {
        thisItem.removeClass('open');
        nextLevel.slideUp(speed);
      } else {
        thisItem.addClass('open');
        nextLevel.slideDown(speed);
      }
    });

    $('.color-selector ul li').on('click', function () {
      $('.color-selector ul li').removeClass('active');
      $(this).addClass('active');
    });

    $('.color-w-name ul li').on('click', function () {
      $('.color-w-name ul li').removeClass('active');
      $(this).addClass('active');
    });

    //list layout view
    $('.list-layout-view').on('click', function () {
      $('.collection-grid-view').css('opacity', '0');
      $('.product-wrapper-grid').css('opacity', '0.2');
      $('.shop-cart-ajax-loader').css('display', 'block');
      $('.product-wrapper-grid').addClass('list-view');
      $('.product-wrapper-grid').children().children().removeClass();
      $('.product-wrapper-grid').children().children().addClass('col-lg-12');
      setTimeout(function () {
        $('.product-wrapper-grid').css('opacity', '1');
        $('.shop-cart-ajax-loader').css('display', 'none');
      }, 500);
    });

    //grid layout view
    $('.grid-layout-view').on('click', function () {
      $('.collection-grid-view').css('opacity', '1');
      $('.product-wrapper-grid').removeClass('list-view');
      $('.product-wrapper-grid').children().children().removeClass();
      $('.product-wrapper-grid').children().children().addClass('col-lg-3');
    });

    $('.product-2-layout-view').on('click', function () {
      if ($('.product-wrapper-grid').hasClass('list-view')) {
      } else {
        $('.product-wrapper-grid').children().children().removeClass();
        $('.product-wrapper-grid').children().children().addClass('col-lg-6');
      }
    });

    $('.product-3-layout-view').on('click', function () {
      if ($('.product-wrapper-grid').hasClass('list-view')) {
      } else {
        $('.product-wrapper-grid').children().children().removeClass();
        $('.product-wrapper-grid')
          .children()
          .children()
          .addClass('col-lg-4 col-6');
      }
    });

    $('.product-4-layout-view').on('click', function () {
      if ($('.product-wrapper-grid').hasClass('list-view')) {
      } else {
        $('.product-wrapper-grid').children().children().removeClass();
        $('.product-wrapper-grid')
          .children()
          .children()
          .addClass('col-xl-3 col-6');
      }
    });

    $('.product-6-layout-view').on('click', function () {
      if ($('.product-wrapper-grid').hasClass('list-view')) {
      } else {
        $('.product-wrapper-grid').children().children().removeClass();
        $('.product-wrapper-grid').children().children().addClass('col-lg-2');
      }
    });

    /*=====================
     18.filter sidebar js
     ==========================*/
    $('.sidebar-popup').on('click', function () {
      $('.open-popup').toggleClass('open');
      $('.collection-filter').css('left', '-15px');
    });
    $('.filter-btn').on('click', function () {
      $('.collection-filter').css('left', '-15px');
    });
    $('.filter-back').on('click', function () {
      $('.collection-filter').css('left', '-365px');
      $('.sidebar-popup').trigger('click');
    });
    $('.account-sidebar').on('click', function () {
      $('.dashboard-left').css('left', '0');
    });
    $('.filter-back').on('click', function () {
      $('.dashboard-left').css('left', '-365px');
    });

    $(() => {
      $('.product-load-more .col-grid-box')
        .slice(0, this.visibleProductsCount)
        .show();
      $('.loadMore').on('click', (e) => {
        e.preventDefault();
        $('.product-load-more .col-grid-box:hidden')
          .slice(0, this.visibleProductsCount)
          .slideDown();
        if ($('.product-load-more .col-grid-box:hidden').length === 0) {
          $('.loadMore').text('no more products');
        }
      });
    });
  };

  /**
   * Retrieves side categories based on the given id.
   * If id is provided, it finds the category with the matching id.
   * If id is not provided, it retrieves the first category from the list of all categories.
   * If the list of categories is empty, it waits for the categories to be fetched and then retrieves the first category.
   * Updates the utility modal with the retrieved subcategories.
   * Sets the category banner image, category image, and banner image description based on the retrieved category.
   * Handles the error case if no category is found.
   */
  getSideCategories = (slug: string) => {
    let categories = [];
    const allCategories = this._cs.allCategoriesWithSubCategory;

    if (allCategories && allCategories.length > 0) {
      // categories = id ? this._cs.allIndividualCatogories.find((cat: any) => cat.Id === id) : allCategories[0];
      categories = slug
        ? this._cs.allIndividualCatogories.find((cat: any) => cat.Slug === slug)
        : allCategories[0];
    } else {
      this._cs.catgoryFetched$.pipe(take(1)).subscribe(() => {
        const allCategories = this._cs.allCategoriesWithSubCategory;
        // categories = id ? this._cs.allIndividualCatogories.find((cat: any) => cat.Id === id) : allCategories[0];
        categories = slug
          ? this._cs.allIndividualCatogories.find(
              (cat: any) => cat.Slug === slug
            )
          : allCategories[0];
      });
    }

    if (categories) {
      this.utilityModal.categories = categories.subCategory || [];
      this.categoryBannerImg = categories.BannerImagesList
        ? categories.BannerImagesList[0].FilePath
        : '';
      this.categoryImg =
        categories.ImagesList && categories.ImagesList[0]
          ? categories.ImagesList[0].FilePath
          : '';
      this.bannerImageDescription = categories.Description || '';
    } else {
      // handle error case
    }
  };

  /**
   * This file contains the implementation of the CategoryInfiniteScrollComponent class.
   * It defines the prepareQuery method which prepares a query object based on various conditions.
   * The query object is used for fetching data related to categories, coupons, rates, brands, attributes, and user login.
   */
  prepareQuery = (e: { minValue?: number; maxValue?: number } = {}): Query => {
    const query: Query = { Type: 'Category' };

    if (this.couponId) {
      query.Type = 'coupon';
      query.Id = this.couponId;
    } 
    if (!this.slug) query.slug = this.slug;

    if (this.utilityModal.categoryId) {
      query.Id = this.utilityModal.categoryId;
    }

    if (e.minValue !== undefined && e.maxValue !== undefined) {
      query.MinRate = e.minValue;
      query.MaxRate = e.maxValue;
    }

    if (this.selectedFilterValue) {
      query.OrderBy = this.selectedFilterValue;
    }

    const selectedBrands = this.brands
      .filter((x) => x.selected)
      .reduce((acc, item) => acc.concat(item.Id), [] as string[])
      .join(',');

    if (selectedBrands) {
      query.BrandIds = selectedBrands;
    }

    if (typeof this.AttributeStr !== 'undefined') {
      query.Attr = this.AttributeStr;
    }

    const isUserLoggedIn = this._cs.isLogin();
    if (isUserLoggedIn) {
      query.CustomerLoginId = this._cs.getLoginUserId();
    }

    return query;
  };

  /**
   * This function retrieves products based on a category and updates the utilityModal with the results.
   * It also handles setting attributes, price range, and events associated with the retrieved products.
   */
  async getProudctByCategory(e: any) {
    this.utilityModal.products = [];
    this.loading = true;
    this.spinner.show('spinner1');

    const res: any = await this._cs.getProductList(this.prepareQuery(e));

    if (res.Data) {
      if (e.setAttribute && res.Data.Attribute) {
        this.utilityModal.attributes = [...res.Data.Attribute];
        this.colorAttributes = this.utilityModal.attributes.find(
          (attribute: any) => attribute.Name === 'Color'
        );
      }

      if (res.Data.Items.length) {
        this.utilityModal.products = [...res.Data.Items];
        setTimeout(() => {
          const loadMoreElement = document.querySelector('.loadMore');
          if (loadMoreElement) {
            loadMoreElement.dispatchEvent(new Event('click'));
          }
        }, 100);
      }

      if (
        e.setAttribute &&
        this.options.ceil === 10000 &&
        res.Data.PriceRange.length
      ) {
        this.utilityModal.priceRange = [...res.Data.PriceRange];
        const newOptions: any = Object.assign({}, this.options);
        newOptions.ceil = res.Data.PriceRange[0].MaxRate;
        this.maxValue = res.Data.PriceRange[0].MaxRate;
        newOptions.floor = res.Data.PriceRange[0].MinRate;
        this.minValue =
          res.Data.PriceRange[0].MinRate === this.maxValue
            ? 0
            : res.Data.PriceRange[0].MinRate;
        this.options = newOptions;
        this.selectedRange = this.maxValue;
      }

      setTimeout(() => {
        this.setEvents();
      }, 100);
    }

    this.spinner.hide('spinner1');
    this.loading = false;
  }

  getBrandsList() {
    this.brandListSubscription$ = this.apiService
      .getApi(API_ENDPOINTS.BRAND_LIST)
      .subscribe(
        (res: any) => {
          if (res.Code === 1000 && res?.Data) {
            this.brands = [...res.Data];
          } else {
            this.brands = [];
          }
        },
        (error: any) => {
          // Handle error
        }
      );
  }

  addToCartAndWishList = (item: any, itemIndex: number, addType?: any) => {
    item.Qty = 1;
    this._cs.validateItemIsInCart(item, this.cartItems);
    this._cs.addToCartAndWishList(item, addType);
  };

  onChangePrice() {
    let e = {
      minValue: 0,
      maxValue: this.selectedRange,
      setAttribute: false,
    };
    this.getProudctByCategory(e);
  }

  // onChangeAttribute = (e: any, attributeName: string, attributeId: string) => {
  //   console.log(attributeName + '' + attributeId);
  //   if (attributeName === 'Color') {
  //     this.colorAttributes.AttributeValue.forEach((color: any) => {
  //       if (this.AttributeStr.includes(color.Id)) {
  //         if (this.AttributeStr.indexOf(color.Id) === 0) {
  //           this.AttributeStr = this.AttributeStr.replace(color.Id, '');
  //         } else {
  //           this.AttributeStr = this.AttributeStr.replace(('-' + color.Id), '')
  //         }
  //       }
  //     });
  //     if (this.AttributeStr === '') {
  //       this.AttributeStr += attributeId;
  //     } else {
  //       this.AttributeStr += '-' + attributeId;
  //     }

  //   } else if (e.target.checked) {
  //     if (this.AttributeStr === '') {
  //       this.AttributeStr += attributeId;
  //     } else {
  //       this.AttributeStr += '-' + attributeId;
  //     }
  //   }

  //   if (e.target.checked === false) {
  //     if (this.AttributeStr.includes(attributeId)) {
  //       if (this.AttributeStr.indexOf(attributeId) === 0) {
  //         this.AttributeStr = this.AttributeStr.replace(attributeId, '');
  //       } else {
  //         this.AttributeStr = this.AttributeStr.replace(('-' + attributeId), '')
  //       }
  //       // this.AttributeStr = this.AttributeStr.replace(('-' + attributeId), '')
  //     }
  //   }
  //   this.getProudctByCategory({ setAttribute: false });

  // };

  /**
   * This code is responsible for handling attribute changes in an infinite scroll component.
   * It updates the AttributeStr variable based on the selected attributes and triggers a function to fetch products by category.
   */
  onChangeAttribute = (e: any, attributeName: string, attributeId: string) => {
    if (attributeName === 'Color') {
      const colorIds = this.colorAttributes.AttributeValue.map(
        (color: any) => color.Id
      );
      const regex = new RegExp(`(^|-)${attributeId}(-|$)`, 'g');
      this.AttributeStr = this.AttributeStr.replace(regex, '');

      if (this.AttributeStr === '') {
        this.AttributeStr += attributeId;
      } else {
        this.AttributeStr += '-' + attributeId;
      }
    } else {
      if (e.target.checked) {
        if (this.AttributeStr === '') {
          this.AttributeStr += attributeId;
        } else {
          this.AttributeStr += '-' + attributeId;
        }
      } else {
        const regex = new RegExp(`(^|-)${attributeId}(-|$)`, 'g');
        this.AttributeStr = this.AttributeStr.replace(regex, '');
      }
    }

    this.getProudctByCategory({ setAttribute: false });
  };

  // all private functions

  private setSliderOptions = () => {
    const newOptions: any = Object.assign({}, this.options);
    newOptions.ceil = APP_CONSTANT.DEFAULT_SLIDER_CEIL_VALUE;
    this.maxValue = APP_CONSTANT.DEFAULT_SLIDER_CEIL_VALUE;
    newOptions.floor = APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE;
    this.minValue = APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE;
    const ranges = [
      APP_CONSTANT.DEFAULT_SLIDER_FLOOR_VALUE,
      4000,
      6000,
      8000,
      APP_CONSTANT.DEFAULT_SLIDER_CEIL_VALUE,
    ];
    this.rangeList = this.createRangeList(ranges);
    this.options = newOptions;
  };

  private createRangeList(ranges: number[]) {
    return ranges.map((range, i) => {
      return {
        selected: false,
        lowerValue: ranges[i] ? ranges[i] : 1,
        upperValue: ranges[i + 1] ? ranges[i + 1] : 10000,
      };
    });
  }

  createLinkHandler = (endpoint: string, product: any): string => {
    //   // [queryParams]="{ 'productId':product.ItemId,'productParentId':product.ParentId}"
    return this._cs.sanitizeLink(endpoint, product.Slug, product.ItemId);
  };

  ngOnDestroy(): void {
    this.brandListSubscription$.unsubscribe();
  }
}

// interfaces

interface Query {
  Type: string;
  Id?: string | number;
  MinRate?: number;
  MaxRate?: number;
  OrderBy?: string | number;
  BrandIds?: string;
  Attr?: string;
  CustomerLoginId?: string;
  slug?: string;
}
