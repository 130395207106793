<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Guidelines</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Guidelines</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
            
                        
            <div class="col-sm-12">
                <h4 class="text-capitalize text-body "> About G9 KART </h4>    
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item">
                        <p class="h6 mb-2 text-dark">G9 KART is more than just an online marketplace; it's a vibrant community where people from all over India come together to buy and sell goods. At G9 KART, we act as a platform that enables users to connect, offering a safe and user-friendly environment to facilitate transactions. While we play a crucial role in connecting buyers and retailers, it's important to note that we are not directly involved in the transaction process. We provide all information and product listings on the App on an "as is where is" basis.</p>
                        <p class="h6 mb-2 text-dark">To maintain a positive and secure shopping experience for everyone, we have some essential policies in place:</p>
                    </li>
                    <li class="list-group-item">
                        <p class="h6 mb-2 text-dark"><strong>1. Prohibited Items: </strong> We do not allow the sale of prohibited items, services, or any products that violate our intellectual property policies. Your safety and satisfaction are our top priorities.</p>
                        <p class="h6 mb-2 text-dark"><strong>2. Open to Everyone: </strong> Anyone over the age of 18 can open a shop on G9 KART. We believe in inclusivity and welcome sellers from all walks of life.</p>
                        <p class="h6 mb-2 text-dark"><strong>3. Shop Policies: </strong> Each G9 KART shop has its own set of policies, payment methods, and shipping procedures. It's essential for shops to uphold these policies and provide excellent customer service.</p>
                        <p class="h6 mb-2 text-dark"><strong>4. Honesty Matters: </strong> Honesty is a cornerstone of our community. We expect shop owners to represent themselves, their businesses, and their products accurately.</p>
                        <p class="h6 mb-2 text-dark"><strong>5. Trust and Safety: </strong> Our Trust and Safety team is here to ensure a secure marketplace. We may contact shops in violation of our guidelines, aiming to help them comply with our rules. However, we reserve the right to close shops that do not adhere to our standards.</p>
                        <p class="h6 mb-2 text-dark"><strong>6. Guidelines Enforcement: </strong> We have the authority to remove listings that do not align with the principles outlined in G9 KART's guidelines.</p>
                        <p class="h6 mb-2 text-dark"><strong>7. Policy Updates: </strong> Please note that we may amend this User Agreement and our Rules and Policies at any time. Any changes will be communicated by posting a revised version on the App.</p>
                    </li>
                </ul>        
            </div>

            <div class="col-sm-12">
                <h4 class="text-capitalize text-body "> Retailer Guidelines </h4>    
                <ul class="list-group list-group-flush ">
                    <li class="list-group-item">
                        <p class="h6 mb-2 text-dark">At G9 KART, trust is the foundation of our marketplace. We want you to succeed as a retailer, and that begins with maintaining a transparent shop, offering exceptional customer service, and respecting our community. We've established Retailer Service Level Standards (SLS) to assist you in building and maintaining trust with your buyers.</p>
                        
                        <p class="h6 mb-2 text-dark">Here are some key guidelines to follow:</p>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <p class="h6 mb-2 text-dark"><strong>1. Accurate Listings: </strong> List only items that are for sale and belong to the categories mentioned on our app. Your listings should accurately represent the items and include high-quality photos.</p>
                                <p class="h6 mb-2 text-dark"><strong>2. Buyer Reviews: </strong> Be aware that buyers can leave reviews for items they've purchased. Provide excellent customer service to earn positive feedback.</p>
                                <p class="h6 mb-2 text-dark"><strong>3. Respect Copyright: </strong> Respect the intellectual property of others. If you believe someone has violated your copyright, you can report it to G9 KART for appropriate action.</p>
                                <p class="h6 mb-2 text-dark"><strong>4. Prohibited Items: </strong> Do not sell prohibited items, as outlined in our policies. We maintain strict standards for product safety and legality.</p>
                                <p class="h6 mb-2 text-dark"><strong>5. Shipping Commitment: </strong> Please adhere to your shop's shipping and processing times to ensure a smooth buying experience for your customers.</p>
                            </li>
                        </ul>
                        <p class="h6 mb-2 text-dark">By following these guidelines, you'll not only earn the trust of your buyers but also contribute to the thriving community that makes G9 KART a trusted and preferred online marketplace in Bharat.</p>
                    </li>
                </ul>        
            </div>
        </div>
    </div>
</section>
<!-- section end -->
