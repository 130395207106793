export const STORAGE_KEY = {
  USER_DETAIL: 'USER_DETAIL',
  SIDE_MENUS: 'SIDE_MENUS',
  SESSION_TIME_OUT: 14400000,
  ADMIN_TOKEN: 'ADMIN_TOKEN',
  USER_TOKEN: 'USER_TOKEN',
  CUSTOMER_ID: 'CUSTOMER_ID',
  STORAGE_METHOD_SET: 'SET',
  STORAGE_METHOD_GET: 'GET',
  STORAGE_METHOD_REMOVE: 'REMOVE',
  STORAGE_METHOD_CLEAR: 'CLEAR'
};
