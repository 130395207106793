import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private cookieAccepted = this.getCookie('cookieAccepted') === 'true'? true : false;

  get isCookieAccepted(): boolean {
    return this.cookieAccepted;
  }

  acceptCookies() {
    this.cookieAccepted = true;
    this.setCookie('cookieAccepted', 'true', 1);
  }

  declineCookies() {
    this.cookieAccepted = false;
    this.setCookie('cookieAccepted', 'false', 1);
  }

  private setCookie(name: string, value: string, days: number) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  }

  private getCookie(name: string): string | null {
    const cookieName = `${name}=`;
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.indexOf(cookieName) === 0) {
        return trimmedCookie.substring(cookieName.length, trimmedCookie.length);
      }
    }
    return null;
  }
}
