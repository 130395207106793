import { Component, ElementRef, Sanitizer, ViewChild, ViewContainerRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common.service';
import { Subject, debounceTime, delay, distinctUntilChanged, of, switchMap, takeUntil } from 'rxjs';
import * as _ from 'lodash';
import { API_ENDPOINTS } from '../api.endpoints';
import { ApiService } from '../api.service';
import { SweetAlertService } from '../sweetalert.service';
import { Router } from '@angular/router';
import { SigninSignupFormComponent } from '../signin-signup-form/signin-signup-form.component';
import { TokenInterceptorService } from '../token.interceptor';


@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent {
  categories: Array<any> = [];
  miniCart: Array<any> = [];
  cartItems: Array<any> = [];
  cartItemSubTotal: number = 0;
  isUserLoggedIn: boolean = false;
  menuCategories: Array<any> = [];
  totalItemsInCart: number = 0;
  private ngUnsubscribe = new Subject<void>();
  keyword = 'Name';
  searchResult: Array<any> = [];
  utilityModal: HeaderModalInterface = {};


  isLogin: boolean = false;
  signInSignUpModalRef: any;
  userName: string = '';
  @ViewChild('signInSignUpContainerRef', {
    read: ViewContainerRef,
    static: false,
  })
  signInSignUpContainerRef: ViewContainerRef | any;

  constructor(
    private spinner: NgxSpinnerService,
    private _cs: CommonService,
    private apiService: ApiService,
    private sweetAlert: SweetAlertService,
    private router: Router,
    private interceptorService: TokenInterceptorService
  ) {
    if (this._cs.isLogin()) {
      this.isLogin = true;
      this.utilityModal.userName = this._cs.getLoginUserName();
      this.userName = this.utilityModal.userName || '';
      if (this.utilityModal.userName && this.utilityModal.userName.length > 50) {
        this.utilityModal.userName = this.utilityModal.userName.substring(0, 50);
      }
    }

    this.getAllCategories();
    // Code of script JS
    const Screen_Width: any = $(window).width();
    this.utilityModal.searchOptionData = [];
  }

  ngOnInit() {
    this.isUserLoggedIn = this._cs.isLogin();
    this._cs.loggedInSuccess.subscribe((res: any) => {
      if (res) {
        this.isLogin = true;
        this.userName = this.utilityModal.userName || '';
      }
    });

    this._cs.loggedOutSuccess.subscribe((res: any) => {
      if (res) {
        this.isLogin = false;
      }
    });

    this._cs.profileUpdated.subscribe((res: any) => {
      if (res) {
        this.utilityModal.userName = this._cs.getLoginUserName();
        if (this.utilityModal.userName) {
          this.utilityModal.userName = this.utilityModal.userName.split(' ')[0];
        }
      }
    });
    this.interceptorService.sessionExpired.subscribe((res: any) => {
      if (res) {
        this._cs.logOut();
        this.openSignInSignupModal();
      }
    });
  }

  mouseEnter = (menu: string) => {
    $('.full-mega-menu').css('display', 'block');
  };

  mouseLeave = (menu: string) => {
    $('.full-mega-menu').css('display', 'none');
  };

  openSearch = () => {
    const el = document.getElementById('search-overlay');
    if (el) {
      el.style.display = 'block';
    }
    // $('.search-overlay').css('display', 'block');
  };

  openSignInSignupModal = (type?: string) => {
    this._cs.loadModalDynamically(
      this,
      'signInSignUpContainerRef',
      'signInSignUpModalRef',
      SigninSignupFormComponent,
      type,
      (data: any) => {}
    );
  };

  closeSearch = () => {
    const el = document.getElementById('search-overlay');
    if (el) {
      el.style.display = 'none';
    }
  };

  getAllCategories() {
    if (
      this._cs.allCategoriesWithSubCategory &&
      this._cs.allCategoriesWithSubCategory.length
    ) {
      this.assignCategory();
      this.triggerMenuJSScriptForHasSubMenu();
    } else {
      this._cs.catgoryFetched$.subscribe({
        next: (response: any) => {
          if (response) {
            this.assignCategory();
            this.triggerMenuJSScriptForHasSubMenu();
          }
        },
      });
    }
  }

  triggerMenuJSScriptForHasSubMenu = () => {
    setTimeout(function () {
      // Code of MEnu JS for Accordian icon
      const mainMenu: any = $('#main-menu');
      mainMenu.smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
      });
      const subMenu: any = $('#sub-menu');
      subMenu.smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: -8,
      });

      // Code of script JS
      const Screen_Width: any = $(window).width();
      if (Screen_Width < 1183) {
        jQuery('.menu-title h5').append('<span class="according-menu"></span>');
        jQuery('.menu-title').on('click', function () {
          jQuery('.menu-title').removeClass('active');
          jQuery('.menu-content').slideUp('normal');
          if (jQuery(this).next().is(':hidden') == true) {
            jQuery(this).addClass('active');
            jQuery(this).next().slideDown('normal');
          }
        });
        jQuery('.menu-content').hide();
      } else {
        jQuery('.menu-content').show();
      }

      /*=====================
        04. Mega menu js
      ==========================*/
      if (Screen_Width > '1200') {
        $('#hover-cls').hover(function () {
          $('.sm').addClass('hover-unset');
        });
      }
      if (Screen_Width > '1200') {
        $('#sub-menu > li').hover(
          function () {
            if ($(this).children().hasClass('has-submenu')) {
              $(this).parents().find('nav').addClass('sidebar-unset');
            }
          },
          function () {
            $(this).parents().find('nav').removeClass('sidebar-unset');
          }
        );
      }
    });
  };

  selectEvent(item: any) {
   
    if (item.Type === 'item') {
      // window.location.href=`/product?productId=${item.Id}`
      const href = this._cs.sanitizeLink('product', item.Slug, item.Id)
      this.router.navigate([href]);
    } else if (item.Type === 'category') {
      const href = this._cs.sanitizeLink('category', item.Slug, item.Id, item.ParentId);
      this.router.navigate([href]);
    }
    this.closeSearch();
  }
  
  onChangeSearch(search: string) {
    search = _.trim(search);
    if (search && search.length >= 3) {
      const query = {
        SearchString: search,
      };
  
      // Introducing a 3-second delay before calling the API
      of(null).pipe(
        debounceTime(2000),
        distinctUntilChanged(),
        switchMap(() =>
          this.apiService.getApi(API_ENDPOINTS.SEARCH_PRODUCT, query, this._cs.isLogin())
        )
      ).subscribe({
        next: (response: any) => {
          if (
            response &&
            response.Code === 1000 &&
            !_.isEmpty(response.Data)
          ) {
            this.searchResult = [...response.Data];
          } else {
            this.searchResult = [];
          }
        },
        error: (err: any) => {
          this.searchResult = [];
        },
        complete: () => {},
      });
    }
  }

  assignCategory() {
    this.categories = [...this._cs.allCategoriesWithSubCategory];
    this.categories = this.categories.filter(
      (category: any) =>
        category.ImagesList &&
        category.ImagesList.length &&
        category.ImagesList[0].FilePath
    );
    this.menuCategories = this.categories.filter(
      (cat: any) => cat.ShowAsMenu === 1
    );

    this._cs
      .getCarItemCount$()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (count) => {
          this.getCartItems();
        },
        error: () => {},
        complete: () => {},
      });
  }

  getCartItems = () => {
    let totalAmount: number = 0;
    let totalItems: number = 0;
    const query = {
      CustomerLoginId: this._cs.isLogin() ? this._cs.getLoginUserId() : 0,
      IsWishingItem: false,
      IsFromMobile: 0,
    };

    this.apiService
      .getApi(API_ENDPOINTS.CART_LIST, query, this._cs.isLogin())
      .subscribe({
        next: (response: any) => {
          if (
            response.Code === 1000 &&
            response.Data &&
            response.Data.AddToCartMainDetailsList.length
          ) {
            this.cartItems = response.Data.AddToCartMainDetailsList;

            this.miniCart = this.cartItems.slice(0, 2); // Create a new array with only the first two items
            totalAmount = this.cartItems.reduce(
              (sum: number, item: any) => sum + item.SaleRate * item.Qty,
              0
            ); // Calculate the total amount
            totalItems = this.cartItems.reduce(
              (sum: number, item: any) => sum + item.Qty,
              0
            ); // Calculate the total number of items
          }
          this.totalItemsInCart = totalItems; // Set the cartItemCount to the total number of items
          this.cartItemSubTotal = totalAmount; // Set the totalAmount for totalItems in cart
        },
      });
  };

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onMainMenuClick() {
    const el = document.getElementById('main-menu');
    if (el) {
      el.style.right = '0';
    }
  }
  openMainMenu() {
    const el = document.getElementById('main-menu');
    if (el) {
      el.style.right = '-300';
    }
  }

  removeItems(item: any) {
    if (!item) {
      return;
    }

    const query = {
      ItemID: item.ItemId || 0,
      IsWishList: 0,
      IsMove: 0,
      CartId: item.Id || 0,
    };

    this.apiService
      .getApi(API_ENDPOINTS.EMPTY_CART_ITEMS, query, this._cs.isLogin())
      .subscribe({
        next: (response: any) => {
          if (response.Code === 1000 && Number(response.Data) === 1000) {
            this.sweetAlert.showToast('success', 'Item removed from cart.');
            this._cs.updateCartItemCount();
            this.miniCart = [];
            this.getCartItems();
          } else if (response.Code === 1000 && Number(response.Data) === 5000) {
            this.sweetAlert.showToast('info', 'Item is already in your cart.');
          }
        },
        error: (err: any) => {
          console.error(err);
          // Handle error scenario here
        },
        // complate: () => {
        //   this.getCartItems();
        // }
      });
  }

  logOut = () => {
    this._cs.logOut();
  };
}

export class HeaderModalInterface {
  categoryList?: Array<any>;
  menuCategoryList?: Array<any>;
  userName?: string;
  categoryImageSrc?: string;
  searchString?: string;
  searchOptionData?: Array<any>;
}