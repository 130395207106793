import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import * as _ from 'lodash';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { APP_CONSTANT } from 'src/app/app.constant';
import { CommonService } from 'src/app/common.service';
import { SeoService } from 'src/app/seo.service';
import { SweetAlertService } from 'src/app/sweetalert.service';

declare const $:any;
@Component({
  selector: 'app-product-detail-page',
  templateUrl: './product-detail-page.component.html',
  styleUrls: ['./product-detail-page.component.scss'],
})
export class ProductDetailPageComponent implements OnInit {
  @ViewChild('pinCodeCheckForm', { static: false }) pinCodeCheckForm: | NgForm | any;
  @ViewChild('mainImageslickModal') mainImageslickModal: | SlickCarouselComponent | undefined;
  @ViewChild('signInSignUpContainerRef', {read: ViewContainerRef, static: false, })
  ratingAndReviews: any[] = [];
  starRatings: Array<number> = []; // Example array of star ratings
  averageRating!: number;
  p: any = 1;
  count: any = 5;
  ItemId: any;
  page: any;

  slideNumber: number = 0;
  pinCode!: number;
  isDeliverable: number | null = null;
  colorAttributes: any = [];
  
  itemreviewid: number = 0;
  productDetails: any;
  Offerdetails: any;
  itemDetail: any;
  itemRateList: any[] = [];
  slideConfig = {
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: false,
    autoplaySpeed: 500,
  };
  starRating: number = 0;
  reviewForm!: FormGroup;
  Images: any[] = [];
  largeImage: any;
  mediumImage: any;
  specificationList: any[] = [];

  recommendedProducts: any[] = [];
  
  // working...
  slideConfig0 = { slidesToShow: 3, slidesToScroll: 1,  vertical: true, verticalSwiping: true, };
  slideConfig1 = { slidesToShow: 3, slidesToScroll: 3 };

  // Attributes vars
  allAttributeIds: Array<number> = [];
  itemAttributeList: any = [];
  attributeStr: any = {};
  attributeIdStr: string = ''; // matching attributeid string with recieved order
  cartItems: Array<any> = [];

  attached: boolean = false;

  imageContainer: any;
  selectedTab: number = 0;

  // working..
  slug: string = '';
  productId: number = 0;
  isProductPurchased: boolean = false;
  IsTopProduct: boolean = false;
  isOutOfStock: boolean = false;
  isAllowNegativePurchase: boolean = false;
  AvailableStock: number = 0;
  IsLatest:boolean = false;
  IsSaver:boolean = false;
  

  //working: for rate and review
  stockLastOneMessage = APP_CONSTANT.STOCK_LAST_ONE;
  stockLastTwoMessage = APP_CONSTANT.STOCK_LAST_TWO;
  stockLastFewMessage = APP_CONSTANT.STOCK_LAST_FEW;
  
  userReviews: any[] = [];
  topTenReviews: any[] = [];
  totalRatingOfProduct: number = 0;
  totalReviews: number = 0;
  averageRatingOfProduct = 0;
  numberofpage: Array<any> = [];
  lastPageNumber!: number;
  pagenumber: any = 1;
  sliceddata: any;
  totalRecord: number = 0;

  constructor(
    private route: ActivatedRoute,
    private _cs: CommonService,
    private _api: ApiService,
    private router: Router,
    private sweetAlertService: SweetAlertService,
    private seoService: SeoService,
  ) {}
  // TODO: OK
  ngOnInit(): void {
    if (this._cs.isVendorAgentTokenExist()) {
      this.initData();
      // this.getRateAndReviews();
    } else {
      this._cs.vendorAgentTokenFetched.subscribe((res) => {
        this.initData();
        // this.getRateAndReviews();
      });
    }

    this._cs.fetchCartItems().subscribe((response: any) => {
      this.cartItems = response ? response : [];
    });

    this.reviewForm = new FormGroup({
      Stars: new FormControl(0, [Validators.required]), // Default value is 0, can set it to any initial value
      CustomerName: new FormControl('', Validators.required),
      Email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      Review: new FormControl('', Validators.required),
    });

  }
  // TODO: OK
  get f(): any {
    return this.reviewForm.controls;
  }
  // TODO: OK
  selectTab(index: number) {
    this.selectedTab = index;
  }
  // TODO: OK
  onSubmit = (): void => {
    if (this.reviewForm.controls['Stars'].status === 'INVALID') {
      return;
    }

    if (this.reviewForm.valid === false) {
      return;
    }

    const payload = {
      CustomerName: this.reviewForm.value.CustomerName,
      Email: this.reviewForm.value.Email,
      ID: 0,
      ItemId: this.itemDetail.Id || 0,
      OrderId: this.itemDetail.OrderId || 0,
      OrderTransId: this.itemDetail.OrderTransId || 0,
      Review: this.reviewForm.value.Review,
      Stars: this.reviewForm.value.Stars,
    };

    this._api.postApi(API_ENDPOINTS.RATE_AND_REVIEW, payload).subscribe({
      next: (res: any) => {
        if (res.Code === 1000) {
          this.sweetAlertService.showToast(
            'success',
            'Review Added Successfully'
          );
        } else {
          this.sweetAlertService.showAlert(
            'error',
            res.Message,
            res.Description
          );
        }
        // console.log('starreview', res);
        // this.itemreviewid = res.ItemId
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {},
    });
    this.reviewForm.reset();
  };
  // TODO: OK
  setEvents = () => {
    $('.color-selector ul li').on('click', (e: any) => {
      const alreadyActive: boolean = $(this).hasClass('active');
    });

    $('.size-box .ball-container .attribute-btn button').on(
      'click',
      (e: any) => {
        let className = e.currentTarget.classList.length ? e.currentTarget.classList[0] : '';
        const alreadyActive: boolean = $(this).hasClass('active');
        // $(('.size-box .ball-container .attribute-btn button.' + className)).removeClass('active');
        // //Only add when btn is already not active & selected
        // if (alreadyActive == false) {
        //   $(this).addClass('active');
        // }
      }
    );

    $('.collapse-block-title').on('click', (e: any) => {
      e.preventDefault;
      const speed = 300;
      const thisItem = $(this).parent(),
        nextLevel = $(this).next('.collection-collapse-block-content');
      if (thisItem.hasClass('open')) {
        thisItem.removeClass('open');
        nextLevel.slideUp(speed);
      } else {
        thisItem.addClass('open');
        nextLevel.slideDown(speed);
      }
    });
  };
  // TODO: OK
  reduceQuantityByOne = () => {
    if (Number(this.itemDetail.Qty) > 1) {
      this.itemDetail.Qty--; //-= 1;
      this.onChangeItemQuantity();
    }
  };
  // TODO: OK
  increaseQuantityByOne = () => {
    if (this.itemDetail.Qty < 3) {
      this.itemDetail.Qty++;
      this.onChangeItemQuantity();
      if (this.itemDetail.Qty === 3) {
        this.itemDetail.Qty = 2;
        this.onChangeItemQuantity();
      }
    }
  };
  // TODO: OK
  onChangeItemQuantity() {
    if (this.itemDetail.IsAddedToCart) {
      this._cs.updateCartItemsQuantity(
        Number(this.itemDetail.Qty),
        Number(this.itemDetail.CartId)
      );
    }
  }
  // TODO: OK
  initData() {
    this.route.params.subscribe((params) => {
      this.slug = params['slug'];
      this.productId = params['id'];
      // const productId = this.route.snapshot.queryParams['productId'];
      if (this.productId) {
        this.getProductDetails(this.productId);
      } else {
        this.getProductDetails();
      }
    });
  }
  // TODO: OK
  getProductDetails = (productId?: number) => {
    const query = {
      ItemId: productId,
      CustomerLoginId: this._cs.getLoginUserId(),
      Slug: this.slug,
    };
    this._cs.getItemDetails(query).subscribe(async (res: any) => {
      if (res.Code === 1000 && res.Data) {
        this.Offerdetails = res.Data?.Offers.length > 0 ? res.Data?.Offers : null; 
        this.productDetails = res.Data?.ItemDetails.length > 0 ? res.Data?.ItemDetails : null; 
        const itemSpecifications = res.Data?.ItemSpecificationList ? res.Data?.ItemSpecificationList : null;
        const attributes = res.Data?.Attribute ? res.Data?.Attribute : null;


        if(!this.productDetails){
          this.sweetAlertService.showToast('info', 'No product details found.');
          return;
        }

        // assign to object
        this.itemDetail = _.isEmpty(this.productDetails) ? null : _.head(this.productDetails);

        // update product based SEO, if any
        this.seoService.updateMetaTags(
          this.itemDetail.MetaTitle,
          this.itemDetail.MetaDescription,
          this.itemDetail.MetaKeyWord);

          this.isProductPurchased = _.get(this.itemDetail, 'IsPurchased') === 1;
          this.isOutOfStock = _.get(this.itemDetail, 'IsSoldOut') === true;
          this.AvailableStock = _.get(this.itemDetail, 'AvailableStock');
          this.IsTopProduct = _.get(this.itemDetail, 'IsTopProduct');
          this.IsLatest = _.get(this.itemDetail, 'IsLatest') === 1;
          this.IsSaver = _.get(this.itemDetail, 'IsSaver') === 1;
          // assign boolean value, if negative purchase allows
          this.isAllowNegativePurchase = _.get(this.itemDetail, 'AllowPurchasing') === true;
          this.isOutOfStock = !this.isAllowNegativePurchase && this.isOutOfStock;
          this.Images = _.get(this.itemDetail, 'ImageList', []);
          this.itemDetail.Qty = _.isNil(this.itemDetail.Qty) || this.itemDetail.Qty === 0 ? 1 : this.itemDetail.Qty;
          


        this.onChangeItemQuantity();
        this.getRateAndReviews();
      
        // check array, if null or undefined or blank
        if (this.Images && this.Images.length > 0) {
          this.largeImage = this.Images[0].FilePath;
          this.mediumImage = this.Images[0].FilePath;
        }


        if (itemSpecifications) {
          // let specificationList=[];
          this.specificationList = itemSpecifications.filter(
            (spec: any) => spec.ParentCategoryId === 0
          );

          if (this.specificationList) {
            this.specificationList.forEach((item, index) => {
              // item.subSpecificationList = itemSpecifications.filter((spec: any) => spec.ParentCategoryId === item.ItemSubTitle_CategoryId);
              item.subSpecificationList = itemSpecifications.filter(
                (spec: any) => spec.SpecificationId === item.SpecificationId
              );
            });

            /**
             * this logic is uded to merge array of same attribute & have distinct as next element
             */
            this.specificationList = this.specificationList.reduce(
              (acc, val, ind) => {
                const index = acc.findIndex(
                  (el: any) => el.SpecificationId === val.SpecificationId
                );
                if (index !== -1) {
                  const key = Object.keys(val)[1];
                  acc[index][key] = val[key];
                } else {
                  acc.push(val);
                }
                return acc;
              }, []
            );
          }
        }


        if (attributes) {
          // const defaultSelectedAttrIds = this.itemDetail?.AttributeIdStr.split(',').trim() || [];
          const defaultSelectedAttrIds = (this.itemDetail?.AttributeIdStr || '').split(',').filter(Boolean);
      
          this.itemAttributeList = [...attributes];
          this.allAttributeIds = attributes.flatMap((attrib:any) => attrib.AttributeValue.map((attrVal:any) => attrVal.Id));

          // attributes.forEach((attr: any, attrIndex: number) => {
          //   attr.AttributeValue.forEach((attrVal: any) => {
          //     if (attrVal.Id === Number(defaultSelectedAttrIds[attrIndex])) {
          //       // attributeGroupId = attr.Id;
          //       this.attributeStr[attr.Id] = attrVal.Id;
          //     }
          //     this.allAttributeIds.push(attrVal.Id);
          //   });
          // });

          setTimeout(() => {
            this.setEvents();
            this.setDefaultSelectedAttributes(defaultSelectedAttrIds);
            // Other initialization tasks
            this.checkAttribute([]);
          }, 100);
        }

        this.getRelatedProductList();
      }
    });
  };
  // TODO: OK
  setDefaultSelectedAttributes(defaultSelectedAttrIds: string[]) {
    defaultSelectedAttrIds.forEach((defaultAttrId, index) => {
        if (this.itemAttributeList[index]?.AttributeValue.some((attrVal:any) => attrVal.Id === Number(defaultAttrId))) {
            this.attributeStr[this.itemAttributeList[index].Id] = Number(defaultAttrId);
        }
    });
  }
  // TODO: OK
  selectAttributesCombination = (itemObject: any) => {
    // let defaultSelectedAttrIds = itemDetail.AttributeIdStr.split(',');
    let defaultSelectedAttrIds = (itemObject?.AttributeIdStr || '').split(',').filter(Boolean);
    this.itemAttributeList.forEach((attr: any, attrIndex: number) => {
      attr.AttributeValue.forEach((attrVal: any) => {
        if (attrVal.Id === Number(defaultSelectedAttrIds[attrIndex])) {
          this.attributeStr[attr.Id] = attrVal.Id;
        }
      });
    });
    setTimeout(() => {
      this.checkAttribute([]);
      // onChangeAttribute({},,itemObject);
    }, 100);
  };
  // TODO: OK
  checkAttribute = (findCheckedCombinations: Array<number>) => {
    let selectedAttributeValues = Object.values(this.attributeStr);
    if (selectedAttributeValues) {
      let attrbuteIds = _.map(this.productDetails, (product: any) => {
        let attributeExist = _.some(
          findCheckedCombinations,
          (selectedAttributeValue: number) => {
            return product.AttributeIdStr.includes(selectedAttributeValue);
          }
        );
        return attributeExist ? product.AttributeIdStr.split(',') : [];
      });
      attrbuteIds = _.union(_.flatten(attrbuteIds));
      attrbuteIds = _.map(attrbuteIds, (id) => Number(id));
      attrbuteIds = _.filter(attrbuteIds, (id) => id);
      if (findCheckedCombinations.length === 0) {
        this.allAttributeIds.forEach((id: any) => this.enableAttribute(id));
      } else {
        this.allAttributeIds.forEach((id: any) => {
          /*findCheckedCombinations.includes(id)*/
          if (id && attrbuteIds.includes(id)) {
            this.enableAttribute(id);
          } else if (id) {
            this.diableAttribute(id);
          }
        });
      }
      // Marking selection at the end to enable & disable
      selectedAttributeValues.forEach((id: any) => {
        if ($('#' + id).hasClass('active') === false) {
          $('#' + id).addClass('active');
        }
      });
    }
  };
  // TODO: OK
  enableAttribute = (id: number) => {
    if ($('#' + id).hasClass('disabled')) {
      $('#' + id).removeClass('disabled');
    }
    if ($('#' + id).hasClass('active')) {
      $('#' + id).removeClass('active');
    }
  };
  // TODO: OK
  diableAttribute = (id: number) => {
    if ($('#' + id).hasClass('disabled') === false) {
      $('#' + id).addClass('disabled');
    }
    if ($('#' + id).hasClass('active')) {
      $('#' + id).removeClass('active');
    }
  };
  // TODO: OK
  getRateAndReviews() {
    const query = { ItemId: this.itemreviewid, };

    this._api
      .getApi(API_ENDPOINTS.RATE_AND_REVIEW_GET, query, this._cs.isLogin())
      .subscribe({
        next: (response: any) => {

          if (_.get(response, 'Code') === 1000 && _.get(response, 'Data')) {

            this.userReviews = _.cloneDeep(_.get(response, 'Data', []));

            // filter out elements from this.userReviews where the rating property is not a number.
            const filteredUserReviews = _.filter(this.userReviews, (r) => _.isNumber(r.rating));

            /** function to calculate the sum of the Stars property for each object 
             in the filteredUserReviews array, assigning the result to variables */
            this.totalRatingOfProduct = _.sumBy(filteredUserReviews, 'Stars');

            // calculate total reviews by count
            this.totalReviews = _.size(filteredUserReviews);

            // Calculate the average rating
            const average = _.round(this.totalRatingOfProduct / this.totalReviews, 1);
            this.averageRatingOfProduct = _.isNaN(average) && _.isNumber(average) ? 0 : average;
            this.topTenReviews = this.sliceTopReviews(this.userReviews);

            // create a slice of the first 10 elements from this.userReviews
            this.sliceddata = _.slice(this.userReviews, 0, 10);

            // deep copy.
            this.totalRecord = this.totalReviews;

            // generate an array of page numbers based on the total number of reviews
            this.numberofpage = _.range(1, Math.ceil(this.totalReviews / 10) + 1);
          }
        },
        error: (error: any) => {
          console.error('product-detail-page:497 :: error: ', error);
          _.assign(this, {
            userReviews: [],
            totalReviews: 0,
            totalRatingOfProduct: 0,
            averageRatingOfProduct: 0,
            totalRecord: 0,
            numberofpage: []
          });
        },
      });
  }
  // TODO: OK
  sliceTopReviews(reviews: any) {
    // Sort the reviews based on the rating in descending order
    const sortedReviews = reviews.sort((a: any, b: any) => b.rating - a.rating);

    // Get the top 5 reviews
    const topReviews = sortedReviews.slice(0, 10);

    return topReviews;
  }
  // FIXME: suspicious
  getRelatedProductList() {
    const query = {
      Type: 'Category',
      Id: this.itemDetail.CategoryId ?? 0,
      recommended: 1,
    };

    this._api.getApi(API_ENDPOINTS.HOME_ITEMS, query, this._cs.isLogin()).subscribe({
      next: (res: any) => {        
        if (res.Code === 5018) { return; }
        
        if(res.Code === 1000 && res.Data && res.Data.Items) {
          // Updating Img path
          res.Data.Items.forEach((item: any) => {
              item.FilePath = item.ImageList && item.ImageList.length ? item.ImageList[0].FilePath : '';
            });
          this.recommendedProducts = [...res.Data.Items];
        }
      },
      error: (error: any) => {
        console.log('product-detail-page.component', error);
      },
    });
  }

  // OK:
  onNavImageChange(index: number, product: any) {
    this.mainImageslickModal?.slickGoTo(index);

    this.setSelectedProduct([product]);
    this.selectAttributesCombination(product);
  }
  // OK:
  onChangeThumbImage(item: any) {
    this.largeImage = item.FilePath; //item.FilepathActual;
    this.mediumImage = item.FilePath; //item.FilepathMedium;
    //this.zoomImage();
  }
  // OK:
  addToCartAndWishList = (item: any, addType?: number, qty?: number) => {
    if (qty && qty > 0) {
      item.Qty += qty;
    }

    if (this.isAllowNegativePurchase) {
      this._cs.addToCartAndWishList(item, addType);
    } else {
      if (this.AvailableStock > item.Qty) {
        // this._cs.validateItemIsInCart(item, this.cartItems);
        this._cs.addToCartAndWishList(item, addType);
      } else if (this.AvailableStock === 1 && item.Qty === 2) {
        this.sweetAlertService.showToast(
          'info',
          'Only one quantity available!'
        );
      }
    }
  };
  // FIXME: checked suspiciously
  onChangeAttribute = (e: any, attributeName: string, attributeValue: any) => {
    // if (Object.values(this.attributeStr).includes(attributeValue.Id)) {
    //   delete this.attributeStr[attributeName];//unselect attribute
    // } else {
    this.attributeStr[attributeName] = attributeValue.Id;
    // }
    // console.log(Object.values(this.attributeStr));
    // let attributeStrIds = Object.values(this.attributeStr);
    let matchingItemDetails = this.productDetails.filter((product: any) => product.AttributeIdStr.includes(attributeValue.Id));
    let findCheckedCombinations = _.map(matchingItemDetails, (item: any) => item.AttributeIdStr.split(','));
    findCheckedCombinations = _.union(_.flatten(findCheckedCombinations));
    findCheckedCombinations = _.map(findCheckedCombinations, (id) =>
      Number(id)
    );
    findCheckedCombinations = _.filter(findCheckedCombinations, (id) => id);

    if (findCheckedCombinations.length) {
      Object.keys(this.attributeStr).forEach((key, i: number) => {
        if (
          findCheckedCombinations.includes(this.attributeStr[key]) === false
        ) {
          delete this.attributeStr[key];
          this.attributeStr[key] = Number(
            matchingItemDetails[0].AttributeIdStr.split(',')[i]
          );
        }
      });
    }
    this.checkAttribute(findCheckedCombinations);
    if (matchingItemDetails.length > 1) {
      matchingItemDetails = _.filter(matchingItemDetails, (item) => {
        return (
          item.AttributeIdStr === Object.values(this.attributeStr).join(',')
        );
      });
    }
    if (matchingItemDetails.length > 0) {
      this.setSelectedProduct(matchingItemDetails);
      //this.zoomImage();
    }

    console.log("matchingItemDetails: ",matchingItemDetails);
    

    this.router.navigate([`/product/${matchingItemDetails[0].Slug}/${matchingItemDetails[0].ItemId}`])
  };
  // FIXME: checked suspiciously
  setSelectedProduct = (itemDetails: any) => {
    this.itemDetail = itemDetails[0];
    this.itemDetail.Qty = this.itemDetail.Qty === 0 ? 1 : this.itemDetail.Qty;
    if (itemDetails[0].ImageList) {
      this.Images = this.itemDetail.ImageList ? this.itemDetail.ImageList : [];
      this.largeImage = itemDetails[0].ImageList[0].FilePath;
      this.mediumImage = itemDetails[0].ImageList[0].FilePath;
    } else {
      this.largeImage = APP_CONSTANT.NO_IMAGE_PATH;
      this.mediumImage = APP_CONSTANT.NO_IMAGE_PATH;
    }
  };
  // FIXME: checked suspiciously
  navigateToBuyProduct(item: any) {
    if (!this._cs.isLogin()) {
      this.router.navigate(['/login'], {
        queryParams: { nextRoute: this.router.url },
      });
    } else {
      this.router.navigate(['checkout'], {
        queryParams: {
          id: item.ItemId,
          itemRateId: 0,
          quantity: item.Qty,
        },
      });
    }
  }
  // OK:
  checkPincodeDelivery() {  
    const query = { Pincode: `${this.pinCode}`};

    this._api.getApi(API_ENDPOINTS.PINCODE_CHECK, query, true).subscribe({
      next: (response: any) => {
        if (response.Code === 1000) {
          this.isDeliverable = response.Data ? 1 : 2;
        } else {
          this.isDeliverable = 2;
        }
      },
      error: () => {
        this.isDeliverable = 2;
      },
      complete: () => {
        this.pinCode = this.pinCode;
      },
    });
  }

  // TODO: OK
  onPinCodeChange() {
    if (!this.pinCode) {
      this.isDeliverable = null;
    }
  }
  //TODO: DEPRECATED:
  imageZoom = (imgID: any, resultID: any) => {
      var img: any, lens: any, result: any, cx: any, cy: any;
      img = document.getElementById(imgID);
      result = document.getElementById(resultID);
      /* Create lens: */
      lens = document.createElement('DIV');
      lens.setAttribute('class', 'img-zoom-lens');
      /* Insert lens: */
      img.parentElement.insertBefore(lens, img);
      /* Calculate the ratio between result DIV and lens: */
      cx = result.offsetWidth / lens.offsetWidth;
      cy = result.offsetHeight / lens.offsetHeight;
      /* Set background properties for the result DIV */
      result.style.backgroundImage = "url('" + img.src + "')";
      result.style.backgroundSize =
        img.width * cx + 'px ' + img.height * cy + 'px';
      /* Execute a function when someone moves the cursor over the image, or the lens: */
      lens.addEventListener('mousemove', moveLens);
      img.addEventListener('mousemove', moveLens);
      /* And also for touch screens: */
      lens.addEventListener('touchmove', moveLens);
      img.addEventListener('touchmove', moveLens);
      function moveLens(e: any) {
        var pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        /* Get the cursor's x and y positions: */
        pos = getCursorPos(e);
        /* Calculate the position of the lens: */
        x = pos.x - lens.offsetWidth / 2;
        y = pos.y - lens.offsetHeight / 2;
        /* Prevent the lens from being positioned outside the image: */
        if (x > img.width - lens.offsetWidth) {
          x = img.width - lens.offsetWidth;
        }
        if (x < 0) {
          x = 0;
        }
        if (y > img.height - lens.offsetHeight) {
          y = img.height - lens.offsetHeight;
        }
        if (y < 0) {
          y = 0;
        }
        /* Set the position of the lens: */
        lens.style.left = x + 'px';
        lens.style.top = y + 'px';
        /* Display what the lens "sees": */
        result.style.backgroundPosition = '-' + x * cx + 'px -' + y * cy + 'px';
      }
      function getCursorPos(e: any) {
        var a,
          x = 0,
          y = 0;
        e = e || window.event;
        /* Get the x and y positions of the image: */
        a = img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
      }
  };
  // TODO: OK
  isUserLoggedIn():boolean {
    if (this._cs.isLogin()) { return true; }
    return false;
  };
  // TODO: OK
  RedirectLink(endpoint: string, object?: any): void {
    let link = this.createLink(endpoint, object);
    window.location.href = link;
  };
  // TODO: OK
  createLink(endpoint: string, object?: any): string {
    return this._cs.sanitizeLink(endpoint, object.Slug, object.ItemId);
  };
  // CLEAR: OK
  createShareLink(platform: string, text?: string): string | undefined {
    return this._cs.createShareLink(this.itemDetail, platform, text);
  };
}
