<!--modal popup start-->
<div class="modal fade bd-example-modal-lg theme-modal signupform_custom" id="signup_popup" tabindex="-1" role="dialog"
aria-hidden="true">
<div class="modal-dialog modal-lg modal-dialog-centered" role="document" >
    <div class="modal-content">
        <div class="modal-body modal1">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <div class="offer-content">
                                 <div class="row">
                                    
                                    <div class="col-md-5 d-none d-md-block">
                                        <div class="row">
                                            <div class="col-12">
                                                <h4>{{utilityModal?.formStates?.signIn ? "Login Now" : "Register Now For"}}</h4>
                                            </div>
                                            <div class="col-12 d-flex flex-md-column flex-sm-row">
                                                <div class="row mt-3">
                                                    <div class="col-3">
                                                        <img class="img-fluid" src="../../../assets/images/offer.png" />
                                                    </div>
                                                    <div class="col-9 ">
                                                        <h5>Exclusive Offer</h5>
                                                        <p>For special offer & value Deals</p>
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-3">
                                                        <img class="img-fluid" src="../../../assets/images/track-order.png" />
                                                    </div>
                                                    <div class="col-9 ">
                                                        <h5>Manage Yours Orders</h5>
                                                        <p>Track orders, manage cancellations & returns.</p>
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-3">
                                                        <img class="img-fluid" src="../../../assets/images/payment_card.png" />
                                                    </div>
                                                    <div class="col-9 ">
                                                        <h5>Instant Checkout</h5>
                                                        <p>Hassle-free payment everytime</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="row">
                                            <div class="col-12 d-flex flex-column">
                                                <h4> {{ utilityModal?.formStates?.signIn ? "login" : "Sign Up" }}
                                                </h4>
                                                <p class="d-none">
                                                    Do not share your personal details!
                                                </p>
                                            </div>
                                            <div class="col-12">
                                                <form action="" class="ps-4" #signUpForm="ngForm" (ngSubmit)="signUpForm.form.valid && submit()" novalidate>
                                                    <div class="row gap-3">
                                                        <div class="col-12">
                                                            <label>Enter Mobile No</label>
                                                            <div class="row">
                                                                <div class="col-3">
                                                                    <ng-container *ngIf="countryCodes">
                                                                        <input list="countryCodes" class="form-control" name="country-code" id="country-code" [value]="CountryCode" (change)="onSelectionCountryCode($event.target)" [disabled]="true">
                                                                        <datalist id="countryCodes">
                                                                            <option *ngFor="let code of countryCodes" [value]="'+'+code.Phonecode"></option>
                                                                        </datalist>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-9 text-start">
                                                                    <input type="number" class="form-control col-10"placeholder="Enter Mobile No" name="mobileNo"
                                                                        [(ngModel)]="utilityModal.MobileNo" required pattern="^[6-9][0-9]{9}$" 
                                                                        
                                                                        [ngClass]="{ 'is-invalid': signUpForm.submitted && !utilityModal.MobileNo }" />
                                                                        
                                                                        <small class="text-danger " *ngIf="signUpForm.form.controls['mobileNo'] && (signUpForm.form.controls['mobileNo'].dirty || signUpForm.form.controls['mobileNo'].touched)">
                                                                            <ng-container *ngIf="signUpForm.form.controls['mobileNo'].hasError('required')">
                                                                                mobile is Required!
                                                                            </ng-container>
                                                                            <ng-container *ngIf="signUpForm.form.controls['mobileNo'].hasError('pattern')">
                                                                                * Invalid mobile number.
                                                                            </ng-container>
                                                                        </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12" *ngIf="!utilityModal?.formStates?.signIn && utilityModal?.otpReceived "> 
                                                            <label for="otp">Enter Otp</label>
                                                            <input
                                                                type="number"
                                                                class="form-control"
                                                                placeholder="Enter Otp Received"
                                                                #otpInput
                                                                name="otp"
                                                                id="otp"
                                                                [(ngModel)]="utilityModal.Code"
                                                               
                                                                [ngClass]="{'is-invalid': signUpForm?.submitted && !utilityModal?.Code }"
                                                                required
                                                                
                                                            />
                                                        </div>
                                                        <div class="col-12" *ngIf="!utilityModal?.formStates?.signUp &&
                                                        (utilityModal?.formStates?.signIn || utilityModal?.otpVerfied)">
                                                            <label for="password">Enter Password</label>
                                                            <input
                                                                type="password"
                                                                class="form-control"
                                                                placeholder="Enter Password"
                                                                name="password"
                                                                id="password"
                                                                [(ngModel)]="utilityModal.Password"
                                                                required
                                                                [ngClass]="{'is-invalid': signUpForm?.submitted &&
                                                                utilityModal.otpVerfied &&
                                                                !utilityModal?.Password }"
                                                            />
                                                        </div>
                                                        <!-- <div class="col-12" *ngIf=" utilityModal?.formStates?.forgotPassword && utilityModal?.otpReceived && utilityModal?.otpVerfied ">
                                                            <label for="confirmPassword">Confirm Password</label>
                                                            <input
                                                                type="password"
                                                                class="form-control"
                                                                placeholder="Enter Confirm Password"
                                                                name="confirmPassword"
                                                                id="confirmPassword"
                                                                [(ngModel)]="utilityModal.confirmPassword"
                                                                required
                                                                [ngClass]="{ 'is-invalid': (signUpForm?.submitted &&
                                                                utilityModal.otpVerfied &&
                                                                !utilityModal?.confirmPassword) ||
                                                                (utilityModal?.Password && utilityModal?.confirmPassword
                                                                && (utilityModal?.Password !== utilityModal?.confirmPassword))}"
                                                            />
                                                        </div> -->

                                                        <div class="col-md-12  ">
                                                            <div class="row gap-3 gap-md-0">
                                                                <div class=" order-sm-first order-last {{utilityModal?.otpReceived?'d-block col-md-6':'d-none'}}" >
                                                                  <button class="  btn btn-solid btn-block waves-effect" [disabled]="resendDisabled" (click)="resendOtp()">Resend OTP
                                                                    <!-- <span *ngIf="resendDisabled==false">Resend OTP</span>
                                                                    <span *ngIf="resendDisabled==true">Resend OTP in {{transform(remainingTime)}}</span> -->
                                                                    </button>
                                                                  <span *ngIf="utilityModal?.otpReceived && resendDisabled===true">Resend OTP in {{transform(remainingTime)}}</span>
                                                                </div>
                                                                <div class="{{utilityModal?.otpReceived?'col-md-6':'col-md-12'}}">
                                                                    <button type="submit" class="btn btn-solid btn-block waves-effect">
                                                                        {{ utilityModal?.formStates?.signIn ? "Login" : utilityModal?.otpReceived
                                                                        ? utilityModal?.otpVerfied ? "Submit" : "Verify" : "Continue" }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-12 text-right mt-2" *ngIf="utilityModal?.formStates?.signIn">
                                                            <a href="javascript:void(0)" class="anchor-login forgot_pass"
                                                                (click)="onAnchorClick('forgotPassword')">Forgot Password ?</a>
                                                        </div>
                        
                                                        <!-- <div class="col-md-12 mt-4" *ngIf="!utilityModal?.formStates?.signIn">
                                                            <a href="javascript:void(0)" class="btn btn-solid w-100"
                                                                (click)="onAnchorClick('signIn')">Existing User Log in</a>
                                                        </div> -->
                        
                                                        <div class="col-md-12 mt-4" *ngIf="utilityModal?.formStates?.signIn">
                                                            <a href="javascript:void(0)" class="btn btn-solid w-100"
                                                                (click)="onAnchorClick('signUp')">New to Create an account</a>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!--modal popup end-->