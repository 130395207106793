import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent {

  OrderId: string = '';
  ItemId: string = '';
  AwbNumber: string = '';
  AwbProductName: string = '';
  AwbProductImg: string = '';
  deliveryStatusOptions: Array<any>;
  trackDetail: any;

  deliveryStatus: number = 0;

  //init
  orders: Array<any> = [];

  // product info
  ProductImage: string = '';
  ProductName: string = '';
  BillNumber:string = '';
  OrderDate:number|'' = '';
  ShippingDate:number|'' = '';
  ShippingAddress:string = '';
  CarrierName = '';


  constructor(
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    public commonService: CommonService,
    private route: ActivatedRoute,
  ) {
    this.deliveryStatusOptions = [...this.commonService.getShippingStatus()];

    this.route.params.subscribe((params) => {      
      // Extract slug parameter from route
      this.ItemId  = params['id'];
      this.OrderId  = params['order'];
      this.AwbNumber  = params['awb'];
    });

    // this.activeRoute.queryParamMap.subscribe({
    //   next: (params:any) => {
    //     this.OrderId = params.get('Order');
    //     this.ItemId = params.get('Item');
    //     this.AwbNumber = params.get('Awb')??'';
    //   },
    //   error: () => {},
    //   complete: () => {}
    // });

    this.orderDetail$(this.OrderId)
    this.trackOrder$(this.AwbNumber);
  }


orderDetail$ = (OrderId: string | undefined) => {
  // Query object with search text, delivery status, and OrderID
  const query = {
    SearchText: '',
    DeliveryStatus: 0,
    OrderID: OrderId
  };
  // Send GET request to API endpoint with the query
  this.apiService
    .getApi(API_ENDPOINTS.ALL_ORDERED_LIST, query, this.commonService.isLogin())
    .subscribe({
      next: (response: any) => {
        if (response.Code === 1000 && response?.Data?.BuyNowMainOrderList?.length) {
          this.orders = response.Data.BuyNowMainOrderList;

          const orderItemDetailsMap = new Map();

          response.Data.BuyNowOrderItemDetailsList.forEach((item: any) => {
            const orderId = item.OrderID;
            if (!orderItemDetailsMap.has(orderId)) {
              orderItemDetailsMap.set(orderId, []);
            }
            orderItemDetailsMap.get(orderId).push(item);
          });
         

          this.orders.forEach((order:any) => {
            const orderId = order.OrderID;
            order.orderItems = orderItemDetailsMap.get(orderId) || [];
          });
        } 
               
      },
      error: () => {
        this.orders = [];
      },
      complete: () => {
        if(this.orders && this.orders[0].orderItems){
          const order = this.orders[0];
          const item = order.orderItems[0];
          this.ProductImage = item.ImagePath;
          this.ProductName = item.ItemName;
          this.BillNumber = item.BillNo;
          this.OrderDate = order.OnDate;
          this.ShippingDate = order.OnDate;
          this.ShippingAddress = order?.Address+' '+order?.City+' '+order?.State+', '+order?.PinCode;
          this.CarrierName = 'Ecom Express';
        }
      }
    });
};

/**
 * Summary: This code defines a trackOrder$ function that is used to track an order.
 * The function takes an AwbNumber as input and makes an API call to retrieve the tracking details.
 * If the AwbNumber is valid, the function sends a request to the API and subscribes to the response.
 * Upon receiving the response, the trackDetail property is updated with the tracking details.
 * If an error occurs during the API call, trackDetail is set to null and the error is logged.
 */

trackOrder$ = (AwbNumber: string|''|undefined) => {
  if(AwbNumber && AwbNumber?.length > 0){
    const formData ={
      "Awb": AwbNumber
    }
    this.apiService
      .getApi(API_ENDPOINTS.TRACK_ORDER, formData, false)
      .subscribe({
        next: (response:any) => {
          this.trackDetail = response?.awb
          // console.log('track me detail: ',response.awb);
        },
        error: (err:any) => {
          this.trackDetail = null;
          // console.error('Observable tracking error: ',err);
        },
        complete: () => {}
      });
  }
};





}
