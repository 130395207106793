import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { APP_CONSTANT } from 'src/app/app.constant';
import { STORAGE_KEY } from 'src/app/browser.storage.constant';
import { CommonService } from 'src/app/common.service';
import { ForgotPasswordFormComponent } from 'src/app/forgot-password-form/forgot-password-form.component';
import { SigninSignupFormComponent } from 'src/app/signin-signup-form/signin-signup-form.component';
import { SweetAlertService } from 'src/app/sweetalert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  countryCodes: Array<any> = [];
  CountryCode: string = '+91';
  nextRoute: string = '/home';

  @ViewChild('signInSignUpContainerRef', { read: ViewContainerRef, static: false }) signInSignUpContainerRef: ViewContainerRef | any;

  constructor(
    private commonService: CommonService,
    private sweetAlert: SweetAlertService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.loginForm = new FormGroup({
      UserName: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      Password: new FormControl('', Validators.required)
    });
    this.route.queryParamMap.subscribe((res) => {
      let routePath = res.get('nextRoute');
      this.nextRoute = routePath ? routePath : '/home';
    });
  }

  ngOnInit() {
    this.commonService.fetchCountryCodes().subscribe(
      (countryCodes: any) => {
        this.countryCodes = countryCodes.Data;
      }
    );

  }
  onSubmit = (): void => {
    let mobileNo = this.loginForm.value.UserName + '';
    if (mobileNo.length !== 10) {
      this.sweetAlert.showToast('error', 'Please provide a valid User Name.');
      return;
    }

    let payLoad = {
      CountryCode: this.CountryCode.replace('+', '').trim(),
      ...this.loginForm.value
    };
    this.apiService.postApi(API_ENDPOINTS.CUSTOMER_LOGIN, payLoad, {}, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000) {
          this.apiService.setLoginAuthToken(res.Data.Token);
          localStorage.setItem(STORAGE_KEY.CUSTOMER_ID, res.Data.UserId);
          // this.sweetAlert.showToast('success', 'Welcome back! You are now signed in.');
          localStorage.setItem(APP_CONSTANT.ECOMMERCE_LOGIN_DETAIL, JSON.stringify(res.Data));
          this.commonService.setProfileInfo().then((val: any) => {
            this.commonService.profileUpdated.next(true);
          });
          this.commonService.updateCartItemCount();
          this.commonService.loggedInSuccess.next(true);
          this.router.navigateByUrl(this.nextRoute);
        } else {
          this.sweetAlert.showToast('error', res.Message);
        }
      });
  };

  // onAnchorClick(type: string) {
  //   if (type === 'signIn') {
  //   } 
  //   else if (type === 'forgotPassword') {
  //     this.openSignInSignupModal('forgotPassword');
  //   }
  // };

  onSelectionCountryCode = (e: any) => {
    this.CountryCode = e.value;
  };



  openSignInSignupModal(type?: string) {
    this.commonService.loadModalDynamically(this, 'signInSignUpContainerRef', 'signInSignUpModalRef',
      SigninSignupFormComponent, type, (data: any) => { console.log('type: ',data)
       });
  }

  openForgotPasswordModal() {
    this.commonService.loadModalDynamically(this, 'signInSignUpContainerRef', 'signInSignUpModalRef',
      ForgotPasswordFormComponent, "forgot_password_popup", (data: any) => { console.log('type: ', data)
       });
  }

  get f(): any {
    return this.loginForm.controls;
  }
}
