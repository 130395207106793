<!-- thank-you section start -->
<!-- <section class="section-b-space light-layout py-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="success-text">
                    <div class="checkmark">
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="star" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.296.747c.532-.972 1.393-.973 1.925 0l2.665 4.872 4.876 2.66c.974.532.975 1.393 0 1.926l-4.875 2.666-2.664 4.876c-.53.972-1.39.973-1.924 0l-2.664-4.876L.76 10.206c-.972-.532-.973-1.393 0-1.925l4.872-2.66L8.296.746z">
                            </path>
                        </svg>
                        <svg class="checkmark__check" height="36" viewBox="0 0 48 36" width="48"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23">
                            </path>
                        </svg>
                        <svg class="checkmark__background" height="115" viewBox="0 0 120 115" width="120"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z">
                            </path>
                        </svg>
                    </div>
                    <h2 class="h4 fw-bold">thank you</h2>
                    <p>Payment is successfully processsed and your order is on the way</p>
                    <p class="font-weight-bold">Order ID: #{{orders[0]?.OrderID}}</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- Section ends -->


<!-- order-detail section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row" *ngFor="let order of orders">
            <div class="col-lg-6">
                <div class="product-order">
                    <div class="row product-order-detail" *ngFor="let item of order.orderItems">
                        <div class="col-3 d-flex flex-column justify-content-center align-content-center">
                            <img 
                                [src]="item.ImagePath?item.ImagePath:'../assets/images/no-image.png'"
                                alt=""
                                [routerLink]="['/product', item.Slug, item.RateSetupID]"
                                class="img-fluid blur-up lazyload"
                                style="cursor: pointer;">
                            <span>{{item?.Quantity}} x {{item?.SaleOrderRate*item?.Quantity | currency:'INR'}}</span>
                        </div>
                        <div class="col-9 order_detail  d-flex flex-column justify-content-center align-content-center">
                            <div>
                                <h4>product name</h4>
                                <h5 [routerLink]="['/product', item.Slug, item.RateSetupID]"
                                 style="cursor: pointer;">{{item?.ItemName}} <br/> <span class="small fw-medium text-black-50 ">({{item?.AttributesName}})</span></h5>
                            </div>

                            <ng-container *ngIf="item.Offers">
                                <ng-container *ngFor="let offer of item.Offers">
                                    <span class="text-success">(-) {{(offer?.Value/100*item.SaleOrderRate) | currency:'INR'}}</span>
                                </ng-container>
                            </ng-container>
                        </div>
                        <!-- <div class="offset-3 col-3 order_detail">
                            <div class="">
                                <h4>quantity</h4>
                                <h5>{{item?.Quantity}}</h5>
                            </div>
                        </div> -->
                        <!-- <div class="col-3 order_detail text-end">
                            <div class="d-flex flex-column">
                                <h4>price</h4>
                                <h5>
                                    <span class="d-flex flex-column justify-content-center align-content-center">
                                        <span>{{item?.SaleOrderRate*item?.Quantity | currency:'INR'}}</span> -->
                                        <!-- <span class="text-success">(-) {{(item?.TotalAmount - item.SaleOrderRate) | currency:'INR'}}</span> -->
                                        <!-- <ng-container *ngIf="item.Offers">
                                            <ng-container *ngFor="let offer of item.Offers">
                                                <span class="text-success">(-) {{(offer?.Value/100*item.SaleOrderRate) | currency:'INR'}}</span>
                                            </ng-container>
                                        </ng-container>
                                    </span>
                                </h5>
                            </div>
                        </div> -->
                        <div class="col-12 order_detail pt-3" style="align-items: flex-start;">
                            <div>
                                <h5 class="text-capitalize d-flex flex-row gap-2 ms-md-2 btn-group">
                                    <a [routerLink]="['/order-tracking', item?.ID, item?.OrderID, item?.AwbNumber]">track order</a>
                                    
                                    <span class="d-none d-md-block vr"></span>
                                    <a [routerLink]="['/review', item.ItemID]">Reviews</a>
                                    
                                    <!-- if Refund policy:3|0 :: 'No Cancellation Policy' or 'NoPolicy' -->
                                    <ng-container *ngIf="item.RefundPolicyId===0 || item.RefundPolicyId===1 || item.RefundPolicyId===2">
                                        <ng-container *ngIf="handleCancellationButton(item.Status, item.DeliveryStatus)">
                                            <span class="d-none d-md-block vr"></span>
                                            <a [className]="['text-primary']" [type]="['button']" (click)="initOrderCancel(item.PaymentMode, item.ID, item.OrderID, item.AwbNumber)">Cancel</a>
                                        </ng-container>
                                    </ng-container>
                                    
                                     <!-- if Refund policy:2 :: 'No Return Policy' -->
                                    <ng-container *ngIf="item.RefundPolicyId===0 || item.RefundPolicyId===1 || item.RefundPolicyId===3">
                                        <ng-container *ngIf="handleReturnButton(item.Status, item.DeliveryStatus)">
                                            <span class="d-none d-md-block vr"></span>
                                            <a [className]="['text-primary']"  [type]="['button']" (click)="initOrderReturnAndReplace(1, item.PaymentMode, item.ID, item.OrderID, item.AwbNumber)">Return</a>
                                        </ng-container>
                                    </ng-container>
                                    
                                    <!-- if Refund policy:1 :: 'No Replace Policy' -->
                                    <ng-container *ngIf="item.RefundPolicyId===0 || item.RefundPolicyId!==2 || item.RefundPolicyId!==3">
                                        <ng-container *ngIf="handleReplaceButton(item.Status, item.DeliveryStatus)">
                                            <span class="d-none d-md-block vr"></span>
                                            <a [className]="['text-primary']"  [type]="['button']" (click)="initOrderReturnAndReplace(2, item.PaymentMode, item.ID, item.OrderID, item.AwbNumber)">Replace</a>
                                        </ng-container>
                                    </ng-container>
                                    
                                   
                               
                                    
                                    
                                    

                                    <!-- <ng-container *ngIf="item.DeliveryStatus==='Ordered' || item.DeliveryStatus==='Packed' || item.DeliveryStatus==='Shipped'">
                                        <span class="d-none d-md-block">|</span>
                                        <a [id]="['cancel-order']" href="javascript:void(0)" type='button' (click)="this.orderCancellation(item)"
                                            [style.pointer-events]="item.IsItemCancelled===1 ?'none':'auto'"
                                            [ngClass]="{'text-muted': item.IsItemCancelled===1}">
                                            {{item.IsItemCancelled===1? 'cancelled': 'cancel'}}</a>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="item.DeliveryStatus==='Delivered'">
                                        <span class="d-none d-md-block" [ngClass]="{'d-none': item.IsItemReturned===2}">|</span>
                                        <a href="javascript:void(0)" type='button' [ngClass]="{'d-none': item.IsItemReturned===2}"
                                            (click)="this.returnAndReplaceOrder(item, 1)">{{item.IsItemReturned===1? 'Returned': 'Return'}}</a>
                                        
                                        <span class="d-none d-md-block" [ngClass]="{'d-none': item.IsItemReturned===1}">|</span>
                                        <a href="javascript:void(0)" type='button' [ngClass]="{'d-none': item.IsItemReturned===1}"
                                            (click)="this.returnAndReplaceOrder(item, 2)">{{item.IsItemReturned===2? 'Replaced': 'Replace'}}</a>
                                    </ng-container> -->
                                </h5>
                            </div>
                        </div>
                    </div>
                   
                    <div class="total-sec">
                        <ul>
                            <li>subtotal <span>{{order?.BillAmount | currency:'INR'}}</span></li>
                            <!-- <li>shipping <span>{{0 | currency:'INR'}}</span></li>
                            <li>tax(GST) <span>{{0 | currency:'INR'}}</span></li> -->
                        </ul>
                    </div>
                    <div class="final-total">
                        <h3>total <span>{{order?.BillAmount | currency:'INR'}}</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="order-success-sec">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4>summary</h4>
                            <ul class="order-detail">
                                <li>Order ID: {{order?.BillNo}}</li>
                                <li>Order Date: {{order?.OnDate | date:'dd MMMM yyyy'}}</li>
                                <li>Order Total: <span>&nbsp;&nbsp;{{order?.BillAmount | currency:'INR'}}</span></li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <h4>shipping address</h4>
                            <ul class="order-detail">
                                <li>{{order.ShippingName}}</li>
                                <li>{{order.Address}}</li>
                                <li>{{order.City}}, {{ order.State }},</li>
                                <li>{{order.Country}} - {{order.PinCode}}</li>
                                <li>Contact No. {{order.ShippingMobileNo}}</li>
                            </ul>
                        </div>
                        <div class="col-sm-12 payment-mode">
                            <h4>payment method</h4>
                            <p>{{order.PaymentMode}}</p>
                            <!-- <p>Card/Net banking acceptance subject to device availability.</p> -->
                        </div>
                        <div class="col-md-12">
                            <div class="delivery-sec">
                                <!-- <h3>expected date of delivery: <span>{{order?.OnDate | date:'longDate'}}</span></h3> -->
                                <h3>date of order creation: <span>{{order?.OnDate | date:'dd MMMM yyyy'}}</span></h3>
                                <!-- <ng-container *ngIf="order.items[0]?.IsItemCancelled===1">
                                    <h3>date of order cancellation: <span>{{order.items[0]?.ItemCancelledDate | date:'dd MMMM yyyy'}}</span></h3>
                                </ng-container>
                                <ng-container *ngIf="order?.items[0]?.IsItemReturned===1">
                                    <h3>date of order returned: <span>{{order?.items[0]?.ItemReturnedDate | date:'dd MMMM yyyy'}}</span></h3>
                                </ng-container> -->
                                
                                <!-- <a  [routerLink]="['/order-tracking', order.Slug]" [queryParams]="{ 'OrderNumber': order?.BillNo, 'Awb':'3269193011', 'img': orders[0].orderItems[0]?.ImagePath, 'name': orders[0].orderItems[0]?.ItemName }">track order</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->
