<!-- breadcrumb start -->
<app-breadcrumb-section screenName="Check-out" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->

<!-- section start -->
<section class="section-b-space">
    <div class="container">
        <div class="checkout-page">
            <div class="checkout-form">
                <div class="row">
                    <div class="col-lg-6 col-sm-12 col-xs-12 dashboard-section">
                        <div class="user-dashboard-section sticky-top " id="address">
                            <div class="faq-content row">
                                <div class="tab-pane col-12">
                                    <div class="card mt-0">
                                        <div class="card-body">
                                            <div class="top-sec">
                                                <h3>DELIVERY ADDRESS</h3>
                                                <a (click)="addNewAddress({})" href="javascript:void(0)"
                                                    class="btn btn-sm btn-solid">+ add
                                                    new</a>
                                            </div>
                                            <div class="address-book-section">
                                                <div class="row g-4">
                                                    <ng-container *ngFor="let address of addresses; let i = index">
                                                        <div class="select-box active col-sm-12 col-md-6 col-xl-6 col-lg-6" >
                                                            <div class="address-box">
                                                                <div class="top">
                                                                    <h6>
                                                                        <input type="radio" id="customRadio-{{ i }}"
                                                                            name="customRadio" class="custom-control-input"
                                                                            [value]="address.Id"
                                                                            [(ngModel)]="checkOutModal.selectedDeliveryAddressId" />
                                                                        {{address.AddressTypeName}}
                                                                        <span *ngIf="address?.IsDefault===1">Default</span>
                                                                    </h6>
                                                                </div>
                                                                <div class="middle" style="min-height: 22vh;">
                                                                    <div class="address">
                                                                        <p> {{address?.Name}},</p>
                                                                        <p> {{address.AddressValue}}</p>
                                                                        <p> {{address.AreaName}} {{address.CityName}} {{address.StateName}} </p>
                                                                        <p> {{address.CountryName}} {{address.PostCode}}</p>
                                                                        <p> {{address?.CountryCode}}-{{address?.MobileNo}}</p>
                                                                        <p> {{address?.Email}}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="bottom justify-content-end gap-1 ">
                                                                    <button type="button" (click)="addNewAddress(address)"
                                                                        data-bs-target="#edit-address"
                                                                        data-bs-toggle="modal"
                                                                        class="btn btn-sm btn-xs btn-solid">edit</button>
                                                                    <button type="button" (click)="deleteAddress(address)"
                                                                        class="btn btn-sm btn-xs btn-solid bg-danger text-white ">remove</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Billing Details -->

                    <div class="col-lg-6 col-sm-12 col-xs-12">
                        <!-- <h3 class="address-header">MAKE PAYMENT</h3> -->
                        <div class="checkout-details">
                            <div class="order-box">
                                <div class="title-box">
                                    <div>Product <span class="text-end ">Total</span></div>
                                </div>
                                <ng-container *ngIf="checkOutModal.cartItems.length > 0">
                                    <ul class="qty">
                                        <ng-container *ngFor="let item of checkOutModal.cartItems">
                                            <li class="d-flex flex-row justify-content-start align-content-center w-100">
                                                <div>
                                                    <div>{{item.ItemName}} × {{item.Qty}}</div>
                                                    <div style="font-size: small; width: 50%;">{{item.AttributesName | lowercase}}</div>
                                                </div>
                                                <span class="text-end">{{item.SaleRate | currency:'INR'}}</span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </ng-container>

                                <ul class="sub-total">
                                    <li>Subtotal <span class="count text-end ">{{this.checkOutModal.subtotal | currency:'INR'}}</span></li>
                                                                       
                                    <ng-container *ngIf="this.checkOutModal.globalDiscount">
                                        <li class="text-success">Additional Discounts (-) <span class="count text-success text-end ">{{this.checkOutModal.globalDiscount | currency:'INR'}}</span></li>
                                    </ng-container>
                                    <ng-container *ngIf="this.checkOutModal.itemDiscount">
                                        <li class="text-success">Special Product Savings (-) <span class="count text-success text-end ">{{this.checkOutModal.itemDiscount | currency:'INR'}}</span></li>
                                    </ng-container>
                                    <ng-container *ngIf="this.checkOutModal.categoryDiscount">
                                        <li class="text-success">Special Category Savings (-) <span class="count text-success text-end ">{{this.checkOutModal.categoryDiscount | currency:'INR'}}</span></li>
                                    </ng-container>

                                    <ng-container *ngIf="this.couponAmount > 0">
                                        <li class="text-success">Coupon Applied (-) <span class="count text-success text-end ">{{this.couponAmount | currency:'INR'}}</span></li>
                                    </ng-container>

                                    <ng-container *ngIf="this.checkOutModal.totalDeliveryCharges">
                                        <li>Shipping Charge (+) <span class="count text-end ">{{this.checkOutModal.totalDeliveryCharges | currency:'INR'}}</span></li>
                                    </ng-container>
                                  
                                </ul>

                                <!-- Final amount -->
                                <ul class="total">
                                    <li>Total <span class="count text-end ">{{this.checkOutModal.totalAmountPayble | currency:'INR'}}</span></li>
                                </ul>

                                <ng-container *ngIf="this.checkOutModal.subtotal > 0 && this.checkOutModal.cartOffers">
                                    <ul class="total">
                                        <ng-container *ngFor="let offer of this.checkOutModal.cartOffers">
                                            <li class="text-black-50 fs-6 "><i class="fa fa-tag text-success me-2"></i>{{offer.Offer}}</li>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </div>
                            
                                <div class="row donationpart_payment">
                                    <div class="form-group col-md-6">
                                        <label>Apply Coupon (Offer) </label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Your Coupon Code"
                                                name="couponCodeName" (input)="trimCode()"
                                                (change)="onCouponCodeChange()" [(ngModel)]="couponCode">
                                            <ng-container  *ngIf="couponCode &&  (couponAmount > 0 || Discountvalue > 0 || isCouponAvail)">
                                                <button type="button" class="btn bg-transparent"
                                                    style="margin-left: -40px; z-index: 100;"
                                                    (click)="handleCouponRemove()">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </ng-container>
                                        </div>
                                        <!-- <ng-container *ngIf="!couponCode">
                                            <small class="text-danger font-weight-bold">
                                                Please fill Coupon Code.
                                            </small>
                                        </ng-container> -->
                                        <ng-container *ngIf="couponCode">
                                            <ng-container *ngIf="couponDetails?.TypeUse && couponAmount">
                                                <small class="text-success font-weight-bold">
                                                    {{ couponDetails.Type === 2 ? "Cashback Amount on Coupon"
                                                    : "Amount Off on Coupon" }} : {{ couponAmount }}</small>
                                            </ng-container>

                                            <ng-container  *ngIf="couponAmount === 0 && isCouponAvail">
                                                <small class="text-danger font-weight-bold">Coupon Code not Valid.</small>
                                            </ng-container>

                                            <ng-container *ngIf=" couponDetails?.Type && couponAmount && couponDetails?.Type === 1">
                                                <small class="text-success font-weight-bold">
                                                    Congratulations ! You have got {{ couponDetails.DiscountValue }}% discount as Rs.
                                                   
                                                </small>
                                            </ng-container>

                                            <ng-container *ngIf="couponDetails?.Type && couponAmount && couponDetails?.Type === 2">
                                                <small class="text-success font-weight-bold" >
                                                    Congratulations ! You have got {{ couponDetails.DiscountValue }}% cashback as Rs.
                                                    {{ couponAmount }}. It will added in your wallet within 24-48 Hours
                                                </small>
                                            </ng-container>

                                            <ng-container *ngIf="couponDetails?.Type && couponAmount && couponDetails?.Type === 3">
                                                <small class="text-success font-weight-bold">
                                                    Congratulations ! Lucky Customer coupon applied, it will get
                                                    in your wallet within 24-48 Hours, if you have been selected
                                                    lucky in 100 Customers
                                                </small>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6 p-4">
                                        <button type="button" class="btn btn-solid" (click)="applyCoupon()"
                                            [disabled]="!couponCode">
                                            Apply Coupon
                                        </button>
                                    </div>
                                </div>
                               
                                <ul class="total">
                                    <li>Total Amount
                                        <span class="count rupee-symbol"> {{ (checkOutModal.totalAmountPayble -
                                            amountToRedeem)| number: "1.2-2" }}</span>
                                    </li>
                                </ul>
                           
                            <div class="payment-box">
                                <div class="upper-box">
                                    <div class="payment-options">
                                        <ul>
                                            <ng-container *ngFor="let paymentMethod of checkOutModal?.paymentMethodList; let i = index">
                                                <li>
                                                    <div class="radio-option">
                                                        <input id="paycustomRadio-{{ i + 1 }}" type="radio"
                                                            name="payment-group" [value]="paymentMethod.Id"
                                                            [disabled]="checkOutModal.totalAmountPayble <= amountToRedeem"
                                                            [(ngModel)]="checkOutModal.selectedPaymentMethodId" />
                                                        <label for="paycustomRadio-{{ i + 1 }}">
                                                            {{ paymentMethod?.Name }}
                                                            
                                                        </label>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <div [ngClass]="['image', 'mt-2']">
                                                <h6 class="product-title text-capitalize fw-bold fs-6">100%
                                                    secure payment</h6>
                                                <img src="../assets/images/payment.png"
                                                    class="img-fluid mt-1" alt="">
                                            </div>
                                        </ul>
                                    </div>
                                    <div class="text-end">
                                        <button class="btn-solid btn" type="button" (click)="placeOrder()" 
                                        [disabled]="!checkOutModal.selectedPaymentMethodId || isOrderPlaced" >
                                            <ng-container *ngIf="isOrderPlaced">
                                                <div class="d-flex align-items-center">
                                                    <strong role="status">Ordering...</strong>
                                                    <div class="spinner-border ms-auto" aria-hidden="true"></div>
                                                  </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isOrderPlaced">
                                                Place Order
                                            </ng-container>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
<ng-container #addNewAdressContainerRef></ng-container>