import { ToasterService } from './../../toaster.service';
import { API_ENDPOINTS } from './../../api.endpoints';
import { ApiService } from './../../api.service';
import { CommonService } from 'src/app/common.service';
import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @ViewChild('addAddressFormGroup', { static: false }) addAddressFormGroupControl: NgForm | undefined;
  formUtilityData: AddressFormUtilityInterface = {};
  modal: AddressModalInterface = {};
  disableDefaultAddress: boolean = false;
  selectedCar: number = 0;

  constructor(
    public commonService: CommonService,
    private apiService: ApiService,
    private toaster: ToasterService
  ) {
    this.getCountryList();
  }

  async ngOnInit() {
  }

  async openModal(item: any) {
    // this.disableDefaultAddress = item.defaultAddressExist;
    this.formUtilityData.mobileCountryCodeList = await this.commonService.getMobileCountryCodeList();    
    this.modal.selectedCountryCodeId = '+91';
    this.modal.selectedCountryCodeId2 = '+91';
    this.formUtilityData.addressTypeList = [...this.commonService.getAddressTypeList()];
    this.commonService.openModal('add_address_popup_id');
    if (item && Object.keys(item).length > 1) {
      this.assignFormData(item);
    }
  }

  closeForm(value: any) {
    this.commonService.closeModal('add_address_popup_id');
    this.closeModal.emit(value);
  }


  

    cars = [
        { id: 1, name: 'Volvo' },
        { id: 2, name: 'Saab' },
        { id: 3, name: 'Opel' },
        { id: 4, name: 'Audi' },
    ];

  getCountryList = () => {
    const keyArray = ['Id', 'CommonDesc', 'ShortName'];
    this.apiService.getApi(API_ENDPOINTS.GET_COUNTRIES, {}, false, keyArray).subscribe((res: any) => {
      if (res?.length) {
        this.formUtilityData.countryList = [...res];
        this.formUtilityData.stateList = [];
        this.formUtilityData.cityList = [];
        this.modal.selectedCountryId = 123;
        this.onCountryChange(123);
      }
    });
  }

  onCountryChange = async (e?: any) => {
    if (this.modal.selectedCountryId) {
      const url = `${API_ENDPOINTS.GET_STATES}${this.modal.selectedCountryId}`;
      const keyArray = ['Id', 'CommonDesc1'];
      this.apiService.getApi(url, {}, false, keyArray).subscribe((res: any) => {
        if (res?.length) {
          this.formUtilityData.stateList = [...res];
          this.formUtilityData.cityList = [];
          if (this.modal.editStateId) {
            this.modal.selectedStateId = this.modal.editStateId;
            this.modal.editStateId = null;
            this.onStateChange();
          }
        }
      });
      const data = await this.commonService.getMobileCountryCodeList(Number(this.modal.selectedCountryId));
      if (data?.length) {
        this.modal.selectedCountryCodeId = data[0].Phonecode;
        this.modal.selectedCountryCodeId2 = data[0].Phonecode;
        this.modal.selectedCountryCodeLength = Number(data[0].Length);
      }
    } else {
      this.formUtilityData.stateList = [];
      this.formUtilityData.cityList = [];
      this.modal.selectedStateId = null;
      this.modal.selectedCityId = null;
    }
  }

  onStateChange = (e?: any) => {
    if (this.modal.selectedStateId) {
      const url = `${API_ENDPOINTS.GET_CITIES}${this.modal.selectedStateId}`;
      const keyArray = ['Id', 'CommonDesc2'];
      this.apiService.getApi(url, {}, false, keyArray).subscribe((res: any) => {
        if (res?.length) {
          this.formUtilityData.cityList = [...res];
          if (this.modal.editCityid) {
            this.modal.selectedCityId = this.modal.editCityid;
            this.modal.editCityid = null;
          }
        }
      });
    } else {
      this.formUtilityData.cityList = [];
      this.modal.selectedCityId = null;
    }
  }

  assignFormData(item: any) {
    this.modal.Id = item.Id;
    this.modal.name = item.Name;
    this.modal.selectedCountryId = Number(item.CountryId);
    this.modal.editStateId = Number(item.StateId);
    this.modal.editCityid = Number(item.CityId);
    this.onCountryChange();
    this.modal.address = item.AddressValue;
    if (item.AddressType && item.AddressType !== null) {
      const filteredData = this.formUtilityData.addressTypeList.filter((x: any) => x.Id === Number(item.AddressType));
      if (filteredData && filteredData.length) {
        this.modal.selectedAddressTypeId = Number(item.AddressType);
      } else {
        this.modal.selectedAddressTypeId = null
      }
    }

    if (this.modal.selectedAddressTypeId === 4) {
      this.modal.otherAddressType = item.DescriptionForOther;
    }
    this.modal.pinCode = Number(item.PostCode);
    this.modal.mobileNo = Number(item.MobileNo);
    this.modal.MobileNo2 = item.MobileNo2 ? Number(item.MobileNo2) : null;
    this.modal.selectedCountryCodeId2 = item.CountryCode2;
    this.modal.Email = item.Email;
    this.modal.selectedCountryCodeId = item.CountryCode;
    this.modal.isDefault = item.IsDefault ? true : false;
    this.modal.isAvailGst = item.IsAvailGst ? true : false;
    this.modal.gstNumber = item.GstNumber;
    this.modal.gstFirmName = item.FirmName
  }

  prepareAddressPayload() {
    return {
      Id: this.modal.Id ? this.modal.Id : 0,
      AddressType: this.modal.selectedAddressTypeId,
      AddressValue: this.modal.address,
      AreaId: 0,
      CityId: this.modal.selectedCityId,
      CountryId: this.modal.selectedCountryId,
      StateId: this.modal.selectedStateId,
      PostCode: this.modal.pinCode,
      IsDefault: this.modal.isDefault ? 1 : 0,
      ParentTypeId: 40,
      // DescriptionForOther: this.modal.selectedAddressTypeId === 4 ? this.modal.otherAddressType : '',
      DescriptionForOther: '',
      MobileNo: this.modal.mobileNo,
      Name: this.modal.name,
      CountryCode: this.modal.selectedCountryCodeId,
      ParentId: this.commonService.getLoginUserId(),
      Email: this.modal.Email,
      MobileNo2: this.modal.MobileNo2,
      CountryCode2: this.modal.selectedCountryCodeId2,
      IsAvailGst: this.modal.isAvailGst,
      GstNumber: this.modal.gstNumber,
      GstFirmName: this.modal.gstFirmName
    };
  }

  submitForm() {
    this.apiService.postApi(API_ENDPOINTS.ADDRESS, this.prepareAddressPayload(), {}, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000) {
          this.toaster.showSuccess('', 'Address Saved Successfully');
          this.closeForm(true);
        } else {
          this.toaster.showError('', res.Message);
        }
      });
  }
}


export class AddressModalInterface {
  Id?: any;
  selectedCountryId?: any;
  selectedStateId?: any;
  editStateId?: any;
  selectedCityId?: any;
  selectedCountryCodeId?: any;
  selectedCountryCodeLength?: any;
  editCityid?: any;
  selectedAddressTypeId?: any;
  address?: string;
  pinCode?: any;
  addressType?: any;
  otherAddressType?: Text;
  mobileNo?: any;
  name?: string;
  isDefault?: boolean;
  Email?: string;
  MobileNo2?: any;
  selectedCountryCodeId2?:any;
  isAvailGst?:boolean;
  gstNumber?:string | '' | undefined;
  gstFirmName?: string | '' | undefined;
}

export class AddressFormUtilityInterface {
  countryList?: any;
  stateList?: any;
  cityList?: any;
  mobileCountryCodeList?: any;
  addressTypeList?: any;
}
