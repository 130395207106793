<!-- header start -->
<header>
  <div class="mobile-fix-option"></div>
  <div class="top-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 left-top-header">
          <div class="header-contact">
            <ul>
              <li>
              <a href="/contact" rel="noopener noreferrer">
              <i class="fa fas fa-user" aria-hidden="true"></i>
              <span style="margin-left: 5px;">Customer Support</span>
              </a></li>
              <li><a href="/best-offers">Best Offers</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <!-- <li class="onhover-dropdown mobile-account">
              <a href="#"><i class="fa fa-user" aria-hidden="true"></i>Become a Supplier</a>
            </li> -->
            <li class="mobile-wishlist"><a href="/wishlist"><i class="fa fa-heart" aria-hidden="true"></i></a>
            </li>
            <!-- <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> {{ isLogin ? (userName | titlecase ): 'My Account'}}
              <ul class="onhover-show-div">
                <ng-container *ngIf="isLogin===false">
                  <li *ngIf="isLogin===false" [routerLink]="['/login']" routerLinkActive="router-link-active">
                    <a href="javascript:void(0)"  [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>
                  </li>
                  <li *ngIf="isLogin===false">
                    <a class="w-100" href="javascript:void(0)" (click)="openSignInSignupModal('signUp')">register</a>
                  </li>
                </ng-container>

                <ng-container *ngIf="isLogin">
                  <li *ngIf="isLogin">
                    <a href="/dashboard">
                      <i class="fa fa-lock dashboard-icon" aria-hidden="true"></i> Account
                    </a>
                  </li>
                  <li *ngIf="isLogin">
                    <a href="javascript:void(0)" (click)="logOut()">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      Logout
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->

<ng-container #signInSignUpContainerRef></ng-container>