import { Injectable, Input } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title, private meta: Meta) {}

  setMetaTags(title:string, description?:string, keywords?:string ): void {

    // Set title meta tag
    this.title.setTitle(title)

    if(title){
      this.title.setTitle(title)
      this.meta.addTag({ name: 'title', content: title });
      this.meta.addTag({ property: 'og:title', content: title });
      this.meta.addTag({ name: 'twitter:title', content: title });
    }
    
    if(description){
      this.meta.addTag({ name: 'description', content: description });
      this.meta.addTag({ property: 'og:description', content: description });
      this.meta.addTag({ name: 'twitter:description', content: description });
    }
   
    if(keywords){
      this.meta.addTag({ name: 'keywords', content: keywords });
    }  
  }

  updateMetaTags(title:string, description:string, keywords?:string): void {
     // Update title and description meta tag
    if(title){
      this.title.setTitle(title)
      this.meta.updateTag({ name: 'title', content: title },"name='title'");
      this.meta.updateTag({ property: 'og:title', content: title },"name='og:title'");
      this.meta.updateTag({ name: 'twitter:title', content: title },"name='twitter:title'");
    }
    
    if(description){
      this.meta.updateTag({ name: 'description', content: description },"name='description'");
      this.meta.updateTag({ property: 'og:description', content: description },"name='og:description'");
      this.meta.updateTag({ name: 'twitter:description', content: description },"name='twitter:description'");
    }
   
    if(keywords){
      this.meta.updateTag({ name: 'keywords', content: keywords },"name='keywords'");
    }    
  }

  removeMetaTags() {
    this.meta.removeTag('name = "title"');
    this.meta.removeTag('name = "og:title"');
    this.meta.removeTag('name = "twitter:title"');
    this.meta.removeTag('name = "description"');
    this.meta.removeTag('name = "og:description"');
    this.meta.removeTag('name = "title:description"');
    this.meta.removeTag('name= "keywords"');
  }

  removeMetaTagElement() {
    const description: HTMLMetaElement|any = this.meta.getTag('name = "description"');
    this.meta.removeTagElement(description);
    const keywords: HTMLMetaElement|any = this.meta.getTag('name= "keywords"');
    this.meta.removeTagElement(keywords);
  }
}
