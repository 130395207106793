import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStartRoutingModule } from './app-start-routing.module';
import { AppStartComponent } from './app-start.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';
import { SigninSignupFormComponent } from '../signin-signup-form/signin-signup-form.component';
import { ForgotPasswordFormComponent } from '../forgot-password-form/forgot-password-form.component';
import { NavHeaderComponent } from '../nav-header/nav-header.component';
import { SharedModule } from '../shared/shared.module';
import { CartComponent } from '../account/cart/cart.component';
import { WishlistComponent } from '../account/wishlist/wishlist.component';
import { CategoryInfiniteScrollComponent } from '../pages/category-infinite-scroll/category-infinite-scroll.component';
import { OrderSuccessComponent } from '../account/order-success/order-success.component';
import { ProductDetailPageComponent } from '../pages/product-detail-page/product-detail-page.component';
import { GetOffersComponent } from '../pages/get-offers/get-offers.component';
import { CookiebarComponent } from '../components/cookiebar/cookiebar.component';
import { ContactComponent } from '../account/contact/contact.component';
import { AboutComponent } from '../pages/about/about.component';
import { ReviewComponent } from '../pages/review/review.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageZoomModule } from 'ngx-image-zoom';



@NgModule({
  declarations: [
    AppStartComponent,
    HeaderComponent,
    FooterComponent,
    SigninSignupFormComponent,
    ForgotPasswordFormComponent,
    NavHeaderComponent,    
    WishlistComponent,
    CartComponent,
    ContactComponent,
    AboutComponent,
    CategoryInfiniteScrollComponent,
    OrderSuccessComponent,
    ProductDetailPageComponent,
    GetOffersComponent,
    ReviewComponent,
    CookiebarComponent,
  ],
  imports: [
    CommonModule,
    AppStartRoutingModule,
    AutocompleteLibModule,
    SharedModule,
    NgxPaginationModule,
    NgbRatingModule,
    NgxImageZoomModule,
  ]
})
export class AppStartModule { }
