import { Component, EventEmitter, Output, ViewChildren } from '@angular/core';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { APP_CONSTANT } from 'src/app/app.constant';
import { CommonService } from 'src/app/common.service';
import { SweetAlertService } from 'src/app/sweetalert.service';



interface CartAndWishListInterface {
  wishList: Array<any>;
  isCartOrWishListChanged: boolean;
}

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent {
  @Output() closeModal = new EventEmitter();
  @ViewChildren('quantityButtonRef') quantityButtonRef: any/*QueryList<IncDecButtonComponent>*/;
  utility: CartAndWishListInterface = { wishList: [], isCartOrWishListChanged: false };

  constructor(
    public commonService: CommonService,
    private apiService: ApiService,
    private sweetAlertService: SweetAlertService
  ) {
    let addToCartAndWishListItem: any = localStorage.getItem(APP_CONSTANT.ADD_TO_CRAT_AND_WISHLIST_ITEM);
    if (addToCartAndWishListItem) {
      addToCartAndWishListItem = JSON.parse(addToCartAndWishListItem);
      this.commonService.addToCartAndWishList(addToCartAndWishListItem.item, addToCartAndWishListItem.addType);
      localStorage.removeItem(APP_CONSTANT.ADD_TO_CRAT_AND_WISHLIST_ITEM)
    }
    this.getWishListItems();
  }

  moveItems = (item: any, isMove: boolean) => {
    const query = {
      ItemID: item && item.ItemId ? item.ItemId : 0,
      IsWishList: 1,
      IsMove: isMove ? 1 : 0,
      CartId: item && item.Id ? item.Id : 0
    };
    this.apiService.getApi(API_ENDPOINTS.EMPTY_CART_ITEMS, query, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000 && Number(res.Data) === 1000) {
          if (isMove) {
            this.sweetAlertService.showToast('success', 'Successfully moved to your cart!');
            this.commonService.updateCartItemCount();
          } else if (item && Object.keys(item).length && !isMove) {
            this.sweetAlertService.showToast('success', 'Items successfully removed from your selection.');
          }
          this.getWishListItems();
          if (!this.utility.isCartOrWishListChanged) {
            this.utility.isCartOrWishListChanged = true;
          }
        } else if (res.Code === 1000 && Number(res.Data) === 5000) {
          this.sweetAlertService.showToast('info', 'Item already added to your cart.');
        }
      });
  }

  getWishListItems = () => {
    const query = {
      CustomerLoginId: this.commonService.isLogin() ? this.commonService.getLoginUserId() : 0,
      IsWishingItem: true,
      IsFromMobile: 0
    };
    this.apiService.getApi(API_ENDPOINTS.CART_LIST, query, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000 && res?.Data && res.Data.AddToCartMainDetailsList ) {
          this.utility['wishList'] = [...res.Data.AddToCartMainDetailsList];
        } else {
          this.utility['wishList'] = [];
        }
      });
  };
}