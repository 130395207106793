import { Component } from '@angular/core';
import { SeoService } from 'src/app/seo.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  constructor(private seoService: SeoService){
    seoService.updateMetaTags('Privacy Policy - G9Kart', 
    'Discover how [Your Ecommerce Brand] collects, uses, and protects your personal information. Read our comprehensive privacy policy to understand your rights and how we safeguard your data.',
    'Privacy Policy, Data Protection, Personal Information, Privacy Statement, Information Security, User Privacy, Data Collection, Data Usage, Privacy Regulations, Confidentiality Policy');
  }
}
