import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { NgForm, Validators } from '@angular/forms';
import { API_ENDPOINTS } from '../api.endpoints';
import { APP_CONSTANT } from '../app.constant';
import { STORAGE_KEY } from '../browser.storage.constant';
import { SweetAlertService } from '../sweetalert.service';
import { Subscription, take, timer } from 'rxjs';

export interface FormStates {
  signIn?: boolean;
  forgotPassword?: boolean;
  signUp?: boolean;
}

export interface SignUpModalInterface extends FormStates {
  MobileNo?: number;
  Code?: number;
  otpReceived?: boolean;
  otpVerfied?: boolean;
  formStates?: FormStates;
  Password?: any;
  confirmPassword?: any;
  UserName?: string;
  OtpId?: number;
}

@Component({
  selector: 'app-signin-signup-form',
  templateUrl: './signin-signup-form.component.html',
  styleUrls: ['./signin-signup-form.component.scss']
})
export class SigninSignupFormComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @ViewChild('otpInput')
  otpInput!: ElementRef;
  @ViewChild('signUpForm', { static: false }) loginFormControl: NgForm | any;
  utilityModal: SignUpModalInterface | any = {};
  countryCodes: Array<any> = [];
  CountryCode: string = '+91';
  resendDisabled: boolean = false;
  count: number = 180; // 3 minutes in seconds
  remainingTime: number = this.count;
  timerSubscription$: Subscription = new Subscription;
  apiSubscription$: Subscription = new Subscription;
  tick: number = 1000;
  isFocused: boolean = false
  constructor(
    private commonService: CommonService,
    private sweetAlertService: SweetAlertService,
    private router: Router,
    private apiService: ApiService,
  ) {
    this.utilityModal.formStates = {};
  }

  ngOnInit() {
    this.commonService.fetchCountryCodes().subscribe(
      (countryCodes: any) => {
        this.countryCodes = countryCodes.Data;
        this.loginFormControl.form.get('mobileNo').addValidators([Validators.required, Validators.pattern('^[6-9][0-9]{9}$')]);
      }
    );
  }

  openModal(type: string = 'signUp'): void {
    this.onAnchorClick(type);
    this.commonService.openModal('signup_popup');
  }

  submit() {
    let mobileNo = this.utilityModal.MobileNo + '';
    if (mobileNo.length !== 10) {
      this.sweetAlertService.showToast('error', 'Invalid Mobile Number. Please check and try again.');
      return;
    }
    
    if (this.utilityModal.formStates.signIn) {
      this.signIn();
    } else if (this.utilityModal.otpReceived && !this.utilityModal.otpVerfied) {
      this.verifyOtp();
    } else if (this.utilityModal.formStates.forgotPassword && this.utilityModal.otpReceived && this.utilityModal.otpVerfied) {
      this.changePassword();
    } else {
      this.generateOtp();
    }
  }

  signIn() {
    const data = {
      CountryCode: this.getCountryCode(),
      UserName: this.utilityModal.MobileNo,
      Password: this.utilityModal.Password
    };
    try {
      this.commonService.signIn(data).subscribe(
        (res: any) => {
          if (res.Code === 1000 && res?.Data) {
            this.apiService.setLoginAuthToken(res.Data.Token);
            localStorage.setItem(STORAGE_KEY.CUSTOMER_ID, res.Data.UserId);
            this.sweetAlertService.showToast('success', 'Welcome back! You\'re in.');
            localStorage.setItem(APP_CONSTANT.ECOMMERCE_LOGIN_DETAIL, JSON.stringify(res.Data));
            this.commonService.setProfileInfo().then(() => {
              this.commonService.profileUpdated.next(true);
              // this.closeForm('LOGIN_SUCCESS');
            });
            this.commonService.updateCartItemCount();
          } else {
            this.sweetAlertService.showToast('error', res.Message);
          }
        });
    } catch (error) {
      // console.error('sign-in error. Please try again.', error);
      this.sweetAlertService.showToast('error', 'sign-in error. Please try again.');
    }
  }

  getCountryCode = () => {
    return this.CountryCode.replace('+', '').trim();
  };

  generateOtp() {
    const queryParam = {
      CountryCode: this.getCountryCode(),
      MobileNo: this.utilityModal.MobileNo,
      OTPFor: this.utilityModal.formStates.forgotPassword ? 'ForgetPassword' : ""
    };

    const url = this.utilityModal.formStates.signUp ? API_ENDPOINTS.GENERATE_OTP : API_ENDPOINTS.FORGOT_PASSWORD_GENERATE_OTP;
    // new with improved observable single object
    this.apiService.getApi(url, queryParam).subscribe({
      next: (response: any) => {
        //console.log('Observable emitted the next value at generateOtp: ', response); 
        if (response.Code === 1000) {
          this.utilityModal.otpReceived = true;
          if (this.otpInput) {
            const element = this.otpInput.nativeElement.focus();
          }
          // if (this.utilityModal.otpReceived) {
          //   // Set focus on the OTP input
          //   if (this.otpInput) {
          //     this.otpInput.nativeElement.focus();
          //   }
          // }
          // if (this.utilityModal.MobileNo && this.utilityModal.MobileNo.length === 10) {
          //   this.otpInput.nativeElement.focus();
          // }

          // setTimeout(() => {
          //   if (this.otpInput) {
          //     const element = this.renderer.selectRootElement(this.otpInput.nativeElement, true)
          //     element.focus({ preventScroll: false })
          //   }
          // }, 1000)
          this.utilityModal.OtpId = response.Data;

          // Initialize the timer
          this.startResendTimer();
          this.sweetAlertService.showToast('success', 'OTP Sent! Check your mobile for the code.');
        } else {
          this.sweetAlertService.showToast('error', response.Description);
        }
      },
      error: (err: any) => { console.error('Observable emitted an error at generateOtp: ', err); },
      complete: () => { }
    });
  };

  onSelectionCountryCode = (e: any) => {
    this.CountryCode = e.value;
  };

  verifyOtp() {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }
    const data = {
      CountryCode: this.getCountryCode(),
      Id: this.utilityModal.OtpId,
      Code: this.utilityModal.Code,
      MobileNo: this.utilityModal.MobileNo,
      // Type: this.utilityModal.formStates.signUp ? 1 : 2
    };
    this.commonService.verifyOtp(data).subscribe({
      next: (res: any) => {
        // console.log('verifyOtp():197 : ',res);
        if (res.Code === 1000) {
          this.sweetAlertService.showToast('success', 'Verification Successful!');
          this.utilityModal.otpVerfied = true;
          // if new user signup
          if (this.utilityModal.formStates.signUp && this.utilityModal.otpVerfied) {
            this.signUp();
          }
          // Password change
          if (this.utilityModal.formStates.ForgetPassword && this.utilityModal.otpVerfied) {
            this.changePassword();
          }
        } else {
          this.sweetAlertService.showToast('error', res.Message);
        }
      },
      error: (err: any) => {
        this.sweetAlertService.showToast('error', err.Message);
      },
      complete: () => { },
    });
  }

  signUp() {
    const data = {
      CountryCode: this.CountryCode,
      LoginId: Number(this.utilityModal.MobileNo),
      OTP: this.utilityModal.Code
    };

    localStorage.setItem('OTP_INFO', JSON.stringify(data));
    this.closeForm();
    this.router.navigate(['register'], { state: data });
  }

  changePassword() {
    if (!this.utilityModal.Password || !this.utilityModal.confirmPassword) {
      this.sweetAlertService.showToast('warning', 'Please enter both password fields.');
      return;
    }

    // Check if passwords match
    if (this.utilityModal.Password !== this.utilityModal.confirmPassword) {
      this.sweetAlertService.showToast('warning', 'Passwords must match. Please check and try again.');
      return;
    }

    // Check if Mobile No and Code fields are empty
    if (!this.utilityModal.MobileNo || !this.utilityModal.Code) {
      this.sweetAlertService.showToast('warning', 'Please enter Mobile No and Code.');
      return;
    }

    // Prepare data for API request
    const data = {
      CountryCode: this.getCountryCode(),
      MobileNo: this.utilityModal.MobileNo,
      NewPassword: this.utilityModal.Password,
      ConfirmPassword: this.utilityModal.confirmPassword,
      ParentTypeId: 40,
      OTP: this.utilityModal.Code
    };

    // Send API request to change password
    this.apiSubscription$ = this.apiService.postApi(API_ENDPOINTS.USER_RESET_PASSWORD, data).subscribe({
      next: (response: any) => {
        // Check if password is updated successfully
        if (response && response.Code === 1000 && response.Data) {
          this.sweetAlertService.showToast('success', 'Password Updated!');
          this.loginFormControl.resetForm();
          this.resetForm();
        } else if (response && response.Description) {
          // Show error message if provided by the API
          this.sweetAlertService.showToast('error', response.Message);
        } else {
          // Show generic error message if no specific error message is provided
          this.sweetAlertService.showToast('error', 'An error occurred while updating the password.');
        }
      },
      error: () => {
        // Show error message if an error occurred during the API request
        this.sweetAlertService.showToast('error', 'An error occurred while updating the password.');
      },
      complete: () => { }
    });

    //apiSubscription$.unsubscribe();
  }

  closeForm(data?: any) {
    this.commonService.closeModal('signup_popup');
    this.closeModal.emit({});
  }

  resetForm() {
    this.onAnchorClick('signIn');
    this.resetUtilityModal();
  }

  resetUtilityModal() {
    this.utilityModal.setOtpReceived(false);
    this.utilityModal.setOtpVerified(false);
  }

  // This function handles the click event on an anchor element
  onAnchorClick(type: string) {
    const formStates = this.utilityModal.formStates;
    formStates.signIn=formStates.signUp=formStates.forgotPassword="";
    formStates.signIn = type === 'signIn';
    formStates.signUp = type === 'signUp';
    formStates.forgotPassword = type === 'forgotPassword';
  }

  startResendTimer(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$?.unsubscribe();
      this.timerSubscription$ = new Subscription;
    }
    this.resendDisabled = true;
    this.timerSubscription$ = timer(0, this.tick)
      .pipe(take(this.remainingTime))
      .subscribe({
        next: () => {
          --this.remainingTime;
          if (this.remainingTime === 0) {
            this.resendDisabled = false;
            this.timerSubscription$.unsubscribe();
          }
        },
        error: (err: any) => console.error('Observable emitted an error for timer: ' + err),
        complete: () => {
          this.timerSubscription$.unsubscribe();
        }
      });
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  resendOtp(): void {
    this.generateOtp();
    this.remainingTime = this.count; // Reset to 3 minutes
    this.startResendTimer();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }
    if (this.apiSubscription$) {
      this.apiSubscription$.unsubscribe();
    }
  }
}


