<!-- breadcrumb start -->
<app-breadcrumb-section screenName="cart" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!--section start-->
<section class="cart-section section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="cart_counter">
                    <div class="countdownholder">
                        <!-- Your cart will be expired in<span id="timer"></span> minutes! -->
                        {{utility.cartList.length > 0 ? 'Your Cart: A collection of your selected items. Ready for checkout!':
                         'our Cart: Awaiting Your Treasures. Shop Now to Fill It Up!'}}

                    </div>
                    <a *ngIf="utility.cartList.length > 0" href="/checkout"
                        class="cart_checkout btn btn-solid btn-xs">check out</a>
                </div>
            </div>
            <div class="col-sm-12 table-responsive-xs">
                <table class="table cart-table">
                    <thead>
                        <tr class="table-head">
                            <th scope="col">image</th>
                            <th scope="col">product name</th>
                            <th scope="col">price</th>
                            <th scope="col">quantity</th>
                            <th scope="col">action</th>
                            <th scope="col">total</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of utility.cartList; let i = index">
                        <tr>
                            <td>
                                <a href="javascript:void(0)" [routerLink]="['/product/'+item.Slug]"
                                    [queryParams]="{ 'productId':item.ItemRateSetupId}">
                                    <img [src]="item?.ImagePath" alt="">
                                </a>
                            </td>
                            <td><a href="javascript:void(0)">{{ item.ItemName | titlecase }}</a>
                                <div class="mobile-cart-content row">
                                    <div class="col">
                                        <div class="qty-box">
                                            <div class="input-group">
                                                <span class="input-group-prepend">
                                                    <button type="button" class="btn quantity-left-minus"
                                                        data-type="minus" data-field="" (click)="reduceQtyByOne(item)"  [disabled]="this.item.Qty <= 1">
                                                        <i class="ti-angle-left"></i></button>
                                                </span>
                                                <input type="text" name="quantity" class="form-control input-number p-sm-1"
                                                    [(ngModel)]="item.Qty" value="1"
                                                    (keyup)="calculateTotalPrice(item)">
                                                <span class="input-group-prepend">
                                                    <button type="button" class="btn quantity-right-plus"
                                                        data-type="plus" data-field=""
                                                        (click)="incrementQtyByOne(item)" [disabled]="this.item.Qty > 3">
                                                        <i class="ti-angle-right"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <h2 class="td-color">{{ item?.SaleRate | currency: 'INR' }} <del>{{ item?.MrpRate | currency: 'INR' }}</del></h2>
                                    </div>
                                    <div class="col">
                                        <h2 class="td-color"><a href="javascript:void(0)" class="icon"
                                                (click)="removeItems(item)"><i class="ti-close"></i></a>
                                        </h2>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h2 class="vstack">
                                    {{ item?.SaleRate | currency: 'INR' }}
                                    <del>{{ item?.MrpRate | currency: 'INR' }}</del>
                                </h2>
                            </td>
                            <td>
                                <div class="qty-box">
                                    <div class="input-group">
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-left-minus" data-type="minus"
                                                data-field="" (click)="reduceQtyByOne(item)">
                                                <i class="ti-angle-left"></i></button>
                                        </span>
                                        <input type="number" name="quantity" class="form-control input-number"
                                            [(ngModel)]="item.Qty" value="1" (change)="calculateTotalPrice(item)">
                                        <!-- (keyup)="calculateTotalPrice(item)"  -->
                                        <span class="input-group-prepend">
                                            <button type="button" class="btn quantity-right-plus" data-type="plus"
                                                data-field="" (click)="incrementQtyByOne(item)">
                                                <i class="ti-angle-right"></i>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="javascript:void(0)" class="icon" (click)="removeItems(item)">
                                    <i class="ti-close"></i>
                                </a>
                            </td>
                            <td>
                                <h2 class="td-color">{{item?.SaleRate * item?.Qty | currency: 'INR'}}</h2>
                            </td>
                        </tr>
                    </tbody>
                   
                    <tbody *ngIf="utility.cartList.length===0">
                        <tr class="text-center">
                            <td colspan="6">
                                <h3 class="font-weight-bold text-opacity-25 ">Your cart is empty. Ready to start shopping?</h3>
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div *ngIf="utility.cartList.length > 0" class="table-responsive-md">
                    <table class="table cart-table ">
                        <tfoot>
                            <tr>
                                <td>total price :</td>
                                <td>
                                    <h2>{{totalPrice | currency:'INR'}}</h2>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-sm-12">
                <ng-container *ngIf="utility.offers.length > 0">
                    <div class="d-flex flex-column justify-content-start align-content-center mt-2">
                        <h5 class="text-bolder">Available Offers </h5>
                        <ng-container *ngFor="let offer of utility.offers; let i = index">
                            <div class="fs-6 small">
                                <i class="fa fa-tag text-success"></i>
                                {{offer?.Offer | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row cart-buttons">
            <div class="col-6"><a href="/" class="btn btn-solid">continue shopping</a></div>
            <div class="col-6"><a href="/checkout" class="btn btn-solid" *ngIf="utility.cartList.length>0">check out</a>
            </div>
        </div>
    </div>
</section>
<!--section end-->