<div class="modal fade right" id="add_address_popup_id" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content rounded-1">
      <div class="modal-header close_popup">
        <h4 class="modal-title w-100 c-grey-900">Address</h4>
        <button type="button" class="close bg-transparent border-0 " data-dismiss="modal" aria-label="Close" (click)="closeForm(false)">
          <span aria-hidden="true">X</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addAddressFormGroup="ngForm" (ngSubmit)="addAddressFormGroup.form.valid && submitForm()" novalidate>
          <div class="row">

            <!-- Name -->
            <div class="form-group col-md-6">
              <label for="deliveryName" [ngClass]="{'text-danger': addAddressFormGroup.submitted && !modal.name}">
                {{ addAddressFormGroup.submitted && !modal.name ? "Please enter name" : "Customer name" }}
              </label>
              <input type="text" class="form-control" placeholder="Enter Name" #nameModal="ngModel" name="deliveryName"
                [(ngModel)]="modal.name" required 
                [ngClass]="{ 'is-invalid': (addAddressFormGroup.submitted && !modal.name) }" />
            </div>

            <!-- Email -->
            <div class="form-group col-md-6">
              <label for="email" 
                [ngClass]="{ 'text-danger': addAddressFormGroup.submitted && !modal.Email }">
                {{ addAddressFormGroup.submitted && !modal.Email ? "Please enter email" : "Email" }}
              </label>
              <input type="mail" class="form-control" id="email" #modelEmail="ngModel" placeholder="Enter Email" name="email"
                [(ngModel)]="modal.Email" required 
                [ngClass]="{ 'is-invalid': (modelEmail?.dirty && modelEmail?.invalid) || (addAddressFormGroup.submitted && !modal.Email) }">
            </div>

            <!-- CountryCode Select and Mobile Number (Primary) -->
            <div class="form-group col-md-6">
              <label for="mobileNoName" [ngClass]="{ 'text-danger': addAddressFormGroup.submitted && !modal.mobileNo}">
                {{ addAddressFormGroup.submitted && !modal.mobileNo ? "Please enter mobile number" : "Mobile number" }}
              </label>
              <div class="input-group">
                <ng-select id="countryCodeName" name="countryCodeName" [items]="formUtilityData.mobileCountryCodeList" bindLabel="Phonecode"
                  bindValue="Phonecode" placeholder="Select Country Code"
                  [(ngModel)]="modal.selectedCountryCodeId" required [disabled]="true" 
                  [ngClass]="{ 'ng-select-invalid': addAddressFormGroup?.submitted && !modal.selectedCountryCodeId }">
                </ng-select>
                <input type="number" class="form-control" placeholder="Enter Mobile No" #mobileNoName="ngModel" [maxLength]="10"
                  name="mobileNoName" [(ngModel)]="modal.mobileNo" [disabled]="!modal.selectedCountryCodeId"
                  (keypress)="commonService.isNumber($event)" [maxlength]="modal.selectedCountryCodeLength"
                  [minlength]="modal.selectedCountryCodeLength" pattern="^[0-9]*$" required [ngClass]="{
                        'is-invalid': (mobileNoName?.dirty && mobileNoName?.invalid) || (addAddressFormGroup.submitted && !modal.mobileNo)
                      }" />
              </div>
            </div>

            <!-- CountryCode Select and Mobile Number (Optional) -->
            <div class="form-group col-md-6">
              <label for="mobileNoNameAlt">
                {{ addAddressFormGroup.submitted && !modal.MobileNo2 ? "Please enter mobile number" : "Alternate number (Optional)" }}
              </label>
              <div class="input-group">
                <ng-select id="countryCodeNameAlt" name="countryCodeNameAlt" [items]="formUtilityData.mobileCountryCodeList" bindLabel="Phonecode"
                bindValue="Phonecode" placeholder="Select Country Code" [disabled]="true"
                [(ngModel)]="modal.selectedCountryCodeId2" [ngClass]="{
                      'ng-select-invalid':
                        addAddressFormGroup?.submitted &&
                        !modal.selectedCountryCodeId2
                    }">
              </ng-select>
              <input type="number" class="form-control" placeholder="Alternate Mobile No" #mobileNoNameAlt="ngModel" [maxLength]="10"
                name="mobileNoNameAlt" [(ngModel)]="modal.MobileNo2" [disabled]="!modal.selectedCountryCodeId2"
                (keypress)="commonService.isNumber($event)" [maxlength]="modal.selectedCountryCodeLength"
                [minlength]="modal.selectedCountryCodeLength" pattern="^[6-9]\d{9}$
                "/>
              </div>
            </div>
            
            <!-- Select2 Country Name (Fixed with 'India') -->
            <div class="form-group col-md-4">
              <label for="countryList" [ngClass]="{ 'text-danger': addAddressFormGroup?.submitted && !modal.selectedCountryId }">
                {{ addAddressFormGroup?.submitted && !modal.selectedCountryId ? "Please Select Country" : "Country" }}
              </label>
              <ng-select name="countryList" [items]="formUtilityData.countryList" bindLabel="CommonDesc" bindValue="Id"
                placeholder="Select Country" [disabled]="true" [(ngModel)]="modal.selectedCountryId"
                (change)="onCountryChange($event)" required [ngClass]="{ 'ng-select-invalid': addAddressFormGroup?.submitted && !modal.selectedCountryId }">
              </ng-select>
            </div>

            <!-- Select2 State Name -->
            <div class="form-group col-md-4">
              <label for="stateList" 
                [ngClass]="{ 'text-danger': addAddressFormGroup?.submitted && !modal.selectedStateId }">
                {{ addAddressFormGroup?.submitted && !modal.selectedStateId ? "Please Select State" : "State" }}
              </label>
              <ng-select name="stateList" [items]="formUtilityData.stateList" bindLabel="CommonDesc1" bindValue="Id"
                placeholder="Select State" [(ngModel)]="modal.selectedStateId" (change)="onStateChange($event)" required
                [ngClass]="{ 'ng-select-invalid': addAddressFormGroup?.submitted && !modal.selectedStateId }">
              </ng-select>
            </div>

            <!-- Select2 City Name -->
            <div class="form-group col-md-4">
              <label for="cityList" [ngClass]="{ 'text-danger': addAddressFormGroup?.submitted && !modal.selectedCityId }">
                {{ addAddressFormGroup?.submitted && !modal.selectedCityId ? "Please Select City" : "City" }}
              </label>
              <ng-select name="cityList" [items]="formUtilityData.cityList" bindLabel="CommonDesc2" bindValue="Id"
                placeholder="Select City" [(ngModel)]="modal.selectedCityId" required [ngClass]="{ 'ng-select-invalid': addAddressFormGroup?.submitted && !modal.selectedCityId }">
              </ng-select>
            </div>

            <!-- Rest Address -->
            <div class="form-group col-md-6">
              <label for="address" [ngClass]="{ 'text-danger': addAddressFormGroup.submitted && !modal.address }">
                {{ addAddressFormGroup.submitted && !modal.address  ? "Please Enter Address" : "Address" }}
              </label>
              <textarea type="text" class="form-control" placeholder="Enter Address" name="address"
                [(ngModel)]="modal.address" required rows="4" [maxlength]="500" [ngClass]="{
                  'is-invalid': addAddressFormGroup.submitted && !modal.address
                }"></textarea>
            </div>

            <!-- Address Type -->
            <div class="form-group col-md-3">
              <label for="addressTypeName"  [ngClass]="{ 'text-danger': addAddressFormGroup.submitted && !modal.selectedAddressTypeId }">
              {{ addAddressFormGroup.submitted && !modal.selectedAddressTypeId ? "Please Select Address Type" : "Address Type" }}
                
              </label>
              <ng-select name="addressTypeName" [items]="formUtilityData.addressTypeList" bindLabel="Text"
                bindValue="Id" placeholder="Select Address Type" [(ngModel)]="modal.selectedAddressTypeId" required [ngClass]="{
                  'is-invalid': addAddressFormGroup.submitted && !modal.selectedAddressTypeId
                }">
              </ng-select>
            </div>

            <!-- Pincode -->
            <div class="form-group col-md-3">
              <label for="pinCodeName" [ngClass]="{ 'text-danger': addAddressFormGroup.submitted && !modal.pinCode }">
                {{ addAddressFormGroup.submitted && !modal.pinCode ? "Please enter Pincode" : "Pincode" }}
              </label>
              <input type="number" class="form-control" placeholder="Enter PinCode" id="pinCodeName" name="pinCodeName"
                [(ngModel)]="modal.pinCode" required [ngClass]="{ 'is-invalid': addAddressFormGroup.submitted && !modal.pinCode }" />
            </div>
           
           <!-- Checkbox for Default Address Check Mark -->
            <div class="col-md-4" class="default-address">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="isDefaultCheckboxId"
                  [(ngModel)]="modal.isDefault" name="isDefaultCheckboxName" [disabled]="disableDefaultAddress">
                <label class="custom-control-label" for="isDefaultCheckboxId" style="margin-left: 5px;">Make this my default address</label>
              </div>
            </div>

            <!-- Checkbox for Company Detail -->
            <div class="col-md-4" class="avail-gst">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="isGstCheckboxId"
                  [(ngModel)]="modal.isAvailGst" name="isGstCheckboxName" [disabled]="disableDefaultAddress">
                <label class="custom-control-label" for="isGstCheckboxId" style="margin-left: 5px;">Do you wish to avail GST benefits?</label>
              </div>
            </div>
            <div class="offset-md-8"></div>

            <!-- Input: GST Number -->
            <div class="form-group col-md-6" [hidden]="!modal.isAvailGst">
              <label for="gst-number"></label>
              <input type="text" id="gst-number" class="form-control" placeholder="Enter GST Number" #nameModal="ngModel" name="gstNumber"
                [(ngModel)]="modal.gstNumber" />
            </div>

            <!-- Input: GST Firm Name -->
            <div class="form-group col-md-6" [hidden]="!modal.isAvailGst">
              <label for="gst-firm-name"></label>
              <input type="text" id="gst-firm-name" class="form-control" placeholder="Enter Firm Name" #nameModal="ngModel" name="gstNumber"
                [(ngModel)]="modal.gstFirmName" />
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-solid" data-dismiss="modal" (click)="closeForm(false)">
              Close
            </button>
            <button type="submit" class="btn btn-solid">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>