import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SigninSignupFormComponent } from 'src/app/signin-signup-form/signin-signup-form.component';
import { TokenInterceptorService } from 'src/app/token.interceptor';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isLogin = false;

  utilityModal: any = {};
  unSubscribe$: Subject<any> = new Subject<any>();
  // @ViewChild('cartListModalContainerRef', { read: ViewContainerRef, static: false }) cartListModalContainerRef: ViewContainerRef = Cartlis;
  @ViewChild('signInSignUpContainerRef', { read: ViewContainerRef, static: false }) signInSignUpContainerRef: ViewContainerRef | any;
  cartListModalRef: any;
  signInSignUpModalRef: any;
  addNewUserRef: any;
  userName: string = '';


  constructor(
    // private viewContainerRef: ViewContainerRef,
    public commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private interceptorService: TokenInterceptorService
  ) {
    if (this.commonService.isLogin()) {
      this.isLogin = true;
      this.utilityModal.userName = this.commonService.getLoginUserName();
      this.userName = this.utilityModal.userName;
      if (this.utilityModal.userName && this.utilityModal.userName.length > 50) {
        this.utilityModal.userName = this.utilityModal.userName.substring(0, 50);
      }
    }
    this.getAllCategoryList();
    this.utilityModal.searchOptionData = [];
  }

  ngOnInit() {
    this.commonService.loggedInSuccess.subscribe((res) => {
      if (res) {
        this.isLogin = true;
        this.userName = this.utilityModal.userName;
      }
    });

    this.commonService.loggedOutSuccess.subscribe((res) => {
      if (res) {
        this.isLogin = false;
      }
    });

    this.commonService.profileUpdated.subscribe((res) => {
      if (res) {
        this.utilityModal.userName = this.commonService.getLoginUserName();
        if (this.utilityModal.userName) {
          this.utilityModal.userName = this.utilityModal.userName.split(' ')[0];
        }
      }
    });
    this.interceptorService.sessionExpired.subscribe((res) => {
      if (res) {
        this.commonService.logOut();
        this.openSignInSignupModal();
      }
    });
  }

  openSignInSignupModal = (type?: string) => {
    this.commonService.loadModalDynamically(this, 'signInSignUpContainerRef', 'signInSignUpModalRef',
      SigninSignupFormComponent, type, (data: any) => { });
  };

  ngOnDestroy = (): void => {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  };

  getAllCategoryList = () => {
    if (this.commonService.allCategoriesWithSubCategory && this.commonService.allCategoriesWithSubCategory.length) {
      this.assignCategory();
    } else {
      this.commonService.catgoryFetched$.subscribe(
        (res: any) => {
          this.assignCategory();
        });
    }
  }

  assignCategory=()=>{
    this.utilityModal.categoryList = JSON.parse(JSON.stringify(this.commonService.allCategoriesWithSubCategory));
    this.utilityModal.menuCategoryList = this.utilityModal.categoryList.filter((cat: any) => cat.ShowAsMenu === 1);
  }

  logOut = () => {
    this.commonService.logOut();
  };
}
