<!--modal popup start-->
<div class="modal fade bd-example-modal-lg theme-modal signupform_custom" id="forgot_password_popup" tabindex="-1" role="dialog"
aria-hidden="true">
<div class="modal-dialog modal-md modal-dialog-centered" role="document" >
    <div class="modal-content">
        <div class="modal-body modal1">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <div class="offer-content">
                                 <div class="row">
                                    
                                    
                                    <div class="'col-md-12">
                                        <div class="row">
                                            <div class="col-12 d-flex flex-column">
                                                <h4>Forgot Password</h4>
                                                <p class="d-none">
                                                    Do not share your personal details!
                                                </p>
                                            </div>
                                            <div class="col-12">
                                                <form action="" class="ps-4" #forgot_password_form="ngForm" (ngSubmit)="forgot_password_form.form.valid && submit()" novalidate>
                                                    <div class="row gap-3">
                                                        <div class="col-12">
                                                            <label>Mobile Number</label>
                                                            <div class="row">
                                                                <ng-container *ngIf="country_code_list">
                                                                    <div class="col-3">
                                                                        <input list="isd-code-list" class="form-control" name="country-code" id="country_code" [value]="default_country_code" (change)="selectCountryCode($event.target)" [disabled]="true">
                                                                        <datalist id="isd-code-list">
                                                                            <option *ngFor="let country_code of country_code_list" [value]="'+'+country_code.Phonecode"></option>
                                                                        </datalist>
                                                                    </div>
                                                                </ng-container>
                                                                <div class="text-start" [class]="country_code_list?'col-9':'col-12'">
                                                                    <input 
                                                                        type="number" 
                                                                        name="mobileNumber"
                                                                        class="form-control col-10"
                                                                        placeholder="enter mobile number" 
                                                                        [(ngModel)]="mobileNumber" 
                                                                        pattern="^[6-9][0-9]{9}$" 
                                                                        required
                                                                        [ngClass]="{'is-invalid': forgot_password_form.submitted && !mobileNumber }" />
                                                                        
                                                                        <small class="text-danger " *ngIf="forgot_password_form.form.controls['mobileNumber'] && (forgot_password_form.form.controls['mobileNumber'].dirty || forgot_password_form.form.controls['mobileNumber'].touched)">
                                                                            <ng-container *ngIf="forgot_password_form.form.controls['mobileNumber'].hasError('required')">
                                                                                mobile is Required!
                                                                            </ng-container>
                                                                            <ng-container *ngIf="forgot_password_form.form.controls['mobileNumber'].hasError('pattern')">
                                                                                * Invalid mobile number.
                                                                            </ng-container>
                                                                        </small>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Otp Input -->
                                                        <ng-container *ngIf="isOtpReceived ">
                                                            <div class="col-12"> 
                                                                <label for="otp">OTP</label>
                                                                <input
                                                                    type="number"
                                                                    class="form-control"
                                                                    placeholder="enter otp"
                                                                    #otpInput
                                                                    name="otp"
                                                                    id="otp"
                                                                    [(ngModel)]="code"
                                                                    [ngClass]="{'is-invalid': forgot_password_form?.submitted && !code }"
                                                                    required />
                                                            </div>
                                                        </ng-container>

                                                        <!-- New Password & Confirm Password Input -->
                                                        <ng-container *ngIf="(isVerifiedOtp)">
                                                            <!-- New password input -->
                                                            <div class="col-12">
                                                                <label for="password">New Password</label>
                                                                <input
                                                                    type="password"
                                                                    class="form-control"
                                                                    placeholder="enter new password"
                                                                    name="password"
                                                                    id="password"
                                                                    [(ngModel)]="password"
                                                                    [ngClass]="{'is-invalid': forgot_password_form?.submitted && isVerifiedOtp && !password }"
                                                                    required />
                                                            </div>
                                                            
                                                            <!-- Confirm password input -->
                                                            <div class="col-12" >
                                                                <label for="confirmPassword">Confirm Password</label>
                                                                <input
                                                                    type="password"
                                                                    class="form-control"
                                                                    placeholder="enter confirm password"
                                                                    name="confirm_password"
                                                                    id="confirm_password"
                                                                    [(ngModel)]="confirmPassword"
                                                                    required
                                                                    [ngClass]="{ 'is-invalid': (forgot_password_form?.submitted && isVerifiedOtp && !confirmPassword) ||
                                                                        (password && confirmPassword && (password !== confirmPassword))}"
                                                                />
                                                            </div>
                                                        </ng-container>

                                                         <!-- Action Button's -->
                                                        <div class="col-md-12  ">
                                                            <div class="row gap-3 gap-md-0">
                                                                <div class=" order-sm-first order-last {{isVerifiedOtp?'d-none':isOtpReceived?'d-block col-md-6':'d-none'}}" >
                                                                  <button class="btn btn-solid btn-block waves-effect" [disabled]="resendDisabled" (click)="resendOtp()">Resend OTP
                                                                    <!-- <span *ngIf="resendDisabled==false">Resend OTP</span>
                                                                    <span *ngIf="resendDisabled==true">Resend OTP in {{transform(remainingTime)}}</span> -->
                                                                    </button>
                                                                  <span *ngIf="isOtpReceived && resendDisabled===true">Resend OTP in {{transform(remainingTime)}}</span>
                                                                </div>
                                                                <div class="{{isVerifiedOtp?'col-md-12':isOtpReceived?'col-md-6':'col-md-12'}}" >
                                                                    <button type="submit" class="btn btn-solid btn-block waves-effect">
                                                                        {{ isVerifiedOtp ? "Reset Password" : isOtpReceived ? "Verify" : "Continue" }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-md-12 text-right mt-2">
                                                            <a href="javascript:void(0)" class="anchor-login forgot_pass"
                                                                (click)="onAnchorClick('SignIn')">Existing User Log in</a>
                                                        </div> -->
                        
                                                        <!-- <div class="col-md-12 mt-4">
                                                            <a href="javascript:void(0)" class="btn btn-solid w-100"
                                                                (click)="onAnchorClick('signUp')">New to Create an account</a>
                                                        </div> -->

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!--modal popup end-->