<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>Terms &amp; Conditions</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Terms &amp; Conditions</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb end -->

 <!-- about section start -->
 <section class="about-page section-b-space">
    <div class="container">
        <div class="row">
           
            <div class="col-sm-12">
                <h5 class="h6 mb-3 text-dark lh-base">Before accessing or using the Application and website at <a href="g9kart.com">www.g9kart.com</a> (here in after referred to
                    as “Platform”) users are advised to go through these terms and conditions ("Terms") carefully
                    along with Privacy Policy published on the Platform ("Privacy Policy") and other policies (as
                    may be notified/displayed/published on the Platform) as these will constitutes the contract
                    between the User of Platform and Company <strong>(collectively “Agreement”)</strong>.</h5>

                <h5 class="h6 mb-3 text-dark lh-base">If any user transact on the Platform, user shall be subject to the policies that are
                    applicable to the Platform for such transaction.</h5>
                
                <h5 class="h6 mb-3 text-dark lh-base">By accessing, browsing or otherwise using the platform, users agree to abide by this Agreement
                    as and when these are published on platform.</h5>
            </div>

            <h4 class="h4">About the Terms</h4>
            <div class="col-sm-12">
                <h4 class="h5"> Introduction </h4>

                <p class="h6 text-dark lh-base mb-3 ">G9KART is an application which provides a display place to registered suppliers
                    <strong>(“Suppliers”)</strong> and offers to sell their products to the registered
                    users of Application.</p>
                <p class="h6 text-dark lh-base mb-3 ">The operation of the application and the website www.g9kart.com is undertaken by
                    <strong>G9KART SERVICES PRIVATE LIMITED (“Company”)</strong></p>
                <p class="h6 text-dark lh-base mb-3 ">Company will provide facilities like Application management and allied marketing,
                    payment collection facility, order fulfilment, order management, enquiry management
                    and other incidental services so as to enable the transactions between supplier and
                    the Users <strong>(“Services”)</strong> and beyond this company will not be responsible for any kind
                    of assistance</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5"> Applicability of terms </h4>
                <p class="h6 text-dark lh-base mb-3 ">
                    If any person installs, download or even merely visit or access any part of the Platform or utilise
                    the Services, such persons are referred to as users, which include without limitation users who are
                    browsers, Suppliers, Resellers, merchants, other purchaser or contributors of content (collectively,
                    <strong>"User"</strong>) and the agreement will be applicable on that user.
                </p>
                <p class="h6 text-dark lh-base mb-3 ">
                    Effective date of agreement between user and the company is the date on which Application is
                    downloaded/Website is accessed and/or the date on which terms of Agreement are updated, creating
                    a legally binding arrangement between the User and the Company.
                </p>                
            </div>
            
            <div class="col-sm-12">
                <h4 class="h5"> Modification in Terms of Agreement </h4>
                <p class="h6 text-dark lh-base mb-3 ">
                    On website user can review the most current version of the Agreement at any time. All right 
                    to unilaterally update, change or replace any part of the Agreement by publishing updates or
                    changes on the Platform is reserved by the company and such amended provisions shall be
                    effective immediately upon being posted on the platform.
                </p>
                <p class="h6 text-dark lh-base mb-3 ">
                    To check this page periodically for any changes is the responsibility of the user. It will be
                    deemed that user accepted those changes if user continues to use or access to the Application
                    following the posting of any changes
                </p>                
            </div>
            
            <div class="col-sm-12">
                <h4 class="h6">***User is advised to refrain from using the Platform in case of not agreeing with the terms of the agreement.</h4>
            </div>

            <h4 class="h4">Account Registration, Suspension &amp; Termination</h4>
            
            <div class="col-sm-12">
                <h4 class="h5"> Registration </h4>
                <p class="h6 text-dark lh-base mb-3 ">To avail the services on the platform user requires to register first as company does not permit users to avail the services without prior registration. On creating an account user become a member and then access the Application.  Subject to the agreement membership is limited for the purpose of buying or selling products and strictly not transferable.</p>
                <p class="h6 text-dark lh-base mb-3 ">Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If any user is a minor i.e. under the age of 18 years, he may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal guardian.</p>
                <p class="h6 text-dark lh-base mb-3 ">The company reserves the right to terminate the membership and / or refuse to provide with access to the Platform if it is brought to Company's notice or if it is discovered that user is under the age of 18 years and transacting on the Platform.</p>
                
                <h4 class="h5">Membership and User Eligibility</h4>
                <p class="h6 text-dark lh-base mb-2 ">On this platform user is required to enter a valid phone number. Upon registration, it will be deemed that user provides consent to be contacted by  Company via phone calls, SMS notifications, instant messages or any other means inter alia for subscription/services/promotional updates etc. Users may 'opt out' of such subscription/service/promotional updates either through the ‘opt out&apos; means provided or by writing to the support team.</p>
                <p class="h6 text-dark lh-base mb-2 ">User&apos;s mobile phone number and/or e-mail address is treated as user primary identifier on the Platform. It is user&apos;s responsibility to ensure that mobile phone number and email address is up to date on the Platform at all times. User agree to notify the company promptly if user&apos;s  mobile phone number or e-mail address changes by updating the same on the Platform through a onetime password verification.</p>
                <p class="h6 text-dark lh-base mb-3 ">If the Company sends an SMS and  User is unable to receive it due to the Users&apos; mobile number is incorrect  or out of data or blocked by the User's service provider, or the Users are otherwise unable to receive SMS, the Company shall be deemed to have  communicate to the User effectively and Users understand and agree to this.</p>
                
                
                <h4 class="h5">Conditions for Termination</h4>
                <p class="h6 text-dark lh-base mb-2 ">The company reserves the right to indefinitely suspend or terminate or block access of user&apos;s membership on the Platform and refuse to provide access to the Platform, remove or edit content at any time without notice and the user will remain liable for all amounts due upto including the date of termination, if:</p>
                <p class="h6 text-dark lh-base mb-2 ">Any information provided at the time of registration process or thereafter proves to be inaccurate, not correct or incomplete; and/or</p>
                
                <p class="h6 text-dark mb-1">During company&apos;s assessment, the user has :</p>
                <p class="h6 text-dark ms-2 ">Engaged in actions that are unlawful, fraudulent, negligent or derogatory to the Company&apos;s interests</p>
                <p class="h6 text-dar ms-2 ">Failed or is suspected to have failed to comply with any term or provision of the Agreement or applicable law.</p>
                <p class="h6 text-dark ms-2 ">Charged an unreasonably high price.</p>
                <p class="h6 text-dark ms-2 ">Unreasonable instances of returns and/or cancellations initiated.</p>
                <p class="h6 text-dark mb-3 ms-2 ">Where the violation of the Agreement gives rise to criminal or civil action, the Company may at its sole discretion pursue such action.</p>
                
                <h4 class="h6 text-dark mb-3 ">***Without prejudice to the above started of the company, in case of alleged fraud or other breach of this agreement by user, company may at its sole discretion (a) withhold all amounts payable to such user; and (b) impose penalties as the company may reasonably determine and set off such penalties from the monies payable by company to such user.</h4>
                
                
                <h4 class="h5">User's Obligation W.R.T Registrerd account</h4>
                <p class="h6 text-dark ms-2 ">
                    User shall be responsible for maintaining the confidentiality of your Display Name and Password 
                    and shall be responsible for all activities that occur under your Display Name and Password and 
                    the company is not be liable for any such activity in any manner.
                </p>
                <p class="h6 text-dark ms-2 ">
                    User acknowledges and agrees that having an account on Platform does not grant it any rights on 
                    Platform not specifically granted to them by the Company, and that the User has no ownership or 
                    other interest in the account. The User understands that all rights in and to the account are 
                    and shall forever be owned by and inure to the benefit of the Company.
                </p>
                <p class="h6 text-dark ms-2 mb-1">
                    On registration, the Users may receive a password protected account and an identification. The 
                    Users agree to:
                </p>
                <p class="h6 text-dark ms-4 mb-2">1. ensure that You successfully log out from Your account at the end of each session.</p>
                <p class="h6 text-dark ms-4 mb-2">2. maintain the confidentiality of their password, if applicable.</p>
                <p class="h6 text-dark ms-4 mb-2">3. immediately notify the company of any unauthorized use of account. If there is reason to
                    believe that there is likely to be a breach of security or misuse of account, company may request user to change the
                    password or  may suspend user account without any liability to company, for such period of time as the company deem
                    appropriate in the circumstances.
                </p>                
            </div>

          

            <h4 class="h4">Placing orders &amp; Financials Terms:</h4>
            <div class="col-sm-12">
                <p class="h6 text-dark lh-base mb-2">The Platform enables the Buyer and Seller to transact on the Platform. Company is not and cannot be a party to or control in any manner any transaction between the Platform's Users. Henceforward:</p>
                <p class="h6 text-dark lh-base mb-2 ms-4">
                    1. The platform facilitates buyers to place the orders for the product listed by Suppliers
                    on Application. Placement of order by a Buyer with Seller on the Platform is an offer to
                    buy the product(s) in by the Buyer to the Seller and it shall not be construed as
                    Seller's acceptance of Buyer's offer to buy the product(s) ordered. The Seller retains
                    the right to cancel any such order placed by the Buyer, at its sole discretion and the
                    Buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid
                    by Buyer in case of such cancellation by Seller, shall be refunded to the Buyer.</p>
                <p class="h6 text-dark lh-base mb-2 ms-4">
                    2. On receipt of an order from a User, Company shall send electronically a confirmation
                    of such order to Supplier and the User concerned. Further, the Company may inform the User
                    about the availability or unavailability or change in price of the order as informed by
                    Supplier concerned, from time to time. Confirmation of the order by Supplier shall be
                    treated as final.</p>
                <p class="h6 text-dark lh-base mb-3 ms-4">
                    3. Company does not make any representation or Warranty as to specifics (such as quality,
                        value, scalability, etc.) of the products or services proposed to be sold or offered
                        to be sold or purchased on the Platform. Company does not implicitly or explicitly
                        support or endorse the sale or purchase of any products or services on the Platform.
                        Company accepts no liability for any errors or omissions, whether on behalf of itself
                        or third parties.
                </p>
            </div>


            <div class="col-sm-12">
                <p class="h6 text-dark lh-base mb-3">
                    All commercial/contractual terms such as Delivery, Dispatch of products and/or services are
                    offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms
                    include without limitation price, shipping costs, payment methods, payment terms, date,
                    period and mode of delivery, warranties related to products and services and after sales
                    services related to products and services. Company does not have any control or does not
                    determine or advise or in any way involve itself in the offering or acceptance of such
                    commercial/contractual terms between the Buyers and Sellers.  All discounts, offers (including
                    exchange offers) are by the Seller/Brand and not by company. The Company also reserves the
                    right to withhold benefits such as COD payments, right to claim refunds from time to time at
                    its sole discretion owing to internal reasons or external factors Policies related to
                    returns/exchanges, penalties, refunds, cancellation will be updated in the Application from
                    time to time. The Company holds the right to change these policies as required in the
                    Application without any permission from the Users.
                    </p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Payment &amp; Settlement of Payment</h4>
                <p class="h6 text-dark lh-base mb-3">
                    The user understands and agrees that the payment facility provided by Application is
                    merely used by the Buyer and Seller to facilitate the completion of the Transaction.
                    Company may, at the request of the Supplier or the Reseller, act as the payment agent
                    for the limited purpose of accepting payments on behalf of such Suppliers and Resellers.
                    The Users understand, accept, and agree that the payment facility provided by the Company
                    is neither a banking nor financial service but is merely a facilitator providing a third-party
                    payment processor for the transactions on the Application. Use of the payment facility
                    shall not render company liable or responsible for the non-delivery, non-receipt, non-payment,
                    damage, breach of representations and warranties, non-provision of after sales or warranty
                    services or fraud as regards the products and /or services listed on  Platform.	The Company
                    will not be liable for any charges made by the Users bank in relation to payment of the total
                    amount.
                </p>
                <p class="h6 text-dark lh-base mb-3">
                    Before shipping / delivering your order to you, Seller may request you to provide supporting
                        documents (including but not limited to Govt. issued ID and address proof) to establish the
                        ownership of the payment instrument used by you for your purchase. This is done in the interest
                        of providing a safe online shopping environment to Our Users. Also, information such as name,
                        transaction details, device type, Platform usage details, PAN number, payment details, billing
                        address and credit card information and any other information in relation thereto may need to be
                        provided either to the Company or the third-party payment processor. If the Users are directed to
                        the third-party payment processor, they may be subject to terms and conditions governing use of
                        that third party&apos;s service and that third party&apos;s personal information collection practices. Users
                        are requested to review such terms and conditions and privacy policy before using the Application.
                </p>

                <p class="h6 text-dark lh-base mb-3">
                    The transaction is bilateral between Suppliers & Users & end users/consumers ("User Transactions"),
                        the Company is not liable to charge or deposit any taxes applicable on such transaction. Company
                        merely collects the payment on behalf of the Supplier. All applicable taxes and levies, the rates
                        thereof and the manner of applicability of such taxes are to be charged and determined by the Supplier.
                        Company holds no responsibility for the legal correctness/validity of the levy of such taxes.
                        <br/><br/>
                        Also, Company is not obligated towards any direct or indirect tax obligation of the User that may
                         arise as a result of User's access or use of Services on the Platform. The requirement for
                         registration and compliances under the GST Laws and other tax laws is the sole responsibility
                         of the User, the Company is not liable in any way for any act by such User who acts in violation
                         of the any applicable law. Accordingly, User is advised to seek independent tax advice relating
                         to its business and/or transaction through Platform including whether it is liable for GST
                         registration.
                </p>

                <p class="h6 text-dark lh-base mb-1">
                    While availing any of the payment method/s available on the Platform, company will not be responsible
                    or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to
                    user due to: </p>
                <ol class="list-group list-group-flush list-group-numbered h6 mb-3">
                    <li class="list-group-item border-0 ">Lack of authorization for any transaction/s, Or;</li>
                    <li class="list-group-item border-0 ">Exceeding the present limit mutually agreed by You and between "Bank/s", Or</li>
                    <li class="list-group-item border-0 ">Any payment issues arising out of the transaction, Or</li>
                    <li class="list-group-item border-0 ">Decline of transaction for any other reason/s.</li>
                </ol>
             

                <p class="h6 text-dark lh-base mb-3">
                    You understand, accept and agree that the payment facility provided by company is neither a banking nor
                        financial service but is merely a facilitator providing an electronic, automated online electronic
                        payment, receiving payment through Cash-on delivery, collection and remittance facility for the 
                        Transactions on the Company&apos;s Platform using the existing authorized banking infrastructure and Credit 
                        Card payment gateway networks. Further, by providing Payment Facility, Company is neither acting as 
                        trustees nor acting in a fiduciary capacity with respect to the Transaction or the Transaction Price.
                </p>
                <p class="h6 text-dark lh-base mb-3">
                    Company may do such checks as it deems fit before approving the receipt of/Buyer&apos;s commitment to
                    pay (for Cash on Delivery transactions) Transaction Price from the Buyer for security or other
                    reasons at the discretion of company. As a result of such check if company is not satisfied with
                    the creditability of the Buyer or genuineness of the Transaction or other reasons at its sole
                    discretion, company shall have the right to reject the receipt of / Buyers commitment to pay
                    Transaction Price. For avoidance of doubt, it is hereby clarified that the ‘Cash on Delivery&apos;
                    feature for payment, may be disabled for certain account users, at the sole discretion of company.
                </p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Service Charges &amp; Fees</h4>
                <p class="h6 text-dark lh-base mb-3">Company at its sole discretion may charge for providing services on platform,
                    subject to approval of user at the time of placing an order on platform.  Company may introduce new services/fees
                    including charges for a premium return service, cancellation charges, cash on delivery handling fees etc and modify
                    some or all of the existing services/fees offered on the Platform. In such an event, Company reserves the right to
                    introduce fees for the new services offered or amend/introduce fees for existing/new services, as the case may be.
                    Changes to the Fee Policy shall be posted on the Platform and such changes shall automatically become effective
                    immediately after they are posted on the Platform. </p>
            </div>
            
            <h4 class="h4">Terms &amp; Conditions of Offers &amp; Benefits provided by Company</h4>
            <div class="col-sm-12">
                <p class="h6 mb-2 text-dark">1.	Unless otherwise mentioned and subject to conditions, all discounts, offers (including exchange offers) on products are by the Seller/Brand and not by the company. These are termed as <strong>“Supplier offers.”</strong></p>
                <p class="h6 mb-2 text-dark">2.	Various types of marketing and promotional campaigns, which may include offers, discounts, and other promotional offers to be used on our platform, are conducted by us from time to time. These are referred to as <strong>“Company&apos;s Offers,”</strong> which are subject to such terms and conditions which are solely determined by the company.</p>
                <p class="h6 mb-2 text-dark">3.	The offers, discounts, and promotional offers may be changed or amended from time to time by us. Terms  of such discounts and offers may be varied for customers based on factors like usage of the platform, volume of transactions, time spent on the platform, city, place of residence, time, etc.</p>
                <p class="h6 mb-2 text-dark">4.	The right to make these offers available for a limited time period and to void, amend, discontinue, cancel or reject the use of any of the offers, discounts or promotional offers without any prior intimation is reserved by us.</p>
                <p class="h6 mb-2 text-dark">5.	The right to vary the timing and dates of the offer; the number and category of products available for offer at any time, subject to our absolute discretion, is reserved by us.</p>
                <p class="h6 mb-2 text-dark">6.	The right to make certain offers valid for limited inventory and subject to availability of product at the time of booking is reserved by us.</p>
                <p class="h6 mb-2 text-dark">7.	Certain offers apply only to certain products covered under such promotional offers given by us. These are not applicable to the same or similar products sold by the same Supplier or other Suppliers but not covered under offers. These offers are operated on Company&apos;s discretion on a first-come-first-serve basis. Once the stocks of offer product are over , the same product on the platform which are available outside the promotional offer may be purchased.</p>
                <p class="h6 mb-2 text-dark">8.	The right to cancel your option to return or exchange any product purchased under offers and discounts is reserved by company at its own discretion.</p>
                <p class="h6 mb-2 text-dark">9.	The terms and conditions governing the offers, discounts, and other promotional offers provided on our platform should be reviewed and complied with by the user.</p>
                <p class="h6 mb-2 text-dark">10. The validity of any of the offers, discounts, or promotional offers, when used in conjunction with other promotional offers or vouchers, hasn&apos;t been established unless explicitly mentioned by us or the Supplier.</p>
                <p class="h6 mb-2 text-dark">11. Unless otherwise communicated, the exchange of the offers, discounts, and promotional offers for cash is not allowed and can be only availed in accordance with terms and conditions of the offer.</p>
                <p class="h6 mb-2 text-dark">12. Offers such as the first order discount, reactivation discount, etc. may be applied at cart level and if multiple products are present in a single transaction, the division and application of such offer, discount, or promotion to each product in the cart are determined by us.</p>
                <p class="h6 mb-2 text-dark">13. If the User (i) cancels any product under any promotion, offer, or discount, or (ii) returns any product which had any promotions, offers, or discounts, subject to the policy of Company&apos;s Cancellation and/or Return, Refund and Replacement. Only the refund of the amount paid by the User for the purchase of such product will be given.</p>
                <p class="h6 mb-2 text-dark">14. If a product is cancelled or returned in line with Company&apos;s Cancellation and/or Return, Refund, and Replacement Policy forfeiture of any offer, promotion, or discount associated with such a product will occur.</p>
                <p class="h6 mb-3 text-dark">15. No liability regarding the offers, discounts, and promotional offers on the platform is held by us or the Supplier.</p>
            </div>

            <h4 class="h4">Terms &amp; Conditions regarding G9KART Recognition &amp; Incentive policy</h4>
            <div class="col-sm-12">
                <p class="h6 mb-2 text-dark">1.	Under the G9Kart Recognition and Incentive Policy, The eligible users will be able to earn certain benefits and rewards in the form of <strong>Reward Credits</strong>.</p>
                <p class="h6 mb-2 text-dark">2.Reward Credit may be earned by the eligible user for(i) purchasing eligible products on G9KART, aligning with the terms herein (ii) or such other activities as may be determined by the Company from time to time.</p>
                <p class="h6 mb-2 text-dark">3.	The Eligible Users understand and acknowledge that the amount of Reward Credit that can be earned by an Eligible User may vary from one Specified Product to another on the Platform or from one Eligible User to another, depending upon such factors and criteria as determined by the Company at its sole discretion.</p>
                <p class="h6 mb-2 text-dark">4.	Eligible User acknowledge and understand that Reward Credits cannot be converted into cash, property, or any other form of economic benefits beyond their designated use for product acquisition, as determined by G9KART. Furthermore, these credits are non-transferable and cannot be shared or transferred to other users or individuals.</p>
                <p class="h6 mb-2 text-dark">5.	Subject to return or cancellation policy, if an Eligible User cancels or returns the entire order of the Redeemable Product(s) in relation to which Reward Credit were redeemed, then, the entire amount of Reward Credit redeemed on such order shall be returned / reinstated and granted back to the concerned Eligible User. In the event, the Eligible User cancels or returns only certain Redeemable Product(s) from the order and not the order in its entirety, then, the proportionate amount of Reward Credit that were redeemed on such returned or cancelled Redeemable Product(s), as applicable, shall be returned / reinstated and granted back to the Eligible User.</p>
                <p class="h6 mb-2 text-dark">6.	Eligible User acknowledges, understand and aware that all Reward Credits come with an expiration date, commencing from the date of their acquisition. To make the most of your rewards, ensure you use them before they expire.</p>
                <p class="h6 mb-3 text-dark">7.	Unless otherwise mandated by applicable legal requirements, if user decides to terminate their account or if the membership lapses for any reason, all accumulated Reward Credits in user possession will immediately be considered void and cannot be redeemed thereafter.</p>
            </div>

            <h4 class="h4">Use of the Flatform</h4>
            <div class="col-sm-12">
                <p class="h6 mb-2 text-dark">24x7 hour online services for new customer will be provided by the company. However, company does not ensure that access to the Application will be uninterrupted, timely, error-free, free of viruses or other harmful components. However, every effort will be made by the Company to rectify such defects.</p>
                <p class="h6 mb-2 text-dark">The Application requires internet connectivity and telecommunication links. These costs to access and use the Application and avail the services will be borne by Users only and not under any circumstances whatsoever, shall be borne by the company. Compatibility with all hardware and software, which Users use, is not guaranteed by the Company.</p>
                <p class="h6 mb-2 text-dark">The Company may carry out constant upgrades on Application, and some functions and features might not be fully operational. The Application is offered on an <strong>‘as is&apos;</strong> and <strong>‘as available&apos;</strong> basis. Any warranties, whether express or implied concerning the records and other data that might be available to Users, are disclaimed by the Company.</p>
                <p class="h6 mb-3 text-dark">Users are held solely responsible for any damages to their data system or any loss of data resulting from downloading content from the Application. Any warranty is not constituted on company on the ground of providing guidance or information, written or oral, obtained from the Company or via the Platform, unless otherwise stated.</p>
            </div>

            <h4 class="h4">Company&apos;s role in performance of Agreement or other Agreements</h4>
            <div class="col-sm-12">
                <p class="h6 mb-2 text-dark">1. G9KART is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. G9KART cannot and does not guarantee that the concerned Buyers and/or Sellers will perform any transaction concluded on the Platform. G9KART shall not and is not required to mediate or resolve any dispute or disagreement between Buyers and Sellers. Company through platform, is a mere facilitator of the transaction including between Supplier and User.</p>
                <p class="h6 mb-2 text-dark">2. Any representation of the Users or the Suppliers is not made by the Company, and any liability concerning any error or inconsistency relating to such Suppliers or Users displayed on the Platform is disclaimed by the Company. User understand and agree that the Services are provided to them on an <strong>"AS IS"</strong> or <strong>"AS AVAILABLE"</strong> basis. G9KART makes no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security and non-compatibility or reliability of any Content; (ii) any harm to user computer system, loss of data, or other harm that results from user access to or use of any Content; (iii) the deletion of, or the failure to store or to transmit, any Content and other communications maintained by the Services; and (iv) whether the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained through the Content, will create any warranty or representation not expressly made herein or therein.</p>
                <p class="h6 mb-2 text-dark">3. Any representation or warranty concerning the item-specifics (such as legal title, creditworthiness, identity, etc.) of its Users isn&apos;t made by the Company. Any misuse of information shared by Users with the Company, through the Users&apos; profile, or with a third party on the Platform, chat rooms, forums, or comments will not be held liable by the Company.</p>
                <p class="h6 mb-2 text-dark">4. Company does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. Users are advised to independently verify the bona fides of any particular Supplier that you choose to deal with on the Platform and use your best judgment in that behalf.</p>
                <p class="h6 mb-2 text-dark">5. Company does not at any point of time during any transaction between Buyer and Seller on the Platform come into or take possession of any of the products or services offered by Seller nor does it at any point gain title to or have any rights or claims over the products or services offered by Seller to Buyer.</p>
                <p class="h6 mb-2 text-dark">6. At no time shall G9KART hold any right, title or interest over the products nor shall G9KART have any obligations or liabilities in respect of such contract entered into between Buyers and Sellers. G9KART is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.</p>
                <p class="h6 mb-3 text-dark">7. Seller&apos;s right or ability to sell or distribute their listed products does not or cannot be verified by the company.</p>
            </div>

            <h4 class="h4">Conditions for Usage of Platform:</h4>
            <div class="col-sm-12">
                <h4 class="h5">Ownership and Rights</h4>
                <p class="h6 mb-1 lh-base text-dark">The Platform should not be used by the User to host, display, upload, download, modify, publish, transmit, update, or share any information which:</p>
                <ol class="list-group list-group-flush list-group-numbered">
                    <li class="list-group-item border-0 fs-6 ">Is owned by another person and to which no right whatsoever is possessed by the User;</li>
                    <li class="list-group-item border-0 fs-6 ">Is considered grossly harmful, is harassed, is blasphemed, is defamed, is seen as obscene, is regarded as pornographic, is viewed as paedophilic, is libelled, is slandered, is criminally incited, or is invaded upon another's privacy, is perceived as hateful, or is seen as racially or ethnically objectionable, is disparaged, is related to or is encouraged for money laundering or gambling, or is viewed as unlawful in any manner whatsoever; or is threatened or harassed unlawfully including but not limited to being represented indecently as women within the context of the Indecent Representation of Women (Prohibition) Act, 1986;
                    <li class="list-group-item border-0 fs-6 ">Is perceived as patently offensive by the online community, such as content that is sexually explicit, or content that is promoted for obscenity, paedophilia, racism, bigotry, hatred or any kind of physical harm against any group or individual</li>
                    <li class="list-group-item border-0 fs-6 ">Is perceived as harmful to a child or a minor.</li>
                    <li class="list-group-item border-0 fs-6 ">Advocate harassment or harasses another person.</li>
                    <li class="list-group-item border-0 fs-6 ">Is infringed upon or violated in terms of any third party&apos;s rights, including, but not limited to, intellectual property rights, rights of privacy (with unauthorized disclosure of a person&apos;s name, image, email address, physical address, or phone number included) or rights of publicity, with any unauthorized use or posting of any third party's social media image without the owner's consent included.</li>
                    <li class="list-group-item border-0 fs-6 ">Is promoted as an illegal or unauthorized copy of another person&apos;s copyrighted work, such as having pirated computer programs provided or links to them, or having information provided to circumvent manufacturer-installed copy-protect devices.</li>
                    <li class="list-group-item border-0 fs-6 ">Attempts to gain unauthorised access  or the scope of authorized access to the Application, profiles, blogs, communities, account information, or other areas of the Application is exceeded, or passwords or personal identifying information for commercial or unlawful purposes are solicited from other Users.</li>
                    <li class="list-group-item border-0 fs-6 ">Is interfered with by another User's use and enjoyment of the Platform or the enjoyment of similar services by any third-party users is interfered with.</li>
                    <li class="list-group-item border-0 fs-6 ">Is referred to a website or URL that, in our sole discretion, is found to contain material that is inappropriate for the Platform or any other website, or contains content that is deemed to be prohibited or is found to violate the spirit of these Terms.</li>
                    <li class="list-group-item border-0 fs-6 ">Is deceived or is misled by the addressee about the origin of the message or any information which is patently false or misleading in nature is knowingly and intentionally communicated, which may reasonably be perceived as a fact, with a false identity being created for the purpose of misleading othersTop of Form.</li>
                    <li class="list-group-item border-0 fs-6 ">Is impersonated as another person.</li>
                    <li class="list-group-item border-0 fs-6 ">The unity, integrity, defence, security or sovereignty of India is threatened, friendly relations with foreign states are threatened, public order is threatened, incitement to the commission of any cognisable offence is caused, the investigation of any offence is prevented, or other nations are insulted.</li>
                    <li class="list-group-item border-0 fs-6 ">Software viruses or other computer programming routines that may damage, detrimentally interfere with, diminish the value of, surreptitiously intercept, or expropriate any system, data, or personal information are contained.</li>
                    <li class="list-group-item border-0 fs-6 ">Offers, attempts to offer, trade, or attempts to trade in any item, either directly or indirectly, where such dealing is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation, or guideline for the time being in force.</li>
                    <li class="list-group-item border-0 fs-6 ">Is recognized as patently false and untrue, and has been written or published in any form, with the intention of misleading or harassing a person, entity, or agency for financial gain or causing injury to any person.</li>
                </ol>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Sole Use of the Application/Platform</h4>
                <p class="h6 mb-1 lh-base text-dark">The User has to comply with and ensure the following while accessing or using the platform or availing the services:</p>
                <ol class="list-group list-group-flush list-group-numbered ">
                    <li class="list-group-item border-0 fs-6">All registration information is truthfully, lawfully, and accurately submitted by the User.</li>
                    <li class="list-group-item border-0 fs-6" >The Application/Platform is used solely by the User and the use of their account is not authorized by others.</li>
                    <li class="list-group-item border-0 fs-6" >Any information that:  i) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or that constitutes an invasion of a right of privacy of another person; (ii) is bigoted, hateful, or racially or otherwise offensive; (iii) is violent, vulgar, obscene, pornographic, or otherwise sexually explicit; (iv) is illegal or that encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them, is not submitted, posted, uploaded, distributed, or otherwise made available or transmitted by the User.</li>
                    <li class="list-group-item border-0 fs-6" >All necessary licenses, consents, permissions, and rights have been owned by Users and no payment, permission, or authorization is required from any other party or entity for the use, distribution, or other exploitation in all manners permitted by the Agreement of all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and/or other proprietary rights contained in any content that has been submitted, posted, uploaded, distributed, or otherwise transmitted or made available by Users.</li>
                    <li class="list-group-item border-0 fs-6" >The Platform will not be used by the User in any way that is unlawful, or harms the Company or any other person or entity.</li>
                    <li class="list-group-item border-0 fs-6" >Any software or other computer files that contain a virus or other harmful component will not be posted, submitted, uploaded, distributed, or otherwise transmitted or made available by the User, and the Platform or any connected network will not be impaired or damaged, or any person or entity&apos;s use or enjoyment of Application will not be interfered with.</li>
                    <li class="list-group-item border-0 fs-6" >Another person&apos;s username, password, or other account information will not be used by the User, nor will another person&apos;s name, likeness, voice, image, or photograph; any person or entity will not be impersonated, and your identity or affiliation with any person or entity will not be misrepresented.</li>
                    <li class="list-group-item border-0 fs-6" >Any content of the Platform, including but not limited to, disclaimers or proprietary notices such as copyright or trademark symbols, logos, will not be deleted or modified, or attempted to be, by the User.</li>
                    <li class="list-group-item border-0 fs-6" >Any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest will not be posted or contributed by the User.</li>
                    <li class="list-group-item border-0 fs-6" >The Platform shall not be accessed without authority by the User or be used in a manner that damages, interferes, or disrupts any part of it or any equipment or any network on which it is stored or any equipment of any third party.</li>
                    <li class="list-group-item border-0 fs-6" >An attempt to gain unauthorized access to any portion or feature of the Application, or any other systems or networks connected to the Platform, shall not be made by the User by any means. The vulnerability of Platform shall not be probed, scanned, or tested, nor shall the security or authentication measures on Platform or any network connected to it be breached by the User.</li>
                    <li class="list-group-item border-0 fs-6" >It is agreed by the User that any device, software, or routine will not be used to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on it, or with another person&apos;s use of the Platform. The Platform or any of its content may not be used for any purpose that is unlawful or prohibited by this Agreement.</li>
                    <li class="list-group-item border-0 fs-6" >Full compliance with the applicable law, as amended from time to time, must be ensured by the User at all times, including compliance with (i) the Information Technology Act, 2000 and the rules established under it; (ii) all applicable domestic laws, rules and regulations (including the provisions of any exchange control laws or regulations currently in force); and (iii) international laws, foreign exchange laws, statutes, ordinances, and regulations (including, but not limited to, Direct and Indirect Taxes as applicable according to the current statute in the country) regarding the use of the Application, listing, purchasing, soliciting offers to purchase, and selling of products or Services. Any transaction which is prohibited by the provisions of any applicable law, including exchange control laws or regulations currently in force, must not be engaged in by the User.</li>
                    <li class="list-group-item border-0 fs-6" >To enable the information supplied by the Users to be used by the Company, without any violation of rights or laws, a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights is agreed to be granted to the Company by the Users. The information will only be used by the Company in accordance with this Agreement, as applicable to the use of the Platform and for the provision of Services.</li>
                </ol>
               
            </div>
            
            <div class="col-sm-12">
                <h4 class="h5">User Account Security and Compliance Measures</h4>
                <p class="h6 mb-3 lh-base text-dark">At times and at their sole discretion, the right to disable any user identification code or password is reserved by the Company if any provisions of this Agreement have been failed to comply with by any User. All rights to take necessary action and claim damages that may occur due to the involvement/participation of the User, either on their own or through group/s of people, intentionally or unintentionally in hacking, shall be held by the Company.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Fair usage policy </h4>
                <p class="h6 text-dark lh-base mb-1 "> At G9Kart, we are not just an e-commerce platform; we are
                    your partner in enhancing your lifestyle. We are committed to continuously improving and
                    innovating to meet your evolving needs and expectations. All customers are encouraged by us
                    to use the platform responsibly. We keep track of customer behaviour which includes maintaining
                    order history and other details relating to the manner of use of our platform to ensure that
                    all the customers use our platform in good faith. Any indication of abuse, fraudulent activity,
                    or association with suspicious behaviour which includes excessive returns or refusal to accept
                    shipments may result in the removal of certain benefits available to our other users and the user
                    may face other actions, which we reserve to take actions, including but not limited to, blocking
                    any user from transacting on the Platform, levying a service fee, discontinuing COD options,
                    blocking COD payment, blocking refund claims, restraining or refusing the option to return the
                    product etc. against you to address such issues.</p>

                <p class="h6 text-dark lh-base mb-3 ">Supplier may offer benefits, from time to time, based on their
                    discretion and policies to the customers whose profiles indicate high volumes of valid transactions
                    on the platform.</p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Accuracy &amp; Completeness of Information on platform </h4>
                <p class="h6 text-dark lh-base mb-1 ">While the Company always strives to provide the most accurate and complete information on the platform, the Users are cautioned not to solely rely on this material. It&apos;s recognized by the User that the primary and most accurate source of information comes from the suppliers themselves, who list the products on the platform. These suppliers are responsible for ensuring the accuracy and completeness of the displayed content, although they don&apos;t have control over every piece of information. Decisions should be made after consulting primary, more accurate, more complete or timely sources of information rather than relying solely on general platform content.</p>
                <p class="h6 text-dark lh-base mb-1 ">The Platform may contain certain contents, but no obligation is undertaken by the Company to monitor these contents. Any reliance User place on the contents provided for user reference on the Platform is at their own risk.</p>                
                <p class="h6 text-dark lh-base mb-1 ">The Platform may contain historical information. User should use the contents of the Platform for reference only. The Company doesn&apos;t obligate itself to monitor changes to or updates in the Services that may have been applied to the Platform. Furthermore, the Company doesn&apos;t guarantee that all the information on the Platform is updated or refreshed.</p>                
                <p class="h6 text-dark lh-base mb-1 ">Occasionally, inaccuracies or omissions may occur in information pertaining to the products, pricing, promotions, offers, shipping charges, transit times, and availability on the Platform. The Platform may also contain typographical errors, pricing inaccuracies, promotions, and offers. The Company reserves the right to correct any errors on the Platform without prior notice.</p>                
                <p class="h6 text-dark lh-base mb-3 ">The Platform does not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted on the Platform or endorse any opinions expressed via the Contents. Use of Independent investigations and sound technical and business judgement of user is advisable.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Information related to Product on platform</h4>
                <p class="h6 text-dark lh-base mb-1 ">The Platform does not provide all the information; instead, suppliers provide it. For all respects, the responsibility for the information lies with the suppliers. Suppliers are discouraged from and should not exaggerate or overemphasise the attributes of such products so as to mislead Users in any manner.</p>
                <p class="h6 text-dark lh-base mb-1 ">Company has right to monitor the materials posted on Platform but has no obligation to monitor them. . However, the company at its own sole discretion has the right to remove or edit any content which violates, or is alleged to violate, any applicable law or the spirit of these Terms. Under no circumstances, whatsoever, Company assume any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on Platform.</p>                
                <p class="h6 text-dark lh-base mb-3 ">For  Correctness of the details pertaining to specifics (such as quality, value, sale ability, etc.) of the products which are proposed to be sold or offered to be sold or purchased on Platform only supplier will be held  responsible. </p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Listing &amp; Selling</h4>
                <p class="h6 text-dark lh-base mb-3 ">Supplier in addition to this Agreement are also Bound by Applicable laws of India, including without limitation, the following laws:</p>
                <p class="h6 text-dark lh-base mb-1">Foreign Exchange Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time.</p>                
                <ol class="list-group list-group-flush list-group-numbered mb-3 h6">
                    <li class="list-group-item border-0 ">Customs Act, Information and Technology Act, 2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there under, Foreign Contribution Regulation Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India)</li>
                    <li class="list-group-item border-0 ">The Legal Metrology Act, 2009 and Legal Metrology (Packaged Commodities) Rules, 2011 (Packaging Rules);</li>
                    <li class="list-group-item border-0 ">Drugs and Cosmetics Act, 1940 and Drugs and Cosmetics Rules, 1945 (D&C Rules);</li>
                    <li class="list-group-item border-0 ">The Consumer Protection (E-Commerce) Rules, 2020;</li>
                    <li class="list-group-item border-0 ">Food Safety and standard Act, 2006, Food Safety and Standard (Packaging and labelling) Regulation 2011, (FSS Packaging Regulation) Food Safety and Standard (Food Product Standard and Food Addictive) Regulation, 2011 (FSS Standard Regulations) and Food Safety and Standard (food or Health Supplements, Nutraceuticals, Food for Special Medical Purpose, Functional Food and Novel Food) Regulation 2016 (FSS Supplement Regulation).</li>
                </ol>    
                             
                <p class="h6 text-dark lh-base mb-3">Company understands that there is an obligation on Supplier to ensure that the package in which the products are sold complies with labelling and packing requirements. Hence to comply with applicable laws shall be the sole responsibility of Supplier and the Company shall not be held responsible in any manner. For any harm or loss in relation to contravention of above regulations or other applicable laws Suppliers shall indemnify Company and the Platform.</p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Listing of products on platform </h4>
                <p class="h6 text-dark lh-base mb-1">In accordance with the terms mentioned in this Agreement and any other contract entered into with Company for the said purpose, wherein other rights and obligations of the parties are defined in details, suppliers are permitted to list products for sale on the Application.</p>
                <p class="h6 text-dark lh-base mb-1">The Suppliers represent and warrant that they are legally capable to sell or list the products on Platform; and the listed items do not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of any third party.</p>                
                <p class="h6 text-dark lh-base mb-3">Any third party's images available over any social media without such owner's consent shall not be used by the Supplier. Suppliers and Users understand and agree that the Company is not responsible for the breach of the same.</p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">User Information &amp; Third party tools/links</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;Company collects non personal information as well as some personal information. All information that are collected, stored, or transmitted in any way on Platform about the users is processed for facilitating various operations on Platform, including registration, order placement, listing, or payments. Users are encouraged to view the Platform&apos;s Privacy Policy available on the Platform to get comprehensive understanding.</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;Access of third-party tools over the Platform which Company neither monitors nor has any control over may be provided by the company to the User. User understands, acknowledges and agrees that  company does not provide any warranties, representations or conditions of any kind and access to such tools is on an 'as is' and 'as available' basis. Under no circumstances, whatsoever, company shall have no liability arising from or relating to your use of optional third-party tools.</p>                
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;At its own risk and discretion User should use the optional tools offered through the Application/Platform. It is the user&apos;s responsibility to ensure that it is familiar with and approves the terms on which such tools are provided by the relevant third-party provider(s).</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;The Company is not responsible for examining or evaluating the content or accuracy and does not warrant and will not have any liability or responsibility of any materials or websites provided on the Platform via third-party links or affiliated with them.</p>                
                <p class="h6 text-dark lh-base mb-3">5.&nbsp;Regardless of existence of any third party link on platform, any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites do not make the Company liable .</p>                
            </div>
           
            <div class="col-sm-12">
                <h4 class="h5">Intellectual property &amp; IP Infringement</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;Without prior written approval from concerned independent third parties Users may not use any trademark, service mark or logo of any independent third parties</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;The trademarks of the Company are represented by “G9KART” and related icons and logos, whether registered or unregistered, and they are protected under applicable copyright, trademark, and other proprietary and intellectual property laws. These marks must not be adopted, copied, modified, used, or published by users without authorization.</p>                
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;The paper or digital copies of any materials that have been printed or downloaded must not be modified by users in any way, and any illustrations, photographs, video or audio sequences, or graphics must not be used by them separately from any accompanying text.</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;Any part of the materials on the Platform must not be used by users for commercial purposes without the Company granting a licence. The Company hereby reserves all rights that aren&apos;t claimed under this Agreement.</p>                
                <p class="h6 text-dark lh-base mb-1">5.&nbsp;It&apos;s understood by the user that the Platform and software within it might come with security components allowing digital materials to be protected. The usage of these materials falls under rules set by the Company or other facilitating parties. Any such security components and usage rules embedded in the Platform must not be overridden, disabled, circumvented, or interfered with by the user.</p>                
                <p class="h6 text-dark lh-base mb-1">6.&nbsp;User(s)/Supplier(s)&apos; trademark, word mark or intellectual property belongs to Such User(s)/Supplier(s) alone and the same isn&apos;t claimed or owned by the Company.</p>                
                <p class="h6 text-dark lh-base mb-1">7.&nbsp;Any material/content/photos often displayed on the Platform are subject to removal by Company at its sole discretion if they believe it&apos;s unlawful, might subject the Company to liability, violates this Agreement, or is found inappropriate by Company&apos;s standards. In the event of any investigation concerning this, the Company is willing to collaborate.</p>                
                <p class="h6 text-dark lh-base mb-1">8.&nbsp;The account of a User can be suspended or terminated by the Company as they see fit. In situations where the Company acts based on IP infringement allegations, Users acknowledge that the Company won&apos;t be held responsible for any damages, whether consequential or otherwise.</p>                
                <p class="h6 text-dark lh-base mb-1">9.&nbsp;Users acknowledge and agree that the Company does not acts as an arbitrator or judge regarding intellectual property disputes and it cannot, by any means, always verify that any Supplier on the Platform doesn&apos;t have the right to sell products. The Company encourages user to assist it in identifying listings on the Platform, which, according to Users&apos; knowledge or belief infringe their rights or third-party rights.</p>                
                <p class="h6 text-dark lh-base mb-3">10.&nbsp;Company does not and cannot be deemed to be endorsing a claim of infringement instances in which Company delist a product from Platform to safeguard Company&apos;s interest, by taking down a listing and further in those instances in which Company declines to take down a listing, Company does not and cannot be deemed to be endorsing that the listing is not infringing of third party rights or endorsing any sale or supply of merchandise or services pursuant to or on account of such listing.</p>
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Disclaimer &amp; Liabilities</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;In no event is or will liability be placed on the Company by the User, or by anyone claiming through a User, concerning a product or other User Transaction under contract, negligence, strict liability or other legal or equitable theory for any special, incidental, indirect, consequential, exemplary or punitive damages, loss of goodwill, loss of revenue, loss of opportunity, loss of anticipated profits, whatsoever, including those resulting from loss of use, data or profits. Additionally, the Company is advised or not advised of the possibility of such damages, company cannot be held liable including breach of contract or warranty or negligence or any other claim arising out of or in connection with the use of or access of Platform.</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;No liability shall be borne by the Company for  any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation any financial losses, loss of data, replacement costs, or any similar damages, whether based in contract, tort, strict liability or otherwise arising from the Platform&apos;s use. These damages can be due to errors, content issues, or any interaction with the Application/Platform, even if the possibility of such issues has been brought to their attention.</p>                
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;Responsibility for any damages to their data system or for data loss resulting from content downloads from the Platform is solely borne by the Users. Any warranty is not constituted by guidance or information, whether written or oral, obtained from the Company or via the Platform, unless explicitly stated.</p>                
                <p class="h6 text-dark lh-base mb-3">4.&nbsp;The products on the Platform are listed by the Suppliers who are solely responsible for the accuracy of product information. The products are to be used in a safe and legal manner, for which users are solely responsible. Any loss, claims, damages, or injury that may arise from the violent or illegal use, or misuse of the product sold by the Suppliers over the Platform are not to be assumed by the Company.</p> 
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Disclaimer Regarding Advertisement</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;General information for the personal use of the Users is intended to be provided by the Advertisements in Platform. The Company doesn&apos;t represent, warrant, or endorse the accuracy or reliability of the Advertisements. The Company doesn&apos;t take any responsibility for the Advertisements, no matter how you use or rely on the Subject Information.</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;The Company doesn&apos;t create the Advertisements on the Platform. The advertisers display the content, and the Company doesn&apos;t control that content. The Company doesn&apos;t guarantee the content of the Advertisements or the products/services they advertise. Furthermore, the Company doesn&apos;t support or endorse the products/services advertised. The Company doesn&apos;t accept liability for errors, inaccuracies, or omissions made by third parties and advertisers.</p>                
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;Users correspond or have business dealings with advertisers they find on or through the Platform including payment, delivery of products or services, and any other associated terms, conditions, warranties, or representations are solely between the User and such advertiser. The Company doesn&apos;t take any responsibility for any losses or damages incurred from these dealings or from the advertisers&apos; presence on the Platform.</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;If a link appears in an Advertisement and you decide to click it, it will take you out of the Platform to a third-party website. Neither the Company nor its affiliates own, operate, or control this third-party website. The Company offers this link just to make things convenient for the User. the third-party website operates under its own policies, which the Company doesn&apos;t control. You alone decide to take the risk when you access the contents, products/services, and any links on this third-party website.</p>                
                <p class="h6 text-dark lh-base mb-3">5.&nbsp;The Company is not to be held responsible by the User and any liabilities, losses, damages, expenses, claims or injury arising out of or in connection with  (i) the advertisements displayed on Platform; (ii) contents of the Advertisement; (iii) representations and statements made by the advertiser; (iv) subject matter of the Advertisements and the products/services referred thereunder (including without limitation on account of suitability, merchantability, reliability, availability or quality of the product/service); and/or (v) Advertisement being misleading and/or not in compliance with applicable laws are disclaimed by the Company.</p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Disclaimer regarding advertisements of products listed on the platform and shown on other media &amp; forums (“Other Advertisements”):</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;The Other Advertisements show images of products for illustration and creative representation, but these might differ from the actual products.</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;Participating sellers/brands offer all the product-related deals/discounts mentioned in Other Advertisements, unless stated otherwise, Company does not involved.</p>
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;Other Advertisements might indicate starting prices for a product category as a reference, but the real product price may be vary.</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;Brand films and videos use scenes, characters, and references that are entirely fictional and serve only for representation.</p>                
                <p class="h6 text-dark lh-base mb-1">5.&nbsp;Depending on various factors related to the customer such as usage of platform, volume of transactions, time spent on the platform, city, place of Residence, time etc. the offers, discounts, and other promotional offers shown in Other Advertisements might change.</p>                
                <p class="h6 text-dark lh-base mb-1">6.&nbsp;The right to modify or terminate any of the offers/deals/discounts is reserved by G9Kart and/or the Suppliers without any prior notification to any User.</p>                
                <p class="h6 text-dark lh-base mb-3">7.&nbsp;Responsibility or liability for any inaccuracies in the data shown in Other Advertisements is not accepted by G9Kart. Regarding any data shown related to products sold on G9Kart, it is noted that this number might not be the latest and might represent data aggregated over a duration.</p>                
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Conditions under which user become liable for damage to company</h4>
                <p class="h6 text-dark lh-base mb-1">1&nbsp;The Company and its subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees shall be indemnified, defended, shall be indemnify and held harmless by the User from and against all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs, and expenses (including legal and statutory fees and disbursements related to them and interest chargeable on them) that are asserted against or incurred by the Company due to:</p>
                <p class="h6 text-dark lh-base mb-1 me-3">2.&nbsp;Breaching of this Agreement by the User.</p>
                <p class="h6 text-dark lh-base mb-1 me-3">3.&nbsp;Any claims asserted by any third party because of or resulting from the User&apos;s use of the Platform.</p>                
                <p class="h6 text-dark lh-base mb-1 me-3">4.&nbsp;The violation of any third party&apos;s rights by the User, including intellectual property rights; and/or</p>                
                <p class="h6 text-dark lh-base mb-3 me-3">5.&nbsp;Breaching of any applicable laws by the User.</p>           
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Communication</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;Contact information of Company in case of any queries regarding Agreement or Grievances relating to Platform. All queries, concerns or questions about the Agreement should be sent to Company at support@G9kart.com. </p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;Consumer Protection Compliance In accordance with the Consumer Protection (E-Commerce) Rules, 2020, please find the required details:
                    Legal name of the e-commerce entity: G9Kart Services Private Limited <strong><a href="http://www.g9kart.com/">http://www.g9kart.com/</a></strong>
                    Email Address: <a href="emailto:support@G9kart.com" >support@G9kart.com</a></p>
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;Notices or demands to or upon a User(s) are deemed effective if they are delivered personally, sent by courier, certified mail, facsimile, or email to the last-known correspondence, fax, or email address that the User(s) provided on the Platform, or if they are posted in a publicly accessible area of the Platform.</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;Notice is deemed to have been received by a User(s) when it is sent to the address, email, or other communication details provided by the User during registration, or immediately when it is published in a publicly accessible area of the Platform.</p>                
                <p class="h6 text-dark lh-base mb-1">5.&nbsp;Users are urged by the Company to be cautious of fake offers and potential fraudsters who might pretend to be representatives of the Company. The Users will never be contacted by the Company&apos;s authorized representatives to demand money for prizes or to request password/PIN/CVV. If Users are approached by anyone claiming to be the Company&apos;s representatives and asking for confidential details, they are advised to request communication through email and to respond only to emails from the g9kart.com domain.</p>           
                <p class="h6 text-dark lh-base mb-3">6.&nbsp;Calls to the Company are kept entirely confidential. Nonetheless, calls from Users might be recorded to maintain service quality. Additionally, for training objectives and to assure superior customer service, calls originating from the Company might be monitored and recorded.</p>           
            </div>

            <div class="col-sm-12">
                <h4 class="h5">Miscellaneous provisions applicable to the agreement</h4>
                <p class="h6 text-dark lh-base mb-1">1.&nbsp;The laws of India govern this Agreement. If there&apos;s any legal action, suit, or other proceeding, which is commenced to resolve any matter arising under or related to this Agreement or the Platform shall be subject to the Jurisdiction of the courts at Jaipur, India.</p>
                <p class="h6 text-dark lh-base mb-1">2.&nbsp;The Company can assign its responsibilities and duties under this Agreement to anyone.</p>
                <p class="h6 text-dark lh-base mb-1">3.&nbsp;If the Company doesn&apos;t act on or enforce a specific right or provision in this Agreement, it doesn&apos;t mean the Company has given up that right or provision.</p>                
                <p class="h6 text-dark lh-base mb-1">4.&nbsp;The Company controls and operates the Platform from India. The Company doesn&apos;t make any representation that the content here is suitable or available everywhere else. If user access this Platform from outside India, then user is at his own sole risk. User must follow local laws, and User agrees to discharge and absolve the Company from any liability or arising from this.</p>                
                <p class="h6 text-dark lh-base mb-3">5.&nbsp;The right to introduce new features, functionalities, and components to the Platform, change, alter, modify, suspend, discontinue or remove the existing ones, and to discontinue one or more of the Services or charge for previously free services is reserved by the Company, and any prior notice to the User might not be provided.</p>           
            </div>

            
        </div>
    </div>
</section>
<!-- about section end -->