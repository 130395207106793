import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SweetAlertService } from 'src/app/sweetalert.service';




@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent {
  // starRating = 0;
  // reviewForm!: FormGroup;

  // productId: number = 0;
  ratingAndReviews: any[] = [];
  sumOfStars: number = 0;
  numberOfReviews: number = 0;
  averageRating!: number;
  p: any = 1;
  count: any = 5;
  OrderID: any
  page: any
  OrderId: string | '' | undefined;
  ItemId: string | '' | undefined;



  constructor(
    private _cs: CommonService,
    private sweetAlert: SweetAlertService,
    private _api: ApiService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.route.params.subscribe((params) => {      
      // Extract slug parameter from route
      this.ItemId  = params['id'];
    });

    this.getRateAndReviewList();
  }

  
  getRateAndReviewList() {
    const query = {
      ItemId: this.ItemId

    };
    // console.log("query ", query);


    this._api.getApi(API_ENDPOINTS.RATE_AND_REVIEW_GET, query, this._cs.isLogin()).subscribe({
      next: (response: any) => {
        // console.log("getRateAndReviewList: ", response);

        if (response?.Code === 1000) {
          // Find the specific product by ID
          // const productId = this.ItemId;
          // this.ratingAndReviews = response.Data.filter((item: any) => item.ItemId ===  this.itemDetail.Id) || [];
          this.ratingAndReviews = response.Data.length === 0 ? [] : [...response.Data];
          // this.calculateAverageRating(ratingAndReviews.star) 
          
          for (let i = 0; i < this.ratingAndReviews.length; i++) {
            this.sumOfStars += this.ratingAndReviews[i].Stars;
            this.numberOfReviews = response.Data.length;
            this.averageRating = Number((this.sumOfStars / this.numberOfReviews).toFixed(1));

          }


         
        }
      },
      error: () => {
        this.ratingAndReviews = [];
      },
      complete: () => {
        // console.log('getRateAndReviewList completed!');

      },
    });
  }

  convertToPercent = (star: number) => {
    return Number(((star/5)*100).toFixed(0))
  }

  convertToPercentOverall = () => {
    return Number(((this.sumOfStars/this.numberOfReviews)*100).toFixed(2))
  }
}
