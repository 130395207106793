<!-- breadcrumb start -->
<app-breadcrumb-section screenName="wishlist" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!-- section start-->
<section class="wishlist-section section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 table-responsive-xs">
                <table class="table cart-table">
                    <thead>
                        <tr class="table-head">
                            <th scope="col">image</th>
                            <th scope="col">product name</th>
                            <th scope="col">price</th>
                            <th scope="col">availability</th>
                            <th scope="col">action </th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let item of utility?.wishList; let i = index">
                        <tr>
                            <td>
                                <a href="javascript:void(0)" [routerLink]="['/product', item.Slug, item.ItemRateSetupId]">
                                    <img [src]="item.ImagePath??'/assets/images/pro/1.jpg'" alt="{{'product'+i}}">
                                </a>
                            </td>
                            <td>
                                <a href="javascript:void(0)" [routerLink]="['/product', item.Slug, item.ItemRateSetupId]">
                                    {{ item.ItemName | titlecase }}
                                </a>
                                <div class="mobile-cart-content row">
                                    <div class="col">
                                        <p>in stock</p>
                                    </div>
                                    <div class="col vstack">
                                        <h2 class="td-color">{{ item?.SaleRate | currency:"INR"}}</h2>
                                        <h2 class="td-color"><del>{{ item?.MrpRate | currency:"INR"}}</del></h2>
                                    </div>
                                    <div class="col">
                                        <h2 class="td-color">
                                            <a href="javascript:void(0)" (click)="moveItems(item, false)" class="icon me-1"><i class="ti-close"></i></a>
                                            <a href="javascript:void(0)" (click)="moveItems(item, true)" class="cart"><i class="ti-shopping-cart"></i></a>
                                        </h2>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="vstack">
                                    <h2 class="td-color">{{ item?.SaleRate | currency:"INR"}}</h2>
                                    <h2 class="td-color"><del>{{ item?.MrpRate | currency:"INR"}}</del></h2>
                                </div>
                            </td>
                            <td>
                                <p>in stock</p>
                            </td>
                            <td>
                                <a href="javascript:void(0)" (click)="moveItems(item, false)" class="icon me-3"><i class="ti-close"></i></a>
                                <a href="javascript:void(0)" (click)="moveItems(item, true)" class="cart"><i class="ti-shopping-cart"></i></a>
                            </td>
                        </tr>
                    </tbody>
 
                    <tbody *ngIf="utility.wishList.length===0">
                        <tr class="text-center">
                            <td colspan="5">
                                <h3 class="font-weight-bold">Wishlist Ready for Your Next Shopping Spree</h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row wishlist-buttons">
            <div class="col-12"><a href="/" class="btn btn-solid">continue shopping</a>
                <a
                    href="/checkout" class="btn btn-solid">check out</a>
            </div>
        </div>
    </div>
</section>
<!-- section end-->