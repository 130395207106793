export const APP_CONSTANT = {
  ECOMMERCE_LOGIN_DETAIL: 'ECOMMERCE_LOGIN_DETAIL',
  PROFILE_INFO: 'PROFILE_INFO',
  MODEL_SHOW: 'show',
  MODEL_HIDE: 'hide',
  DEFAULT_SLIDER_FLOOR_VALUE: 0,
  DEFAULT_SLIDER_CEIL_VALUE: 10000,
  RANGE_GAP: 2000,
  CURRENT_TRANSACTION_QUERY: 'CURRENT_TRANSACTION_QUERY',
  SANNIRO_API_KEY: 'AIzaSyAm_DaQwayiWpTeboiDFICkFiGU9xlEGqk',
  PAYUMONEY_KEY: 'V3b7BMf5',
  PAYUMONEY_SALT: 'mxlXnvTzQS',
  PAYUMONEY_SERVICE_PROVIDER: 'payu_paisa',
  SIGNUP_DATA: 'SIGNUP_DATA',
  NO_IMAGE_PATH: "../../../assets/images/no-image.png",
  ADD_TO_CRAT_AND_WISHLIST_ITEM: 'addToCartAndWishListItem',
  STOCK_LAST_ONE : "Last One in Stock! Grab it before it's gone!",
  STOCK_LAST_TWO : "Hurry, Only 2 Left in Stock! Get Yours Now!",
  STOCK_LAST_FEW : "Limited Stock Available! Don't Miss Out, Order Now!",
};
