import { Component, EventEmitter, Output, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { APP_CONSTANT } from 'src/app/app.constant';
import { CommonService } from 'src/app/common.service';
import { SweetAlertService } from 'src/app/sweetalert.service';

interface CartAndWishListInterface {
  cartList: Array<any>;
  isCartOrWishListChanged: boolean;
  offers: Array<any>;
}


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  @Output() closeModal = new EventEmitter();
  @ViewChildren('quantityButtonRef') quantityButtonRef: any/*QueryList<IncDecButtonComponent>*/;
  utility: CartAndWishListInterface = { cartList: [], isCartOrWishListChanged: false, offers: [] };
  totalPrice: number = 0;

  constructor(
    public commonService: CommonService,
    private apiService: ApiService,
    private sweetAlert: SweetAlertService
  ) {
    let addToCartAndWishListItem: any = localStorage.getItem(APP_CONSTANT.ADD_TO_CRAT_AND_WISHLIST_ITEM);
    if (addToCartAndWishListItem) {
      addToCartAndWishListItem = JSON.parse(addToCartAndWishListItem);
      this.commonService.addToCartAndWishList(addToCartAndWishListItem.item, addToCartAndWishListItem.addType);
      localStorage.removeItem(APP_CONSTANT.ADD_TO_CRAT_AND_WISHLIST_ITEM)
    }
    this.getCartListItems();
  }

  getCartListItems = () => {
    const query = {
      CustomerLoginId: this.commonService.isLogin() ? this.commonService.getLoginUserId() : 0,
      IsWishingItem: false,
      IsFromMobile: 0
    };
    this.apiService.getApi(API_ENDPOINTS.CART_LIST, query, this.commonService.isLogin()).subscribe({
      next: (res: any) => {        
        if (res.Code === 1000 && res.Data && res.Data.AddToCartMainDetailsList.length) {
          this.utility['cartList'] = [...res.Data.AddToCartMainDetailsList];
          this.utility['offers'] = [...res.Data.Offers];
        } else {
          this.utility['cartList'] = [];
          this.utility['offers'] = [];
        }
        this.calculateTotalPrice(this.utility['cartList']);
        this.commonService.updateCartItemCount();
      }
    })
  };

  calculateTotalPrice = (cartItem: any) => {
    if (cartItem.Qty <= 0) {
      cartItem.Qty = 1;
    }
    this.totalPrice = this.utility.cartList.reduce((total, item) => {
      return total + (Number(item.SaleRate) * Number(item.Qty));
    }, 0);

    if (cartItem.Qty) {
      this.commonService.updateCartItemsQuantity(cartItem.Qty, Number(cartItem.Id));
    }
  };

  removeItems(item: any) {
    const query = {
      ItemID: !_.isEmpty(item) ? item.ItemId : 0,
      IsWishList: 0,
      IsMove: 0,
      CartId: !_.isEmpty(item) ? item.Id : 0
    };
    this.apiService.getApi(API_ENDPOINTS.EMPTY_CART_ITEMS, query, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000 && Number(res.Data) === 1000) {
          this.sweetAlert.showToast('success', 'Item removed from cart.');
          this.getCartListItems();
        } else if (res.Code === 1000 && Number(res.Data) === 5000) {
          this.sweetAlert.showToast('info', '"Item is already in your cart.');
        }
      });
  };

  reduceQtyByOne = (item: any) => {
    // if (item.Qty > 1)
    //   item.Qty--;
    // this.calculateTotalPrice(item);
    if (item.Qty > 1)
    {
      item.Qty--;
      this.calculateTotalPrice(item);
    }
  };

  incrementQtyByOne = (item: any) => {
    if (item.Qty < 3){
      item.Qty++;
      this.calculateTotalPrice(item);
      if(item.Qty === 3){
        item.Qty = 2;
        this.calculateTotalPrice(item);
      }
    }
  };
}
