<!-- breadcrumb start -->
<app-breadcrumb-section screenName="contact" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->



<!--section start-->
<section class="contact-page section-b-space">
    <div class="container">
        <div class="row section-b-space">
            <div class="col-lg-7 map">
                    <div class="mapouter">
                        <div class="gmap_canvas">
                        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" 
                            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Hari+Om+Tower,+Nr+UDB+Building,+Ajmer+Road,+Sodala+Chauraha,+Sodala,+Jaipur,+Rajasthan&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        <a href="https://connectionsgame.org/">Connections Unlimited</a>
                    </div>
                        <style>.mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}</style></div>

            </div>
            <div class="col-lg-5 ">
                
               
                    <div class="contact-right">
                        <ul>
                            <!-- <li>
                                <div class="contact-icon"><img src="../assets/images/icon/phone.png"
                                        alt="Generic placeholder image">
                                    <h6>Contact Us</h6>
                                </div>
                                <div class="media-body media-center">
                                    <p><a href="tel:+917014390932">+91 701 - 439 - 0932</a></p>
                                </div>
                            </li> -->
                            <li>
                                <div class="contact-icon"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    <h6>Address</h6>
                                </div>
                                <div class="media-body ">
                                    <p>2nd Floor, Hari Om Tower</p>
                                    <p>Nr UDB Building, Ajmer Road,</p>
                                    <p>Sodala Chauraha, Jaipur 302006, Bharat</p>
                                </div>
                            </li>
                            <li>
                                <div class="contact-icon"><img src="../assets/images/icon/email.png"
                                        alt="Generic placeholder image">
                                    <h6>Address</h6>
                                </div>
                                <div class="media-body ">
                                    <p><a href="mailto:info@g9kart.com">info@g9kart.com</a></p>
                                    <!-- <p><a href="mailto:nikhil.jain@saniiro.in">Nikhil.Jain@Saniiro.in -->
                                </div>
                            </li>
                            <li>
                                <div class="contact-icon"><i class="fa fa-support" style="color:orangered" aria-hidden="true"></i>
                                    <h6>Support</h6>
                                </div>
                                <div class="media-body ">
                                    <p><a href="mailto:support@g9kart.com">support@g9kart.com</a></p>
                                    <!-- <p>Info@g9kart.com</p> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <form class="theme-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="form-row row">
                        <div class="col-md-6">
                            <label for="name">Name</label>
                            <input type="text" class="form-control" id="name" placeholder="Enter Your name"
                                formControlName="Name" 
                                required [ngClass]="{
                                    'is-invalid': !contactForm.controls['Name'].valid
                                  }"/>
                                <!-- [ngClass]="{
                                    'is-invalid': !contactForm.controls?.userName?.invalid
                                  }"  -->
                              <!-- <i class="far fa-user"></i> -->
                        </div>
                        <!-- <div class="col-md-6">
                            <label for="email">Last Name</label>
                            <input type="text" class="form-control" id="last-name" placeholder="Email"
                                formControlName="LastName" required="">
                        </div> -->
                        <div class="col-md-6">
                            <label for="email">Email</label>
                            <input type="email" class="form-control" id="email" name="email" placeholder="Email"
                                formControlName="Email" required [ngClass]="{
                                    'is-invalid': !contactForm.controls['Email'].valid
                                }">
                        </div>
                        <div class="col-md-6">
                            <label for="review">Phone number</label>
                            <!-- <input type="number" class="form-control" id="review" placeholder="Enter your number"
                                formControlName="MobileNo" required/> -->
                                <!-- ="" [ngClass]="{
                                    'is-invalid': !contactForm.controls?.mobileNo?.invalid
                                }"  -->

                                <div class="row">
                                    <div class="col-3">
                                        <ng-container *ngIf="countryCodes.length">
                                            <input list="countryCodes" class="form-control" name="country-code" id="country-code" [value]="CountryCode" (change)="onSelectionCountryCode($event.target)" [disabled]="true">
                                            <datalist id="countryCodes">
                                                <option *ngFor="let code of countryCodes" [value]="'+'+code.Phonecode"></option>
                                            </datalist>
                                        </ng-container>
                                    </div>
                                    <div class="col-9">
                                        <input type="number" class="form-control" id="MobileNo" placeholder="Enter Mobile No" formControlName="MobileNo" pattern="^[0-9]*$" required
                                        [ngClass]="{
                                            'is-invalid': !contactForm.controls['MobileNo'].valid
                                        }">
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-12">
                            <label for="review">Write Your Message</label>
                            <textarea class="form-control" placeholder="Write Your Message"
                                formControlName="WriteYourMessage" id="exampleFormControlTextarea1" rows="6" [ngClass]="{
                                    'is-invalid': !contactForm.controls['WriteYourMessage'].valid
                                }"></textarea>
                                <!-- [ngClass]="{
                                    'is-invalid': !contactForm.controls?.WriteYourMessage?.invalid
                                }" -->
                        </div>
                        <div class="col-md-12">
                            <button class="btn btn-solid" type="submit"  [disabled]="contactForm.invalid">Send Your Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->