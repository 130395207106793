import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SeoService } from 'src/app/seo.service';
import { ToasterService } from 'src/app/toaster.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  contactForm: FormGroup;
  countryCodes: Array<any> = [];
  CountryCode: string = '+91';

  constructor(
    private commonService: CommonService,
    private toaster: ToasterService,
    private apiService: ApiService,
    private router: Router,
    private seoService: SeoService
  ) {

    seoService.updateMetaTags('Contact Us - Get in Touch with G9Kart', 
    'Connect with G9Kart for inquiries, support, and collaborations. Find our contact information and reach out to our dedicated team for assistance.',
    'Contact Us, Get in Touch, Customer Support, Inquiry Form, Collaborate with Us, Contact Information, Reach Out to Us, Assistance, Customer Care, Contact G9Kart');

    this.contactForm = new FormGroup({
      Name: new FormControl('', Validators.required),
      Email: new FormControl('', Validators.required),
      MobileNo: new FormControl('', [Validators.required,
      Validators.pattern("^[0-9]*$")]),
      WriteYourMessage: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.commonService.fetchCountryCodes().subscribe(
      (countryCodes: any) => {
        this.countryCodes = countryCodes.Data;
      }
    );
  }

  onSelectionCountryCode = (e: any) => {
    this.CountryCode = e.value;
  };

  onSubmit = (): void => {
    this.apiService.postApi(API_ENDPOINTS.GET_IN_TOUCH, this.contactForm.value, {}, this.commonService.isLogin()).subscribe(
      (res: any) => {
        if (res.Code === 1000) {
          this.toaster.showSuccess('', 'Request Sent Successfully');
          this.router.navigate(['/home']);
        } else {
          this.toaster.showError('', res.Message);
        }
      });
  };

}
