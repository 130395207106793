<div class="container-fluid sticky-top bg-white">
    <div class="row">
        <div class="col-sm-12">
            <div class="main-menu">

                <div class="menu-left">
                    <!-- <div class="navbar">
                        <a href="javascript:void(0)" onclick="openNav()">
                            <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                            </div>
                        </a>
                        <div id="mySidenav" class="sidenav">
                            <a href="javascript:void(0)" class="sidebar-overlay" onclick="closeNav()"></a>
                            <nav>
                                <div onclick="closeNav()">
                                    <div class="sidebar-back text-start"><i class="fa fa-angle-left pe-2"
                                            aria-hidden="true"></i> Back</div>
                                </div>
                                <ul id="sub-menu" class="sm pixelstrap sm-vertical">
                                    <li *ngFor="let category of allCategory">
                                        <a href="javascript:void(0)" routerLink="{{'/category/'}}"
                                            [queryParams]="{ 'categoryId':category.Id,'categoryParentId':category.ParentId,'categoryName': category.Name}"
                                            onclick="closeNav()">{{category?.Name}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div> -->
                    <div class="navbar-brand">
                        <a href="javascript:void(0)" routerLink="/">
                            <img src="../../assets/images/icon/logo.webp" class="img-fluid blur-up lazyload"
                                alt="logo">
                        </a>
                    </div>
                </div>

                <div class="menu-right pull-right align-items-center">
                    <div>
                        <div class="ng-autocomplete">
                            <ng-autocomplete class="form-control p-0 border-0 w-100" id="searchProduct" [data]="searchResult"
                                [searchKeyword]="keyword" placeholder="Search a Product or Category" 
                                (selected)='selectEvent($event)'
                                (inputChanged)='onChangeSearch($event)' 
                                [itemTemplate]="itemTemplate" 
                                [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                        
                            <ng-template #itemTemplate let-item>
                                <a [innerHTML]="[item.Name]"></a>
                            </ng-template>
                            
                            <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                            </ng-template>
                        </div>
                    </div>
                    <div>
                        <nav id="main-nav">
                            <div class="toggle-nav" onclick="mainMenuOpen()">
                                <i class="fa fa-bars sidebar-bar"></i>
                            </div>

                            <ul id="main-menu" class="sm pixelstrap sm-horizontal">
                                <li onclick="mainMenuClose()">
                                    <div class="mobile-back text-end">Back
                                        <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                                    </div>
                                </li>
                            
                                <li class="mega" id="hover-cls" (mouseenter)="mouseEnter('feature') "
                                    (mouseleave)="mouseLeave('feature')">
                                    <a href="javascript:void(0)" >Products </a>
                                    <ul class="mega-menu full-mega-menu" style="
                                    top: 72px;
                                    left: 0px;
                                    margin-left: -633.112px;
                                    margin-top: 0px;
                                    min-width: 10em;
                                    max-width: 20em;">
                                        <li>
                                            <div class="container pimages">
                                                <div class="row">
                                                    <div class="col mega-box" *ngFor="let menu of menuCategories;">
                                                        <div class="link-section">
                                                            <div class="menu-title">
                                                                <h5>{{menu.Name}}</h5>
                                                            </div>
                                                            <div class="menu-content">
                                                                <ul>
                                                                    <li *ngFor="let subCategory of menu.subCategory;">
                                                                        <a [routerLink]="['/category', subCategory.Slug, subCategory.Id]"
                                                                            (click)="mouseLeave('feature')">{{subCategory.Name}}</a>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </li>

                                <!-- <li class="mega" id="hover-cls" (mouseenter)="mouseEnter('feature') "
                                    (mouseleave)="mouseLeave('feature')">
                                    <a href="javascript:void(0)" >{{ isLogin ? (userName | titlecase ): 'My Account'}} </a>
                                    <ul class="mega-menu full-mega-menu" style="
                                    top: 72px;
                                    left: 0px;
                                    margin-left: -633.112px;
                                    margin-top: 0px;
                                    min-width: 10em;
                                    max-width: 20em;">
                                        <li>
                                            <div class="container pimages">
                                                <div class="row">
                                                    <div class="col mega-box">
                                                        <div class="link-section">
                                                            <div class="menu-title">
                                                                <h5>profile</h5>
                                                            </div>
                                                            <div class="menu-content">
                                                                <ul>
                                                                    <ng-container *ngIf="isLogin===false">
                                                                        <li [routerLink]="['/login']" routerLinkActive="router-link-active">
                                                                            <a href="javascript:void(0)" [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>
                                                                        </li>
                                                                        <li>
                                                                            <a class="w-100" href="javascript:void(0)" (click)="openSignInSignupModal('signUp')">register</a>
                                                                        </li>
                                                                    </ng-container>
                                                            
                                                                    <ng-container *ngIf="isLogin">
                                                                        <li>
                                                                            <a href="/dashboard">
                                                                                <i class="fa fa-lock dashboard-icon" aria-hidden="true"></i> Account
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a href="javascript:void(0)" (click)="logOut()">
                                                                                <i class="fa fa-sign-out" aria-hidden="true"></i>
                                                                                Logout
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </li> -->
                               
                            </ul>
                        </nav>
                    </div>

                    <div>
                        <div class="icon-nav">
                            <ul>                                
                                <li class="onhover-div mobile-setting">
                                    <div><img src="../assets/images/icon/users.png"
                                            class="img-fluid blur-up lazyload" alt=""> <i
                                            class="ti-user"></i></div>
                                    <div class="show-div setting">
                                        <h6>{{ isLogin ? (userName | titlecase ): 'My Account'}}</h6>
                                        <ul class="onhover-show-div">
                                            <ng-container *ngIf="isLogin===false">
                                                <li [routerLink]="['/login']" routerLinkActive="router-link-active">
                                                    <a href="javascript:void(0)" [routerLink]="['/login']" routerLinkActive="router-link-active">Login</a>
                                                </li>
                                                <li>
                                                    <a class="w-100" href="javascript:void(0)" (click)="openSignInSignupModal('signUp')">register</a>
                                                </li>
                                            </ng-container>
                                    
                                            <ng-container *ngIf="isLogin">
                                                <li>
                                                    <a href="/dashboard">
                                                        <i class="fa fa-lock dashboard-icon" aria-hidden="true"></i> Account
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="javascript:void(0)" (click)="logOut()">
                                                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                                                        Logout
                                                    </a>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </li>

                                <li class="onhover-div mobile-cart">
                                    <div><img src="../assets/images/icon/cart-1.png"
                                            class="img-fluid blur-up lazyloaded cart-img" alt="minicart">
                                        <i class="ti-shopping-cart"></i>
                                    </div>
                                    <span *ngIf="totalItemsInCart!==0" class="cart_qty_cls">{{totalItemsInCart}}
                                        <span class="visually-hidden"></span>
                                    </span>

                                    <ng-container *ngIf="isUserLoggedIn===true">
                                        <ul class="show-div shopping-cart">
                                            <ng-container *ngFor="let cart of miniCart">
                                                <li>
                                                    <div class="media">
                                                        <a href="javascript:void(0)" routerLink="{{'/product'}}"
                                                            [queryParams]="{ 'productId':cart.ItemRateSetupId}">
                                                            <img alt="" class="me-3"
                                                                [src]="[cart?.ImagePath ?? '../assets/images/fashion/product/1.jpg']">
                                                        </a>
                                                        <div class="media-body ps-1">
                                                            <a href="javascript:void(0)" routerLink="{{'/product'}}"
                                                                [queryParams]="{ 'productId':cart.ItemRateSetupId}">
                                                                <h4>{{ cart.ItemName | titlecase }}</h4>
                                                            </a>
                                                            <h4><span>{{cart.Qty}} x {{cart.SaleRate | currency:'INR'}}</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div class="close-circle" (click)="removeItems(cart)"><a
                                                            href="javascript:void(0)"><i class="fa fa-times"
                                                                aria-hidden="true"></i></a></div>
                                                </li>
                                            </ng-container>

                                            <ng-container *ngIf="cartItems.length > 2">
                                                <li  [className]="['d-flex py-0']"><span
                                                        class="ms-auto small">*see more..</span></li>
                                            </ng-container>

                                            <li>
                                                <div class="total">
                                                    <h5>subtotal : <span>{{cartItemSubTotal | currency:'INR'}}</span></h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="buttons"><a href="/cart" class="view-cart">view
                                                        cart</a> <a href="/checkout" class="checkout">checkout</a></div>
                                            </li>
                                        </ul>
                                    </ng-container>

                                    <ng-container *ngIf="isUserLoggedIn=== false">
                                        <ul class="show-div shopping-cart" >
                                            <li class="d-flex justify-content-center align-items-center">
                                                No Product
                                            </li>
                                            <li>
                                                <div class="buttons"><a href="/cart" class="view-cart">view
                                                        cart</a> <a href="/checkout" class="checkout">checkout</a></div>
                                            </li>
                                        </ul>
                                    </ng-container>
                                </li>
                            </ul>
                        </div>
                    </div>

                 
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container #signInSignUpContainerRef></ng-container>