import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { switchMap } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { ToasterService } from 'src/app/toaster.service';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  nextYear = new Date().getFullYear() + 1;
  Email: string = '';

  constructor(
    private apiService: ApiService,
    private toaster: ToasterService,
    private el: ElementRef, private renderer: Renderer2
  ) {}


  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.handleWindowResize();
  }

  ngAfterViewInit(): void {
    this.handleWindowResize();
  }

  handleWindowResize(): void {
    const contentWidth = window.innerWidth;

    if (contentWidth < 767) {
      this.addSpan('.footer-title h4');
      this.addClickListener('.footer-title');
      this.hideElement('.footer-contant');
    } else {
      this.showElement('.footer-contant');
    }

    if (contentWidth < 1183) {
      this.addSpan('.menu-title h5');
      this.addClickListener('.menu-title');
      this.hideElement('.menu-content');
    } else {
      this.showElement('.menu-content');
    }
  }

  private addSpan(selector: string): void {
    const elements = this.el.nativeElement.querySelectorAll(selector);
    elements.forEach((element:any) => {
      element.innerHTML += '<span class="according-menu"></span>';
    });
  }

  private addClickListener(selector: string): void {
    const elements = this.el.nativeElement.querySelectorAll(selector);
    elements.forEach((element:any) => {
      this.renderer.listen(element, 'click', () => {
        elements.forEach((el:any) => {
          this.renderer.removeClass(el, 'active');
        });
        const nextElement = element.nextElementSibling;
        if (nextElement && nextElement.style.display === 'none') {
          this.renderer.addClass(element, 'active');
          this.renderer.setStyle(nextElement, 'display', 'block');
        } else {
          this.renderer.setStyle(nextElement, 'display', 'none');
        }
      });
    });
  }

  private hideElement(selector: string): void {
    const elements = this.el.nativeElement.querySelectorAll(selector);
    elements.forEach((element:any) => {
      this.renderer.setStyle(element, 'display', 'none');
    });
  }

  private showElement(selector: string): void {
    const elements = this.el.nativeElement.querySelectorAll(selector);
    elements.forEach((element:any) => {
      this.renderer.setStyle(element, 'display', 'block');
    });
  }


  subscribeEmail = () => {
    this.apiService
      .getApi(API_ENDPOINTS.NEWS_LETTER_SUBSCRIBER, { Email: this.Email })
      .subscribe((res: any) => {
        if (res.Code === 1000 && res.Data) {
          this.toaster.showSuccess('', res.Description);
        }
      });
  };
}
