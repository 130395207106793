import { STORAGE_KEY } from './browser.storage.constant';
import { ApiService } from './api.service';
import { ToasterService } from './toaster.service';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from './api.endpoints';
import { map, tap, share, switchMap } from 'rxjs/operators';
import { APP_CONSTANT } from './app.constant';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

declare var bolt: any;
declare const $: any;

@Injectable({
  providedIn: 'root',
})

/**
 * @class CommonService
 * @discription All Common services thet required throught-out project
 */
export class CommonService {
  menuClickEmitter = new Subject();
  commonUtility: EcommerceInteface = {};
  loggedInSuccess = new Subject<boolean>();
  loggedOutSuccess = new Subject<boolean>();
  profileUpdated = new Subject<boolean>();
  catgoryFetched$ = new Subject<Array<any>>();
  public countryCodes$ = new Subject<Array<any>>();
  private cartCount$: BehaviorSubject<string> = new BehaviorSubject('0');
  vendorAgentTokenFetched = new Subject<boolean>();
  cartOrWishListChanged = new Subject<boolean>();
  customerProfileDetails: any = {};
  slideConfig = {
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  /**
   * Product slider default configurations
   */
  productSliderConfig = {
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };
  allCategoriesWithSubCategory: any = [];
  allIndividualCatogories: any;
  countryCodes: Array<any> = [];
  // public countryCodesState = this.countryCodes.asObservable();
  mobileCountryCodes: Array<any> = [];

  /**
   * @constructor
   *
   * @Params ApiService
   * @Params ToasterService
   * @Params Router
   *
   */
  constructor(
    private apiService: ApiService,
    private toaster: ToasterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.initParmas();
    if (this.isLogin() === false) {
      this.setVendorAgentAuthToken();
    }
    this.fetchCountryCodes();
    this.getMobileCountryCodeList().then((mobileCountryCodes: any) => {
      this.mobileCountryCodes = mobileCountryCodes;
    });
  }

  fetchCountryCodes = () => {
    return this.apiService.getApi(API_ENDPOINTS.GET_COUNTRY_CODES, {}, false);
  };

  async initParmas() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const avoidCallOverRoutes: Array<string> = ['/about', 'contact', ''];
      if (this.isLogin() && avoidCallOverRoutes.includes(evt.url) === false) {
        this.updateCartItemCount();
      }
      window.scrollTo(0, 0);
    });

    if (!this.router.url.includes('sign-up')) {
      this.setProfileInfo();
    }

    this.loggedOutSuccess.subscribe((res) => {
      if (res) {
        this.customerProfileDetails = {};
        this.updateCartItemCount();
        this.router.navigate(['/home']);
      }
    });
  }

  isLogin() {
    return !!localStorage.getItem(STORAGE_KEY.CUSTOMER_ID);
  }

  getLoginUserId() {
    const userDetails = JSON.parse(
      localStorage.getItem(APP_CONSTANT.ECOMMERCE_LOGIN_DETAIL)!
    );
    if (userDetails && Object.keys(userDetails).length) {
      return userDetails.CustomerLoginId;
    } else {
      return 0;
    }
  }

  setProfileInfo() {
    const userDetails = JSON.parse(
      localStorage.getItem(APP_CONSTANT.ECOMMERCE_LOGIN_DETAIL)!
    );
    if (
      userDetails &&
      Object.keys(userDetails).length &&
      userDetails.ContactNo
    ) {
      const query = {
        LoginID: userDetails.ContactNo,
      };
      return this.apiService
        .getApi(API_ENDPOINTS.PROFILE_GET, query, this.isLogin())
        .pipe(
          tap((res: any) => {
            if (res.Code === 1000 && res.Data && res.Data.length) {
              this.customerProfileDetails = res.Data[0];
              localStorage.setItem(
                APP_CONSTANT.PROFILE_INFO,
                JSON.stringify(this.customerProfileDetails)
              );
              this.profileUpdated.next(true);
            }
          })
        )
        .toPromise();
    } else {
      this.customerProfileDetails = {};
      return Promise.resolve({});
    }
  }

  getProfileInfo() {
    const data = JSON.parse(localStorage.getItem(APP_CONSTANT.PROFILE_INFO)!);
    if (data && Object.keys(data).length) {
      return data;
    } else {
      return {};
    }
  }

  getLoginUserName() {
    let data: any = localStorage.getItem(APP_CONSTANT.PROFILE_INFO);
    if (data && Object.keys(data).length) {
      data = JSON.parse(data);
      return data.CustomerName;
    } else {
      return '';
    }
  }

  getLoginInfo() {
    const data = JSON.parse(localStorage.getItem(APP_CONSTANT.PROFILE_INFO)!);
    if (data && Object.keys(data).length) {
      return data;
    } else {
      return '';
    }
  }

  // openModal(id: string) {
  //   $(`#${id}`).modal({ backdrop: 'static', keyboard: false });
  //   $(`#${id}`).modal(APP_CONSTANT.MODEL_SHOW);
  // }

  openModal(id: string) {
    $(`#${id}`).modal({ backdrop: 'static', keyboard: false });
    $(`#${id}`).modal(APP_CONSTANT.MODEL_SHOW);
  }

  loadModalDynamically(
    that: any,
    containerRef: any,
    componentRef: any,
    componentToLoad: any,
    dataToPass: any,
    next: any
  ): void {
    that[containerRef].clear();
    that[componentRef] = that[containerRef].createComponent(componentToLoad);
    that[componentRef].instance.openModal(dataToPass);
    that[componentRef].instance.closeModal.subscribe((data: any) => {
      that[componentRef].destroy();
      next(data);
    });
  }

  closeModal(id: string) {
    $(`#${id}`).modal(APP_CONSTANT.MODEL_HIDE);
  }

  /* Function to allow numeric input only for input type text  ----b */
  isNumber(evt: KeyboardEvent | any): boolean {
    evt = evt ? evt : window.event;
    const charCode: number = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // TODO: working on new routing
  navigateToCategorySlug(category: { Slug: string }) {
    this.router.navigate(['category' + category.Slug], { relativeTo: this.activatedRoute, replaceUrl: true });
  }

  // TODO: Will deprecate soon
  navigateToCategory(category: {
    Id: string | number;
    ParentId: number;
    Name: string;
  }) {
    this.router.navigate(['category'], {
      queryParams: {
        categoryName: category.Name,
        categoryId: category.Id,
        categoryParentId: category.ParentId,
      },
    });
  }

  getAllCategories() {
    this.apiService
      .getApi(API_ENDPOINTS.ITEM_CATEGORY, { ParentId: 0 }, true)
      .subscribe(async (res: any) => {
        if (res.Code === 5018) {
          return;
        }

        if (res.Code === 1000) {
          this.allIndividualCatogories = res.Data.CategoryDetailsList
            ? JSON.parse(JSON.stringify(res.Data.CategoryDetailsList))
            : '';
          let parentCategories = this.allIndividualCatogories.filter(
            (cat: any) => {
              return cat.ParentId === 0; //&& cat.ImagesList && cat.ImagesList.length > 0;
            }
          );
          await parentCategories.forEach((parentCategory: any) => {
            parentCategory['subCategory'] = this.allIndividualCatogories.filter(
              (cat: any) => cat.ParentId === parentCategory.Id
            );
          });
          this.allCategoriesWithSubCategory = JSON.parse(
            JSON.stringify(parentCategories)
          );
          this.catgoryFetched$.next(this.allCategoriesWithSubCategory);
        }
      });
  }


  getRelatedProductList(queryParam: any) {
    return this.apiService
      .getApi(API_ENDPOINTS.HOME_ITEMS, queryParam, this.isLogin())
      .subscribe({
        next: (res: any) => {
          if (res.Code === 5018) {
            return;
          }

          if (res.Code === 1000 && res.Data && res.Data.Items) {
            res.Data.Items.forEach((item: any) => {
              item.FilePath =
                item.ImageList && item.ImageList.length
                  ? item.ImageList[0].FilePath
                  : '';
            });            
            return res.Data.Items;
          }
        },
        error: (error: any) => {
          console.log('common.service.ts:302', error);
        },
      });
  }

  /**
   * Retrieves a list of products based on the provided query parameters.
   * @param queryParam - The query parameters for the API request.
   * @returns A promise that resolves to the list of products.
   */
  // getProductList(queryParam: any): Promise<any> {
  //   return this.apiService
  //     .getApi(API_ENDPOINTS.HOME_ITEMS, queryParam, this.isLogin())
  //     .pipe(
  //       switchMap(async (res: any) => {
  //         if (res.Code === 5018) {
  //           return;
  //         }
  //         if (
  //           res.Code === 1000 &&
  //           res.Data &&
  //           res.Data?.AllItemsCategoryWiseList &&
  //           res.Data?.ItemRateList
  //         ) {
  //           await this.mapItemRate(res);
  //           return res;
  //         } else {
  //           // Updating Img path
  //           res.Data.Items.forEach((item: any) => {
  //             item.FilePath =
  //               item.ImageList && item.ImageList.length
  //                 ? item.ImageList[0].FilePath
  //                 : '';
  //           });
  //           return Promise.resolve(res);
  //         }
  //       })
  //     )
  //     .toPromise();
  // }
  
  getProductList(queryParam: any): Promise<any> {
    return this.apiService.getApi(API_ENDPOINTS.HOME_ITEMS, queryParam, this.isLogin())
      .pipe(
        switchMap(async (res: any) => {
          if (res.Code === 5018) {
            return;
          }
          if (res.Code === 1000 && res.Data && res.Data?.AllItemsCategoryWiseList && res.Data?.ItemRateList) {
            await this.mapItemRate(res);
            return res;
          } else {

            // Updating Img path
            res.Data.Items.forEach((item: any) => {
              item.FilePath = item.ImageList && item.ImageList.length ? item.ImageList[0].FilePath : '';
            });
            return Promise.resolve(res);
          }
        })
      )
      .toPromise();
  }

  mapItemRate(res: any) {
    return new Promise(async (resolve, reject) => {
      await res.Data.AllItemsCategoryWiseList.forEach(async (item: any) => {
        item.rateList = await res.Data.ItemRateList.filter((x: any) => x.ItemId === item.ItemId);
        if (item.rateList && item.rateList.length) {
          await item.rateList.forEach((rateItem: any) => {
            if (rateItem.IsSameRange) {
              rateItem.showLabel = `1 ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
            } else {
              rateItem.showLabel = `${rateItem.RangeFrom}-${rateItem.RangeUpto} ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
            }
          });

          const index = item.rateList.findIndex((x: any) => x.IsAddedToCart === 1);

          if (index !== -1) {
            item.selectedRate = { ...item.rateList[index] };
            item.selectedRateItemId = Number(
              item.rateList[index].ItemRateSetupId
            );
            item.quantity = Number(item.rateList[index].Qty);
          } else {
            item.selectedRate = item.rateList[0];
            item.selectedRateItemId = item.rateList[0].ItemRateSetupId;
            if (item.rateList[0].IsSameRange) {
              item.quantity = 1;
            } else {
              item.quantity = item.rateList[0].RangeFrom;
            }
          }
        }
      });
      resolve(res);
    });
  }


  // mapItemRate(res: any) {
  //   const itemRateList = res.Data.ItemRateList;

  //   for (const item of res.Data.AllItemsCategoryWiseList) {
  //     item.rateList = itemRateList.filter((rateItem:any) => rateItem.ItemId === item.ItemId);

  //     if (item.rateList.length > 0) {
  //       for (const rateItem of item.rateList) {
  //         if (rateItem.IsSameRange) {
  //           rateItem.showLabel = `1 ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
  //         } else {
  //           rateItem.showLabel = `${rateItem.RangeFrom}-${rateItem.RangeUpto} ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
  //         }
  //       }

  //       const index = item.rateList.findIndex((rateItem:any) => rateItem.IsAddedToCart === 1);
  //       if (index !== -1) {
  //         const selectedRate = item.rateList[index];
  //         item.selectedRate = { ...selectedRate };
  //         item.selectedRateItemId = Number(selectedRate.ItemRateSetupId);
  //         item.quantity = Number(selectedRate.Qty);
  //       } else {
  //         const selectedRate = item.rateList[0];
  //         item.selectedRate = selectedRate;
  //         item.selectedRateItemId = selectedRate.ItemRateSetupId;
  //         item.quantity = selectedRate.IsSameRange ? 1 : selectedRate.RangeFrom;
  //       }
  //     }
  //   }
  // }

  // mapItemRate(res: any) {
  //   return new Promise(async (resolve, reject) => {
  //     await res.Data.AllItemsCategoryWiseList.forEach(async (item: any) => {
  //       item.rateList = await res.Data.ItemRateList.filter(
  //         (x: any) => x.ItemId === item.ItemId
  //       );
  //       if (item.rateList && item.rateList.length) {
  //         await item.rateList.forEach((rateItem: any) => {
  //           if (rateItem.IsSameRange) {
  //             rateItem.showLabel = `1 ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
  //           } else {
  //             rateItem.showLabel = `${rateItem.RangeFrom}-${rateItem.RangeUpto} ${rateItem.UnitName} - Rs. ${rateItem.SaleRate}/${rateItem.UnitName}`;
  //           }
  //         });

  //         const index = item.rateList.findIndex(
  //           (x: any) => x.IsAddedToCart === 1
  //         );

  //         if (index !== -1) {
  //           item.selectedRate = { ...item.rateList[index] };
  //           item.selectedRateItemId = Number(
  //             item.rateList[index].ItemRateSetupId
  //           );
  //           item.quantity = Number(item.rateList[index].Qty);
  //         } else {
  //           item.selectedRate = item.rateList[0];
  //           item.selectedRateItemId = item.rateList[0].ItemRateSetupId;
  //           if (item.rateList[0].IsSameRange) {
  //             item.quantity = 1;
  //           } else {
  //             item.quantity = item.rateList[0].RangeFrom;
  //           }
  //         }
  //       }
  //     });
  //     resolve(res);
  //   });
  // }

  getItemDetails(queryParam: any) {
    return this.apiService.getApi(API_ENDPOINTS.ITEM_DETAILS,queryParam, this.isLogin());
  }

  getCustomerAccountDetails() {
    return this.apiService.getApi(API_ENDPOINTS.CUSTOMER_GET);
  }

  generateOtp(queryParam: string) {
    return this.apiService.getApi(API_ENDPOINTS.GENERATE_OTP, queryParam);
  }

  verifyOtp(data: any) {
    return this.apiService.postApi(API_ENDPOINTS.VERIFY_OTP, data);
  }

  signIn(data: any) {
    return this.apiService.postApi(API_ENDPOINTS.CUSTOMER_LOGIN, data);
  }

  createPassword(data: any) {
    return this.apiService.postApi(API_ENDPOINTS.CUSTOMER_CREATE_PASSWORD, data);
  }

  signUp(data: any) {
    return this.apiService.postApi(API_ENDPOINTS.CUSTOMER_ADD, data);
  }

  logOut() {
    localStorage.clear();
    this.setVendorAgentAuthToken();
    this.loggedOutSuccess.next(true);
  }

  localStorage(type: string, key?: string, value?: any) {
    if (type === STORAGE_KEY.STORAGE_METHOD_GET) {
      return JSON.parse(localStorage.getItem(key!)!);
    } else if (type === STORAGE_KEY.STORAGE_METHOD_SET) {
      localStorage.setItem(key!, JSON.stringify(value));
    } else if (type === STORAGE_KEY.STORAGE_METHOD_REMOVE) {
      localStorage.removeItem(key!);
    } else if (type === STORAGE_KEY.STORAGE_METHOD_CLEAR) {
      localStorage.clear();
    }
  }

  updateCartItemCount = (): void => {
    if (this.isLogin()) {
      this.apiService
        .getApi(API_ENDPOINTS.CART_ITEM_QTY, {}, this.isLogin())
        .subscribe((res: { Code: number; Data: any }) => {
          if (res.Code === 1000 && res.Data) {
            this.commonUtility.cartItemCount = res.Data;
          } else {
            this.commonUtility.cartItemCount = '';
          }
          this.cartCount$.next(this.commonUtility.cartItemCount);
        });
    }
  };

  getCarItemCount$ = (): Observable<string> => {
    return this.cartCount$.asObservable();
  };

  updateCartItemsQty(queryParam: any) {
    return this.apiService.getApi(API_ENDPOINTS.UPDATE_CART_QTY, queryParam);
  }

  prepareCartAndWishListPayload(
    item: { ItemId: any; Qty: any; selectedRateItemId: any },
    addType?: any
  ) {
    return {
      IsFromMobile: 0,
      IsWishingItem: addType ? 1 : 0,
      CartDetails: [
        {
          ItemId: item.ItemId,
          Qty: item.Qty,
          ItemRateSetupId: item.selectedRateItemId
            ? item.selectedRateItemId
            : 0,
        },
      ],
    };
  }

  loadDataOnLogin(that: any, componentToLoad: any, callback: () => void) {
    this.toaster.showWarning('', 'Please Login First');
    this.loadModalDynamically(
      that,
      'signInSignUpContainerRef',
      'signInSignUpModalRef',
      componentToLoad,
      '',
      (data: string) => {
        if (data === 'LOGIN_SUCCESS') {
          callback();
        }
      }
    );
  }

  updateCartAddedItemsQty(
    e: any,
    item: { ItemRangeFrom: any; Qty: number; ItemRangeTo: any; Id: any }
  ) {
    if (Number(e) < Number(item.ItemRangeFrom)) {
      this.toaster.showWarning(
        '',
        `Min Quantity Allowed is ${item.ItemRangeFrom}`
      );
      item.Qty = Number(item.ItemRangeFrom);
      return;
    } else if (Number(e) > Number(item.ItemRangeTo)) {
      this.toaster.showWarning(
        '',
        `Max Quantity Allowed is ${item.ItemRangeTo}`
      );
      item.Qty = Number(item.ItemRangeFrom);
      return;
    }
    this.updateCartItemsQuantity(e, Number(item.Id));
  }

  updateCartItemsQuantity(e: any, itemId: any) {
    const query = {
      Id: itemId,
      Qty: Number(e),
    };
    this.updateCartItemsQty(query).subscribe((res: { Code: number }) => {
      if (res.Code === 5020) {
        this.toaster.showWarning(
          '',
          'Cart limit reached for this item (max 2 quantity).'
        );
      }
    });
  }

  openCartList(that: any, componentToLoad: any) {
    this.loadModalDynamically(
      that,
      'cartListModalContainerRef',
      'cartListModalRef',
      componentToLoad,
      '',
      (data: boolean) => {
        if (data) {
          this.cartOrWishListChanged.next(data);
          this.updateCartItemCount();
        }
      }
    );
  }

  // /* Funtion to get all the mobile country code list  */
  // getMobileCountryCodeList = (countryId?: any) => {
  //   const query = {
  //     Id: countryId ? countryId : 0,
  //     Strvalue: '',
  //     CodeFor: 'shopping',
  //   };
  //   return this.apiService
  //     .getApi(API_ENDPOINTS.MOBILE_COUNTRY_CODE, query, this.isLogin(), [
  //       'Id',
  //       'Phonecode',
  //       'Length',
  //       'Iso',
  //     ])
  //     .pipe(
  //       map((Data: any) => {
  //         return Data.map((element: any) => {
  //           return {
  //             Id: element.Id,
  //             Phonecode: `+${element.Phonecode}`,
  //             Length: element.Length,
  //             StringToMatchWithCountry: element.Iso,
  //           };
  //         });
  //       })
  //     )
  //     .toPromise();
  // };

  getMobileCountryCodeList = (countryId?: any) => {
    const query = {
      Id: countryId ? countryId : 0,
      Strvalue: '',
      CodeFor: 'shopping',
    };

    return this.apiService
      .getApi(API_ENDPOINTS.MOBILE_COUNTRY_CODE, query, this.isLogin(), [
        'Id',
        'Phonecode',
        'Length',
        'Iso',
      ])
      .toPromise()
      .then((data: any) => {
        // Check if 'data' is an array before using map

        if (Array.isArray(data)) {
          return data.map((element: any) => ({
            Id: element.Id,
            Phonecode: `+${element.Phonecode}`,
            Length: element.Length,
            StringToMatchWithCountry: element.Iso,
          }));
        } else {
          // Handle the case where 'data' is not an array
          console.error('Invalid data format:', data);
          // this.toaster.showWarning( '', `${data.Message}` );
          return [];
        }
      })
      .catch((error: any) => {
        // Handle the error
        console.error('Error fetching mobile country codes:', error);
        return [];
      });
  };

  getAddressTypeList() {
    return [
      {
        Id: 6,
        Text: 'Home',
      },
      {
        Id: 5,
        Text: 'Delivery',
      },
      {
        Id: 4,
        Text: 'Other',
      },
    ];
  }

  getPaytmCredentials() {
    return [
      {
        key: 'MID',
        value: 'FBZBDS12535883745021',
      },
      {
        key: 'CHANNEL_ID',
        value: 'WEB',
      },
      {
        key: 'WEBSITE',
        value: 'WEBSTAGING',
      },
      // {
      //   key: 'CALLBACK_URL',
      //   value: window.location.href
      // },
      {
        key: 'INDUSTRY_TYPE_ID',
        value: 'Retail',
      },
    ];
  }

  isVendorAgentTokenExist() {
    return !!localStorage.getItem(STORAGE_KEY.USER_TOKEN);
  }

  async setVendorAgentAuthToken() {
    let data = location.hostname;
    if (data && data.includes('www')) {
      const a = data.split('.');
      a.splice(0, 1);
      data = a.join('.');
    }
    const queryParam = {
      WorkDomain: data === 'localhost' ? 'ecommerce.saniiro.com' : data,
    };
    const res = await this.apiService
      .getApi(API_ENDPOINTS.VENDOR_AGENT, queryParam)
      .toPromise();
    if (res.Data && res.Data.Token) {
      localStorage.setItem(STORAGE_KEY.USER_TOKEN, res.Data.Token);
      this.vendorAgentTokenFetched.next(true);
    }
    return res;
  }

  getVendorAgentTokenFetched$ = (): Observable<any> => {
    return this.vendorAgentTokenFetched.asObservable();
  };

  getShippingStatus() {
    return [
      {
        id: 0,
        name: 'Ordered',
      },
      {
        id: 1,
        name: 'Packed',
      },
      {
        id: 2,
        name: 'Shipped',
      },
      {
        id: 3,
        name: 'Delivered',
      },
    ];
  }

  checkOutPayumoney(Data: {
    ORDER_ID: any;
    CHECKSUMHASH: any;
    TXN_AMOUNT: any;
    FirstName: any;
    Email: any;
    Mobile: any;
    ProductInfo: any;
  }) {
    const userEmail = this.customerProfileDetails.Email
      ? this.customerProfileDetails.Email
      : '';
    const RequestData = {
      key: APP_CONSTANT.PAYUMONEY_KEY,
      txnid: Data.ORDER_ID,
      hash: Data.CHECKSUMHASH,
      amount: Data.TXN_AMOUNT,
      firstname: Data.FirstName,
      email: Data.Email,
      phone: this.customerProfileDetails.LoginId
        ? this.customerProfileDetails.LoginId
        : Data.Mobile,
      productinfo: Data.ProductInfo,
      surl: 'http://rexaplanet.com/paytm-callback',
      furl: 'http://rexaplanet.com/paytm-callback',
      service_provider: APP_CONSTANT.PAYUMONEY_SERVICE_PROVIDER,
    };
    bolt.launch(RequestData, {
      responseHandler: (BOLT: {
        response: { txnStatus: string; txnMessage: string };
        message: string;
      }) => {
        if (
          BOLT &&
          Object.keys(BOLT).length &&
          BOLT.response &&
          Object.keys(BOLT.response).length
        ) {
          if (BOLT.response.txnStatus === 'CANCEL') {
            this.toaster.showError('', BOLT.response.txnMessage);
          } else if (BOLT.response.txnStatus === 'SUCCESS') {
            this.payumoneySuccessFailureHandler(BOLT, Data);
          } else {
            this.toaster.showError('', BOLT.response.txnMessage);
          }
        } else {
          this.toaster.showError('', BOLT.message);
        }
      },
      catchException: (BOLT: {
        response: { txnStatus: string; txnMessage: string };
        message: string;
      }) => {
        // console.log(JSON.stringify(BOLT));
        if (
          BOLT &&
          Object.keys(BOLT).length &&
          BOLT.response &&
          Object.keys(BOLT.response).length
        ) {
          if (BOLT.response.txnStatus === 'FAILED') {
            this.payumoneySuccessFailureHandler(BOLT, Data);
          } else {
            this.toaster.showError('', BOLT.response.txnMessage);
          }
        } else {
          this.toaster.showError('', BOLT.message);
        }
      },
    });
  }
  // payumoneySuccessFailureHandler(BOLT: { response: { txnStatus: string; txnMessage: string; }; message: string; }, Data: { ORDER_ID: any; CHECKSUMHASH: any; TXN_AMOUNT: any; FirstName: any; Email: any; Mobile: any; ProductInfo: any; }) {
  //   throw new Error('Method not implemented.');
  // }

  payumoneySuccessFailureHandler(
    BOLT: { response: any; message?: string },
    Data: {
      ORDER_ID?: any;
      CHECKSUMHASH?: any;
      TXN_AMOUNT?: any;
      FirstName?: any;
      Email?: any;
      Mobile?: any;
      ProductInfo?: any;
      Api?: any;
      Type?: any;
    }
  ) {
    const res = { ...BOLT.response };
    const paytmResponse = {
      RESPMSG: res.txnMessage,
      PAYMENTMODE: res.mode,
      RESPCODE: res.txnStatus === 'SUCCESS' ? '01' : '05',
      TXNID: res.txnid,
      TXNAMOUNT: res.amount,
      STATUS: res.status,
      TXNDATE: res.addedon,
      CHECKSUMHASH: res.hash,
      ORDERID: res.txnid,
      CustomerName: res.firstname,
      Email: res.email,
    };
    this.apiService.getApi(Data.Api, paytmResponse).subscribe((data: any) => {
      if (data.Code === 1000) {
        if (Data.Type === 'PURCHASE') {
          this.toaster.showSuccess('', 'Order Placed Successfully');
          this.router.navigate(['account/orderlist']);
        } else if (Data.Type === 'SIGNUP') {
          this.toaster.showSuccess('', 'Sign up Successfully');
          this.router.navigate(['']);
        } else if (Data.Type === 'TOPUP') {
          this.toaster.showSuccess('', 'Top up Successfully');
          this.router.navigate(['account/wallet']);
        } else if (Data.Type === 'UPGRADE') {
          this.toaster.showSuccess('', 'Upgrade Successfully');
          this.router.navigate(['account/wallet']);
        }
      } else {
        this.toaster.showError('', res.Message);
      }
    });
  }

  addToCartAndWishList = (item: any, addType?: any) => {
    if (this.isLogin() === false) {
      localStorage.setItem(
        APP_CONSTANT.ADD_TO_CRAT_AND_WISHLIST_ITEM,
        JSON.stringify({
          addType: addType,
          item: {
            ItemId: item.ItemId,
            Qty: item.Qty,
            selectedRateItemId: item.selectedRateItemId,
          },
        })
      );
      this.router.navigate(['/login'], {
        queryParams: { nextRoute: addType ? 'wishlist' : 'cart' },
      });
      return;
    } else {
      if (item.Qty === 0) {
        this.toaster.showInfo('', 'Minimum 1 Quantity is required.');
        return;
      } else if (addType && item.IsAddedToWishList) {
        this.toaster.showInfo('', 'Items already in Wihslist.');
        return;
      } else if (!addType && item.IsAddedToCart) {
        this.router.navigateByUrl('/cart');
        // this.toaster.showInfo('', 'Items already in Cart.');
        return;
      }

      this.apiService
        .postApi(
          API_ENDPOINTS.CART_LIST,
          this.prepareCartAndWishListPayload(item, addType),
          {},
          this.isLogin()
        )
        .subscribe((res: any) => {
          if (res.Code === 1000 && res.Data) {
            if (addType) {
              item.IsAddedToWishList = 1;
              this.toaster.showSuccess(
                '',
                'Items added to Whishlist Successfully'
              );
            } else {
              item.IsAddedToCart = 1;
              this.updateCartItemCount();
              this.toaster.showSuccess('', 'Items added to Cart Successfully');
            }
            item.CartId = res.Data;
          }
        });
    }
  };

  fetchCartItems = (): Observable<any> => {
    const query = {
      CustomerLoginId: this.isLogin() ? this.getLoginUserId() : 0,
      IsWishingItem: false,
      IsFromMobile: 0,
    };
    return this.apiService.getApi(
      API_ENDPOINTS.CART_LIST,
      query,
      this.isLogin()
    );
  };

  validateItemIsInCart = (item: any, cartItems: Array<any>) => {
    let cartItemIds = _.map(cartItems, (item) => item?.ItemRateSetupId);
    item.IsAddedToCart = cartItemIds.includes(item.ItemId) ? 1 : 0;
  };

  getSelectedMobileCoutryCode = (countryId: string): string => {
    const filteredCountryCode = _.filter(
      this.mobileCountryCodes,
      (mobileCode) => mobileCode.Id === Number(countryId)
    );
    return filteredCountryCode.length ? filteredCountryCode[0].Phonecode : '';
  };

  openNewTab(props: any): string {
    switch (props.TypeName) {
      case 'Registration':
        return'';
      case 'Item':
      case 'Product':
      case 'product':
        return '_blank';
      case 'Category':
      case 'category':
        return '';
      default:
        return '';
    }
  }

  // **RUNNING : Used to handle link generation on home page and other pages
  createLinkHandler = (props: any): string => {
    switch (props?.TypeName) {
      case 'Registration':
        return `/register`;
      case 'Item':
      case 'Product':
      case 'product':
        return this.sanitizeLink('product', props.Slug, props.TypeValue)
      case 'Category':
      case 'category':
        let url = this.sanitizeLink('category', props.Slug, props.TypeValue)
        // if (props.Name !== undefined && props.Name !== null) {
        //   url += `/${props.Name}`;
        // }
        return url;
      default:
        return '';
    }
  };

  sanitizeLink = (endpoint: string, slug:string, id:string, parentId?:string): string => {
    if(endpoint === 'category'){
      return `/${endpoint}/${slug}/${id}`;
    }
    if(endpoint === 'product'){
      return `/${endpoint}/${slug}/${id}`;
    }
    return '';
  };

  createShareLink = (product:any, platform:string, text?:string):string|undefined => {
    const endpoint = this.createLinkHandler(product);
    const url = environment.BASE_URL_FOR_SOCIAL
    text = text === undefined || text === '' ? '' : text
    if(platform==='facebook'){
      return `https://www.facebook.com/sharer/sharer.php?u=${url}${endpoint}`;
    }

    if(platform === 'twitter'){
      return `https://twitter.com/intent/tweet?url=${url}${endpoint}&text=${text}`;
    }

    if(platform === 'instagram'){
      return `https://www.instagram.com/g9kart/sharer.php?url=${url}${endpoint}&title=&summary=${text}&source=`;
    }

    if(platform === 'linkedin'){
      return `https://www.linkedin.com/shareArticle?mini=true&url=${url}${endpoint}&title=&summary=${text}&source=`;
    }

    if(platform === 'whatsapp'){
      return `https://api.whatsapp.com/send?text=${url}${endpoint}`;
    }
    if(platform === 'telegram'){
      return `https://t.me/share/url?url=${url}${endpoint}&text=${text}`;
    }
    if(platform === 'email'){
      return `mailto:?&subject=&body=${url}${endpoint}`;
    }

    return;
  }
}


export class EcommerceInteface {
  cartItemCount?: any;
}


