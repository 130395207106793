import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { STORAGE_KEY } from './browser.storage.constant';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // private apiUrl = 'http://api.saniiro.in';
  apiUrl = environment.BASE_URL;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://app.saniiro.in',
  });

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  // fetchData(): void { 
  //   this.http.get(this.apiUrl).subscribe(
  //     (response) => { 
  //       console.log('API Response:', response); 
  //     }, 
  //     (error) => { 
  //       console.error('API Error:', error); 
  //     } 
  //   ); 
  // }

  fetchData(): void {
    this.http.get(this.apiUrl).subscribe({
        next: response => console.log('Observable emitted the API response: ' + response),
        error: err => console.error('Observable emitted an error: ' + err),
        complete: () => console.log('Observable emitted the complete notification')
      });
  }

  checkAndGetUserToken(token: string | null | undefined): string {
    if (token === null || token === undefined) {
      return '';
    }
    return token;
  }

  checkAndGetCUSTOMER_ID(CUSTOMER_ID: string | null | undefined): string {
    if (CUSTOMER_ID === null || CUSTOMER_ID === undefined) {
      return '0';
    }
    return CUSTOMER_ID;
  }

  setHeaders(isTokenRequired: boolean) {
    if (isTokenRequired) {
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Userid': this.checkAndGetCUSTOMER_ID(localStorage.getItem(STORAGE_KEY.CUSTOMER_ID)),
        'X-San-Jwt': this.checkAndGetUserToken(
          localStorage.getItem(STORAGE_KEY.USER_TOKEN)
        ),
      });
    } else {
      this.headers = new HttpHeaders();
    }
  }

  cookie(type: string, key?: string, value?: string): void | string {
    if (!isPlatformBrowser(this.platformId)) {
      // Handle the case when not running in the browser environment (e.g., server-side rendering)
      return;
    }

    switch (type) {
      case STORAGE_KEY.STORAGE_METHOD_GET:
        return this.cookieService.get(key || '');
      case STORAGE_KEY.STORAGE_METHOD_SET:
        const expired = new Date();
        expired.setTime(expired.getTime() + STORAGE_KEY.SESSION_TIME_OUT);
        this.cookieService.set(key || '', value || '', expired);
        break;
      case STORAGE_KEY.STORAGE_METHOD_REMOVE:
        this.cookieService.delete(key || '');
        break;
      case STORAGE_KEY.STORAGE_METHOD_CLEAR:
        this.cookieService.deleteAll();
        break;
      default:
        // Handle the case when an invalid type is provided
        console.error(`Logger: ~ file: api.service.ts:80 ~ ApiService ~ cookie ~ Invalid storage type::`, type);
        break;
    }
  }

  setLoginAuthToken(token: string) {
    localStorage.setItem(STORAGE_KEY.USER_TOKEN, token);
  }

  removeToken(key: string) {
    this.cookieService.delete(key);
  }

  filterResponse(res: any, keyArray: any): any {
    if (!res.Data) {
      return res;
    } else if (!!res.Data && !keyArray) {
      return res;
    } else {
      return res.Data.map((item: any) => {
        let obj: any = {}
        keyArray.forEach((key: string) => {
          obj[key] = item[key]
        })
        return obj;
      });
    }
  }

  getQueryStringFromObject(obj: any) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  postApi(endPoint?: any, data?: any, queryParam?: any, isTokenRequired?: any) {
    let url;
    if (queryParam && Object.keys(queryParam).length) {
      const queryParamString = this.getQueryStringFromObject(queryParam);
      url = `${endPoint}?${queryParamString}`;
    } else {
      url = endPoint;
    }
    this.setHeaders(true);
    data = data ? data : {};
    return this.http.post(`${this.apiUrl}/${url}`, data, { headers: this.headers });
  }

  getApi(
    endPoint?: any,
    queryParam?: any,
    isTokenRequired?: any,
    keyArray?: any
  ): any {
    let url: string;
    if (queryParam && Object.keys(queryParam).length) {
      const queryParamString = this.getQueryStringFromObject(queryParam);
      url = `${endPoint}?${queryParamString}`;
    } else {
      url = endPoint;
    }
    this.setHeaders(true);
    return this.http
      .get(`${this.apiUrl}/${url}`, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return this.filterResponse(res, keyArray);
        })
      );
  }

  deleteApi(endPoint?: any, queryParam?: any, isTokenRequired?: any) {
    let url;
    if (queryParam && Object.keys(queryParam).length) {
      const queryParamString = this.getQueryStringFromObject(queryParam);
      url = `${endPoint}?${queryParamString}`;
    } else {
      url = endPoint;
    }
    this.setHeaders(true);
    return this.http.delete(`${this.apiUrl}/${url}`, { headers: this.headers });
  }

  putApi(endPoint?: any, data?: any, queryParam?: any, isTokenRequired?: any) {
    let url;
    if (queryParam && Object.keys(queryParam).length) {
      const queryParamString = this.getQueryStringFromObject(queryParam);
      url = `${endPoint}?${queryParamString}`;
    } else {
      url = endPoint;
    }
    this.setHeaders(true);
    data = data ? data : {};
    return this.http.put(`${this.apiUrl}/${url}`, data, { headers: this.headers });
  }
}
