import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppStartComponent } from './app-start.component';
import { ContactComponent } from '../account/contact/contact.component';
import { AboutComponent } from '../pages/about/about.component';
import { CancellationPolicyComponent } from '../pages/cancellation-policy/cancellation-policy.component';
import { CategoryInfiniteScrollComponent } from '../pages/category-infinite-scroll/category-infinite-scroll.component';
import { GuidelinesComponent } from '../pages/guidelines/guidelines.component';
import { PrivacyPolicyComponent } from '../pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../pages/terms-conditions/terms-conditions.component';
import { UserAgreementComponent } from '../pages/user-agreement/user-agreement.component';
import { CartComponent } from '../account/cart/cart.component';
import { CheckoutComponent } from '../account/checkout/checkout.component';
import { WishlistComponent } from '../account/wishlist/wishlist.component';
import { LoginComponent } from '../account/login/login.component';
import { RegisterComponent } from '../account/register/register.component';
import { OrderSuccessComponent } from '../account/order-success/order-success.component';
import { ShippingPolicyComponent } from '../pages/shipping-policy/shipping-policy.component';
import { OrderTrackingComponent } from '../account/order-tracking/order-tracking.component';
import { ProductDetailPageComponent } from '../pages/product-detail-page/product-detail-page.component';
import { GetOffersComponent } from '../pages/get-offers/get-offers.component';
import { CallbackComponent } from '../account/callback/callback.component';
import { AuthGuard } from '../auth.guard';
import { ReviewComponent } from '../pages/review/review.component';

const routes: Routes = [
  {
    path: '',
    component: AppStartComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../components/home/home.module').then(mod => mod.HomeModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../account/dashboard/dashboard.module').then(mod => mod.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'account',
        loadChildren: () => import('../account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard]
      },
      { path: 'about', component: AboutComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'category/:slug/:id', component: CategoryInfiniteScrollComponent },
      { path: 'product/:slug/:id', component: ProductDetailPageComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent },
      { path: 'user-agreement', component: UserAgreementComponent },
      { path: 'cancellation-policy', component: CancellationPolicyComponent },
      { path: 'shipping-policy', component: ShippingPolicyComponent },
      { path: 'guidelines', component: GuidelinesComponent },
      { path: 'review/:id', component: ReviewComponent },
      {
        path: 'cart', component: CartComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'wishlist', component: WishlistComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'checkout', component: CheckoutComponent,
        canActivate: [AuthGuard]
      },
      // { path: '', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'best-offers', component: GetOffersComponent },
      // { path: 'collection', component: CollectionComponent },
      // { path: 'search', component: SearchComponent },
      // { path: 'review', component: ReviewComponent },
      // { path: 'wishlist', component: WishlistComponent },
      // { path: 'dashboard', component: DashboardComponent },
      // { path: 'forget_pwd', component: ForgetPwdComponent },
      // { path: 'profile', component: ProfileComponent },
      { path: 'order/:slug/:id', component: OrderSuccessComponent },
      { path: 'order-tracking/:id/:order/:awb', component: OrderTrackingComponent },
      // { path: 'become-vendor', component: BecomeVendorComponent },
      // { path: 'vendor-dashboard', component: VendorDashboardComponent },
      // { path: 'vendor-profile', component: VendorProfileComponent },
      // { path: 'grid2', component: Grid2Component },
      // { path: 'grid3', component: Grid3Component },
      // { path: 'grid4', component: Grid4Component },
      // { path: 'masonary2-grid', component: Masonary2GridComponent },
      // { path: 'masonary3-grid', component: Masonary3GridComponent },
      // { path: 'masonary4-grid', component: Masonary4GridComponent },
      // { path: 'masonary-fullwidth', component: MasonaryFullwidthComponent },
      // { path: 'compare2', component: Compare2Component },
      // { path: 'compare', component: CompareComponent },
      // { path: 'lookbook', component: LookbookComponent },
      // { path: 'sitemap', component: SitemapComponent },
      // { path: 'page404', component: Page404Component },
      // { path: 'coming-soon', component: ComingSoonComponent },
      // { path: 'faq', component: FaqComponent },
      { path: 'phonepe', component: CallbackComponent },
      { path: '**', redirectTo: '' }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppStartRoutingModule { }
