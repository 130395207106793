import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { SeoService } from 'src/app/seo.service';
import { SweetAlertService } from 'src/app/sweetalert.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent {
  addRateAndReviewRef: any;
  orders: Array<any> = [];
  searchText: any;
  selectedStatusTypeId: any;
  slug: string = '';
  orderId: string = '';
  isCancelled:boolean = false;
  RefundPolicyStatus:0|1|2|3 = 0;

  constructor(
    private apiService: ApiService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private sweetAlertService: SweetAlertService,
    private seoService : SeoService,
  ) {
    this.route.params.subscribe((params) => {      
      // Extract slug parameter from route
      this.slug  = params['slug'];
      this.orderId  = params['id'];
      this.getOrderedList();
    });
  }
  
  /**
 * @description Retrieves the list of ordered items from the API and processes the data.
 * @returns {void}
 */
  getOrderedList(): void {
    /** Construct the query object */
    const query = {
      SearchText: this.searchText || '',
      DeliveryStatus: this.selectedStatusTypeId || 0,
      OrderID: this.orderId
    };

    /** Call the API to fetch the ordered list */
    this.apiService
      .getApi(API_ENDPOINTS.ALL_ORDERED_LIST, query, this.commonService.isLogin())
      .subscribe(
        (res: any) => {
           /** Check if the response code indicates success and data is available */ 
          if (res.Code === 1000 && res?.Data?.BuyNowMainOrderList?.length) {
            /** Extract the orders data from the response */
            this.orders = res.Data.BuyNowMainOrderList;

            /** Initialize maps to store order item details and other charges */
            const orderItemDetailsMap = new Map();
            const orderOtherChargesMap = new Map();

            /** Process the order item details */
            res.Data.BuyNowOrderItemDetailsList.forEach((item: any) => {
              const orderId = item.OrderID;

              if (!orderItemDetailsMap.has(orderId)) {
                orderItemDetailsMap.set(orderId, []);
              }

              item.Offers = JSON.parse(item.Offers);
              orderItemDetailsMap.get(orderId).push(item);
            });

            /** Process the other charges associated with each order item */
            res.Data.BuyNowOrderOtherChargesList.forEach((charge: any) => {
              const orderId = charge.OrderID;
              const itemId = charge.ItemID;
              const key = `${orderId}-${itemId}`;

              if (!orderOtherChargesMap.has(key)) {
                orderOtherChargesMap.set(key, []);
              }

              orderOtherChargesMap.get(key).push(charge);
            });

            /** Assign the item details and charges to each order */
            this.orders.forEach((order) => {
              const orderId = order.OrderID;
              order.orderItems = orderItemDetailsMap.get(orderId) || [];

              order.orderItems.forEach((item: any) => {
                const itemId = item.ItemID;
                const key = `${orderId}-${itemId}`;
                item.itemCharges = orderOtherChargesMap.get(key) || [];
              });
            });
            
          } else {
            /** No data available or request failed, reset orders array */
            this.orders = [];
          }
        }
      );
  }



  /**
   * @param {enum} approvalStatus 
   * @param {enum} deliveryStatus
   * @returns {boolean} true OR false
   * @description if cancellation policy becomes a `true` (3) then, check button appearance according to following status
   *  */ 
  handleCancellationButton(approvalStatus: ApprovalStatus, deliveryStatus: DeliveryStatus): boolean {
    
    if(approvalStatus === ApprovalStatus.Pending){ return true; }
    if (approvalStatus === ApprovalStatus.Approved){
      /** 
       * in case of approved! then check follow if status
       * @example if(deliveryStatus deepEqualsTo Ordered || deliveryStatus deepEqualsTo Packed || deliveryStatus deepEqualsTo Delivered)
       *  */ 
      if(deliveryStatus === DeliveryStatus.Ordered  || 
          deliveryStatus === DeliveryStatus.Packed || 
          deliveryStatus === DeliveryStatus.Delivered) { return true; }
      
      /** hide if, Delivery Status set to Shipped (not applicable) */
      return false;
    }

    /** hide button, if rejected or cancelled  (not applicable) */
    if(approvalStatus === ApprovalStatus.Rejected) { return false; }
    if(approvalStatus === ApprovalStatus.Cancelled) { return false; }
    return false;
  }

  /**
   * @param {enum} approvalStatus 
   * @param {enum} deliveryStatus
   * @returns {boolean} true OR false
   * @description if return policy becomes a `true` (2) then, check button appearance according to following status
   *  */ 
  handleReturnButton(approvalStatus: ApprovalStatus, deliveryStatus: DeliveryStatus ): boolean {
    
    if (approvalStatus === ApprovalStatus.Approved){
      // if order delivered, then button visible
      if( deliveryStatus === DeliveryStatus.Shipped){ return true; }
      // hide if status is, ordered, packed and delivered
      return false;
    }

    // hide if, pending rejected or cancelled
    if(approvalStatus === ApprovalStatus.Pending){ return false; }
    if(approvalStatus === ApprovalStatus.Rejected) { return false; }
    if(approvalStatus === ApprovalStatus.Cancelled) { return false; }
    return false;
  }

   /**
   * @param {enum} approvalStatus 
   * @param {enum} deliveryStatus
   * @returns {boolean} true OR false
   * @description if replace policy becomes a `true` (1) then, check button appearance according to following status
   *  */ 
  handleReplaceButton(approvalStatus: ApprovalStatus, deliveryStatus: DeliveryStatus ): boolean {
    
    if (approvalStatus === ApprovalStatus.Approved){ 
      // if order delivered, then button visible
      if( deliveryStatus === DeliveryStatus.Shipped){ return true; }
      return false;
    }

    if(approvalStatus === ApprovalStatus.Pending){ return false; }
    if(approvalStatus === ApprovalStatus.Rejected) { return false; }
    if(approvalStatus === ApprovalStatus.Cancelled) { return false; }
    return false;
  }

  /**
   * @param {string} paymentMode - pay mode cash or online
   * @param {number} id - product id
   * @param {number} orderId - order id number
   * @param {string} awb - an airway bil number
   * @returns {void} - doesn't return anything
   * @description Cancels an order by making an API call to the server.
   * Prompts the user for confirmation before canceling the order.
   * Displays success or error messages based on the API response.
   * Updates the "isCancelled" variable to reflect the cancellation status.
   */
  initOrderCancel(paymentMode:string, id:number, orderId:number, awb:string): void{
    if(paymentMode === "COD"){
      this.handleOrderCancel(id, orderId, awb)
    } else {
      this.handleOrderCancel(id, orderId, awb)
      //this.initiateRefund();
    }
  }

  /**
   * @description usualy call this function when init initOrderCancel function by end-user.
   * @param {number} id - product id
   * @param {number} orderId - order id number
   * @param {string} awb - an airway bil number
   * @returns {void} - doesn't return anything
   */
  handleOrderCancel(id:number, orderId:number, awb:string): void {
    
    const query = {
      ItemId: _.defaultTo(id, 0),
      OrderId: _.defaultTo(orderId, 0),
      Awbs: _.defaultTo(awb, ''),
    };

    this.sweetAlertService
      .showConfirm(
        'info', 'Cancel Order?',
        'Are you sure you want to cancel this order? This action cannot be undone.',
        'Yes, cancel it!', 'No, keep product!'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.apiService
            .postApi(API_ENDPOINTS.ORDER_CANCELLATION, query, this.commonService.isLogin())
            .subscribe({
              next: (response: any) => {
                // console.log('ORDER_CANCELLATION: RES :: ', response);
                if (response.Status === 1000) {

                  if(response.Data !== 'Order cancelled successfully..'){
                    this.sweetAlertService.showAlert('warning','Warning!', response.Data );
                  }
                  else {
                    $('#cancel-order').addClass('text-muted').css('pointer-events','none').prop('disabled', true);
                    this.sweetAlertService.showAlert(
                      'success',
                      'Cancelled!',
                      'Your selected order has been cancelled.'
                    )
                    .then(()=> {
                      window.location.reload();
                    });
                  }
                }
              },
              error: (err:any) => {
                if (err?.status && err?.statusText) {
                  this.sweetAlertService.showAlert('error', 'Error!', err?.statusText);
                } else {
                  console.error('ORDER_CANCELLATION: ERR:: ', err);
                }
              },
            });
        } else {
          this.sweetAlertService.showAlert(
            'success',
            'Great choice!',
            'Your product is confirmed to be kept.'
          );
        }
      });
  }

  initiateRefund() {
    // Initiate refund process
    alert('refund initiated. will refund in 4-5 day!')
  }

  /**
 * @description Initializes the return and replace options for an order.
 * @param {OrderType} orderType - The type of order (return or replace).
 * @param {string} paymentMode - The payment mode for the order.
 * @param {number} id - The ID of the item.
 * @param {number} orderId - The ID of the order.
 * @param {string} awb - The AWB number of the order.
 * @returns {void}
 */
  initOrderReturnAndReplace(orderType:OrderType, paymentMode: string, id: number, orderId: number, awb: string): void {
    const query:OrderQuery = {
      ReturnType: _.defaultTo(orderType, 1),
      ItemId: _.defaultTo(id, 0),
      OrderId: _.defaultTo(orderId, 0),
      Awb: _.defaultTo(awb, ''),
    }

    if(orderType === OrderType.Return){ this.handleOrderReturnAndReplace(query); }
    if(orderType === OrderType.Replace){ this.handleOrderReturnAndReplace(query); }
  }

  /**
   * @description Handles the return and replace options for an order.
   * @param {OrderQuery} query - The query parameters for the order.
   * @returns {void}
   */
  handleOrderReturnAndReplace(query:OrderQuery): void {
    const title: string = _.isEqual(query.ReturnType, 1) ? 'Return Order?' : 'Return and Replace Order?';
    const message: string = _.isEqual(query.ReturnType, 1) ? 'Would you like to return your order?' : 'Would you like to return and replace your order?';
    const successMessage: string = _.isEqual(query.ReturnType, 1) ? 'Return Request Sent!' : 'Return and Replace Request Sent!';

    this.sweetAlertService.showConfirm('info', title, message, 'Yes, proceed!', 'No, keep product!')
    .then((result) => {
      if (result.isConfirmed) {
        this.apiService.postApi(API_ENDPOINTS.ORDER_RETURN_REPLACE, query, this.commonService.isLogin())
          .subscribe({
            next: (response: any) => {
              if (_.get(response, 'Status') === 1000) {
                this.sweetAlertService.showAlert('info', successMessage, _.get(response, 'Data'));
                this.isCancelled = true;
              }
            },
            error: (err: any) => {
              if (_.get(err, 'status') && _.get(err, 'statusText')) {
                this.sweetAlertService.showAlert('error', 'Sorry, there was an unexpected error.', _.get(err, 'statusText'));
              } else {
                console.error('ORDER_CANCELLATION: ERR:: ', err);
              }
            },
          });
      } else {
        this.sweetAlertService.showAlert(
          'success',
          'Order Kept',
          'Great choice! Your order will be processed for delivery as planned.'
        );
      }
    });

  }
 
  /**
 * @description Handles the cancellation logic based on approval status, delivery status, and payment mode.
 * @param {ApprovalStatus} approvalStatus - The approval status of the order.
 * @param {DeliveryStatus} deliveryStatus - The delivery status of the order.
 * @param {PaymentMode} paymentMode - The payment mode of the order.
 * @returns {void}
 */
  handleCancellation(approvalStatus: ApprovalStatus, deliveryStatus: DeliveryStatus, paymentMode: PaymentMode): void {
    if (approvalStatus === ApprovalStatus.Pending || approvalStatus === ApprovalStatus.Rejected || approvalStatus === ApprovalStatus.Cancelled) {
      this.cancelOrder(paymentMode);
    } else if (approvalStatus === ApprovalStatus.Approved) {
      if (deliveryStatus === DeliveryStatus.Ordered || deliveryStatus === DeliveryStatus.Packed) {
        this.cancelOrder(paymentMode);
      } else if (deliveryStatus === DeliveryStatus.Shipped) {
        if (paymentMode === PaymentMode.Prepaid) {
          this.initiateRefund();
        }
        // Handle cancellation based on e-commerce portal
      } else if (deliveryStatus === DeliveryStatus.Delivered) {
        this.handleDeliveredOrder(paymentMode);
      }
    }
  }

  /**
   * @param paymentMode 
   * @returns {void}
   * @description handle function to perform specific actions
   */
  cancelOrder(paymentMode: PaymentMode): void {
    if (paymentMode === PaymentMode.Cash) {
      // Cancel the order
    } else if (paymentMode === PaymentMode.Prepaid) {
      this.initiateRefund();
    }
  }

  
  /**
   * @description Handles the delivered order based on the payment mode.
   * @param {PaymentMode} paymentMode - The payment mode of the order.
   * @returns {void}
   */
  handleDeliveredOrder(paymentMode: PaymentMode): void {
    if (paymentMode === PaymentMode.Cash) {
      // Specify returnable/replaceable options for COD
    } else if (paymentMode === PaymentMode.Prepaid) {
      // Specify returnable/replaceable options for prepaid
    }
  }

}

/**
 * @description Enum representing the approval status of an order.
 * @enum {string}
 */
enum ApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Cancelled = "Cancelled"
}

/**
 * @description Enum representing the delivery status of an order.
 * @enum {string}
 */
enum DeliveryStatus {
  Ordered = "Ordered",
  Packed = "Packed",
  Shipped = "Shipped",
  Delivered = "Delivered"
}

/**
 * @description Enum representing the payment mode of an order.
 * @enum {string}
 */
enum PaymentMode {
  Cash = "COD",
  Prepaid = "Prepaid",
}

/**
 * @description Enum representing the refund policy status.
 * @enum {number}
 */
enum RefundPolicy {
  NoPolicy = 0,
  Replaceable = 1,
  NoReturnable = 2,
  NoCancellation = 3
}

/**
 * @description Enum representing the type of order (return or replace).
 * @enum {number}
 */
enum OrderType {
  Return = 1,
  Replace = 2
}

/**
 * @description Interface representing the query parameters for an order.
 * @interface
 */
interface OrderQuery {
  ReturnType: OrderType;
  ItemId: number;
  OrderId: number;
  Awb: string;
}
