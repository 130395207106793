<!-- breadcrumb start -->
<app-breadcrumb-section screenName="create account" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!--section start-->
<section class="register-page section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3>create account</h3>
        <div class="theme-card">
          <form class="theme-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="form-row row">
              <!-- Customer Name -->
              <div class="col-md-6">
                <label for="firstName">Customer Name *</label>
                <input type="text" class="form-control" id="CustomerName" placeholder="Customer Name"
                  formControlName="CustomerName" [ngClass]="{
                    'is-invalid': (f.CustomerName.touched && f.CustomerName.errors)
                  }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.CustomerName.touched && f.CustomerName.errors && f.CustomerName.errors.required">
                  Please provide your name
                </small>
              </div>
              <!-- Last Name -->
              <!-- <div class="col-md-6">
                <label for="LastName">Last Name</label>
                <input type="text" class="form-control" id="LastName" placeholder="Last Name"
                  formControlName="LastName" [ngClass]="{
                    'is-invalid': (f.LastName.touched && f.LastName.errors)
                  }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.LastName.touched && f.LastName.errors && f.LastName.errors.required">
                  Please provide your last name
                </small>
              </div> -->
              
              <!-- Email -->
              <div class="col-md-6">
                <label for="email">email *</label>
                <input type="mail" class="form-control" id="email" placeholder="Email" formControlName="Email"
                  [ngClass]="{
                  'is-invalid': (f.Email.touched && f.Email.errors)
                }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.Email.touched && f.Email.errors && f.Email.errors.required">
                  Enter your email address
                </small>
              </div>

              <!-- Mobile Number -->
              <div class="col-md-6">
                <label for="LoginId">Mobile No *</label>
                <div class="row">
                  <div class="col-3">
                    <ng-container *ngIf="countryCodes">
                      <input list="countryCodes" class="form-control" name="country-code" id="country-code"
                        [value]="CountryCode" (change)="onSelectionCountryCode($event.target)"
                        [readonly]="countryCodeReadOnly" [disabled]="true">
                      <datalist id="countryCodes">
                        <option *ngFor="let code of countryCodes" [value]="'+'+code.Phonecode"></option>
                      </datalist>
                    </ng-container>
                  </div>
                  <div class="col-9">
                    <input type="number" class="form-control" id="LoginId" formControlName="LoginId"
                      placeholder="Enter your number" readonly [ngClass]="{
                  'is-invalid': (f.LoginId.touched && f.LoginId.errors)
                  }">
                    <small class="text-danger font-weight-bold"
                      *ngIf="f.LoginId.touched && f.LoginId.errors && f.LoginId.errors.required">
                      Enter your mobile number
                    </small>
                  </div>
                </div>
              </div>

              <!-- Password -->
              <div class="col-md-6">
                <label for="password">Password *</label>
                <input type="password" class="form-control" id="password" placeholder="Enter your password"
                  formControlName="Password" [ngClass]="{
                  'is-invalid': (f.Password.touched && f.Password.errors)
                }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.Password.touched && f.Password.errors && f.Password.errors.required">
                  Password is required.
                </small>
              </div>

              

               <!-- Country -->
               <div class="col-md-4 select_input">
                <label for="review">Country *</label>
                <select class="form-control" size="1" formControlName="CountryId" (change)="getStateList(countryId)"
                  [ngClass]="{'is-invalid': (f.CountryId.touched && f.CountryId.errors)}" [disabled]=true>
                  <option *ngFor="let country of countryList" [value]="country.Id" [disabled]="true">{{country.CommonDesc}}
                  </option>
                </select>
                <small class="text-danger font-weight-bold"
                  *ngIf="f.CountryId.touched && f.CountryId.errors && f.CountryId.errors.required">
                  Country is required.
                </small>
              </div>

              <!-- Region/State -->
              <div class="col-md-4 select_input">
                <label for="review">Region/State *</label>
                <select class="form-control" size="1" formControlName="StateId" (change)="getCityList(stateId)"
                  [ngClass]="{
                  'is-invalid': (f.StateId.touched && f.StateId.errors)
                }">
                  <option *ngFor="let state of stateList" [value]="state.Id">{{state.CommonDesc1}}
                  </option>
                </select>
                <small class="text-danger font-weight-bold"
                  *ngIf="f.StateId.touched && f.StateId.errors && f.StateId.errors.required">
                  Region/State is required.
                </small>
              </div>

               <!-- City -->
               <div class="col-md-4 select_input">
                <label for="review">City *</label>
                <select class="form-control" size="1" formControlName="CityId" id="City" placeholder="City"
                  (change)="getAreaList(cityId)" [ngClass]="{
                    'is-invalid': (f.CityId.touched && f.CityId.errors)
                  }">
                  <option *ngFor="let city of cityList" [value]="city.Id">{{city.CommonDesc2}}</option>
                </select>
                <small class="text-danger font-weight-bold"
                  *ngIf="f.CityId.touched && f.CityId.errors && f.CityId.errors.required">
                  City is required.
                </small>
              </div>
              
             

              <!-- Address -->
              <div class="col-md-6">
                <label for="name">Address *</label>
                <input type="text" class="form-control" id="address-two" placeholder="Address" formControlName="Address"
                  [ngClass]="{
                  'is-invalid': (f.Address.touched && f.Address.errors)
                }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.Address.touched && f.Address.errors && f.Address.errors.required">
                  Address is required.
                </small>
              </div>

              <!-- Area (Deprecated) -->
              <!-- <div class="col-md-6 select_input">
                <label for="review">Area</label>
                <select class="form-control" size="1" formControlName="AreaId" id="areaName" placeholder="Area"
                  (change)="onAreaChange()" [ngClass]="{
                    'is-invalid': (f.AreaId.touched && f.AreaId.errors)
                  }">
                  <option *ngFor="let area of areaList" [value]="area.Id">{{area.CommonDesc3}}</option>
                </select>
                <small class="text-danger font-weight-bold"
                  *ngIf="f.AreaId.touched && f.AreaId.errors && f.AreaId.errors.required">
                  Area is required.
                </small>
              </div> -->

               <!-- Zip Code -->
               <div class="col-md-6">
                <label for="email">Zip Code *</label>
                <input type="number" class="form-control" id="zip-code" placeholder="zip-code" formControlName="Pincode"
                  [ngClass]="{
                  'is-invalid': (f.Pincode.touched && f.Pincode.errors)
                }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.Pincode.touched && f.Pincode.errors && f.Pincode.errors.required">
                  Zip-Code is required.
                </small>
              </div>
              
              <!-- Promo Code -->
              <div class="col-md-6 select_input">
                <label for="PromoCode">Promo Code</label>
                <input type="text" class="form-control" id="PromoCode" placeholder="Promo Code" name="Promo Code"
                  formControlName="PromoCode" [ngClass]="{
                  'is-invalid': (f.PromoCode.touched && f.PromoCode.errors)
                }">
                <small class="text-danger font-weight-bold"
                  *ngIf="f.PromoCode.touched && f.PromoCode.errors && f.PromoCode.errors.required">
                  Promo Code is required.
                </small>
              </div>
              
              <!-- Button : Create Acocunt -->
              <div class="form-row row">
                <button class="btn btn-solid w-auto" type="submit" [disabled]="signUpForm.invalid">create
                  Account</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->


<div class="modal" tabindex="-1" role="dialog" id="areaAddPopUp">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add Area</h5>
        <button type="button" (click)="closeAreaModal()" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addAreaForm="ngForm" novalidate (ngSubmit)="addAreaForm.form.valid && submitArea()">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label class="required">New Area</label>
              <input required type="text" class="form-control" placeholder="Enter New Area" name="areaElementName"
                [(ngModel)]="newAreaName" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-sm btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>