import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbSectionComponent } from './components/breadcrumb-section/breadcrumb-section.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAddressComponent } from '../account/add-address/add-address.component';



@NgModule({
  declarations: [
    BreadcrumbSectionComponent,
    AddAddressComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    SlickCarouselModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    /* Components Export Start */
    BreadcrumbSectionComponent,
    AddAddressComponent,
    /* Components Export End */

    /* Module Export Start */
    NgSelectModule,
    SlickCarouselModule,
    ToastrModule,
    FormsModule,
    ReactiveFormsModule
    /* Module Export End */
  ]
})
export class SharedModule { }
