<!-- breadcrumb start -->
<app-breadcrumb-section screenName="login" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->
<!--section start-->
<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <h3>Login</h3>
                <div class="theme-card">
                    <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="form-group col-md-12">
                            <label for="mobileNo">Enter Mobile No</label>
                            <div class="row">
                                <div class="col-3">
                                    <ng-container *ngIf="countryCodes && countryCodes.length">
                                        <input list="countryCodes" class="form-control" name="country-code"
                                            id="country-code" [value]="CountryCode"
                                            (change)="onSelectionCountryCode($event.target)">
                                        <datalist id="countryCodes">
                                            <option *ngFor="let code of countryCodes" [value]="'+'+code.Phonecode">
                                            </option>
                                        </datalist>
                                    </ng-container>
                                </div>
                                <div class="col-9">
                                    <input type="tel" class="form-control" id="mobileNo" placeholder="Enter Mobile No"
                                        formControlName="UserName" required [ngClass]="{
                                            'is-invalid': (f.UserName.touched && f.UserName.errors)
                                          }">
                                    <small class="text-danger font-weight-bold"
                                        *ngIf="f.UserName.touched && f.UserName.errors && f.UserName.errors.pattern">
                                        Please, Enter 10 digit Mobile Number.
                                    </small>
                                    <small class="text-danger font-weight-bold"
                                        *ngIf="f.UserName.touched && f.UserName.errors && f.UserName.errors.required">
                                        Mobile Number is required.
                                    </small>
                                    <!-- <div *ngIf="f.UserName.touched && f.UserName.invalid" class="alert alert-danger">  
                                        <div *ngIf="f.UserName.errors.required">Mobile Number is required.</div>  
                                        <div *ngIf="f.UserName.errors.pattern">Please, Enter 10 digit Mobile Number.</div>  
                                    </div>   -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" class="form-control" id="password" placeholder="Enter your password"
                                formControlName="Password" required [ngClass]="{
                                    'is-invalid': (f.Password.touched && f.Password.errors)
                                  }">
                            <small class="text-danger font-weight-bold"
                                *ngIf="f.Password.touched && f.Password.errors && f.Password.errors.required">
                                Password is required.
                            </small>
                        </div>
                        <button class="btn btn-solid" type="submit" [disabled]="loginForm.invalid">Login</button>
                        <div class="col-md-12 text-right mt-2">
                            <a href="javascript:void(0)" class="anchor-login forgot_pass"
                                (click)="openForgotPasswordModal()">Forgot Password ?</a>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6 right-login">
                <h3>New Customer</h3>
                <div class="theme-card authentication-right">
                    <h6 class="title-font">Create A Account</h6>
                    <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be
                        able to order from our shop. To start shopping click register.</p>
                    <a class="btn btn-solid" href="javascript:void(0)" (click)="openSignInSignupModal()">Create an
                        Account</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->

<ng-container #signInSignUpContainerRef></ng-container>