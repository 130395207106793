<!-- breadcrumb start -->
<app-breadcrumb-section screenName="order tracking" parentScreenName="Home"></app-breadcrumb-section>
<!-- breadcrumb End -->


<!-- tracking page start -->
<section class="tracking-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h3>status for order no: {{this.OrderId}}</h3>
                <div class="row border-part">
                    <div class="col-xl-2 col-md-3 col-sm-4">
                        <div class="product-detail">
                            <img [src]="[this.ProductImage?this.ProductImage:'../assets/images/fashion/pro/1.jpg']"
                             class="img-fluid blur-up lazyload" alt="">
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-7 col-sm-8">
                        <div class="tracking-detail">
                            <ul>
                                <li>
                                    <div class="left">
                                        <span>Order name</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.ProductName}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>Order Id</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.BillNumber}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>order date</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.OrderDate | date:'dd MMM yyyy'}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>Ship Date</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.ShippingDate | date:'dd MMM yyyy'}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>shipping address</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.ShippingAddress}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>carrier</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.CarrierName}}</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <span>carrier tracking number</span>
                                    </div>
                                    <div class="right">
                                        <span>{{this.AwbNumber}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-xl-5 col-lg-4">
                        <div class="order-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55107.59629446948!2d-97.77629221286301!3d30.316123884942762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644ca7d7a2a6d0d%3A0x209a4c2782a39461!2sCentral%20Market!5e0!3m2!1sen!2sin!4v1607754725548!5m2!1sen!2sin"
                                frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div> -->
                </div>
                <div class="wrapper">
                    <!-- <div class="arrow-steps clearfix">
                        <div class="step done" [className]="[]"> <span> order placed</span> </div>
                        <div class="step done"> <span>preparing to ship</span> </div>
                        <div class="step" ngClass="{{orders[0]?.DeliveryStatus === 'Shipped' ? 'current': ''}}"><span> shipped</span> </div>
                        <div class="step"><span>delivered</span> </div>
                        
                    </div> -->
                    <div class="arrow-steps clearfix">
                        <div class="step" [ngClass]="orders[0]?.orderItems[0].DeliveryStatus === 'Ordered' ? 'current' : 'done'">
                          <span>order placed</span>
                        </div>
                        <div class="step" [ngClass]="orders[0]?.orderItems[0].DeliveryStatus === 'Packed' ? 'current' : (orders[0]?.orderItems[0].DeliveryStatus === 'Shipped' ? 'done' : '')">
                          <span>preparing to ship</span>
                        </div>
                        <div class="step" [ngClass]="orders[0]?.orderItems[0].DeliveryStatus === 'Shipped' ? 'current' : (orders[0]?.orderItems[0].DeliveryStatus === 'Delivered' ? 'done' : '')">
                          <span>shipped</span>
                        </div>
                        <div class="step" [ngClass]="orders[0]?.orderItems[0].DeliveryStatus === 'Delivered' ? 'current' : ''">
                          <span>delivered</span>
                        </div>
                    </div>
                </div>
                <div class="order-table table-responsive-sm">
                    <table class="table mb-0 table-striped table-borderless">
                        <thead class="">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Description</th>
                                <th scope="col">Location</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="trackDetail == null || trackDetail.awb.length===0">
                            <tr>
                                <td colspan="4" class="text-center">Not Yet Shipped</td>
                            </tr>
                        </tbody>
                        <ng-container *ngIf="trackDetail && trackDetail.awb">
                            <ng-container *ngFor="let stage of trackDetail.awb.slice().reverse()">
                                <tr>
                                    <td>{{stage.updated_on | date:'dd MMM yyyy'}}</td>
                                    <td>{{stage.updated_on | date:'hh:mm: a'}}</td>
                                    <td>{{stage.status}}</td>
                                    <td>{{stage.city_name}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <tbody >
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- tracking page end -->