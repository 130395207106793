import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_ENDPOINTS } from 'src/app/api.endpoints';
import { ApiService } from 'src/app/api.service';
import { CommonService } from 'src/app/common.service';
import { AddAddressComponent } from '../add-address/add-address.component';
import { APP_CONSTANT } from 'src/app/app.constant';
import * as _ from 'lodash';
import { WindowRefService } from 'src/app/core/services/window-ref.service';
import { SweetAlertService } from 'src/app/sweetalert.service';
import { Subject, debounceTime } from 'rxjs';
declare var document: any;


export interface CheckOutModalInterface {
  userDetails: any;
  queryitemId: number;
  queryitemRateId: number;
  queryitemQty: number;
  ItemDetails: any;
  cartItems: Array<any>;
  cartOffers: Array<any>;
  selectedDeliveryAddressId: number;
  defaultAddressExist: boolean;
  addresses: Array<any>;
  paymentMethodList: Array<any>;
  shippingChargeRangeList: Array<any>;
  selectedPaymentMethodId: number;
  
  subtotal: number;
  grandTotal: number; 

  totalMrpPrice: number;
  totalDiscount: number;
  otherDiscount: number;
  totalDeliveryCharges: number;
  totalOtherCharges: number;
  totalAmountPayble: number;
  globalDiscount:number,
  itemDiscount:number,
  categoryDiscount:number,
};


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent {
  @ViewChild('addNewAdressContainerRef', {
    read: ViewContainerRef,
    static: false,
  })
  addNewAdressContainerRef: ViewContainerRef | undefined;
  // checkoutForm: FormGroup;
  fatherName: any;
  countryId: any;
  stateId: any;
  cityId: any;
  areaId: any;
  pinCode: any;
  address: any;
  occupation: any;
  identification: any;
  fssaiNo: any;
  feedback: any;
  countries: Array<any> = [];
  stateList: Array<any> = [];
  cityList: Array<any> = [];
  areaList: Array<any> = [];
  addresses: Array<any> = [];
  totalPaymentList: Array<any> = [];
  couponList: Array<any> = [];
  Couponid: any;
  amountToRedeem: number = 0;
  donationAmount: number = 0;
  safetyAmount: number = 0;
  tipAmount: number = 0;
  totalPrice: number = 0;
  totalDiscount: number = 0;
  couponAmount: number = 0;
  selectedDeliveryAddressIndex: number = 0;
  paytmCredentialArray: any = {};
  payumoneyCredential: any;
  isWalletSelect: boolean = false;
  walletAmountInvalid: boolean = false;
  isSameDayDelivery: boolean = false;
  isSafetyAmount: boolean = true;
  isTipAmount: boolean = true;
  isDonation: boolean = true;
  isCouponAvail: boolean = false;
  couponCode: any;
  couponDetails: any;
  selectedCouponId: any;
  tipAmountList: any;
  Discountvalue: any;
  selectedcashondeliveryId: any;

  isOrderPlaced = false;

  // model object
  checkOutModal: CheckOutModalInterface = {
    userDetails: {},
    queryitemId: 0,
    queryitemRateId: 0,
    queryitemQty: 0,
    ItemDetails: {},
    cartItems: [],
    cartOffers: [],
    selectedDeliveryAddressId: 0,
    addresses: [],
    paymentMethodList: [],
    shippingChargeRangeList: [],
    selectedPaymentMethodId: 0,

    subtotal: 0,
    grandTotal: 0,

    totalMrpPrice: 0,
    totalDiscount: 0,
    otherDiscount: 0,
    totalDeliveryCharges: 0,
    totalOtherCharges: 0,

    totalAmountPayble: 0,
    defaultAddressExist: false,
    globalDiscount: 0,
    itemDiscount: 0,
    categoryDiscount: 0,
  };

  constructor(
    protected commonService: CommonService,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private winRef: WindowRefService,
    private sweetAlertService: SweetAlertService
  ) {
    if (this.commonService.isLogin()) {
      this.initializeUserDetails();
    }

    this.route.queryParamMap.subscribe((parameters: any) => {
      this.handleQueryParameters(parameters);
    });
  }

  // Helper functions
  private initializeUserDetails(): void {
    this.checkOutModal.userDetails = this.commonService.getProfileInfo();
    this.getAddress();
    this.getPaymentMethodList();
  }
  // Helper functions
  private handleQueryParameters(parameters: any): void {
    this.checkOutModal.queryitemId = Number(parameters.get('id'));
    this.checkOutModal.queryitemRateId = Number(parameters.get('itemRateId'));
    this.checkOutModal.queryitemQty = Number(parameters.get('quantity'));
    localStorage.setItem(
      APP_CONSTANT.CURRENT_TRANSACTION_QUERY,
      JSON.stringify(parameters.params)
    );

    if (this.checkOutModal.queryitemId) {
      this.getProductDetails();
    } else {
      this.getCartProductList();
    }
  }

  ngOnInit() {}

  getProductDetails() {
    this.checkOutModal.cartItems = [];

    const query = {
      ItemId: this.checkOutModal.queryitemId,
      CustomerLoginId: this.commonService.getLoginUserId(),
    };

    this.commonService.getItemDetails(query).subscribe((res: any) => {
      if (this.isValidItemDetailsResponse(res)) {
        const selectedItem = res.Data.ItemDetails[0];
        const data = this.createCartItem(selectedItem);

        this.checkOutModal.cartItems.push({ ...data });

        this.updateDonationSafetyAmount();
        this.calculateCharges();
        this.getCouponList();
      }
    });
  }

  // Helper functions
  private isValidItemDetailsResponse(res: any): boolean {
    return (
      res.Code === 1000 &&
      !_.isEmpty(res.Data) &&
      !_.isEmpty(res.Data.ItemDetails)
    );
  }

  // Helper functions
  private createCartItem(selectedItem: any): any {
    return {
      ItemId: selectedItem.ItemId,
      ItemRateSetupId: selectedItem.ItemRateSetupId || 0,
      ItemName: selectedItem.ItemName,
      MrpRate: selectedItem.MrpRate,
      SaleRate: selectedItem.SaleRate,
      PercentageOff: selectedItem.PercentageOff,
      Qty: this.checkOutModal.queryitemQty,
      ImagePath: _.get(selectedItem.ImageList, '[0].FilePath', ''),
    };
  }

  // Helper functions
  private updateDonationSafetyAmount(): void {
    const profileDetails = this.commonService.customerProfileDetails;

    this.isDonation = profileDetails.DonationAmount > 0;
    this.donationAmount = this.isDonation ? profileDetails.DonationAmount : 0;

    this.isSafetyAmount = profileDetails.SafetyAmount > 0;
    this.safetyAmount = this.isSafetyAmount ? profileDetails.SafetyAmount : 0;
  }

  // Get coupons for products
  getCouponList() {
    const requestBody = {
      CouponId: 0,
      CouponCode: this.couponCode,
      CartDetails: this.mapCartItems(this.checkOutModal.cartItems),
    };

    this.apiService
      .postApi(API_ENDPOINTS.COUPON_LIST, requestBody)
      .subscribe((response: any) => {
        this.handleCouponListResponse(response);
      });
  }

  // Helper functions
  private mapCartItems(cartItems: any[]): any[] {
    return cartItems.map((item) => ({
      ItemId: item.ItemId,
      Qty: item.Qty,
      ItemRateSetupId: item.ItemRateSetupId,
    }));
  }

  // Helper functions
  private handleCouponListResponse(response: any): void {
    if (
      response.Code === 1000 &&
      !_.isEmpty(response.Data) &&
      !_.isEmpty(response.Data.MainCouponList)
    ) {
      this.couponList = [...response.Data.MainCouponList];
    } else {
      this.couponList = [];
    }
  }

  // Fetch payment methods
  getPaymentMethodList() {
    this.apiService
      .getApi(API_ENDPOINTS.PAYMENT_METHOD, {}, this.commonService.isLogin())
      .subscribe(
        (res: any) => this.handlePaymentMethodResponse(res),
        (error: any) => this.handlePaymentMethodError(error)
      );
  }

  // Helper functions
  private handlePaymentMethodResponse(response: any) {
    if (response.Code === 1000 && !_.isEmpty(response.Data)) {
      this.totalPaymentList = [...response.Data];
      this.checkOutModal.paymentMethodList = [...response.Data];
      console.log('this.checkOutModal.paymentMethodList', this.checkOutModal.paymentMethodList);
      
      this.checkOutModal.selectedPaymentMethodId = 0;
    } else {
      this.clearPaymentMethodLists();
    }
  }

  // Helper functions
  private handlePaymentMethodError(error: any) {
    // Handle error, e.g., log or show an alert
    console.error('Error fetching payment methods:', error);
    this.clearPaymentMethodLists();
  }

  // Helper functions
  private clearPaymentMethodLists() {
    this.totalPaymentList = [];
    this.checkOutModal.paymentMethodList = [];
  }

  /**
   * 1) Retrieves location details for country, state, city, and area in the cart summary.
   */

  // // Retrieves all countries details
  // getCountryList = () => {
  //   this.apiService.getApi(API_ENDPOINTS.GET_COUNTRIES, {}, false).subscribe((res: any) => {
  //     if (res?.Data?.length) {
  //       this.updateCountryList(res.Data);
  //       this.stateList = [];
  //       this.cityList = [];
  //       this.areaList = [];
  //       this.resetLocationIds();
  //       this.getStateList(this.countryId);
  //     }
  //   });
  // };

  // // Retrieves all states details based on country
  // getStateList = (countryCode: any, item?: any) => {
  //   const countryId = this.checkoutForm.controls['CountryId'].value;
  //   const url = countryId ? `${API_ENDPOINTS.GET_STATES}${countryId}` : '';
  //   this.apiService.getApi(url, {}, false).subscribe((res: any) => {
  //     if (res?.Data?.length) {
  //       this.stateList = [...res.Data];
  //       this.cityList = [];
  //       if (item && Object.keys(item).length) {
  //         this.stateId = item.StateId;
  //         this.getCityList(this.stateId, item);
  //       }
  //     }
  //   });
  // };

  // // Retrieves all cities details based on state
  // getCityList(stateCode: any, item?: any) {
  //   const stateId = this.checkoutForm.controls['StateId'].value;
  //   const url = stateId ? `${API_ENDPOINTS.GET_CITIES}${stateId}` : '';
  //   this.apiService.getApi(url).subscribe((res: any) => {
  //     if (res.Code === 1000 && res?.Data?.length) {
  //       this.cityList = [...res.Data];
  //       if (item && Object.keys(item).length) {
  //         this.cityId = Number(item.CityId);
  //         this.getAreaList(this.cityId, item);
  //       }
  //     }
  //   });
  // };

  // // Retrieves all area details based on city
  // getAreaList(cityCode: any, item?: any) {
  //   cityCode = Number(this.checkoutForm.controls['CityId'].value);
  //   if (cityCode === -1) {
  //     const data = {
  //       countries: this.countries?.length ? [...this.countries] : [],
  //       stateList: this.stateList?.length ? [...this.stateList] : [],
  //       countryId: this.countryId || 0,
  //       stateId: this.stateId || 0
  //     };
  //     // this.addNewCityRefModel.openModal(data);
  //   } else if (cityCode > 0) {
  //     const url = `${API_ENDPOINTS.GET_AREA}${cityCode}`;
  //     this.apiService.getApi(url).subscribe((res: any) => {
  //       if (res.Code === 1000 && res?.Data?.length) {
  //         this.areaList = [{ Id: -1, CommonDesc3: '+Add New' }, ...res.Data];
  //         if (item && Object.keys(item).length) {
  //           this.areaId = Number(item.AreaId);
  //         }
  //       } else {
  //         this.areaList = [{ Id: -1, CommonDesc3: '+Add New' }];
  //         this.areaId = null;
  //       }
  //     });
  //   }
  // };

  // // Helper functions
  // private updateCountryList(data: any[]) {
  //   this.countries = [...data];
  // }

  // private resetLocationIds() {
  //   this.stateId = null;
  //   this.cityId = null;
  //   this.areaId = null;
  // }

  /**
   * 2) Cart Product data
   * */
  getCartProductList = () => {
    const query = {
      CustomerLoginId: this.commonService.isLogin()
        ? this.commonService.getLoginUserId()
        : 152,
      IsWishingItem: false,
      IsFromMobile: 0,
    };
    this.apiService
      .getApi(
        API_ENDPOINTS.CART_LIST_WITH_CHARGE,
        query,
        this.commonService.isLogin()
      )
      .subscribe((res: any) => {
        if (res.Code === 1000 && res?.Data?.AddToCartMainDetailsList.length) {
          this.checkOutModal.cartItems = res.Data.AddToCartMainDetailsList.map(
            (item: any) => {
              item.chargeList =
                res.Data.AddToCartOtherChargesDetailsList?.filter(
                  (x: any) => x.ItemID === item.ItemID
                );
              return item;
            }
          );

          // Get offfers of cart items, If any
          this.checkOutModal.cartOffers = res?.Data.hasOwnProperty('Offers')
            ? res.Data.Offers
            : [];

          // Filter safety, donation, tip amounts
          const profileDetails = this.commonService.customerProfileDetails;
          this.isDonation = profileDetails.DonationAmount > 0;
          this.isSafetyAmount = profileDetails.SafetyAmount > 0;
          this.isTipAmount = profileDetails.TipAmount > 0;

          this.donationAmount = this.isDonation
            ? profileDetails.DonationAmount
            : 0;
          this.safetyAmount = this.isSafetyAmount
            ? profileDetails.SafetyAmount
            : 0;
          this.tipAmount = this.isTipAmount ? profileDetails.TipAmount : 0;

          this.calculateCharges();
          // this.getCouponList();
        } else {
          this.checkOutModal.cartItems = [];
          this.checkOutModal.cartOffers = [];
        }
      });
  };

  private calculateSubtotal(cartItem: Array<any>): number {
    return cartItem.reduce((total, item) => {
      let itemRate = item.SaleRate || item.MrpRate;
      return total + item.Qty * itemRate;
    }, 0);
  }

  /**
   * Calculates the total discount on items based on a global offer.
   * @returns The total discounted amount on all eligible items.
   */
  private calculateGlobalOfferDiscount(): number {
    // Initialize the variable to store the discounted amount.
    let discountedItemAmount: number = 0;

    // Iterate through each offer in the cart.
    this.checkOutModal.cartOffers.forEach((offer: any) => {
      // Check if the offer is based on all items (1).
      if (offer.BasedOn === '1') {
        // Calculate the total amount for all eligible items.
        const total = this.calculateTotalAmountForEligibleItems();

        // Apply the offer discount to the total amount.
        discountedItemAmount += (offer.OfferValue / 100) * total;
      }
    });

    // Return the final discounted amount.
    return discountedItemAmount;
  }

  /**
   * Calculates the total discount on items based on an offer applied to a specific item.
   * @returns The total discounted amount on the specified item.
   */
  private calculateItemOfferDiscount(): number {
    // Initialize the variable to store the discounted amount.
    let discountedItemAmount: number = 0;

    // Iterate through each offer in the cart.
    this.checkOutModal.cartOffers.forEach((offer: any) => {
      // Check if the offer is based on a specific item (2).
      if (offer.BasedOn === '2') {
        // Calculate the total amount for the specified item.
        const total = this.calculateTotalAmountForSpecificItem(offer.Id);

        // Apply the offer discount to the total amount.
        discountedItemAmount += (offer.OfferValue / 100) * total;
      }
    });

    // Return the final discounted amount.
    return discountedItemAmount;
  }

  /**
   * Calculates the total amount for a specific item that is eligible for an offer.
   * @param itemId - The ID of the specific item.
   * @returns The total amount for the specified item eligible for a discount.
   */
  private calculateTotalAmountForSpecificItem(itemId: string): number {
    return this.checkOutModal.cartItems
      .filter(
        (item: any) => item.IsNotDiscountable === 0 && item.ItemId === itemId
      )
      .reduce((sum: number, item: any) => sum + item.SaleRate * item.Qty, 0);
  }

  /**
   * Calculates the total amount for all eligible items.
   * @returns The total amount for all items that are eligible for discounts.
   */
  private calculateTotalAmountForEligibleItems(): number {
    return this.checkOutModal.cartItems
      .filter((item: any) => item.IsNotDiscountable === 0)
      .reduce((sum: number, item: any) => sum + item.SaleRate * item.Qty, 0);
  }

  /**
   * Calculates the total discount on items based on a category-specific offer.
   * @returns The total discounted amount on items belonging to the specified category.
   */
  private calculateCategoryOfferDiscount(): number {
    // Initialize the variable to store the discounted amount.
    let discountedCategoryAmount: number = 0;

    // Iterate through each offer in the cart.
    this.checkOutModal.cartOffers.forEach((offer: any) => {
      // Check if the offer is based on a specific category (3).
      if (offer.BasedOn === '3') {
        // Calculate the total amount for items belonging to the specified category.
        const total = this.calculateTotalAmountForCategory(offer.Id);

        // Apply the offer discount to the total amount.
        discountedCategoryAmount += (offer.OfferValue / 100) * total;
      }
    });

    // Return the final discounted amount.
    return discountedCategoryAmount;
  }

  /**
   * Calculates the total amount for items belonging to the specified category.
   * @param categoryId - The ID of the specific category.
   * @returns The total amount for items belonging to the specified category.
   */
  private calculateTotalAmountForCategory(categoryId: string): number {
    return this.checkOutModal.cartItems
      .filter(
        (item: any) =>
          item.IsNotDiscountable === 0 && item.CategoryId === categoryId
      )
      .reduce((sum: number, item: any) => sum + item.SaleRate * item.Qty, 0);
  }

  private calculateShippingCharges(): number {
    const amount =
      this.checkOutModal.subtotal - this.checkOutModal.totalDiscount || 0;
    const matchingRange = this.checkOutModal.shippingChargeRangeList.find(
      (range) => {
        const { AmountFrom, AmountTo } = range;
        return amount >= Number(AmountFrom) && amount <= Number(AmountTo);
      }
    );
    return matchingRange ? Number(matchingRange.ChargesAmt) : 0;
  }

  // Helper functions
  calculateCharges = () => {
    this.resetCharges();
    const { SameDayDeliveryCharges } =
      this.commonService.customerProfileDetails;

    this.checkOutModal.subtotal = this.calculateSubtotal(
      this.checkOutModal.cartItems
    );

    // // Apply auto discounts & offers if available
    // this.checkOutModal.globalDiscount = this.calculateGlobalOfferDiscount();
    // this.checkOutModal.itemDiscount = this.calculateItemOfferDiscount();
    // this.checkOutModal.categoryDiscount = this.calculateCategoryOfferDiscount();

    // Coupon: Apply coupon discount
    if (
      !_.isEmpty(this.couponDetails) &&
      this.couponDetails.Type === 1 &&
      Number(this.couponDetails.CouponAmount) > 0
    ) {
      this.checkOutModal.subtotal -= Number(this.couponDetails.CouponAmount);
    }

    // // Deduction all discounted amount from subtotal,
    // this.checkOutModal.totalDiscount =
    //   this.checkOutModal.globalDiscount +
    //   this.checkOutModal.itemDiscount +
    //   this.checkOutModal.categoryDiscount;
    // // If total discount, then deduct from total payable amount
    // this.checkOutModal.subtotal -= this.checkOutModal.totalDiscount;

    // Apply shipping charges,
    this.checkOutModal.totalDeliveryCharges = this.calculateShippingCharges();

    // If sipping charge found, then add to total payable amount
    this.checkOutModal.grandTotal =
      this.checkOutModal.subtotal + this.checkOutModal.totalDeliveryCharges;

    // Apply same day delivery charges if available
    this.isSameDayDelivery = true;
    if (this.isSameDayDelivery) {
      this.checkOutModal.totalDeliveryCharges += SameDayDeliveryCharges;
      this.checkOutModal.grandTotal += SameDayDeliveryCharges;
    }

    // final amount
    this.checkOutModal.totalAmountPayble = this.checkOutModal.grandTotal;
  };

  removeItem = (item: any, index: any) => {
    this.checkOutModal.cartItems.splice(index, 1);
    this.calculateCharges();
    // this.getCouponList();
  };

  resetCharges = () => {
    this.checkOutModal.totalDeliveryCharges = 0;
    this.checkOutModal.totalOtherCharges = 0;
    this.checkOutModal.totalAmountPayble = 0;
    this.checkOutModal.subtotal = 0;
    this.checkOutModal.totalDiscount = 0;
  };

  /**
   * 3) Handles Address related functions and its data
   */
  getAddress = () => {
    const queryParams = {
      ParentId: this.commonService.getLoginUserId(),
      ParentTypeId: 40,
    };

    this.apiService
      .getApi(API_ENDPOINTS.ADDRESS, queryParams)
      .subscribe((response: any) => {
        if (response.Code === 1000 && response.Data.length > 0) {
          this.addresses = response.Data;

          const defaultAddressIndex = this.addresses.findIndex(
            (address) => address.IsDefault === 1
          );
          this.checkOutModal.defaultAddressExist = defaultAddressIndex > -1;

          if (this.addresses.length === 1) {
            this.checkOutModal.selectedDeliveryAddressId = Number(
              this.addresses[0].Id
            );
          } else if (defaultAddressIndex > -1) {
            this.checkOutModal.selectedDeliveryAddressId = Number(
              this.addresses[defaultAddressIndex].Id
            );
          }
        } else {
          this.addresses = [];
        }
      });
  };

  addNewAddress = (address?: any) => {
    const defaultAddressExist =
      address && address.IsDefault
        ? false
        : this.checkOutModal.defaultAddressExist;

    this.commonService.loadModalDynamically(
      this,
      'addNewAdressContainerRef',
      'addAddressRef',
      AddAddressComponent,
      { ...address, defaultAddressExist },
      (data: any) => {
        if (data) {
          this.getAddress();
        }
      }
    );
  };

  deleteAddress = (item: any) => {
    const query = { Id: item.Id };

    if (this.addresses.length > 1) {
      this.sweetAlertService
        .showConfirm(
          'info',
          'Address Deletion Confirmation',
          'Are you sure you want to delete this address? Once deleted, it cannot be recovered.',
          'Yes, delete it!',
          'No, cancel!'
        )
        .then((result) => {
          if (result.isConfirmed) {
            this.apiService
              .deleteApi(
                API_ENDPOINTS.ADDRESS,
                query,
                this.commonService.isLogin()
              )
              .subscribe((response: any) => {
                if (response.Code === 5015) {
                  this.sweetAlertService.showAlert(
                    'success',
                    'Address Removed',
                    'Address successfully removed. Proceed with confidence; you can always add a new one!'
                  );
                  this.getAddress();
                } else {
                  this.sweetAlertService.showToast('error', response.Message);
                }
              });
          } else {
            this.sweetAlertService.showAlert(
              'info',
              'Address Safe',
              'Your address details remain unchanged. No addresses were deleted.'
            );
          }
        });
    } else {
      this.sweetAlertService.showAlert(
        'info',
        'Address Required',
        'Please add another address before deleting the existing one.'
      );
    }
  };

  /**
   * 4) Apply Coupon
   * @discription This will apply coupon if it valid else shows error message
   */
  applyCoupon() {
    if (!this.couponCode) {
      this.sweetAlertService.showAlert(
        'error',
        'Invalid coupon code. Please check and try again.',
        ''
      );
      return;
    }

    const cartDetails = this.checkOutModal.cartItems.map((item) => ({
      ItemId: item.ItemId,
      Qty: item.Qty,
      ItemRateSetupId: item.ItemRateSetupId,
    }));

    const payload = {
      CouponId: this.selectedCouponId,
      CouponCode: this.couponCode,
      CartDetails: cartDetails,
    };

    this.apiService
      .postApi(API_ENDPOINTS.COUPON_LIST, payload)
      .subscribe((res: any) => {
        // console.log('applyCoupon: ', res);

        if (
          res.Code === 1000 &&
          !_.isEmpty(res.Data) &&
          !_.isEmpty(res.Data.MainCouponList)
        ) {
          this.handleCouponSuccess(res.Data.MainCouponList[0]);
        } else {
          this.handleCouponFailure();
        }
      });
  }
  // Helper functions
  handleCouponSuccess(couponDetails: any) {
    this.couponDetails = { ...couponDetails };
    this.couponAmount = Number(this.couponDetails.CouponAmount);
    this.Discountvalue = Number(this.couponDetails.DiscountValue);
    this.Couponid = Number(this.couponDetails.CouponId);
    this.isCouponAvail = false;

    if (couponDetails.Type === 1 && this.couponAmount > 0) {
      this.checkOutModal.totalAmountPayble -= this.couponAmount;
    }
  }

  handleCouponFailure() {
    this.couponDetails = {};
    this.couponAmount = 0;
    this.isCouponAvail = true;
  }

  handleCouponRemove = () => {
    this.couponCode = '';
    if (
      this.couponDetails.Type === 1 &&
      Number(this.couponDetails.CouponAmount) > 0
    ) {
      this.checkOutModal.totalAmountPayble += Number(
        this.couponDetails.CouponAmount
      );
    }
    this.onCouponCodeChange();
  };

  onChangeIsDonation = () => {
    if (!this.isDonation) {
      this.donationAmount = 0;
    }
    return true;
  };

  trimCode = () => {
    if (this.couponCode) {
      this.couponCode = this.couponCode.replace(/ /g, '');
    }
  };

  onCouponCodeChange = () => {
    this.couponDetails = {};
    this.couponAmount = 0;
    this.isCouponAvail = false;
  };

  // checkValid() {
  //   if (
  //     this.amountToRedeem &&
  //     this.amountToRedeem >
  //       this.commonService.customerProfileDetails.WalletBalance
  //   ) {
  //     // this.amountToRedeem = this.commonService.customerProfileDetails.WalletBalance;
  //     if (
  //       this.checkOutModal.totalAmountPayble >
  //       this.commonService.customerProfileDetails.WalletBalance
  //     ) {
  //       this.amountToRedeem =
  //         this.commonService.customerProfileDetails.WalletBalance;
  //     } else if (
  //       this.checkOutModal.totalAmountPayble <
  //       this.commonService.customerProfileDetails.WalletBalance
  //     ) {
  //       this.amountToRedeem = this.checkOutModal.totalAmountPayble;
  //     }
  //   }
  //   if (
  //     this.amountToRedeem &&
  //     this.amountToRedeem > this.checkOutModal.totalAmountPayble
  //   ) {
  //     this.amountToRedeem = this.checkOutModal.totalAmountPayble;
  //   }
  //   if (this.checkOutModal.totalAmountPayble <= this.amountToRedeem) {
  //     this.checkOutModal.selectedPaymentMethodId = 0;
  //   }
  // }

  checkValid() {
    const walletBalance =
      this.commonService.customerProfileDetails.WalletBalance;
    const totalAmountPayble = this.checkOutModal.totalAmountPayble;

    if (this.amountToRedeem && this.amountToRedeem > walletBalance) {
      this.amountToRedeem = Math.min(walletBalance, totalAmountPayble);
    }

    if (this.amountToRedeem && this.amountToRedeem > totalAmountPayble) {
      this.amountToRedeem = totalAmountPayble;
    }

    if (totalAmountPayble <= this.amountToRedeem) {
      this.checkOutModal.selectedPaymentMethodId = 0;
    }
  }

  // onStepEnter(e: any, stepId?: any) {
  //   if (e === 0 && stepId === 3) {
  //     // tslint:disable-next-line:max-line-length
  //     if (
  //       typeof this.commonService.customerProfileDetails.TipAmounts ===
  //       'string' &&
  //       this.commonService.customerProfileDetails.TipAmounts
  //     ) {
  //       this.tipAmountList = this.commonService.customerProfileDetails.TipAmounts.split(
  //         ','
  //       );
  //       this.tipAmountList = _.map(this.tipAmountList, (item) => Number(item));
  //     } else {
  //       this.tipAmountList = [];
  //     }
  //   }
  // }

  // onStepExit = () => {};

  // getSelectedMobileCoutryCode = (countryId: string): string => {
  //   return this.commonService.getSelectedMobileCoutryCode(countryId);
  // };

  /**
   * 5) Place Order
   ***/

  // Function to prepare the payload for the API call
  preparePayload = () => {
    // Calculate gatewayAmount based on selectedPaymentMethodId
    const gatewayAmount = [737, 746, 34652].includes(
      Number(this.checkOutModal.selectedPaymentMethodId)
    )
      ? this.checkOutModal.totalAmountPayble - this.amountToRedeem
      : 0;

    // Construct and return the payload
    return {
      IsFromMobile: 0,
      CouponId: this.Couponid || 0,
      PaymentTypeId: Number(this.checkOutModal.selectedPaymentMethodId),
      BillingAddressId:
        Number(this.checkOutModal.selectedDeliveryAddressId) || 0,
      GatewayAmount: gatewayAmount,
      SameDayDelivery: this.isSameDayDelivery ? 1 : 0,
      BuyNowDetails: this.mapCartItems(this.checkOutModal.cartItems),
      CashOnDelivery:
        Number(this.checkOutModal.selectedPaymentMethodId) === 34652 ? 1 : 0,
      // WalletAmount: this.isWalletSelect && this.amountToRedeem ? this.amountToRedeem : 0,
      // DonationAmount: this.isDonation ? this.donationAmount : 0,
      // SafetyAmount: this.safetyAmount ? this.safetyAmount : 0,
      // TipAmount: this.tipAmount ? this.tipAmount : 0,
    };
  };

  // Declare a subject for debouncing
  private placeOrderSubject = new Subject();

  // Function to handle the place order logic
  placeOrder = () => {
    // Pipe the debouncing operator to the subject
    this.placeOrderSubject
      .pipe(debounceTime(300)) // Adjust the debounce time as needed (300 milliseconds in this example)
      .subscribe(() => {
        // Check if the order is already placed
        if (this.isOrderPlaced) return;

        // Prepare the payload
        const payload = this.preparePayload();
        this.isOrderPlaced = true;
        
        // Make the API call to place the order
        this.apiService
          .postApi(
            API_ENDPOINTS.CHECK_OUT_ORDER,
            payload,
            {},
            this.commonService.isLogin()
          )
          .subscribe((res: any) => {
            // Handle different cases based on the API response
            if (res && res.Code === 5020) {
              this.sweetAlertService.showToast('error', res.Message);
              return;
            }
            
            if (res && res.Code === 1000) {
              // Handle payment method specific redirection
              this.handlePaymentMethod(res.Data);
            } else {
              this.sweetAlertService.showToast(
                'error',
                'Unable to place your order. Please try again.'
              );
            }

            // Reset the flag after handling the order placement
            this.isOrderPlaced = false;
          });
      });

    // Emit a value to trigger the debouncing
    this.placeOrderSubject.next('');
  };

  // Function to handle redirection based on selected payment method
  private handlePaymentMethod = (data: any) => {
    console.log(data);
    
    if(this.checkOutModal.selectedPaymentMethodId == 1){
      // Handle success for other (e.g. COD) payment methods
      this.sweetAlertService.showToast('success', 'Order successfully placed!');
      this.router.navigate(['dashboard'], {
        queryParams: { selectedTab: 'orders' },
      });
    }else if(this.checkOutModal.selectedPaymentMethodId > 1) {
      this.handlePhonePeRedirection(data);
    }else {
      this.sweetAlertService.showToast('error', 'Payment Method Missing! '+data);
    }

    // switch (this.checkOutModal.selectedPaymentMethodId) {
    //   case 1:
    //     // Handle success for other (e.g. COD) payment methods
    //     this.sweetAlertService.showToast(
    //       'success',
    //       'Order successfully placed!'
    //     );
    //     this.router.navigate(['dashboard'], {
    //       queryParams: { selectedTab: 'orders' },
    //     });
    //     break;

    //   case 7:
    //   case 8:
    //   case 9:
    //     this.handlePhonePeRedirection(data);
    //     break;

    //   // case 2345654:
    //   //   this.handleRazorPayPopup(data);
    //   //   break;

    //   // case 737:
    //   //   this.handlePaytmRedirection(data);
    //   //   break;

    //   // case 746:
    //   //   this.handlePayumoneyRedirection(data);
    //   //   break;

    //   default:
    //     this.sweetAlertService.showToast('error', 'Payment Method Missing!');
    //     break;
    // }
  };

  // Function to handle PhonePe redirection
  private handlePhonePeRedirection = (url: string) => {
    // Redirect to PhonePe Payment Gateway
    window.location.href = url;
  };

  // Function to handle Paytm redirection
  private handlePaytmRedirection = (data: any) => {
    this.paytmCredentialArray = { ...data };
    // Submit the Paytm form after a delay
    setTimeout(() => document.getElementById('paytmForm').submit(), 1500);
  };

  // Function to handle Payumoney redirection
  private handlePayumoneyRedirection = (data: any) => {
    this.payumoneyCredential = { ...data };
    const payumoneyData = {
      TXN_AMOUNT: this.payumoneyCredential.TXN_AMOUNT,
      CHECKSUMHASH: this.payumoneyCredential.CHECKSUMHASH,
      ORDER_ID: this.payumoneyCredential.ORDER_ID,
      ProductInfo: 'ProductInfo',
      FirstName: this.commonService.customerProfileDetails.CustomerName,
      Email: this.commonService.customerProfileDetails.Email || '',
      Mobile: '',
    };
    // Redirect to Payumoney payment gateway
    this.commonService.checkOutPayumoney(payumoneyData);
  };

  // Function to handle Razorpay popup modal
  private handleRazorPayPopup(data: any) {
    // Configure Razorpay options
    const options = {
      key: 'Your Key ID' || 'rzp_test_SM3bW3j7o663gR', // Enter the Key ID generated from the Dashboard
      amount: data.GatewayAmount,
      currency: 'INR',
      name: this.commonService.customerProfileDetails.CustomerName,
      description: 'Razorpay Payment gateway',
      order_id: data.ORDER_ID,
      image: 'https://img.icons8.com/color/48/backend-development--v1.png',
      prefill: {
        email: this.commonService.customerProfileDetails.Email,
        contact: this.commonService.customerProfileDetails.LoginId,
      },
      config: {
        // Razorpay Checkout configuration
        display: {
          blocks: {
            utib: {
              name: 'Pay using Axis Bank',
              instruments: [
                { method: 'upi' },
                { method: 'netbanking', banks: ['UTIB'] },
                { method: 'card', issuers: ['UTIB'] },
              ],
            },
            icici: {
              name: 'Pay using ICICI Bank',
              instruments: [
                { method: 'upi' },
                { method: 'netbanking', banks: ['ICICI'] },
                { method: 'card', issuers: ['ICICI'] },
              ],
            },
            other: {
              name: 'Other Payment modes',
              instruments: [
                { method: 'upi' },
                { method: 'netbanking' },
                { method: 'card', issuers: ['ICIC'] },
              ],
            },
          },
          // hide: [
          // // hide UPI method
          //   { method: "upi" }
          // ],
          sequence: ['block.icici', 'block.utib', 'block.other'],
          preferences: {
            show_default_blocks: false, // Should Checkout show its default blocks?
          },
        },
      },
      // Payment handler
      handler: this.handleRazorPayResponse.bind(this),
      // Dismiss modal handler
      modal: { ondismiss: this.handleModalDismiss.bind(this) },
    };

    const rzp1 = new this.winRef.nativeWindow.Razorpay(options);

    // Payment failure handler
    rzp1.on('payment.failed', this.handleRazorPayFailure.bind(this));

    // Open the Razorpay modal
    rzp1.open();
  }

  // Private method to handle payment response
  private handleRazorPayResponse(response: any): void {
    document.getElementById('rzp-id').innerText =
      'Payment id => ' + response.razorpay_payment_id;
    document.getElementById('rzp-order').innerText =
      'Order id => ' + response.razorpay_order_id;
    document.getElementById('rzp-signature').innerText =
      'Signature => ' + response.razorpay_signature;
  }

  // Private method to handle payment failure
  private handleRazorPayFailure(response: any): void {
    document.getElementById('rzp-id').innerText =
      'Error code => ' + response.error.code;
    document.getElementById('rzp-order').innerText =
      'Description => ' + response.error.description;
    document.getElementById('rzp-signature').innerText =
      'Error Reason => ' + response.error.reason;
  }

  // Private method to handle modal dismiss
  private handleModalDismiss(): void {
    if (confirm('Are you sure, you want to close the form?')) {
      console.log('Checkout form closed by the user');
    } else {
      console.log('Complete the Payment');
    }
  }

  // payWithRazor(placePayload: any) {
  //   let options = {
  //     "key": "Key ID", // Enter the Key ID generated from the Dashboard
  //     "amount": placePayload.GatewayAmount,
  //     "currency": "INR",
  //     "name": this.commonService.customerProfileDetails.CustomerName,//"Saniiro Elite", // Replace with your product name
  //     "description": "Saniiro Elite with basic features for startups",
  //     "order_id": placePayload.ORDER_ID,
  //     "image": "https://img.icons8.com/color/48/backend-development--v1.png",
  //     "prefill":
  //     {
  //       "email": this.commonService.customerProfileDetails.Email,
  //       "contact": this.commonService.customerProfileDetails.LoginId
  //     },
  //     config: {
  //       display: {
  //         blocks: {
  //           utib: { //name for Axis block
  //             name: "Pay using Axis Bank",
  //             instruments: [
  //               {
  //                 method: "upi"
  //               },
  //               {
  //                 method: "netbanking",
  //                 banks: ["UTIB"]
  //               },
  //               {
  //                 method: "card",
  //                 issuers: ["UTIB"]
  //               }
  //             ]
  //           },
  //           icici: { //name for Axis block
  //             name: "Pay using ICICI Bank",
  //             instruments: [
  //               {
  //                 method: "upi"
  //               },
  //               {
  //                 method: "netbanking",
  //                 banks: ["ICICI"]
  //               },
  //               {
  //                 method: "card",
  //                 issuers: ["ICICI"]
  //               }
  //             ]
  //           },
  //           other: { //  name for other block
  //             name: "Other Payment modes",
  //             instruments: [
  //               {
  //                 method: 'upi',
  //               },
  //               {
  //                 method: 'netbanking',
  //               },
  //               {
  //                 method: "card",
  //                 issuers: ["ICIC"]
  //               }
  //             ]
  //           }
  //         },
  //         hide: [
  //           // want to hide method, if any
  //           {
  //           method: "upi"
  //           }
  //         ],
  //         // maintain sequest order
  //         sequence: ["block.icici", "block.utib", "block.other"],
  //         preferences: {
  //           show_default_blocks: false // Should Checkout show its default blocks?
  //         }
  //       }
  //     },
  //     "handler": function (response: any) {
  //       //console.log(`Payment ID: ${response.razorpay_payment_id}, Order ID: ${response.razorpay_order_id}, Signature: ${response.razorpay_signature}`);
  //       //alert(`Payment ID: ${response.razorpay_payment_id}, Order ID: ${response.razorpay_order_id}, Signature: ${response.razorpay_signature}`);

  //       document.getElementById('rzp-id').innerText = 'Payment id => ' + response.razorpay_payment_id;
  //       document.getElementById('rzp-order').innerText = 'Order id => ' + response.razorpay_order_id
  //       document.getElementById('rzp-signature').innerText = 'Singnature => ' + response.razorpay_signature
  //     },
  //     "modal": {
  //       "ondismiss": function () {
  //         if (confirm("Are you sure, you want to close the form?")) {
  //           // txt = "You pressed OK!";
  //           console.log("Checkout form closed by the user");
  //         } else {
  //           // txt = "You pressed Cancel!";
  //           console.log("Complete the Payment")
  //         }
  //       }
  //     }
  //   };

  //   const rzp1 = new this.winRef.nativeWindow.Razorpay(options);

  //   rzp1.on('payment.failed', (response: any) => {
  //     //console.log(`Razorpay payment failure: => `, response);

  //     document.getElementById('rzp-id').innerText = 'Error code => ' + response.error.code;
  //     document.getElementById('rzp-order').innerText = 'Description => ' + response.error.description;
  //     document.getElementById('rzp-signature').innerText = 'Error Reason => ' + response.error.reason;

  //   });
  //   rzp1.open();
  // }

  // initiatePaymentGateway = (placePayload: any) => {
  //   const merchantKey = 'b726e0cb-80f8-467f-a82b-532086a92026';//saltkey
  //   const saltKey = '099eb0cd-02cf-4e2a-8aca-3e6c6aff0399';//b726e0cb-80f8-467f-a82b-532086a92026';
  //   const saltIndex = 1;
  //   ///placePayload.GatewayAmount,
  //   // let payload = {
  //   //   "merchantId": "PGTESTPAYUAT",
  //   //   "merchantTransactionId": "MT7850590068188104",
  //   //   "merchantUserId": "MUID123",
  //   //   "amount": 10000,
  //   //   "redirectUrl": window.location.origin + '/dashboard?selectedTab=orders',
  //   //   "redirectMode": "POST",
  //   //   "callbackUrl": window.location.href,
  //   //   "mobileNumber": this.commonService.customerProfileDetails.LoginId,
  //   //   "paymentInstrument": {
  //   //     "type": "PAY_PAGE"
  //   //   }
  //   // };
  //   let payload = {
  //     "merchantId": "PGTESTPAYUAT",
  //     "merchantTransactionId": "MT7850590068188104",
  //     "merchantUserId": "MUID123",
  //     "amount": 10000,
  //     "redirectUrl": "https://webhook.site/redirect-url",
  //     "redirectMode": "REDIRECT",
  //     "callbackUrl": "https://webhook.site/callback-url",
  //     "mobileNumber": "9999999999",
  //     "paymentInstrument": {
  //       "type": "PAY_PAGE"
  //     }
  //   }
  //   let objJsonStr = JSON.stringify(payload);
  //   let main_payload = Buffer.from(objJsonStr).toString("base64");//btoa(objJsonStr);
  //   let final_payload = main_payload + '/pg/v1/pay' + saltKey;
  //   const shajs = require('sha.js');
  //   let sha256 = shajs('sha256').update(final_payload).digest('hex');
  //   let checksum = sha256 + '###' + saltIndex;

  //   console.log(checksum);
  //   // const options = {
  //   //   headers: {
  //   //     'Content-Type': 'application/json',
  //   //     'accept': 'application/json',
  //   //     'X-VERIFY': checksum
  //   //   }
  //   // };
  //   const settings = {
  //     async: true,
  //     crossDomain: true,
  //     url: '/pg/v1/pay',
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'X-VERIFY': checksum
  //     },
  //     data: { request: main_payload }
  //   };
  //   $.ajax(settings)
  //     .done((response) => {
  //       // response = {
  //       //   "success": true,
  //       //   "code": "PAYMENT_INITIATED",
  //       //   "message": "Payment Initiated",
  //       //   "data": {
  //       //     "merchantId": "MERCHANTUAT",
  //       //     "merchantTransactionId": "OD620471739210623",
  //       //     "instrumentResponse": {
  //       //       "type": "UPI_INTENT",
  //       //       "intentUrl": "upi://pay?pa=MERCHANTUAT@ybl&pn=MerchantUAT&am=3.00&mam=3.00&tr=OD620471739210623&tn=Payment%20for%OD620471739210623&mc=5311&mode=04&purpose=00&utm_campaign=DEBIT&utm_medium=FKRT&utm_source=OD620471739210623"
  //       //     }
  //       //   }
  //       // }
  //       if (response.code === "PAYMENT_INITIATED") {
  //         console.log(response);
  //         console.log(response.data.instrumentResponse.redirectInfo.url);
  //         window.location.href = response.data.instrumentResponse.redirectInfo.url;
  //         this.apiService.postApi(API_ENDPOINTS.PHONE_PAY_PAYMENT_RESPONSE, {})
  //           .subscribe((res: any) => {
  //             console.log(res);
  //           });
  //         this.router.navigate(['dashboard'], {
  //           queryParams: { selectedTab: 'orders' },
  //         });
  //       }
  //     })
  //     .fail((error: any) => {
  //       // alert("error" + error.statusText);
  //       if (error.success === false)
  //         this.toaster.showError('Error', error.statusText);
  //     });
  // };

  // placeOrder = () => {
  //   // if (this.checkoutForm.invalid) {
  //   //   return;
  //   // }
  //   let payload = this.preparePayload();
  //   this.checkOutModal.isButtonDisabled = true
  //   this.apiService
  //     .postApi(
  //       API_ENDPOINTS.CHECK_OUT_ORDER,
  //       payload,
  //       {},
  //       this.commonService.isLogin()
  //     )
  //     .subscribe((res: any) => {
  //       if (res && res.Code === 5020) {
  //         this.sweetAlertService.showToast('error', res.Message);
  //         return;
  //       }
  //       if (res && res.Code === 1000) {
  //         if (this.checkOutModal.selectedPaymentMethodId === 737) {
  //           if (res && res.hasOwnProperty('Code')) {
  //             this.sweetAlertService.showToast('error', res.Message);
  //           } else {
  //             this.paytmCredentialArray = { ...res };
  //             setTimeout(() => {
  //               $('#paytmForm').submit();
  //             }, 1500);
  //           }
  //         } else if (this.checkOutModal.selectedPaymentMethodId === 746) {
  //           this.payumoneyCredential = { ...res };
  //           const data = {
  //             TXN_AMOUNT: this.payumoneyCredential.TXN_AMOUNT,
  //             CHECKSUMHASH: this.payumoneyCredential.CHECKSUMHASH,
  //             ORDER_ID: this.payumoneyCredential.ORDER_ID,
  //             ProductInfo: 'ProductInfo',
  //             // Api: API_ENDPOINTS.PAYUMONEY_RES,
  //             FirstName: this.commonService.customerProfileDetails.CustomerName,
  //             Email: this.commonService.customerProfileDetails.Email
  //               ? this.commonService.customerProfileDetails.Email
  //               : '',
  //             Mobile: ''
  //             // Type: 'PURCHASE',
  //           };
  //           this.commonService.checkOutPayumoney(data);
  //         } else if (this.checkOutModal.selectedPaymentMethodId === 1) {
  //           this.sweetAlertService.showToast('success','Order successfully placed!');
  //           this.router.navigate(['dashboard'], {
  //             queryParams: { selectedTab: 'orders' },
  //           });
  //         } else if (this.checkOutModal.selectedPaymentMethodId === 7) {
  //           console.log("checkout: ", res);

  //           // let extractedResponse = JSON.parse(res.Data);
  //           // if (res.Data && res.code === 1000) {

  //           // localStorage.setItem("merchantId", res.data.merchantId);
  //           //localStorage.setItem("merchantTransactionId", res.data.merchantTransactionId);

  //           //Redirect to phonepay....
  //           window.location.href = res.Data;
  //           // }
  //         } else if (
  //           this.isWalletSelect &&
  //           this.checkOutModal.selectedPaymentMethodId === null
  //         ) {
  //           if (res.Code === 1000) {
  //             this.router.navigate(['paytm-callback'], {
  //               queryParams: { method: 'wallet' },
  //             });
  //           } else {
  //             this.sweetAlertService.showToast('error', res.Message);
  //           }
  //         }
  //       } else {
  //         this.sweetAlertService.showToast('error', "Unable to place your order. Please try again.");
  //       }
  //     });

  //     this.checkOutModal.isButtonDisabled = false
  //   // this.initiatePaymentGateway(payload);
  // };
}
